import { Button } from "@mui/material";
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate } from "react-router-dom";
import Menu from "./Menu";

export default function BodyLogin() {
  const { keycloak } = useKeycloak();

  let navigate = useNavigate();

  function authCheck() {
    if (!keycloak.authenticated) {
      keycloak.login();
    } else changeRoute();
  }

  function changeRoute() {
    if (keycloak.authenticated && keycloak.hasRealmRole("00_Informatica")) {
      navigate("/nit");
    } else if (
      keycloak.authenticated &&
      keycloak.hasRealmRole("00_Nucleo_Estagios")
    ) {
      navigate("/ne");
    } else if (
      keycloak.authenticated &&
      keycloak.hasRealmRole("00_PresidenteJuriNe")
    ) {
      navigate("/docente");
    } else if (keycloak.authenticated && keycloak.hasRealmRole("00_Alunos")) {
      navigate("/aluno");
    } else {
      navigate("/");
    }
  }

  return (
    <div className="flex flex-col items-center pb-[3ch]">
      {keycloak.authenticated ? <Menu /> : null}
      <div className="text-[70px] font-bold leading-[124px] text-fful">
        Núcleo de Estágios
      </div>
      <img
        className="max-w-full h-[35ch] rounded-[5ch] p-[2ch]"
        src="/images/foto-fful.jpg"
        alt="FFUL"
      />
      {keycloak.authenticated ? (
        <Button
          className="button"
          variant="contained"
          type="submit"
          onClick={() => navigate("/aluno")}
          sx={{
            my: 2,
            color: "white",
            display: "block",
            backgroundColor: "#543B83",
            "&:hover": {
              backgroundColor: "#543B83",
              opacity: [0.8, 0.8, 0.8],
            },
          }}
        >
          Ver Vagas
        </Button>
      ) : (
        <Button
          className="button"
          variant="contained"
          type="submit"
          onClick={authCheck}
          sx={{
            my: 2,
            color: "white",
            display: "block",
            backgroundColor: "#543B83",
            "&:hover": {
              backgroundColor: "#543B83",
              opacity: [0.8, 0.8, 0.8],
            },
          }}
        >
          Login
        </Button>
      )}
    </div>
  );
}
