import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import "jspdf-autotable";
import { FaFileExcel } from "react-icons/fa";

function ListagemAlunos({ anoLetivo, alunos }) {
  const [vagasFarmacias, setVagasFarmacias] = useState([]);
  const [vagasHospitais, setVagasHospitais] = useState([]);
  const [trabalhos, setTrabalhos] = useState([]);
  const [farmacias, setFarmacias] = useState([]);
  const [hospitais, setHospitais] = useState([]);
  const [blocosF, setBlocosF] = useState([]);
  const [blocosH, setBlocosH] = useState([]);
  const [aluno, setAluno] = useState([]);
  const [loading, setLoading] = useState(true);

  const apiVagasFarmacias = process.env.REACT_APP_API_VAGASFARMACIAS;
  const apiFarmacias = process.env.REACT_APP_API_FARMACIAS;
  const apiBlocoF = process.env.REACT_APP_API_BLOCOF;

  const apiVagasHospitais = process.env.REACT_APP_API_VAGASHOSPITAIS;
  const apiHospitais = process.env.REACT_APP_API_HOSPITAIS;
  const apiBlocoH = process.env.REACT_APP_API_BLOCOH;

  const apiTrabalhos = process.env.REACT_APP_API_TRABALHOS;

  const apiContatoFarmacias = process.env.REACT_APP_API_CONTATOFARMACIAS;
  const apiContatoHospitais = process.env.REACT_APP_API_CONTATOHOSPITAIS;

  const apiAlunos = process.env.REACT_APP_API_ALUNOS;

  useEffect(() => {
    fetch(`${apiVagasFarmacias}/anoLetivo/${anoLetivo.anoLetivo}`)
      .then((response) => response.json())
      .then((data) => {
        setVagasFarmacias(data);
        setLoading(false);
      });
  }, []);
  useEffect(() => {
    fetch(`${apiTrabalhos}/anoLetivo/${anoLetivo.anoLetivo}`)
      .then((response) => response.json())
      .then((data) => setTrabalhos(data));
  }, []);
  useEffect(() => {
    fetch(`${apiVagasHospitais}/anoLetivo/${anoLetivo.anoLetivo}`)
      .then((response) => response.json())
      .then((data) => setVagasHospitais(data));
  }, []);
  useEffect(() => {
    fetch(`${apiFarmacias}`)
      .then((response) => response.json())
      .then((data) => setFarmacias(data));
  }, []);
  useEffect(() => {
    fetch(`${apiHospitais}`)
      .then((response) => response.json())
      .then((data) => setHospitais(data));
  }, []);
  useEffect(() => {
    fetch(`${apiBlocoF}/anoLetivo/${anoLetivo.anoLetivo}`)
      .then((response) => response.json())
      .then((data) => setBlocosF(data));
  }, []);
  useEffect(() => {
    fetch(`${apiBlocoH}/anoLetivo/${anoLetivo.anoLetivo}`)
      .then((response) => response.json())
      .then((data) => setBlocosH(data));
  }, []);

  const fetchAndCreateExcel = async (alunos) => {
    const workbook = XLSX.utils.book_new();
    const data = [];

    for (const aluno of alunos) {
      let trabalhoChoice = null;
      let vagaFarmaciaChoices = [];
      let farmaciaChoices = [];
      let blocoFChoices = [];
      let vagaHospitalChoice = null;
      let hospitalChoice = null;
      let blocoHChoice = null;

      if (aluno.idTrabalho !== null) {
        trabalhoChoice = trabalhos.find(
          (trabalho) => trabalho.idTrabalho === aluno.idTrabalho
        );
      }

      if (aluno.idAluno !== null) {
        vagaFarmaciaChoices = vagasFarmacias.filter(
          (vaga) => vaga.idAluno === aluno.idAluno
        );
        for (const vagaFarmaciaChoice of vagaFarmaciaChoices) {
          let farmaciaChoice = farmacias.find(
            (farmacia) => farmacia.idFarmacia === vagaFarmaciaChoice.idFarmacia
          );
          let blocoFChoice = blocosF.find(
            (blocoF) => blocoF.idBlocoF === vagaFarmaciaChoice.idBlocoF
          );
          farmaciaChoices.push(farmaciaChoice);
          blocoFChoices.push(blocoFChoice);
        }

        vagaHospitalChoice = vagasHospitais.find(
          (vaga) => vaga.idAluno === aluno.idAluno
        );
        if (vagaHospitalChoice !== undefined) {
          hospitalChoice = hospitais.find(
            (hospital) => hospital.idHospital === vagaHospitalChoice.idHospital
          );
          blocoHChoice = blocosH.find(
            (blocoH) => blocoH.idBlocoH === vagaHospitalChoice.idBlocoH
          );
        }
      }

      // Ensure the loop runs at least once by using a do-while loop or ensuring the array has at least one element
      const iterations =
        vagaFarmaciaChoices.length > 0 ? vagaFarmaciaChoices.length : 1;

      for (let i = 0; i < iterations; i++) {
        // Use ternary operators to handle cases where there might be no vagaFarmaciaChoices
        data.push({
          // Aluno
          nome: aluno.nome,
          numAluno: aluno.numAluno,
          email: aluno.email,
          telemovel: aluno.telemovel,
          estatuto: aluno.estatuto,
          notaFarmacia: aluno.notaFarmacia,
          dataNotaFarmacia: aluno.dataNotaFarmacia,
          notaHospital: aluno.notaHospital,
          dataNotaHospital: aluno.dataNotaHospital,
          notaErasmus: aluno.notaErasmus,
          dataNotaErasmus: aluno.dataNotaErasmus,
          notaExameEscrito: aluno.notaExameEscrito,
          dataNotaExameEscrito: aluno.dataNotaExameEscrito,
          notaTrabalho: aluno.notaTrabalho,
          dataNotaTrabalho: aluno.dataNotaTrabalho,
          notaFinal: aluno.notaFinal,
          presidente: aluno.presidente,
          arguente: aluno.arguente,
          revisaoTrabalho: aluno.revisaoTrabalho,
          // Trabalho
          tema: trabalhoChoice ? trabalhoChoice.tema : "",
          tipoTrabalho: trabalhoChoice ? trabalhoChoice.tipoTrabalho : "",
          orientador: trabalhoChoice ? trabalhoChoice.orientador : "",
          "coorientador/cotutor": trabalhoChoice
            ? trabalhoChoice.coorientador1
            : "",
          // Vaga Farmácia
          nomeFarmacia: farmaciaChoices[i] ? farmaciaChoices[i].nome : "",
          blocoF: blocoFChoices[i] ? blocoFChoices[i].bloco : "",
          dataInicioF: blocoFChoices[i] ? blocoFChoices[i].dataInicio : "",
          dataFimF: blocoFChoices[i] ? blocoFChoices[i].dataFim : "",
          // Vaga Hospital
          nomeHospital: hospitalChoice ? hospitalChoice.nome : "",
          blocoH: blocoHChoice ? blocoHChoice.bloco : "",
          dataInicioH: blocoHChoice ? blocoHChoice.dataInicio : "",
          dataFimH: blocoHChoice ? blocoHChoice.dataFim : "",
          // Observações do Aluno
          observacoes: aluno.obs,
        });
      }
    }

    //   for (let i = 0; i < vagaFarmaciaChoices.length; i++) {
    //     // Add the fetched data to the data array
    //     data.push({
    //       // Aluno
    //       nome: aluno.nome,
    //       numAluno: aluno.numAluno,
    //       email: aluno.email,
    //       telemovel: aluno.telemovel,
    //       estatuto: aluno.estatuto,
    //       notaFarmacia: aluno.notaFarmacia,
    //       dataNotaFarmacia: aluno.dataNotaFarmacia,
    //       notaHospital: aluno.notaHospital,
    //       dataNotaHospital: aluno.dataNotaHospital,
    //       notaErasmus: aluno.notaErasmus,
    //       dataNotaErasmus: aluno.dataNotaErasmus,
    //       notaExameEscrito: aluno.notaExameEscrito,
    //       dataNotaExameEscrito: aluno.dataNotaExameEscrito,
    //       notaFinal: aluno.notaFinal,
    //       presidente: aluno.presidente,
    //       arguente: aluno.arguente,
    //       revisaoTrabalho: aluno.revisaoTrabalho,
    //       // Trabalho
    //       tema: trabalhoChoice ? trabalhoChoice.tema : "",
    //       tipoTrabalho: trabalhoChoice ? trabalhoChoice.tipoTrabalho : "",
    //       orientador: trabalhoChoice ? trabalhoChoice.orientador : "",
    //       "coorientador/cotutor": trabalhoChoice
    //         ? trabalhoChoice.coorientador1
    //         : "",
    //       // Vaga Farmácia
    //       nomeFarmacia: farmaciaChoices[i] ? farmaciaChoices[i].nome : "",
    //       blocoF: blocoFChoices[i] ? blocoFChoices[i].bloco : "",
    //       dataInicioF: blocoFChoices[i] ? blocoFChoices[i].dataInicio : "",
    //       dataFimF: blocoFChoices[i] ? blocoFChoices[i].dataFim : "",
    //       // Vaga Hospital
    //       nomeHospital: hospitalChoice ? hospitalChoice.nome : "",
    //       blocoH: blocoHChoice ? blocoHChoice.bloco : "",
    //       dataInicioH: blocoHChoice ? blocoHChoice.dataInicio : "",
    //       dataFimH: blocoHChoice ? blocoHChoice.dataFim : "",
    //       // Observações do Aluno
    //       observacoes: aluno.obs,
    //     });
    //   }
    // }

    // Create a worksheet from the data array
    const worksheet = XLSX.utils.json_to_sheet(data);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Alunos");

    // Write the workbook to a file
    XLSX.writeFile(workbook, "Alunos.xlsx");
  };

  // const exportToExcel = () => {
  //   const headerData = [
  //     [
  //       "Ano Letivo " +
  //         anoLetivo?.anoLetivo +
  //         " - Vagas de Estágios Curriculares em Farmácias Comunitárias",
  //     ],
  //     [new Date().toLocaleDateString()],
  //   ];
  //   const columnNames = [Object.keys(tableData[0])];
  //   const data = tableData.map(Object.values);
  //   const combinedData = [...headerData, ...columnNames, ...data];
  //   const ws = XLSX.utils.aoa_to_sheet(combinedData);
  //   const wb = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  //   const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  //   const dataExcel = new Blob([excelBuffer], {
  //     type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
  //   });
  //   saveAs(dataExcel, "VagasFarmacias.xlsx");
  // };

  return (
    <div>
      <>
        <button
          className={`${
            loading
              ? "text-gray-400 cursor-not-allowed py-4 text-6xl"
              : "text-fful hover:text-iconhover py-4 text-6xl"
          }`}
          title="Gerar Excel"
          onClick={() => fetchAndCreateExcel(alunos)}
          disabled={loading}
        >
          <FaFileExcel />
        </button>
        {loading && (
          <p className="text-fful">
            A carregar ficheiro, espere uns segundos por favor.
          </p>
        )}
      </>
    </div>
  );
}

export default ListagemAlunos;
