import React from "react";
import {
  FaFacebookSquare,
  FaYoutube,
  FaTwitterSquare,
  FaLinkedin,
} from "react-icons/fa";

export default function Footer() {
  return (
    <div className="items-center bg-fful justify-center mt-auto">
      <div className="flex items-center bg-fful justify-center">
        <img
          src="/images/fful-footer-logo.png"
          className="max-w-full h-[20ch]"
          alt="Footer Logo"
        />
        <a
          href="https://www.ff.ulisboa.pt/contactos/"
          className="no-underline text-my_white hover:text-iconhover"
        >
          <h1 className="text-[1.2em] px-3">Contactos</h1>
        </a>
        <a
          href="https://www.ff.ulisboa.pt/ligacoes-uteis/"
          className="no-underline text-my_white hover:text-iconhover"
        >
          <h1 className="text-[1.2em] px-3">Ligações Úteis</h1>
        </a>
        <div className="flex flex-col items-center">
          <h1 className="text-[1.2em] px-3">Siga-nos</h1>
          <div className="flex hover:text-iconhover">
            <a
              href="https://www.facebook.com/faculdade.farmacia.lisboa"
              className="no-underline text-my_white hover:text-iconhover"
            >
              <FaFacebookSquare size="50px" />
            </a>
            <a
              href="https://twitter.com/facfarmaciaUL"
              className="no-underline text-my_white hover:text-iconhover"
            >
              <FaTwitterSquare size="50px" />
            </a>
            <a
              href="https://www.linkedin.com/edu/school?id=196011"
              className="no-underline text-my_white hover:text-iconhover"
            >
              <FaLinkedin size="50px" />
            </a>
            <a
              href="https://www.youtube.com/channel/UCQa0xBnc_x6tIi51Yjt07PQ"
              className="no-underline text-my_white hover:text-iconhover"
            >
              <FaYoutube size="50px" />
            </a>
          </div>
        </div>
      </div>
      <h6 className="bg-footer text-footerletra items-center content-center text-center text-[0.6em] p-[1em] m-0">
        © FACULDADE DE FARMÁCIA DA UNIVERSIDADE DE LISBOA{" "}
        {new Date().getFullYear()} |{" "}
        <a
          href="https://www.ff.ulisboa.pt/termos-de-utilizacao/"
          className="no-underline text-footerletra hover:underline hover:text-footerletra"
        >
          TERMOS DE UTILIZAÇÃO
        </a>{" "}
        |{" "}
        <a
          href="https://www.ff.ulisboa.pt/categoria/sitemap/?lang=pt"
          className="no-underline text-footerletra hover:underline hover:text-footerletra"
        >
          MAPA DO SITE
        </a>{" "}
        |{" "}
        <a
          href="https://www.ff.ulisboa.pt/ficha-tecnica/"
          className="no-underline text-footerletra hover:underline hover:text-footerletra"
        >
          FICHA TÉCNICA
        </a>
      </h6>
    </div>
  );
}
