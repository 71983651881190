import React, { useState, useEffect } from "react";
import CreateBlocoF from "../Create/CreateBlocoF";
import EditBlocoF from "../Edit/EditBlocoF";

function GerirBlocosF(anoLetivo) {
  const [blocosF, setBlocosF] = useState([]);

  const apiBlocoF = process.env.REACT_APP_API_BLOCOF;

  useEffect(() => {
    fetch(`${apiBlocoF}/anoLetivo/${anoLetivo.anoLetivo}`)
      .then((response) => response.json())
      .then((data) => setBlocosF(data));
  }, [apiBlocoF]);

  return (
    <div className="flex flex-col items-stretch min-h-full">
      <main className="flex-grow p-4">
        <div className="flex items-center mb-4">
          <CreateBlocoF key={anoLetivo} anoLetivo={anoLetivo.anoLetivo} />
        </div>
        <table className="table-auto w-full">
          <thead>
            <tr>
              <th className="px-4 py-2">ID</th>
              <th className="px-4 py-2">Bloco</th>
              <th className="px-4 py-2">Data Início</th>
              <th className="px-4 py-2">Data Fim</th>
              <th className="px-4 py-2">Ações</th>
            </tr>
          </thead>
          <tbody>
            {blocosF.map((bloco) => (
              <tr key={bloco.idBlocoF}>
                <td className="border px-4 py-2">{bloco.idBlocoF}</td>
                <td className="border px-4 py-2">{bloco.bloco}</td>
                <td className="border px-4 py-2">{bloco.dataInicio}</td>
                <td className="border px-4 py-2">{bloco.dataFim}</td>
                <td className="border px-4 py-2">
                  <div>
                    <EditBlocoF blocoData={bloco} />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </main>
    </div>
  );
}

export default GerirBlocosF;
