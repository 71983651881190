import React, { useState, useEffect } from "react";

function GerirLogsDocentes(anoLetivo) {
  const [logs, setLogs] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const apiLogs = process.env.REACT_APP_API_LOGS;

  useEffect(() => {
    fetch(`${apiLogs}/anoLetivo/${anoLetivo.anoLetivo}`)
      .then((response) => response.json())
      .then((data) => setLogs(data));
  }, [apiLogs]);

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Function to remove accents from a string
  function removeAccents(str) {
    const accents = "ÀÁÂÃÄÅàáâãäåÒÓÔÕÖØòóôõöøÈÉÊËèéêëÇçÌÍÎÏìíîïÙÚÛÜùúûüÿÑñ";
    const accentsOut = "AAAAAAaaaaaaOOOOOOooooooEEEEeeeeCcIIIIiiiiUUUUuuuuyNn";
    return str
      .split("")
      .map((letter) => {
        const accentIndex = accents.indexOf(letter);
        return accentIndex !== -1 ? accentsOut[accentIndex] : letter;
      })
      .join("");
  }

  function filteredLogs() {
    const searchTermLowerCase = removeAccents(searchTerm.toLowerCase());
    const filtered = logs.filter((log) => {
      const idLogMatch = log.idLog?.toString().includes(searchTerm);
      const changesLowerCase = removeAccents(log.changes.toLowerCase());
      const authorLowerCase = removeAccents(log.author.toLowerCase());
      const dateLowerCase = removeAccents(log.date.toLowerCase());
      const numAlunoMatch = log.numAluno?.toString().includes(searchTerm);
      const matchesSearchTerm =
        idLogMatch ||
        changesLowerCase.includes(searchTermLowerCase) ||
        authorLowerCase.includes(searchTermLowerCase) ||
        dateLowerCase.includes(searchTermLowerCase) ||
        numAlunoMatch;
      return matchesSearchTerm;
    });
    return Array.isArray(filtered) ? filtered : [filtered];
  }

  const filteredLogsArray = Array.isArray(filteredLogs()) ? filteredLogs() : [];

  return (
    <div className="flex flex-col items-stretch min-h-full">
      <main className="flex-grow p-4">
        <div>
          <input
            type="text"
            placeholder="Pesquisar"
            value={searchTerm}
            onChange={handleSearchTermChange}
            className="border border-gray-400 rounded px-2 py-1 mb-3"
          />
        </div>
        <table className="table-auto w-full">
          <thead>
            <tr>
              <th className="px-4 py-2">ID</th>
              <th className="px-4 py-2">Alteração</th>
              <th className="px-4 py-2">Autor</th>
              <th className="px-4 py-2">Data </th>
              <th className="px-4 py-2">Nº Aluno</th>
            </tr>
          </thead>
          <tbody>
            {filteredLogsArray.map((log) => (
              <tr key={log.idLog}>
                <td className="border px-4 py-2">{log.idLog}</td>
                <td className="border px-4 py-2">{log.changes}</td>
                <td className="border px-4 py-2">{log.author}</td>
                <td className="border px-4 py-2">{log.date}</td>
                <td className="border px-4 py-2">{log.numAluno}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </main>
    </div>
  );
}

export default GerirLogsDocentes;
