import React, { useState } from "react";
import { FaFolder } from "react-icons/fa";
import ShowVagasFarmacias from "../Show/ShowVagasFarmacias";
import ShowVagasHospitais from "../Show/ShowVagasHospitais";
import ShowTrabalhos from "../Show/ShowTrabalhos";
import {
  Modal as BootstrapModal,
  Row,
  Form,
  Col,
  Button,
} from "react-bootstrap";
import { ImCancelCircle } from "react-icons/im";
import { MdEmail } from "react-icons/md";
import Modal from "react-modal";
import jsPDF from "jspdf";

Modal.setAppElement("#root");

function AlocarVaga({ alunoData, onChildUpdate, anoLetivo }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [showVagasFarmacias, setShowVagasFarmacias] = useState(false);
  const [showVagasHospitais, setShowVagasHospitais] = useState(false);
  const [showTrabalhos, setShowTrabalhos] = useState(false);
  const [editedAlunoData, setEditedAlunoData] = useState(alunoData);
  const [idVagaFarmacia, setIdVagaFarmacia] = useState("");
  const [idVagaHospital, setIdVagaHospital] = useState("");
  const [vagaFarmaciaData, setVagaFarmaciaData] = useState("");
  const [vagaHospitalData, setVagaHospitalData] = useState("");
  const [showVagaFarmaciaModal, setShowVagaFarmaciaModal] = useState(false);
  const [showVagaHospitalModal, setShowVagaHospitalModal] = useState(false);
  const [farmacias, setFarmacias] = useState([]);
  const [alunos, setAlunos] = useState([]);
  const [blocosF, setBlocosF] = useState([]);
  const [hospitais, setHospitais] = useState([]);
  const [blocosH, setBlocosH] = useState([]);
  const [emailAluno, setEmailAluno] = useState(editedAlunoData.email);
  const [emailFarmacia, setEmailFarmacia] = useState();
  const [emailHospital, setEmailHospital] = useState();
  const [vagaFarmaciaChoice, setVagaFarmaciaChoice] = useState(null);
  const [vagaHospitalChoice, setVagaHospitalChoice] = useState(null);
  const [trabalhoChoice, setTrabalhoChoice] = useState(null);
  const [farmaciaData, setFarmaciaData] = useState({});
  const [hospitalData, setHospitalData] = useState({});
  const [blocoFData, setBlocoFData] = useState({});
  const [blocoHData, setBlocoHData] = useState({});
  const [selectedVagas, setSelectedVagas] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showModalTrabalho, setShowModalTrabalho] = useState(false);

  const apiVagasFarmacias = process.env.REACT_APP_API_VAGASFARMACIAS;
  const apiFarmacias = process.env.REACT_APP_API_FARMACIAS;
  const apiAlunos = process.env.REACT_APP_API_ALUNOS;
  const apiBlocoF = process.env.REACT_APP_API_BLOCOF;
  const apiVagasHospitais = process.env.REACT_APP_API_VAGASHOSPITAIS;
  const apiHospitais = process.env.REACT_APP_API_HOSPITAIS;
  const apiBlocoH = process.env.REACT_APP_API_BLOCOH;
  const apiContatoFarmacias = process.env.REACT_APP_API_CONTATOFARMACIAS;
  const apiContatoHospitais = process.env.REACT_APP_API_CONTATOHOSPITAIS;
  const apiTrabalhos = process.env.REACT_APP_API_TRABALHOS;
  const apiEmail = process.env.REACT_APP_API_EMAIL;

  const INITIAL_STATE = {
    anoLetivo: anoLetivo.anoLetivo,
    tema: "Trabalho de Erasmus: " + editedAlunoData.numAluno,
    nVagas: "1",
    nVagasInicial: "1",
    tipoTrabalho: "ERASMUS",
    orientador: "",
    coorientador1: "",
    coorientador2: "",
    cotutor: "",
    origem: "",
    obs: "",
    visivel: true,
  };

  const handleReset = () => {
    setNewTrabalhoData(INITIAL_STATE);
  };

  const [newTrabalhoData, setNewTrabalhoData] = useState(INITIAL_STATE);

  const handleCreateTrabalhoErasmus = async (e) => {
    fetch(`${apiTrabalhos}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newTrabalhoData),
    })
      .then((response) => response.json())
      .then((data) => {
        setShowModalTrabalho(false);
        handleReset();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleSearchVagaFarmaciaById = (id) => {
    fetch(`${apiVagasFarmacias}/${id}`)
      .then((response) => response.json())
      .then((data) => {
        const vagaFarmacia = data;
        if (vagaFarmacia) {
          setVagaFarmaciaData(vagaFarmacia);
          const farmaciaIds = vagaFarmacia.idFarmacia;
          const alunoIds = vagaFarmacia.idAluno;
          const blocoFIds = vagaFarmacia.idBlocoF;
          Promise.all([
            farmaciaIds ? fetch(`${apiFarmacias}/${farmaciaIds}`) : "",
            alunoIds ? fetch(`${apiAlunos}/${alunoIds}`) : "",
            blocoFIds ? fetch(`${apiBlocoF}/${blocoFIds}`) : "",
          ])
            .then((responses) =>
              Promise.all(responses.map((res) => res && res.json()))
            )
            .then(([farmacia, aluno, blocoF]) => {
              setFarmacias(farmacia);
              setAlunos(aluno);
              setBlocosF(blocoF);
            });
        }
      })
      .catch((error) => console.log(error));
  };

  const handleSearchVagaHospitalById = (id) => {
    fetch(`${apiVagasHospitais}/${id}`)
      .then((response) => response.json())
      .then((data) => {
        const vagaHospital = data;
        if (vagaHospital) {
          setVagaHospitalData(vagaHospital);
          const hospitalIds = vagaHospital.idHospital;
          const alunoIds = vagaHospital.idAluno;
          const blocoHIds = vagaHospital.idBlocoH;
          Promise.all([
            hospitalIds ? fetch(`${apiHospitais}/${hospitalIds}`) : "",
            alunoIds ? fetch(`${apiAlunos}/${alunoIds}`) : "",
            blocoHIds ? fetch(`${apiBlocoH}/${blocoHIds}`) : "",
          ])
            .then((responses) =>
              Promise.all(responses.map((res) => res && res.json()))
            )
            .then(([hospital, aluno, blocoH]) => {
              setHospitais(hospital);
              setAlunos(aluno);
              setBlocosH(blocoH);
            });
        }
      })
      .catch((error) => console.log(error));
  };

  const handleAlocarVagaFarmacia = (id) => {
    handleSearchVagaFarmaciaById(id);
    setShowVagaFarmaciaModal(true);
  };

  const handleAlocarVagaHospital = (id) => {
    handleSearchVagaHospitalById(id);
    setShowVagaHospitalModal(true);
  };

  const handleConfirmVagaFarmacia = () => {
    handleEditVagaFarmacia();
    setShowVagaFarmaciaModal(false);
  };

  const handleCancelVagaFarmacia = () => {
    setIdVagaFarmacia("");
    setShowVagaFarmaciaModal(false);
  };

  const handleConfirmVagaHospital = () => {
    handleEditVagaHospital();
    setShowVagaHospitalModal(false);
  };

  const handleCancelVagaHospital = () => {
    setIdVagaHospital("");
    setShowVagaHospitalModal(false);
  };

  const handleEditVagaFarmacia = () => {
    const editedVagaFarmacia = {
      ...vagaFarmaciaData,
      idAluno: editedAlunoData.idAluno,
      disponivel: false,
    };
    fetch(`${apiVagasFarmacias}/${vagaFarmaciaData.idVagaFarmacia}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(editedVagaFarmacia),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.text();
      })
      .then((data) => {
        console.log(data);
        setIdVagaFarmacia("");
        setVagaFarmaciaData({});
        setFarmacias([]);
        setAlunos([]);
        setBlocosF([]);
      })
      .catch((error) => console.log(error));
  };

  const handleEditVagaHospital = () => {
    const editedVagaHospital = {
      ...vagaHospitalData,
      idAluno: editedAlunoData.idAluno,
      disponivel: false,
    };
    fetch(`${apiVagasHospitais}/${vagaHospitalData.idVagaHospital}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(editedVagaHospital),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.text();
      })
      .then((data) => {
        console.log(data);
        setIdVagaHospital("");
        setVagaHospitalData({});
        setHospitais([]);
        setAlunos([]);
        setBlocosH([]);
      })
      .catch((error) => console.log(error));
  };

  function getCurrentAcademicYear() {
    const today = new Date();
    const currentYear = today.getFullYear();
    const academicYearStartMonth = 8;
    if (today.getMonth() >= academicYearStartMonth) {
      return `${currentYear}-${currentYear + 1}`;
    } else {
      return `${currentYear - 1}-${currentYear}`;
    }
  }

  const handleCreateVagaHospital = (id) => {
    const newVagaHospital = {
      anoLetivo: getCurrentAcademicYear(),
      disponivel: true,
      idHospital: id,
      idAluno: null,
      idBlocoH: null,
      obs: "",
    };
    fetch(apiVagasHospitais, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newVagaHospital),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        setIdVagaHospital(data);
      })
      .catch((error) => console.log(error));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedAlunoData((prevEditedAlunoData) => ({
      ...prevEditedAlunoData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    fetch(`${apiAlunos}/${editedAlunoData.idAluno}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(editedAlunoData),
    })
      .then((response) => response.json())
      .then((data) => {
        setModalIsOpen(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const fetchData = async () => {
    const fetchPromises = [];

    if (editedAlunoData.idTrabalho !== null) {
      fetchPromises.push(
        fetch(`${apiTrabalhos}/${editedAlunoData.idTrabalho}`)
      );
    }

    if (editedAlunoData.idAluno !== null) {
      fetchPromises.push(
        fetch(
          `${apiVagasFarmacias}/anoLetivo/${anoLetivo.anoLetivo}/aluno/${editedAlunoData.idAluno}`
        ),
        fetch(
          `${apiVagasHospitais}/anoLetivo/${anoLetivo.anoLetivo}/aluno/${editedAlunoData.idAluno}`
        )
      );
    }

    if (fetchPromises.length > 0) {
      const responses = await Promise.all(fetchPromises);
      const data = await Promise.all(responses.map((res) => res && res.json()));

      let trabalhoChoiceDecoy, vagaFarmaciaChoiceDecoy, vagaHospitalChoiceDecoy;

      if (editedAlunoData.idTrabalho !== null) {
        trabalhoChoiceDecoy = data.shift();
        setTrabalhoChoice(trabalhoChoiceDecoy);
      }

      if (editedAlunoData.idAluno !== null) {
        vagaFarmaciaChoiceDecoy = data.shift();
        vagaHospitalChoiceDecoy = data.shift();
        setVagaFarmaciaChoice(vagaFarmaciaChoiceDecoy[0]);
        setVagaHospitalChoice(vagaHospitalChoiceDecoy[0]);

        if (vagaFarmaciaChoiceDecoy[0]) {
          const farmaciaResponses = await Promise.all([
            fetch(`${apiFarmacias}/${vagaFarmaciaChoiceDecoy[0].idFarmacia}`),
            fetch(`${apiBlocoF}/${vagaFarmaciaChoiceDecoy[0].idBlocoF}`),
          ]);
          const [farmaciaData, blocoFData] = await Promise.all(
            farmaciaResponses.map((res) => res.json())
          );
          const vagaFarmaciaChoice = vagaFarmaciaChoiceDecoy[0];
          setFarmaciaData(farmaciaData);
          setBlocoFData(blocoFData);
          setSelectedVagas((prevSelectedVagas) => [
            ...prevSelectedVagas,
            { vagaFarmaciaChoice, farmaciaData, blocoFData },
          ]);
        }
        if (vagaFarmaciaChoiceDecoy[1]) {
          const farmaciaResponses = await Promise.all([
            fetch(`${apiFarmacias}/${vagaFarmaciaChoiceDecoy[1].idFarmacia}`),
            fetch(`${apiBlocoF}/${vagaFarmaciaChoiceDecoy[1].idBlocoF}`),
          ]);
          const [farmaciaData, blocoFData] = await Promise.all(
            farmaciaResponses.map((res) => res.json())
          );
          const vagaFarmaciaChoice = vagaFarmaciaChoiceDecoy[1];
          setFarmaciaData(farmaciaData);
          setBlocoFData(blocoFData);
          setSelectedVagas((prevSelectedVagas) => [
            ...prevSelectedVagas,
            { vagaFarmaciaChoice, farmaciaData, blocoFData },
          ]);
        }

        if (vagaHospitalChoiceDecoy[0]) {
          const hospitalResponses = await Promise.all([
            fetch(`${apiHospitais}/${vagaHospitalChoiceDecoy[0].idHospital}`),
            fetch(`${apiBlocoH}/${vagaHospitalChoiceDecoy[0].idBlocoH}`),
          ]);
          const [hospitalData, blocoData] = await Promise.all(
            hospitalResponses.map((res) => res.json())
          );
          setHospitalData(hospitalData);
          setBlocoHData(blocoData);
        }
      }
    }
  };

  const handleVagaSelection = (
    vagaFarmaciaChoice,
    farmaciaData,
    blocoFData
  ) => {
    const isSelected = selectedVagas.some(
      (vaga) =>
        vaga.vagaFarmaciaChoice.idVagaFarmacia ===
        vagaFarmaciaChoice.idVagaFarmacia
    );

    if (isSelected) {
      // If the Vaga is selected, remove it (deselect)
      setSelectedVagas((prevSelectedVagas) =>
        prevSelectedVagas.filter(
          (vaga) =>
            vaga.vagaFarmaciaChoice.idVagaFarmacia !==
            vagaFarmaciaChoice.idVagaFarmacia
        )
      );
    } else {
      // If the Vaga is not selected, add it to the list
      setSelectedVagas((prevSelectedVagas) => [
        ...prevSelectedVagas,
        { vagaFarmaciaChoice, farmaciaData, blocoFData },
      ]);
    }
  };

  const handleOpenAlocar = () => {
    setModalIsOpen(true);
    fetchData();
  };

  const handleCloseAlocar = () => {
    handleSubmit();
    setSelectedVagas([]);
  };

  const handleSendEmail = () => {
    setShowConfirmationModal(true);
  };

  const handleCancelSend = () => {
    setShowConfirmationModal(false);
  };

  const handleCreateTrabalho = () => {
    setShowModalTrabalho(true);
  };

  const handleCancelCreateTrabalho = () => {
    setShowModalTrabalho(false);
  };

  const handleConfirmSend = () => {
    // Load the image
    let img = new Image();
    img.src = "/images/fful-header-logo.png";
    img.onload = function () {
      let dataURI = getBase64Image(img);
      const pdfBase64 = generatePDF(dataURI);
      sendEmail(pdfBase64);
    };
  };

  const handleConfirmCreateTrabalho = () => {
    handleCreateTrabalhoErasmus();
  };

  function getBase64Image(img) {
    let canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    let ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, img.width, img.height);
    let dataURL = canvas.toDataURL("image/png");
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }

  const sendEmail = (pdfBase64) => {
    fetch(`${apiEmail}/vagas-aluno`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ pdf: pdfBase64, email: emailAluno }),
    })
      .then((response) => {
        if (response.ok) {
          setShowConfirmationModal(false);
        } else {
          throw new Error("Failed to send email");
        }
        return response.json();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const generatePDF = (imgData) => {
    const pdf = new jsPDF();

    // Add a colored header
    pdf.setFillColor(84, 59, 131); // RGB color for #543b83
    pdf.rect(0, 0, pdf.internal.pageSize.getWidth(), 30, "F");

    // Add the image to the left of the title
    pdf.addImage(imgData, "PNG", 5, 0, 30, 30); // Adjust position and size as needed

    // Add the main title
    pdf.setTextColor(255, 255, 255);
    let title = "Escolhas de vagas - ano letivo " + anoLetivo.anoLetivo;
    let fontSize = 18; // or whatever size you want
    pdf.setFontSize(fontSize);

    let pageWidth = pdf.internal.pageSize.getWidth();
    let txtWidth =
      (pdf.getStringUnitWidth(title) * fontSize) / pdf.internal.scaleFactor;
    let x = (pageWidth - txtWidth) / 2;

    pdf.text(title, x, 20);
    pdf.setTextColor(0, 0, 0);

    // Add the text fields
    pdf.setFontSize(10); // Set the font size for the text fields
    pdf.text("Número de aluno: " + editedAlunoData.numAluno, 20, 40);
    pdf.text("Nome: " + editedAlunoData.nome, 20, 50);
    pdf.text("Email: " + editedAlunoData.email, 20, 60);
    // Split the observations text into lines that fit within a width of 160 units
    var obsLines = pdf.splitTextToSize(
      "Observações: " + editedAlunoData.obs,
      160
    );

    // Draw the observation lines starting at position 20, 70
    pdf.text(obsLines, 20, 70);

    // Function to add a table to the PDF
    const addTableToPDF = (data, columns, startY, title) => {
      // Add title
      pdf.setFontSize(14);
      pdf.text(title, 20, startY);

      // Add table below the title
      pdf.autoTable({
        head: [columns],
        body: data,
        startY: startY + 5, // Start the table 10 units below the title
        headStyles: { fillColor: [84, 59, 131] },
      });

      // Return the Y position for the next table
      return pdf.autoTable.previous.finalY + 20; // Add some space between tables
    };

    // Y position for the first table
    let startY = 90;

    // Table 1: selectedVagas
    if (selectedVagas.length > 0) {
      const selectedVagasData = selectedVagas.map((vaga) => [
        vaga.vagaFarmaciaChoice?.idVagaFarmacia || "",
        vaga.farmaciaData?.nome || "",
        vaga.farmaciaData?.codPostal || "",
        vaga.blocoFData?.bloco || "",
        vaga.blocoFData?.dataInicio || "",
        vaga.blocoFData?.dataFim || "",
      ]);
      const selectedVagasColumns = [
        "Id",
        "Farmácia",
        "Código Postal",
        "Bloco",
        "Data Início",
        "Data Fim",
      ];
      addTableToPDF(
        selectedVagasData,
        selectedVagasColumns,
        startY,
        "Vaga de estágio curricular em Farmácia Comunitária"
      );
      startY = pdf.previousAutoTable.finalY + 20; // Set Y position for the next table
    }

    // Table 2: vagaHospitalChoice
    if (vagaHospitalChoice) {
      const vagaHospitalData = [
        [
          vagaHospitalChoice.idVagaHospital || "",
          hospitalData?.nome || "",
          hospitalData?.codPostal || "",
          blocoHData?.bloco || "",
          blocoHData?.dataInicio || "",
          blocoHData?.dataFim || "",
        ],
      ];
      const vagaHospitalColumns = [
        "Id",
        "Hospital",
        "Código Postal",
        "Bloco",
        "Data Início",
        "Data Fim",
      ];
      addTableToPDF(
        vagaHospitalData,
        vagaHospitalColumns,
        startY,
        "Vaga de estágio curricular em Farmácia Hospitalar"
      );
      startY = pdf.previousAutoTable.finalY + 20; // Set Y position for the next table
    }

    // Table 3: trabalhoChoice
    if (trabalhoChoice) {
      const trabalhoData = [
        [
          trabalhoChoice.idTrabalho || "",
          trabalhoChoice.tema || "",
          trabalhoChoice.orientador || "",
          trabalhoChoice.coorientador1 || "",
          trabalhoChoice.tipoTrabalho || "",
        ],
      ];
      const trabalhoColumns = [
        "Id",
        "Tema",
        "Orientador",
        "Co-orientador",
        "Tipo",
      ];
      addTableToPDF(trabalhoData, trabalhoColumns, startY, "Vaga de Trabalho");
      // startY = pdf.previousAutoTable.finalY + 10; // Optional: add space after the last table
    }

    // Add the date at the bottom left corner
    pdf.setFontSize(8);
    let date = new Date().toLocaleDateString();
    pdf.text(date, 10, pdf.internal.pageSize.getHeight() - 10);

    // Save the PDF
    // pdf.save("vagas_escolhidas.pdf");
    // Save the PDF as a Base64 string
    const pdfBase64String = pdf.output("datauristring");

    // Remove the 'data:application/pdf;filename=generated.pdf;base64,' part
    const base64 = pdfBase64String.replace(
      "data:application/pdf;filename=generated.pdf;base64,",
      ""
    );

    return base64;
  };

  return (
    <>
      <button
        className="text-purple-700 mx-1"
        title="Alocar Vaga"
        onClick={handleOpenAlocar}
      >
        <FaFolder />
      </button>
      <div>
        <BootstrapModal show={showConfirmationModal} centered>
          <BootstrapModal.Body>
            Tem a certeza que pretende enviar o email para o Aluno com as
            escolhas efetuadas?
          </BootstrapModal.Body>
          <BootstrapModal.Footer>
            <Button variant="danger" onClick={handleConfirmSend}>
              Sim
            </Button>
            <Button variant="secondary" onClick={handleCancelSend}>
              Não
            </Button>
          </BootstrapModal.Footer>
        </BootstrapModal>
      </div>
      <div>
        <BootstrapModal show={showModalTrabalho} centered>
          <BootstrapModal.Body>
            Tem a certeza que pretende criar uma vaga de trabalho erasmus?
          </BootstrapModal.Body>
          <BootstrapModal.Footer>
            <Button variant="danger" onClick={handleConfirmCreateTrabalho}>
              Sim
            </Button>
            <Button variant="secondary" onClick={handleCancelCreateTrabalho}>
              Não
            </Button>
          </BootstrapModal.Footer>
        </BootstrapModal>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={{ content: { maxWidth: "0%", maxHeight: "0%" } }}
      >
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg p-6 md:p-8 w-auto min-w-custom max-h-screen overflow-y-scroll">
            <h2 className="text-lg font-semibold mb-4 bg-fful px-4 py-2 rounded-lg text-white">
              Alocar vaga
            </h2>
            <Row className="mb-4 d-flex align-items-center">
              <Form.Group as={Col} md={2}>
                <Form.Label className="font-bold">Número do Aluno</Form.Label>
                <Form.Control
                  type="text"
                  value={editedAlunoData.numAluno}
                  readOnly
                  plaintext
                  className="!bg-gray-200 rounded"
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label className="font-bold">Nome</Form.Label>
                <Form.Control
                  type="text"
                  value={editedAlunoData.nome}
                  readOnly
                  plaintext
                  className="!bg-gray-200 rounded"
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label className="font-bold">E-mail</Form.Label>
                <Form.Control
                  type="email"
                  value={editedAlunoData.email}
                  readOnly
                  plaintext
                  className="!bg-gray-200 rounded"
                />
              </Form.Group>
            </Row>
            <Row className="mb-4 d-flex align-items-center">
              <Form.Group as={Col}>
                <Form.Label className="font-bold">
                  Observações do Aluno/a
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  name="obs"
                  value={editedAlunoData.obs}
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>
            <div className="flex-col">
              <div className="px-4 py-2">
                <div className="flex items-center space-x-4">
                  <button
                    className="bg-fful hover:bg-iconhover text-white font-bold py-2 px-4 rounded"
                    onClick={() => setShowVagasFarmacias(!showVagasFarmacias)}
                  >
                    {showVagasFarmacias
                      ? "Fechar Vagas Farmácias"
                      : "Ver Vagas Farmácias"}
                  </button>
                  <div>
                    {selectedVagas.length > 0 && (
                      <table className="table-auto w-full">
                        <thead>
                          <tr>
                            <th>Id</th>
                            <th>Farmácia</th>
                            <th>Código Postal</th>
                            <th>Bloco</th>
                            <th>Data Início</th>
                            <th>Data Fim</th>
                          </tr>
                        </thead>
                        <tbody>
                          {selectedVagas.map((vaga, index) => (
                            <tr key={index}>
                              <td className="border px-4 py-2">
                                {vaga.vagaFarmaciaChoice &&
                                vaga.vagaFarmaciaChoice.idVagaFarmacia
                                  ? vaga.vagaFarmaciaChoice.idVagaFarmacia
                                  : ""}
                              </td>
                              <td className="border px-4 py-2">
                                {vaga.farmaciaData && vaga.farmaciaData.nome
                                  ? vaga.farmaciaData.nome
                                  : ""}
                              </td>
                              <td className="border px-4 py-2">
                                {vaga.farmaciaData &&
                                vaga.farmaciaData.codPostal
                                  ? vaga.farmaciaData.codPostal
                                  : ""}
                              </td>
                              <td className="border px-4 py-2">
                                {vaga.blocoFData && vaga.blocoFData.bloco
                                  ? vaga.blocoFData.bloco
                                  : ""}
                              </td>
                              <td className="border px-4 py-2">
                                {vaga.blocoFData && vaga.blocoFData.dataInicio
                                  ? vaga.blocoFData.dataInicio
                                  : ""}
                              </td>
                              <td className="border px-4 py-2">
                                {vaga.blocoFData && vaga.blocoFData.dataFim
                                  ? vaga.blocoFData.dataFim
                                  : ""}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
                {showVagasFarmacias && (
                  <ShowVagasFarmacias
                    editedAlunoData={editedAlunoData}
                    onChoiceMade={handleVagaSelection}
                    // setFarmaciaData={setFarmaciaData}
                    // setBlocoFData={setBlocoFData}
                  />
                )}
              </div>
              {/* <div>
                    {vagaFarmaciaChoice && (
                      <table className="table-auto w-full">
                        <thead>
                          <tr>
                            <th>Id</th>
                            <th>Farmácia</th>
                            <th>Código Postal</th>
                            <th>Bloco</th>
                            <th>Data Início</th>
                            <th>Data Fim</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="border px-4 py-2">
                              {vagaFarmaciaChoice.idVagaFarmacia === null
                                ? ""
                                : vagaFarmaciaChoice.idVagaFarmacia}
                            </td>
                            <td className="border px-4 py-2">
                              {farmaciaData && farmaciaData.nome
                                ? farmaciaData.nome
                                : ""}
                            </td>
                            <td className="border px-4 py-2">
                              {farmaciaData && farmaciaData.codPostal
                                ? farmaciaData.codPostal
                                : ""}
                            </td>
                            <td className="border px-4 py-2">
                              {blocoFData && blocoFData.bloco
                                ? blocoFData.bloco
                                : ""}
                            </td>
                            <td className="border px-4 py-2">
                              {blocoFData && blocoFData.dataInicio
                                ? blocoFData.dataInicio
                                : ""}
                            </td>
                            <td className="border px-4 py-2">
                              {blocoFData && blocoFData.dataFim
                                ? blocoFData.dataFim
                                : ""}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
                {showVagasFarmacias && (
                  <ShowVagasFarmacias
                    editedAlunoData={editedAlunoData}
                    onChoiceMade={setVagaFarmaciaChoice}
                    setFarmaciaData={setFarmaciaData}
                    setBlocoFData={setBlocoFData}
                  />
                )}
              </div> */}
              <div className="px-4 py-2">
                <div className="flex items-center space-x-4">
                  <button
                    className="bg-fful hover:bg-iconhover text-white font-bold py-2 px-4 rounded"
                    onClick={() => setShowVagasHospitais(!showVagasHospitais)}
                  >
                    {showVagasHospitais
                      ? "Fechar Vagas Hospitais"
                      : "Ver Vagas Hospitais"}
                  </button>
                  <div>
                    {vagaHospitalChoice && (
                      <table className="table-auto w-full">
                        <thead>
                          <tr>
                            <th>Id</th>
                            <th>Hospital</th>
                            <th>Código Postal</th>
                            <th>Bloco</th>
                            <th>Data Início</th>
                            <th>Data Fim</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="border px-4 py-2">
                              {vagaHospitalChoice.idVagaHospital === null
                                ? ""
                                : vagaHospitalChoice.idVagaHospital}
                            </td>
                            <td className="border px-4 py-2">
                              {hospitalData && hospitalData.nome
                                ? hospitalData.nome
                                : ""}
                            </td>
                            <td className="border px-4 py-2">
                              {hospitalData && hospitalData.codPostal
                                ? hospitalData.codPostal
                                : ""}
                            </td>
                            <td className="border px-4 py-2">
                              {blocoHData && blocoHData.bloco
                                ? blocoHData.bloco
                                : ""}
                            </td>
                            <td className="border px-4 py-2">
                              {blocoHData && blocoHData.dataInicio
                                ? blocoHData.dataInicio
                                : ""}
                            </td>
                            <td className="border px-4 py-2">
                              {blocoHData && blocoHData.dataFim
                                ? blocoHData.dataFim
                                : ""}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
                {showVagasHospitais && (
                  <ShowVagasHospitais
                    editedAlunoData={editedAlunoData}
                    onChoiceMade={setVagaHospitalChoice}
                    setHospitalData={setHospitalData}
                    setBlocoHData={setBlocoHData}
                  />
                )}
              </div>
              <div className="px-4 py-2">
                <div className="flex items-center space-x-4">
                  <button
                    className="bg-fful hover:bg-iconhover text-white font-bold py-2 px-4 rounded"
                    onClick={() => setShowTrabalhos(!showTrabalhos)}
                  >
                    {showTrabalhos ? "Fechar Trabalhos" : "Ver Trabalhos"}
                  </button>
                  <div>
                    {trabalhoChoice && (
                      <table className="table-auto w-full ml-12">
                        <thead>
                          <tr>
                            <th>Id</th>
                            <th>Tema</th>
                            <th>Orientador</th>
                            <th>Co-orientador</th>
                            <th>Tipo</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="border px-4 py-2">
                              {trabalhoChoice.idTrabalho === null
                                ? ""
                                : trabalhoChoice.idTrabalho}
                            </td>
                            <td className="border px-4 py-2">
                              {trabalhoChoice.tema === null
                                ? ""
                                : trabalhoChoice.tema}
                            </td>
                            <td className="border px-4 py-2">
                              {trabalhoChoice.orientador === null
                                ? ""
                                : trabalhoChoice.orientador}
                            </td>
                            <td className="border px-4 py-2">
                              {trabalhoChoice.coorientador1 === null
                                ? ""
                                : trabalhoChoice.coorientador1}
                            </td>
                            <td className="border px-4 py-2">
                              {trabalhoChoice.tipoTrabalho === null
                                ? ""
                                : trabalhoChoice.tipoTrabalho}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
                {showTrabalhos && (
                  <ShowTrabalhos
                    anoLetivo={anoLetivo.anoLetivo}
                    editedAlunoData={editedAlunoData}
                    setEditedAlunoData={setEditedAlunoData}
                    onChildUpdate={onChildUpdate}
                    onChoiceMade={setTrabalhoChoice}
                  />
                )}
              </div>
            </div>
            <div>
              <button
                className="text-fful hover:text-iconhover font-bold py-2 px-4 rounded"
                onClick={() => handleCreateTrabalho()}
              >
                Criar Vaga de Trabalho Erasmus
              </button>
            </div>
            <div className="py-4 flex justify-between">
              <button
                type="button"
                className="text-white px-4 py-2 mx-2 rounded-md bg-fful hover:bg-iconhover"
                onClick={() => handleCloseAlocar()}
              >
                <ImCancelCircle />
              </button>
              <button
                type="submit"
                className="text-white px-4 py-2 rounded-md bg-fful hover:bg-iconhover"
                onClick={() => handleSendEmail()}
              >
                <MdEmail />
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
export default AlocarVaga;
