import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import "jspdf-autotable";
import { FaFileExcel } from "react-icons/fa";

function ListagemHospitais({
  anoLetivo,
  hospitais,
  contatosHospitais,
  loading,
}) {
  const fetchAndCreateExcel = async (hospitais) => {
    const workbook = XLSX.utils.book_new();
    const data = [];

    const visibleHospitais = hospitais.filter(
      (hospital) => hospital.visivel === true
    );

    for (const hospital of visibleHospitais) {
      let contatoChoice = null;

      if (hospital.idHospital !== null) {
        contatoChoice = contatosHospitais.find(
          (contato) => contato.idHospital === hospital.idHospital
        );
      }
      // Add the fetched data to the data array
      data.push({
        // Hospital
        nomeHospital: hospital.nome,
        idHospital: hospital.idHospital,
        // Contato Hospital
        diretor: contatoChoice ? contatoChoice.nome : "",
        email: contatoChoice ? contatoChoice.email : "",
        emailEstagios: contatoChoice ? contatoChoice.emailEstagios : "",
        emailAlternativo: contatoChoice ? contatoChoice.emailAlternativo : "",
        telefone: contatoChoice ? contatoChoice.telefone : "",
        telemovel: contatoChoice ? contatoChoice.telemovel : "",
      });
    }

    // Create a worksheet from the data array
    const worksheet = XLSX.utils.json_to_sheet(data);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Hospitais");

    // Write the workbook to a file
    XLSX.writeFile(workbook, "Hospitais.xlsx");
  };

  // const exportToExcel = () => {
  //   const headerData = [
  //     [
  //       "Ano Letivo " +
  //         anoLetivo?.anoLetivo +
  //         " - Vagas de Estágios Curriculares em Farmácias Comunitárias",
  //     ],
  //     [new Date().toLocaleDateString()],
  //   ];
  //   const columnNames = [Object.keys(tableData[0])];
  //   const data = tableData.map(Object.values);
  //   const combinedData = [...headerData, ...columnNames, ...data];
  //   const ws = XLSX.utils.aoa_to_sheet(combinedData);
  //   const wb = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  //   const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  //   const dataExcel = new Blob([excelBuffer], {
  //     type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
  //   });
  //   saveAs(dataExcel, "VagasFarmacias.xlsx");
  // };

  return (
    <div>
      <>
        <button
          className={`${
            loading
              ? "text-gray-400 cursor-not-allowed py-4 text-6xl"
              : "text-fful hover:text-iconhover py-4 text-6xl"
          }`}
          title="Gerar Excel"
          onClick={() => fetchAndCreateExcel(hospitais)}
          disabled={loading}
        >
          <FaFileExcel />
        </button>
        {loading && (
          <p className="text-fful">
            A carregar ficheiro, espere uns segundos por favor.
          </p>
        )}
      </>
    </div>
  );
}

export default ListagemHospitais;
