import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { FaRegSave } from "react-icons/fa";
import { ImCancelCircle } from "react-icons/im";
import Modal from "react-modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { AiOutlineUserAdd } from "react-icons/ai";

Modal.setAppElement("#root");

function CreateTrabalho({ anoLetivo }) {
  const INITIAL_STATE = {
    anoLetivo: anoLetivo || getCurrentAcademicYear(),
    tema: "",
    nVagas: "",
    nVagasInicial: "",
    tipoTrabalho: "MONOGRAFIA",
    orientador: "",
    coorientador1: "",
    coorientador2: "",
    cotutor: "",
    origem: "",
    obs: "",
    visivel: true,
  };
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [newTrabalhoData, setNewTrabalhoData] = useState(INITIAL_STATE);

  const handleReset = () => {
    setNewTrabalhoData(INITIAL_STATE);
  };

  const apiTrabalhos = process.env.REACT_APP_API_TRABALHOS;

  const [errors, setErrors] = useState({
    tema: "",
    nVagas: "",
    tipoTrabalho: "",
    orientador: "",
    coorientador1: "",
    coorientador2: "",
    cotutor: "",
    obs: "",
  });

  function getCurrentAcademicYear() {
    const today = new Date();
    const currentYear = today.getFullYear();

    // Determine the start and end months of the academic year
    const academicYearStartMonth = 8; // September (0-based index)
    // const academicYearEndMonth = 8; // August (0-based index)

    // Determine the academic year based on the current month
    if (today.getMonth() >= academicYearStartMonth) {
      // If the current month is greater than or equal to the start month,
      // consider it as part of the current academic year
      return `${currentYear}-${currentYear + 1}`;
    } else {
      // If the current month is before the start month,
      // consider it as part of the previous academic year
      return `${currentYear - 1}-${currentYear}`;
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;

    setNewTrabalhoData((prevNewTrabalhoData) => {
      if (name === "nVagas") {
        return {
          ...prevNewTrabalhoData,
          nVagas: value,
          nVagasInicial: value,
        };
      } else {
        return {
          ...prevNewTrabalhoData,
          [name]: value,
        };
      }
    });

    validateField(name, value);
  };

  const validateField = (fieldName, value) => {
    let error = "";
    switch (fieldName) {
      case "nVagas":
        if (!value) {
          error = "Campo obrigatório";
        } else if (!/^\d+$/.test(value)) {
          error = "O número de vagas só pode conter dígitos";
        }
        break;
      case "tema":
        if (!value) {
          error = "Campo obrigatório";
        }
        break;
      case "orientador":
        if (!value) {
          error = "Campo obrigatório";
        }
        break;
      default:
        break;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: error,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    fetch(`${apiTrabalhos}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newTrabalhoData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setModalIsOpen(false);
        handleReset();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    handleReset();
    setModalIsOpen(false);
  };

  useEffect(() => {
    if (modalIsOpen) {
      for (const key in newTrabalhoData) {
        validateField(key, newTrabalhoData[key]);
      }
    }
  }, [modalIsOpen]);

  return (
    <>
      <button
        className="text-purple-700 mx-1 flex items-center"
        title="Adicionar"
        onClick={openModal}
      >
        <span className="mr-2">Adicionar trabalho</span>
        <AiOutlineUserAdd />
      </button>
      <Modal isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)}>
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg p-6 md:p-8">
            <h2 className="text-lg font-semibold mb-4 bg-fful px-4 py-2 rounded-lg text-white">
              Adicionar Trabalho
            </h2>
            <Form noValidate onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="tema">
                  <Form.Label>Tema do trabalho</Form.Label>
                  <Form.Control
                    type="text"
                    name="tema"
                    onChange={handleChange}
                    value={newTrabalhoData.tema}
                    isInvalid={!!errors.tema}
                    isValid={!errors.tema}
                  />
                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.tema}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="orientador">
                  <Form.Label>Orientador</Form.Label>
                  <Form.Control
                    type="text"
                    name="orientador"
                    onChange={handleChange}
                    value={newTrabalhoData.orientador}
                    isInvalid={!!errors.orientador}
                    isValid={!errors.orientador}
                  />

                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.orientador}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="coorientador1">
                  <Form.Label>Co-Orientador</Form.Label>
                  <Form.Control
                    type="text"
                    name="coorientador1"
                    onChange={handleChange}
                    value={newTrabalhoData.coorientador1}
                    isInvalid={!!errors.coorientador1}
                    isValid={!errors.coorientador1}
                  />

                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.coorientador1}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="coorientador2">
                  <Form.Label>Co-Orientador Alternativo</Form.Label>
                  <Form.Control
                    type="text"
                    name="coorientador2"
                    onChange={handleChange}
                    value={newTrabalhoData.coorientador2}
                    isInvalid={!!errors.coorientador2}
                    isValid={!errors.coorientador2}
                  />
                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.coorientador2}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="cotutor">
                  <Form.Label>Co-Tutor</Form.Label>
                  <Form.Control
                    type="text"
                    name="cotutor"
                    onChange={handleChange}
                    value={newTrabalhoData.cotutor}
                    isInvalid={!!errors.cotutor}
                    isValid={!errors.cotutor}
                  />

                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.cotutor}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="3" controlId="tipoTrabalho">
                  <Form.Label>Tipo de Trabalho</Form.Label>

                  <Form.Select
                    name="tipoTrabalho"
                    onChange={handleChange}
                    value={newTrabalhoData.tipoTrabalho}
                  >
                    <option value="MONOGRAFIA">Monografia</option>
                    <option value="TRABALHOCAMPO">Trabalho de campo</option>
                    <option value="ERASMUS">Erasmus</option>
                    <option value="EXCECAO">Exceção</option>
                  </Form.Select>

                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.tipoTrabalho}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="3" controlId="nVagas">
                  <Form.Label>Número de vagas</Form.Label>
                  <Form.Control
                    type="text"
                    name="nVagas"
                    onChange={handleChange}
                    value={newTrabalhoData.nVagas}
                    isInvalid={!!errors.nVagas}
                    isValid={!errors.nVagas}
                  />
                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.nVagas}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="origem">
                  <Form.Label>Origem</Form.Label>
                  <Form.Control
                    type="text"
                    name="origem"
                    onChange={handleChange}
                    value={newTrabalhoData.origem}
                    isInvalid={!!errors.origem}
                    isValid={!errors.origem}
                  />
                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.origem}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="obs">
                  <Form.Label>Observações</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="obs"
                    onChange={handleChange}
                    value={newTrabalhoData.obs}
                    isInvalid={!!errors.obs}
                    isValid={!errors.obs}
                  />

                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.obs}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <div className="py-4">
                <button
                  type="submit"
                  disabled={Object.values(errors).some((value) => value !== "")}
                  className={
                    Object.values(errors).some((value) => value !== "")
                      ? "text-white px-4 py-2 rounded-md bg-gray-400 cursor-not-allowed"
                      : "text-white px-4 py-2 rounded-md bg-fful hover:bg-iconhover"
                  }
                >
                  <FaRegSave />
                </button>
                <button
                  type="button"
                  className="text-white px-4 py-2 mx-2 rounded-md bg-fful hover:bg-iconhover"
                  onClick={closeModal}
                >
                  <ImCancelCircle />
                </button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default CreateTrabalho;
