import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Form, Modal } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function BodyFormFarmacia() {
  const anoLetivo = getCurrentAcademicYear();

  const INITIAL_STATE_CONTATO = {
    anoLetivo: anoLetivo,
    idFarmacia: "",
    email: "",
    telefone: "",
    telemovel: "",
    nome: "",
    emailAlternativo: "",
    emailEstagios: "",
  };

  const INITIAL_STATE_BLOCO = {
    anoLetivo: anoLetivo,
    idFarmacia: "",
    idBlocoF: "",
    disponivel: true,
    obs: "",
  };

  const INITIAL_STATE_ORIENTADOR = {
    anoLetivo: anoLetivo,
    idFarmacia: "",
    nome: "",
    numOrdem: "",
    obs: "",
    sexo: "",
    idade: "",
    numAnosExperiencia: "",
    habilitacoes: "",
  };

  const INITIAL_STATE_FARMACIA = {
    numTotalFarmaceuticos: "",
    numTotalFarmOriEst: "",
    sifarma: "",
    winfar: "",
    softreis: "",
    gabAP: "",
    segmentadaAreas: "",
    mnsrm: "",
    supAlimentares: "",
    fitoterapia: "",
    dermofarmacia: "",
    dispMedicos: "",
    homeopatia: "",
    cosmetica: "",
    puericultura: "",
    medVet: "",
    manualQual: "",
    procTecEscritos: "",
    fontesInfoTecLegais: "",
    defFuncColab: "",
    avalParBioFis: "",
    cuidadosFarm: "",
    cuidadosDermo: "",
    administracaoMed: "",
    administracaoVac: "",
    prepSemanal: "",
    prepMed: "",
    apoioDom: "",
    primeirosSocorros: "",
    dispensaMedDom: "",
    radiografia: "",
    infoMed: "",
    campanhaInfo: "",
    recolhaMed: "",
    programasSeguimento: "",
    historicoConsumo: "",
    protocoloAtendimentoMNSRM: "",
    participaEstudos: "",
    projetosIntLocal: "",
    servNacionalF: "",
    prodPropriaFolhetos: "",
    planoPercursoEstagiario: "",
    formInterna: "",
    ministradaInterna: "",
    formExterna: "",
    ministradaExterna: "",
  };

  const [isDateValid, setIsDateValid] = useState(false);
  const [datas, setDatas] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [codigo, setCodigo] = useState("");
  const [isCodigoValid, setIsCodigoValid] = useState(false);
  const [farmaciaData, setFarmaciaData] = useState(INITIAL_STATE_FARMACIA);
  const [confirmButton, setConfirmButton] = useState(false);
  const [contatoData, setContatoData] = useState(INITIAL_STATE_CONTATO);
  const [blocos, setBlocos] = useState([INITIAL_STATE_BLOCO]);
  const [orientadores, setOrientadores] = useState([INITIAL_STATE_ORIENTADOR]);
  const [blocosF, setBlocosF] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [formAlreadySubmitted, setFormAlreadySubmitted] = useState(false);

  const [errorsContatoData, setErrorsContatoData] = useState({
    email: "",
    emailAlternativo: "",
    emailEstagios: "",
    telefone: "",
    telemovel: "",
    nome: "",
  });
  const [errorsFarmaciaData, setErrorsFarmaciaData] = useState({
    numTotalFarmaceuticos: "",
    numTotalFarmOriEst: "",
    sifarma: "",
    winfar: "",
    softreis: "",
    gabAP: "",
    segmentadaAreas: "",
    mnsrm: "",
    supAlimentares: "",
    fitoterapia: "",
    dermofarmacia: "",
    dispMedicos: "",
    homeopatia: "",
    cosmetica: "",
    puericultura: "",
    medVet: "",
    manualQual: "",
    procTecEscritos: "",
    fontesInfoTecLegais: "",
    defFuncColab: "",
    avalParBioFis: "",
    cuidadosFarm: "",
    cuidadosDermo: "",
    administracaoMed: "",
    administracaoVac: "",
    prepSemanal: "",
    prepMed: "",
    apoioDom: "",
    primeirosSocorros: "",
    dispensaMedDom: "",
    radiografia: "",
    infoMed: "",
    campanhaInfo: "",
    recolhaMed: "",
    programasSeguimento: "",
    historicoConsumo: "",
    protocoloAtendimentoMNSRM: "",
    participaEstudos: "",
    projetosIntLocal: "",
    servNacionalF: "",
    prodPropriaFolhetos: "",
    planoPercursoEstagiario: "",
    formInterna: "",
    ministradaInterna: "",
    formExterna: "",
    ministradaExterna: "",
  });
  const [errorsOrientadorData, setErrorsOrientadorData] = useState({
    0: {
      nome: "Campo obrigatório",
      numOrdem: "Campo obrigatório",
      sexo: "Campo obrigatório",
      idade: "Campo obrigatório",
      numAnosExperiencia: "Campo obrigatório",
      habilitacoes: "Campo obrigatório",
    },
  });
  const [errorsBlocoData, setErrorsBlocoData] = useState({
    0: { idBlocoF: "" },
  });

  const apiDatas = process.env.REACT_APP_API_DATAS;
  const apiFarmacias = process.env.REACT_APP_API_FARMACIAS;
  const apiContatoFarmacias = process.env.REACT_APP_API_CONTATOFARMACIAS;
  const apiVagasFarmacias = process.env.REACT_APP_API_VAGASFARMACIAS;
  const apiBlocoF = process.env.REACT_APP_API_BLOCOF;
  const apiOrientadorFarmacia = process.env.REACT_APP_API_ORIENTADOR_FARMACIA;

  // Add this new state for storing the number of vagas for each bloco
  const [numVagas, setNumVagas] = useState({});

  // Add this function to handle numVagas change
  const handleNumVagasChange = (index, value) => {
    setNumVagas((prev) => ({ ...prev, [index]: parseInt(value) }));
  };

  function getCurrentAcademicYear() {
    const today = new Date();
    const currentYear = today.getFullYear();
    // Determine the start and end months of the academic year
    const academicYearStartMonth = 8; // September (0-based index)
    // const academicYearEndMonth = 8; // August (0-based index)
    // Determine the academic year based on the current month
    if (today.getMonth() >= academicYearStartMonth) {
      // If the current month is greater than or equal to the start month,
      // consider it as part of the current academic year
      return `${currentYear}-${currentYear + 1}`;
    } else {
      // If the current month is before the start month,
      // consider it as part of the previous academic year
      return `${currentYear - 1}-${currentYear}`;
    }
  }

  useEffect(() => {
    fetch(`${apiBlocoF}/anoLetivo/${anoLetivo}`)
      .then((response) => response.json())
      .then((data) => setBlocosF(data));
  }, [apiBlocoF]);

  useEffect(() => {
    fetch(`${apiDatas}/1`)
      .then((response) => response.json())
      .then((data) => {
        setDatas(data);

        const currentDate = new Date();
        const startDateFarmacias = new Date(data.startDateFarmacias);
        const endDateFarmacias = new Date(data.endDateFarmacias);
        setIsDateValid(
          currentDate >= startDateFarmacias && currentDate <= endDateFarmacias
        );
        setIsLoading(false);
      });
  }, [apiDatas]);

  useEffect(() => {
    if (isCodigoValid) {
      const fetchFarmaciaData = async () => {
        try {
          const response = await fetch(`${apiFarmacias}/${codigo}`);
          if (!response.ok) {
            throw new Error("Farmacia not found");
          }
          const data = await response.json();

          if (data.visivel) {
            setFarmaciaData(data);
            setBlocos((prevBlocos) =>
              prevBlocos.map((bloco) => ({
                ...bloco,
                idFarmacia: data.idFarmacia,
              }))
            );
            setContatoData((prevContatoData) => ({
              ...prevContatoData,
              idFarmacia: data.idFarmacia,
            }));
            setOrientadores((prevOrientadores) =>
              prevOrientadores.map((orientador) => ({
                ...orientador,
                idFarmacia: data.idFarmacia,
              }))
            );
            setErrorMessage("");
          } else {
            throw new Error("Farmacia not visible");
          }
        } catch (error) {
          console.error("Error fetching farmacia data:", error);
          setFarmaciaData(null);
          setErrorMessage(
            "Essa Farmácia não existe, por favor, insira um código válido."
          );
        }
      };

      fetchFarmaciaData();
    }
  }, [codigo, isCodigoValid, apiFarmacias]);

  useEffect(() => {
    if (confirmButton) {
      for (const key in contatoData) {
        validateFieldContatoData(key, contatoData[key]);
      }
      for (const key in farmaciaData) {
        validateFieldFarmaciaData(key, farmaciaData[key]);
      }
      blocos.forEach((bloco, index) => {
        for (const key in bloco) {
          validateFieldBlocoData(key, bloco[key], index);
        }
      });
      orientadores.forEach((orientador, index) => {
        for (const key in orientador) {
          validateFieldOrientadorData(key, orientador[key], index);
        }
      });
    }
  }, [confirmButton]);

  if (!isLoading && !isDateValid) {
    return (
      <>
        <h1 className="flex items-center justify-center text-fful my-16">
          Neste momento o preenchimento do formulário encontra-se encerrado.
        </h1>
      </>
    );
  }
  const confirmFormCode = () => {
    setConfirmButton(true);
    checkVagasThisAnoLetivo();
  };

  const handleCodigoChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setCodigo(value);
      setIsCodigoValid(value.length > 0);
      setConfirmButton(false);

      // Reset all form data and errors to initial state
      setFarmaciaData(INITIAL_STATE_FARMACIA);
      setContatoData(INITIAL_STATE_CONTATO);
      setBlocos([INITIAL_STATE_BLOCO]);
      setOrientadores([INITIAL_STATE_ORIENTADOR]);
      setErrorMessage("");
      setErrorsContatoData({
        email: "",
        emailAlternativo: "",
        emailEstagios: "",
        telefone: "",
        telemovel: "",
        nome: "",
      });
      setErrorsFarmaciaData({
        numTotalFarmaceuticos: "",
        numTotalFarmOriEst: "",
        sifarma: "",
        winfar: "",
        softreis: "",
        gabAP: "",
        segmentadaAreas: "",
        mnsrm: "",
        supAlimentares: "",
        fitoterapia: "",
        dermofarmacia: "",
        dispMedicos: "",
        homeopatia: "",
        cosmetica: "",
        puericultura: "",
        medVet: "",
        manualQual: "",
        procTecEscritos: "",
        fontesInfoTecLegais: "",
        defFuncColab: "",
        avalParBioFis: "",
        cuidadosFarm: "",
        cuidadosDermo: "",
        administracaoMed: "",
        administracaoVac: "",
        prepSemanal: "",
        prepMed: "",
        apoioDom: "",
        primeirosSocorros: "",
        dispensaMedDom: "",
        radiografia: "",
        infoMed: "",
        campanhaInfo: "",
        recolhaMed: "",
        programasSeguimento: "",
        historicoConsumo: "",
        protocoloAtendimentoMNSRM: "",
        participaEstudos: "",
        projetosIntLocal: "",
        servNacionalF: "",
        prodPropriaFolhetos: "",
        planoPercursoEstagiario: "",
        formInterna: "",
        ministradaInterna: "",
        formExterna: "",
        ministradaExterna: "",
      });
      setErrorsOrientadorData({
        0: {
          nome: "Campo obrigatório",
          numOrdem: "Campo obrigatório",
          sexo: "Campo obrigatório",
          idade: "Campo obrigatório",
          numAnosExperiencia: "Campo obrigatório",
          habilitacoes: "Campo obrigatório",
        },
      });
      setErrorsBlocoData({
        0: { idBlocoF: "" },
      });
    }
  };

  const getAvailableBlocos = (currentIndex) => {
    const selectedBlocos = blocos
      .map((b) => b.idBlocoF)
      .filter((id, index) => index !== currentIndex && id !== "");
    return blocosF.filter(
      (blocoF) => !selectedBlocos.includes(blocoF.idBlocoF)
    );
  };

  const handleChangeBlocoF = (index, option) => {
    setBlocos((prevBlocos) =>
      prevBlocos.map((bloco, i) =>
        i === index ? { ...bloco, idBlocoF: option ? option.value : "" } : bloco
      )
    );
    validateFieldBlocoData("idBlocoF", option ? option.value : "", index);
  };

  const handleChangeContatoData = (e) => {
    const { name, value } = e.target;
    setContatoData((prevContatoData) => ({
      ...prevContatoData,
      [name]: value,
    }));
    validateFieldContatoData(name, value);
  };
  const handleChangeFarmaciaData = (e) => {
    const { name, value } = e.target;
    setFarmaciaData((prevFarmaciaData) => ({
      ...prevFarmaciaData,
      [name]: value,
    }));
    validateFieldFarmaciaData(name, value);
  };
  const handleChangeOrientadorData = (e, index) => {
    const { name, value } = e.target;
    setOrientadores((prevOrientadores) =>
      prevOrientadores.map((orientador, i) =>
        i === index ? { ...orientador, [name]: value } : orientador
      )
    );
    validateFieldOrientadorData(name, value, index);
  };
  const validateFieldContatoData = (fieldName, value) => {
    let error = "";
    switch (fieldName) {
      case "nome":
        if (!value) {
          error = "Campo obrigatório";
        } else if (!/^[a-zA-Z\u00C0-\u017F\s']+$/.test(value)) {
          error = "O nome só pode conter letras";
        }
        break;
      case "email":
        if (!value) {
          error = "Campo obrigatório";
        } else if (!/^\S+@\S+\.\S+(\.\S+)?$/.test(value)) {
          error = "O email deve estar no formato a@b.c";
        }
        break;
      case "emailEstagios":
        if (value && !/^\S+@\S+\.\S+(\.\S+)?$/.test(value)) {
          error = "O email deve estar no formato a@b.c";
        }
        break;
      case "emailAlternativo":
        if (value && !/^\S+@\S+\.\S+(\.\S+)?$/.test(value)) {
          error = "O email deve estar no formato a@b.c";
        }
        break;
      case "telefone":
        if (!value) {
          error = "Campo obrigatório";
        } else if (value && !/^\d+$/.test(value)) {
          error = "O telefone deve conter apenas dígitos";
        }
        break;
      case "telemovel":
        if (value && !/^\d+$/.test(value)) {
          error = "O telemóvel deve conter apenas dígitos";
        }
        break;

      default:
        break;
    }
    setErrorsContatoData((prevErrorsContatoData) => ({
      ...prevErrorsContatoData,
      [fieldName]: error,
    }));
  };

  const validateFieldFarmaciaData = (fieldName, value) => {
    let error = "";
    switch (fieldName) {
      case "numTotalFarmaceuticos":
        if (!value) {
          error = "Campo obrigatório";
        } else if (value && !/^\d+$/.test(value)) {
          error = "O número de farmacêuticos deve conter apenas dígitos";
        }
        break;
      case "numTotalFarmOriEst":
        if (!value) {
          error = "Campo obrigatório";
        } else if (value && !/^\d+$/.test(value)) {
          error =
            "O número de farmacêuticos orientadores de estágio deve conter apenas dígitos";
        }
        break;
      case "sifarma":
      case "winfar":
      case "softreis":
      case "gabAP":
      case "segmentadaAreas":
      case "mnsrm":
      case "supAlimentares":
      case "fitoterapia":
      case "dermofarmacia":
      case "dispMedicos":
      case "homeopatia":
      case "cosmetica":
      case "puericultura":
      case "medVet":
      case "manualQual":
      case "procTecEscritos":
      case "fontesInfoTecLegais":
      case "defFuncColab":
      case "avalParBioFis":
      case "cuidadosFarm":
      case "cuidadosDermo":
      case "administracaoMed":
      case "administracaoVac":
      case "prepSemanal":
      case "prepMed":
      case "apoioDom":
      case "primeirosSocorros":
      case "dispensaMedDom":
      case "radiografia":
      case "infoMed":
      case "campanhaInfo":
      case "recolhaMed":
      case "programasSeguimento":
      case "historicoConsumo":
      case "protocoloAtendimentoMNSRM":
      case "participaEstudos":
      case "projetosIntLocal":
      case "servNacionalF":
      case "prodPropriaFolhetos":
      case "planoPercursoEstagiario":
      case "formInterna":
      case "ministradaInterna":
      case "formExterna":
      case "ministradaExterna":
        if (value === null || value === undefined) {
          error = "Campo obrigatório";
        }
        break;
      default:
        break;
    }
    setErrorsFarmaciaData((prevErrorsFarmaciaData) => ({
      ...prevErrorsFarmaciaData,
      [fieldName]: error,
    }));
  };

  const validateFieldOrientadorData = (fieldName, value, index) => {
    let error = "";
    switch (fieldName) {
      case "nome":
        if (!value) {
          error = "Campo obrigatório";
        } else if (!/^[a-zA-Z\u00C0-\u017F\s']+$/.test(value)) {
          error = "O nome só pode conter letras";
        }
        break;
      case "numOrdem":
        if (!value) {
          error = "Campo obrigatório";
        } else if (value && !/^\d+$/.test(value)) {
          error = "O número de Ordem deve conter apenas dígitos";
        }
        break;
      case "sexo":
        if (!value) {
          error = "Campo obrigatório";
        }
        break;
      case "idade":
        if (!value) {
          error = "Campo obrigatório";
        } else if (value && !/^\d+$/.test(value)) {
          error = "A idade deve conter apenas dígitos";
        }
        break;
      case "numAnosExperiencia":
        if (!value) {
          error = "Campo obrigatório";
        } else if (value && !/^\d+$/.test(value)) {
          error = "O número de anos de experiência deve conter apenas dígitos";
        }
        break;
      case "habilitacoes":
        if (!value) {
          error = "Campo obrigatório";
        }
        break;

      default:
        break;
    }
    setErrorsOrientadorData((prevErrors) => ({
      ...prevErrors,
      [index]: {
        ...prevErrors[index],
        [fieldName]: error,
      },
    }));
  };
  const validateFieldBlocoData = (fieldName, value, index) => {
    let error = "";
    if (fieldName === "idBlocoF" && !value) {
      error = "Campo obrigatório";
    }
    setErrorsBlocoData((prevErrors) => ({
      ...prevErrors,
      [index]: {
        ...prevErrors[index],
        [fieldName]: error,
      },
    }));
  };

  const addOrientador = () => {
    setOrientadores((prevOrientadores) => [
      ...prevOrientadores,
      {
        ...INITIAL_STATE_ORIENTADOR,
        ...(farmaciaData && { idFarmacia: farmaciaData.idFarmacia, obs: "" }),
      },
    ]);
    setErrorsOrientadorData((prevErrors) => ({
      ...prevErrors,
      [orientadores.length]: {
        nome: "Campo obrigatório",
        numOrdem: "Campo obrigatório",
        sexo: "Campo obrigatório",
        idade: "Campo obrigatório",
        numAnosExperiencia: "Campo obrigatório",
        habilitacoes: "Campo obrigatório",
      },
    }));
  };

  const removeOrientador = (index) => {
    setOrientadores((prevOrientadores) =>
      prevOrientadores.filter((_, i) => i !== index)
    );
    setErrorsOrientadorData((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[index];
      // Reindex the remaining errors
      return Object.fromEntries(
        Object.entries(newErrors).map(([key, value], i) => [i, value])
      );
    });
  };

  const addBloco = () => {
    setBlocos((prevBlocos) => [
      ...prevBlocos,
      {
        ...INITIAL_STATE_BLOCO,
        ...(farmaciaData && { idFarmacia: farmaciaData.idFarmacia }),
      },
    ]);
    setErrorsBlocoData((prevErrors) => ({
      ...prevErrors,
      [blocos.length]: { idBlocoF: "Campo obrigatório" },
    }));
  };

  const removeBloco = (index) => {
    setBlocos((prevBlocos) => prevBlocos.filter((_, i) => i !== index));
    setErrorsBlocoData((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[index];
      return Object.fromEntries(
        Object.entries(newErrors).map(([key, value], i) => [i, value])
      );
    });
  };

  const isFormValid = () => {
    const isContatoValid = Object.values(errorsContatoData).every(
      (error) => error === ""
    );
    const isFarmaciaValid = Object.values(errorsFarmaciaData).every(
      (error) => error === ""
    );
    const isBlocoValid = Object.values(errorsBlocoData).every((blocoErrors) =>
      Object.values(blocoErrors).every((error) => error === "")
    );
    const isOrientadoresValid = Object.values(errorsOrientadorData).every(
      (orientadorErrors) =>
        Object.values(orientadorErrors).every((error) => error === "")
    );
    return (
      isContatoValid && isFarmaciaValid && isBlocoValid && isOrientadoresValid
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Submit contato data
      await fetch(`${apiContatoFarmacias}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(contatoData),
      });

      // Submit farmacia data (update)
      await fetch(`${apiFarmacias}/${farmaciaData.idFarmacia}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(farmaciaData),
      });

      // Submit bloco data for each bloco, creating multiple vagas based on numVagas
      for (let index = 0; index < blocos.length; index++) {
        const bloco = blocos[index];
        const vagasCount = numVagas[index] || 1;
        for (let i = 0; i < vagasCount; i++) {
          await fetch(`${apiVagasFarmacias}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(bloco),
          });
        }
      }

      // Submit orientadores data
      for (const orientador of orientadores) {
        await fetch(`${apiOrientadorFarmacia}`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(orientador),
        });
      }

      // Show success modal
      setShowSuccessModal(true);

      // Handle successful submission (e.g., show success message, reset form)
      console.log("Form submitted successfully");
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle error (e.g., show error message)
    }
  };

  const handleCloseModal = () => {
    setShowSuccessModal(false);
    window.location.reload();
  };

  const checkVagasThisAnoLetivo = () => {
    fetch(
      `${apiVagasFarmacias}/anoLetivo/${anoLetivo}/farmacia/${codigo}/NoAlunos`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          setFormAlreadySubmitted(true);
        } else {
          setFormAlreadySubmitted(false);
        }
      });
  };

  return (
    <div>
      <div className="flex flex-col items-center justify-center text-center">
        <h1 className="text-5xl font-bold my-10 text-fful">
          Formulário Farmácia Comunitária
        </h1>
      </div>
      <div className="flex flex-col items-center justify-center text-center">
        <img
          src="/images/codigo-farmacia.jpg"
          alt="Secondary image"
          className="mb-4 w-96 h-auto"
        />
        <div className="mb-4">
          <label
            htmlFor="codigoFarmacia"
            className="block text-lg font-medium mb-2"
          >
            Código da Farmácia
          </label>
          <input
            type="text"
            id="codigoFarmacia"
            value={codigo}
            onChange={handleCodigoChange}
            className="border border-gray-300 p-2 rounded"
            placeholder="Digite o código"
          />
        </div>
      </div>
      {errorMessage && (
        <p className="text-red-500 mt-4 text-center">{errorMessage}</p>
      )}
      {codigo && farmaciaData && (
        <div className="w-full max-w-6xl mx-auto">
          <div className="flex justify-center items-center -mx-2">
            <div className="w-full md:w-2/4 px-2 mb-4">
              <label
                htmlFor="farmaciaNome"
                className="text-lg font-medium mb-2 text-center"
              >
                Nome da Farmácia
              </label>
              <input
                type="text"
                id="farmaciaNome"
                className="border border-gray-300 p-2 rounded w-full bg-gray-100"
                value={farmaciaData.nome ? "Farmácia " + farmaciaData.nome : ""}
                readOnly
              />
            </div>
            <div className="w-full md:w-2/4 px-2 mb-4">
              <label
                htmlFor="farmaciaMorada"
                className=" text-lg font-medium mb-2 text-center"
              >
                Morada da Farmácia
              </label>
              <input
                type="text"
                id="farmaciaMorada"
                className="border border-gray-300 p-2 rounded w-full bg-gray-100"
                value={farmaciaData.morada || ""}
                readOnly
              />
            </div>
            <div className="w-full md:w-2/4 px-2 mb-4">
              <label
                htmlFor="farmaciaLocalidade"
                className=" text-lg font-medium mb-2 text-center"
              >
                Localidade da Farmácia
              </label>
              <input
                type="text"
                id="farmaciaLocalidade"
                className="border border-gray-300 p-2 rounded w-full bg-gray-100"
                value={farmaciaData.localidade || ""}
                readOnly
              />
            </div>
            <div className="w-full md:w-2/4 px-2 mb-4">
              <label
                htmlFor="farmaciaCodPostal"
                className=" text-lg font-medium mb-2 text-center"
              >
                Código Postal da Farmácia
              </label>
              <input
                type="text"
                id="farmaciaCodPostal"
                className="border border-gray-300 p-2 rounded w-full bg-gray-100"
                value={farmaciaData.codPostal || ""}
                readOnly
              />
            </div>
          </div>
          <div className="flex flex-col items-center justify-center text-center mb-10">
            <div className="text-xl text-fful">
              Se confirma que é esta a farmácia para a qual pretende preencher o
              formulário clique no botão confirmo,
            </div>
            <div className="text-xl text-fful">
              caso contrário coloque o código da Farmácia correto (recebeu no
              email).
            </div>
            <button
              className="bg-fful hover:bg-iconhover text-white font-bold py-2 px-4 rounded mt-6"
              onClick={() => confirmFormCode()}
            >
              Confirmo
            </button>
          </div>
          {confirmButton && formAlreadySubmitted && (
            <div className="flex items-center justify-center text-fful my-16">
              <h4>
                <div>
                  Esta entidade já submeteu o formulário para este ano letivo.
                  Para fazer alterações, por favor envie um email detalhado com
                  as alterações a fazer para nucleodeestagios@ff.ulisboa.pt
                </div>
                <div className="flex items-center justify-center text-fful my-16">
                  Obrigado
                </div>
              </h4>
            </div>
          )}
          {confirmButton && !formAlreadySubmitted && (
            <div>
              <div className="w-full max-w-3xl mx-auto mb-10">
                <h2 className="text-xl font-bold mb-4 text-center text-fful">
                  Contato da Farmácia
                </h2>
                <form className="space-y-4">
                  {/* Contato */}
                  <Form.Group as={Col} md="12" controlId="nome">
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      Nome Diretor Técnico
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="nome"
                      placeholder="Digite o nome do Diretor Técnico"
                      value={contatoData.nome}
                      onChange={handleChangeContatoData}
                      isInvalid={!!errorsContatoData.nome}
                      isValid={!errorsContatoData.nome}
                    />
                    <Form.Control.Feedback
                      type="valid"
                      tooltip
                      style={{ display: "none" }}
                    >
                      <span className="visually-hidden">Success</span>
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errorsContatoData.nome}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="12" controlId="email">
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      Email Diretor Técnico
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="email"
                      placeholder="Digite o email do Diretor Técnico"
                      value={contatoData.email}
                      onChange={handleChangeContatoData}
                      isInvalid={!!errorsContatoData.email}
                      isValid={!errorsContatoData.email}
                    />
                    <Form.Control.Feedback
                      type="valid"
                      tooltip
                      style={{ display: "none" }}
                    >
                      <span className="visually-hidden">Success</span>
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errorsContatoData.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="12" controlId="emailEstagios">
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      Email Gabinete de Coordenação de estágios (se aplicável)
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="emailEstagios"
                      placeholder="Digite o email do Gabinete de Coordenação de estágios"
                      value={contatoData.emailEstagios}
                      onChange={handleChangeContatoData}
                      isInvalid={!!errorsContatoData.emailEstagios}
                      isValid={!errorsContatoData.emailEstagios}
                    />
                    <Form.Control.Feedback
                      type="valid"
                      tooltip
                      style={{ display: "none" }}
                    >
                      <span className="visually-hidden">Success</span>
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errorsContatoData.emailEstagios}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="12" controlId="emailAlternativo">
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      Email Alternativo
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="emailAlternativo"
                      placeholder="Digite o email Alternativo"
                      value={contatoData.emailAlternativo}
                      onChange={handleChangeContatoData}
                      isInvalid={!!errorsContatoData.emailAlternativo}
                      isValid={!errorsContatoData.emailAlternativo}
                    />
                    <Form.Control.Feedback
                      type="valid"
                      tooltip
                      style={{ display: "none" }}
                    >
                      <span className="visually-hidden">Success</span>
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errorsContatoData.emailAlternativo}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Row>
                    <Form.Group as={Col} md="6" controlId="telefone">
                      <Form.Label className="block text-lg font-medium mb-2 text-fful">
                        Telefone
                      </Form.Label>
                      <Form.Control
                        type="number"
                        name="telefone"
                        placeholder="Digite o telefone"
                        value={contatoData.telefone}
                        onChange={handleChangeContatoData}
                        isInvalid={!!errorsContatoData.telefone}
                        isValid={!errorsContatoData.telefone}
                        onWheel={(e) => e.target.blur()}
                      />
                      <Form.Control.Feedback
                        type="valid"
                        tooltip
                        style={{ display: "none" }}
                      >
                        <span className="visually-hidden">Success</span>
                      </Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        {errorsContatoData.telefone}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="telemovel">
                      <Form.Label className="block text-lg font-medium mb-2 text-fful">
                        Telemóvel
                      </Form.Label>
                      <Form.Control
                        type="number"
                        name="telemovel"
                        placeholder="Digite o telemóvel"
                        value={contatoData.telemovel}
                        onChange={handleChangeContatoData}
                        isInvalid={!!errorsContatoData.telemovel}
                        isValid={!errorsContatoData.telemovel}
                        onWheel={(e) => e.target.blur()}
                      />
                      <Form.Control.Feedback
                        type="valid"
                        tooltip
                        style={{ display: "none" }}
                      >
                        <span className="visually-hidden">Success</span>
                      </Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        {errorsContatoData.telefone}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                </form>
              </div>
              <div className="w-full max-w-3xl mx-auto mb-10">
                <h2 className="text-xl font-bold mb-4 text-center text-fful">
                  Informação da Farmácia
                </h2>
                <form className="space-y-4">
                  <Row>
                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="numTotalFarmaceuticos"
                    >
                      <Form.Label className="block text-lg font-medium mb-2 text-fful mt-4">
                        Número Total de Farmacêuticos
                      </Form.Label>
                      <Form.Control
                        type="number"
                        name="numTotalFarmaceuticos"
                        placeholder="Digite o número total de Farmacêuticos"
                        onChange={handleChangeFarmaciaData}
                        isInvalid={!!errorsFarmaciaData.numTotalFarmaceuticos}
                        isValid={!errorsFarmaciaData.numTotalFarmaceuticos}
                        onWheel={(e) => e.target.blur()}
                      />
                      <Form.Control.Feedback
                        type="valid"
                        tooltip
                        style={{ display: "none" }}
                      >
                        <span className="visually-hidden">Success</span>
                      </Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        {errorsFarmaciaData.numTotalFarmaceuticos}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="numTotalFarmOriEst">
                      <Form.Label className="block text-lg font-medium mb-2 text-fful">
                        Número Total de Farmacêuticos Orientadores de Estágio
                      </Form.Label>
                      <Form.Control
                        type="number"
                        name="numTotalFarmOriEst"
                        placeholder="Digite o número total de Farmacêuticos Orientadores de Estágio"
                        onChange={handleChangeFarmaciaData}
                        isInvalid={!!errorsFarmaciaData.numTotalFarmOriEst}
                        isValid={!errorsFarmaciaData.numTotalFarmOriEst}
                        onWheel={(e) => e.target.blur()}
                      />
                      <Form.Control.Feedback
                        type="valid"
                        tooltip
                        style={{ display: "none" }}
                      >
                        <span className="visually-hidden">Success</span>
                      </Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        {errorsFarmaciaData.numTotalFarmOriEst}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <Form.Label className="block text-lg font-medium mb-2 text-fful">
                    A Farmácia possui o sistema informático:
                  </Form.Label>
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="sifarma"
                    className="ml-10"
                  >
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      Sifarma
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Sim"
                        name="sifarma"
                        id="sifarmaSim"
                        value="true"
                        checked={farmaciaData.sifarma === true}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "sifarma",
                              value: true,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.sifarma}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Não"
                        name="sifarma"
                        id="sifarmaNao"
                        value="false"
                        checked={farmaciaData.sifarma === false}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "sifarma",
                              value: false,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.sifarma}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errorsFarmaciaData.sifarma}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="winfar"
                    className="ml-10"
                  >
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      Winfar
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Sim"
                        name="winfar"
                        id="winfarSim"
                        value="true"
                        checked={farmaciaData.winfar === true}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "winfar",
                              value: true,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.winfar}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Não"
                        name="winfar"
                        id="winfarNao"
                        value="false"
                        checked={farmaciaData.winfar === false}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "winfar",
                              value: false,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.winfar}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errorsFarmaciaData.winfar}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="softreis"
                    className="ml-10"
                  >
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      SoftReis
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Sim"
                        name="softreis"
                        id="softreisSim"
                        value="true"
                        checked={farmaciaData.softreis === true}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "softreis",
                              value: true,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.softreis}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Não"
                        name="softreis"
                        id="softreisNao"
                        value="false"
                        checked={farmaciaData.softreis === false}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "softreis",
                              value: false,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.softreis}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errorsFarmaciaData.softreis}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="12" controlId="gabAP">
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      A Farmácia possui Gabinete de Atendimento Personalizado
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Sim"
                        name="gabAP"
                        id="gabAPSim"
                        value="true"
                        checked={farmaciaData.gabAP === true}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "gabAP",
                              value: true,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.gabAP}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Não"
                        name="gabAP"
                        id="gabAPNao"
                        value="false"
                        checked={farmaciaData.gabAP === false}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "gabAP",
                              value: false,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.gabAP}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errorsFarmaciaData.gabAP}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="12" controlId="segmentadaAreas">
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      A Farmácia está segmentada em diferentes áreas
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Sim"
                        name="segmentadaAreas"
                        id="segmentadaAreasSim"
                        value="true"
                        checked={farmaciaData.segmentadaAreas === true}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "segmentadaAreas",
                              value: true,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.segmentadaAreas}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Não"
                        name="segmentadaAreas"
                        id="segmentadaAreasNao"
                        value="false"
                        checked={farmaciaData.segmentadaAreas === false}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "segmentadaAreas",
                              value: false,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.segmentadaAreas}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errorsFarmaciaData.segmentadaAreas}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Label className="block text-lg font-medium mb-2 text-fful">
                    Indique as áreas:
                  </Form.Label>
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="mnsrm"
                    className="ml-10"
                  >
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      MNSRM
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Sim"
                        name="mnsrm"
                        id="mnsrmSim"
                        value="true"
                        checked={farmaciaData.mnsrm === true}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "mnsrm",
                              value: true,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.mnsrm}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Não"
                        name="mnsrm"
                        id="mnsrmNao"
                        value="false"
                        checked={farmaciaData.mnsrm === false}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "mnsrm",
                              value: false,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.mnsrm}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errorsFarmaciaData.mnsrm}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="supAlimentares"
                    className="ml-10"
                  >
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      Suplementos alimentares
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Sim"
                        name="supAlimentares"
                        id="supAlimentaresSim"
                        value="true"
                        checked={farmaciaData.supAlimentares === true}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "supAlimentares",
                              value: true,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.supAlimentares}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Não"
                        name="supAlimentares"
                        id="supAlimentaresNao"
                        value="false"
                        checked={farmaciaData.supAlimentares === false}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "supAlimentares",
                              value: false,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.supAlimentares}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errorsFarmaciaData.supAlimentares}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="fitoterapia"
                    className="ml-10"
                  >
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      Fitoterapia
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Sim"
                        name="fitoterapia"
                        id="fitoterapiaSim"
                        value="true"
                        checked={farmaciaData.fitoterapia === true}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "fitoterapia",
                              value: true,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.fitoterapia}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Não"
                        name="fitoterapia"
                        id="fitoterapiaNao"
                        value="false"
                        checked={farmaciaData.fitoterapia === false}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "fitoterapia",
                              value: false,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.fitoterapia}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errorsFarmaciaData.fitoterapia}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="dermofarmacia"
                    className="ml-10"
                  >
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      Dermofarmácia
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Sim"
                        name="dermofarmacia"
                        id="dermofarmaciaSim"
                        value="true"
                        checked={farmaciaData.dermofarmacia === true}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "dermofarmacia",
                              value: true,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.dermofarmacia}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Não"
                        name="dermofarmacia"
                        id="dermofarmaciaNao"
                        value="false"
                        checked={farmaciaData.dermofarmacia === false}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "dermofarmacia",
                              value: false,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.dermofarmacia}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errorsFarmaciaData.dermofarmacia}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="dispMedicos"
                    className="ml-10"
                  >
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      Dispositivos Médicos
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Sim"
                        name="dispMedicos"
                        id="dispMedicosSim"
                        value="true"
                        checked={farmaciaData.dispMedicos === true}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "dispMedicos",
                              value: true,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.dispMedicos}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Não"
                        name="dispMedicos"
                        id="dispMedicosNao"
                        value="false"
                        checked={farmaciaData.dispMedicos === false}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "dispMedicos",
                              value: false,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.dispMedicos}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errorsFarmaciaData.dispMedicos}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="homeopatia"
                    className="ml-10"
                  >
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      Homeopatia
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Sim"
                        name="homeopatia"
                        id="homeopatiaSim"
                        value="true"
                        checked={farmaciaData.homeopatia === true}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "homeopatia",
                              value: true,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.homeopatia}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Não"
                        name="homeopatia"
                        id="homeopatiaNao"
                        value="false"
                        checked={farmaciaData.homeopatia === false}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "homeopatia",
                              value: false,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.homeopatia}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errorsFarmaciaData.homeopatia}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="cosmetica"
                    className="ml-10"
                  >
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      Cosmética/Higiene
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Sim"
                        name="cosmetica"
                        id="cosmeticaSim"
                        value="true"
                        checked={farmaciaData.cosmetica === true}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "cosmetica",
                              value: true,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.cosmetica}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Não"
                        name="cosmetica"
                        id="cosmeticaNao"
                        value="false"
                        checked={farmaciaData.cosmetica === false}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "cosmetica",
                              value: false,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.cosmetica}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errorsFarmaciaData.cosmetica}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="puericultura"
                    className="ml-10"
                  >
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      Puericultura
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Sim"
                        name="puericultura"
                        id="puericulturaSim"
                        value="true"
                        checked={farmaciaData.puericultura === true}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "puericultura",
                              value: true,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.puericultura}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Não"
                        name="puericultura"
                        id="puericulturaNao"
                        value="false"
                        checked={farmaciaData.puericultura === false}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "puericultura",
                              value: false,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.puericultura}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errorsFarmaciaData.puericultura}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="medVet"
                    className="ml-10"
                  >
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      Medicamentos/Produtos Veterinária
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Sim"
                        name="medVet"
                        id="medVetSim"
                        value="true"
                        checked={farmaciaData.medVet === true}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "medVet",
                              value: true,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.medVet}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Não"
                        name="medVet"
                        id="medVetNao"
                        value="false"
                        checked={farmaciaData.medVet === false}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "medVet",
                              value: false,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.medVet}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errorsFarmaciaData.medVet}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="12" controlId="manualQual">
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      A Farmácia possui Manual de Qualidade escrito
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Sim"
                        name="manualQual"
                        id="manualQualSim"
                        value="true"
                        checked={farmaciaData.manualQual === true}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "manualQual",
                              value: true,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.manualQual}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Não"
                        name="manualQual"
                        id="manualQualNao"
                        value="false"
                        checked={farmaciaData.manualQual === false}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "manualQual",
                              value: false,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.manualQual}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errorsFarmaciaData.manualQual}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="12" controlId="procTecEscritos">
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      A Farmácia possui alguns procedimentos técnicos escritos
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Sim"
                        name="procTecEscritos"
                        id="procTecEscritosSim"
                        value="true"
                        checked={farmaciaData.procTecEscritos === true}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "procTecEscritos",
                              value: true,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.procTecEscritos}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Não"
                        name="procTecEscritos"
                        id="procTecEscritosNao"
                        value="false"
                        checked={farmaciaData.procTecEscritos === false}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "procTecEscritos",
                              value: false,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.procTecEscritos}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errorsFarmaciaData.procTecEscritos}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="12" controlId="fontesInfoTecLegais">
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      A Farmácia possui fontes de informação adequadas à
                      atividade técnico-legal
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Sim"
                        name="fontesInfoTecLegais"
                        id="fontesInfoTecLegaisSim"
                        value="true"
                        checked={farmaciaData.fontesInfoTecLegais === true}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "fontesInfoTecLegais",
                              value: true,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.fontesInfoTecLegais}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Não"
                        name="fontesInfoTecLegais"
                        id="fontesInfoTecLegaisNao"
                        value="false"
                        checked={farmaciaData.fontesInfoTecLegais === false}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "fontesInfoTecLegais",
                              value: false,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.fontesInfoTecLegais}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errorsFarmaciaData.fontesInfoTecLegais}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="12" controlId="defFuncColab">
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      Estão definidas claramente as funções dos colaboradores
                      (farmacêuticos e técnicos)
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Sim"
                        name="defFuncColab"
                        id="defFuncColabSim"
                        value="true"
                        checked={farmaciaData.defFuncColab === true}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "defFuncColab",
                              value: true,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.defFuncColab}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Não"
                        name="defFuncColab"
                        id="defFuncColabNao"
                        value="false"
                        checked={farmaciaData.defFuncColab === false}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "defFuncColab",
                              value: false,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.defFuncColab}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errorsFarmaciaData.defFuncColab}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Label className="block text-lg font-medium mb-2 text-fful">
                    Indique quais os serviços que a Farmácia presta aos seus
                    utentes e comunidade que serve:
                  </Form.Label>
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="avalParBioFis"
                    className="ml-10"
                  >
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      Avaliação de parâmetros bioquímicos e fisiológicos
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Sim"
                        name="avalParBioFis"
                        id="avalParBioFisSim"
                        value="true"
                        checked={farmaciaData.avalParBioFis === true}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "avalParBioFis",
                              value: true,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.avalParBioFis}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Não"
                        name="avalParBioFis"
                        id="avalParBioFisNao"
                        value="false"
                        checked={farmaciaData.avalParBioFis === false}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "avalParBioFis",
                              value: false,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.avalParBioFis}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errorsFarmaciaData.avalParBioFis}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="cuidadosFarm"
                    className="ml-10"
                  >
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      Cuidados farmacoterapêuticos
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Sim"
                        name="cuidadosFarm"
                        id="cuidadosFarmSim"
                        value="true"
                        checked={farmaciaData.cuidadosFarm === true}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "cuidadosFarm",
                              value: true,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.cuidadosFarm}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Não"
                        name="cuidadosFarm"
                        id="cuidadosFarmNao"
                        value="false"
                        checked={farmaciaData.cuidadosFarm === false}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "cuidadosFarm",
                              value: false,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.cuidadosFarm}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errorsFarmaciaData.cuidadosFarm}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="cuidadosDermo"
                    className="ml-10"
                  >
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      Cuidados dermofarmacêuticos
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Sim"
                        name="cuidadosDermo"
                        id="cuidadosDermoSim"
                        value="true"
                        checked={farmaciaData.cuidadosDermo === true}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "cuidadosDermo",
                              value: true,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.cuidadosDermo}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Não"
                        name="cuidadosDermo"
                        id="cuidadosDermoNao"
                        value="false"
                        checked={farmaciaData.cuidadosDermo === false}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "cuidadosDermo",
                              value: false,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.cuidadosDermo}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errorsFarmaciaData.cuidadosDermo}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="administracaoMed"
                    className="ml-10"
                  >
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      Administração de medicamentos
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Sim"
                        name="administracaoMed"
                        id="administracaoMedSim"
                        value="true"
                        checked={farmaciaData.administracaoMed === true}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "administracaoMed",
                              value: true,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.administracaoMed}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Não"
                        name="administracaoMed"
                        id="administracaoMedNao"
                        value="false"
                        checked={farmaciaData.administracaoMed === false}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "administracaoMed",
                              value: false,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.administracaoMed}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errorsFarmaciaData.administracaoMed}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="administracaoVac"
                    className="ml-10"
                  >
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      Administração de vacinas extra PNV
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Sim"
                        name="administracaoVac"
                        id="administracaoVacSim"
                        value="true"
                        checked={farmaciaData.administracaoVac === true}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "administracaoVac",
                              value: true,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.administracaoVac}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Não"
                        name="administracaoVac"
                        id="administracaoVacNao"
                        value="false"
                        checked={farmaciaData.administracaoVac === false}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "administracaoVac",
                              value: false,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.administracaoVac}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errorsFarmaciaData.administracaoVac}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="prepSemanal"
                    className="ml-10"
                  >
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      Preparação semanal de medicação
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Sim"
                        name="prepSemanal"
                        id="prepSemanalSim"
                        value="true"
                        checked={farmaciaData.prepSemanal === true}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "prepSemanal",
                              value: true,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.prepSemanal}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Não"
                        name="prepSemanal"
                        id="prepSemanalNao"
                        value="false"
                        checked={farmaciaData.prepSemanal === false}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "prepSemanal",
                              value: false,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.prepSemanal}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errorsFarmaciaData.prepSemanal}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="prepMed"
                    className="ml-10"
                  >
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      Preparação de medicamentos manipulados
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Sim"
                        name="prepMed"
                        id="prepMedSim"
                        value="true"
                        checked={farmaciaData.prepMed === true}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "prepMed",
                              value: true,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.prepMed}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Não"
                        name="prepMed"
                        id="prepMedNao"
                        value="false"
                        checked={farmaciaData.prepMed === false}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "prepMed",
                              value: false,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.prepMed}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errorsFarmaciaData.prepMed}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="apoioDom"
                    className="ml-10"
                  >
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      Apoio domiciliário
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Sim"
                        name="apoioDom"
                        id="apoioDomSim"
                        value="true"
                        checked={farmaciaData.apoioDom === true}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "apoioDom",
                              value: true,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.apoioDom}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Não"
                        name="apoioDom"
                        id="apoioDomNao"
                        value="false"
                        checked={farmaciaData.apoioDom === false}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "apoioDom",
                              value: false,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.apoioDom}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errorsFarmaciaData.apoioDom}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="primeirosSocorros"
                    className="ml-10"
                  >
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      Prestação de primeiros socorros
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Sim"
                        name="primeirosSocorros"
                        id="primeirosSocorrosSim"
                        value="true"
                        checked={farmaciaData.primeirosSocorros === true}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "primeirosSocorros",
                              value: true,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.primeirosSocorros}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Não"
                        name="primeirosSocorros"
                        id="primeirosSocorrosNao"
                        value="false"
                        checked={farmaciaData.primeirosSocorros === false}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "primeirosSocorros",
                              value: false,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.primeirosSocorros}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errorsFarmaciaData.primeirosSocorros}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="dispensaMedDom"
                    className="ml-10"
                  >
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      Dispensa de medicamentos ao domicílio
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Sim"
                        name="dispensaMedDom"
                        id="dispensaMedDomSim"
                        value="true"
                        checked={farmaciaData.dispensaMedDom === true}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "dispensaMedDom",
                              value: true,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.dispensaMedDom}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Não"
                        name="dispensaMedDom"
                        id="dispensaMedDomNao"
                        value="false"
                        checked={farmaciaData.dispensaMedDom === false}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "dispensaMedDom",
                              value: false,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.dispensaMedDom}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errorsFarmaciaData.dispensaMedDom}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Label className="block text-lg font-medium mb-2 text-fful">
                    Intervenção comunitária:
                  </Form.Label>
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="radiografia"
                    className="ml-10"
                  >
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      Recolha de radiografias
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Sim"
                        name="radiografia"
                        id="radiografiaSim"
                        value="true"
                        checked={farmaciaData.radiografia === true}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "radiografia",
                              value: true,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.radiografia}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Não"
                        name="radiografia"
                        id="radiografiaNao"
                        value="false"
                        checked={farmaciaData.radiografia === false}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "radiografia",
                              value: false,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.radiografia}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errorsFarmaciaData.radiografia}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="infoMed"
                    className="ml-10"
                  >
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      Informação sobre medicamentos
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Sim"
                        name="infoMed"
                        id="infoMedSim"
                        value="true"
                        checked={farmaciaData.infoMed === true}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "infoMed",
                              value: true,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.infoMed}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Não"
                        name="infoMed"
                        id="infoMedNao"
                        value="false"
                        checked={farmaciaData.infoMed === false}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "infoMed",
                              value: false,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.infoMed}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errorsFarmaciaData.infoMed}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="campanhaInfo"
                    className="ml-10"
                  >
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      Campanhas de informação para prevenção da doença e
                      promoção da saúde
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Sim"
                        name="campanhaInfo"
                        id="campanhaInfoSim"
                        value="true"
                        checked={farmaciaData.campanhaInfo === true}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "campanhaInfo",
                              value: true,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.campanhaInfo}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Não"
                        name="campanhaInfo"
                        id="campanhaInfoNao"
                        value="false"
                        checked={farmaciaData.campanhaInfo === false}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "campanhaInfo",
                              value: false,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.campanhaInfo}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errorsFarmaciaData.campanhaInfo}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="12" controlId="recolhaMed">
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      Recolha de medicamentos não usados e/ou fora da validade
                      (Valormed)
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Sim"
                        name="recolhaMed"
                        id="recolhaMedSim"
                        value="true"
                        checked={farmaciaData.recolhaMed === true}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "recolhaMed",
                              value: true,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.recolhaMed}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Não"
                        name="recolhaMed"
                        id="recolhaMedNao"
                        value="false"
                        checked={farmaciaData.recolhaMed === false}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "recolhaMed",
                              value: false,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.recolhaMed}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errorsFarmaciaData.recolhaMed}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="12" controlId="programasSeguimento">
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      A Farmácia participa em programas de seguimento
                      farmacoterapêutico ou cuidados farmacêuticos
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Sim"
                        name="programasSeguimento"
                        id="programasSeguimentoSim"
                        value="true"
                        checked={farmaciaData.programasSeguimento === true}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "programasSeguimento",
                              value: true,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.programasSeguimento}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Não"
                        name="programasSeguimento"
                        id="programasSeguimentoNao"
                        value="false"
                        checked={farmaciaData.programasSeguimento === false}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "programasSeguimento",
                              value: false,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.programasSeguimento}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errorsFarmaciaData.programasSeguimento}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="12" controlId="historicoConsumo">
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      A Farmácia faz registo do histórico de consumo de
                      medicamentos dos utentes habituais
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Sim"
                        name="historicoConsumo"
                        id="historicoConsumoSim"
                        value="true"
                        checked={farmaciaData.historicoConsumo === true}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "historicoConsumo",
                              value: true,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.historicoConsumo}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Não"
                        name="historicoConsumo"
                        id="historicoConsumoNao"
                        value="false"
                        checked={farmaciaData.historicoConsumo === false}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "historicoConsumo",
                              value: false,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.historicoConsumo}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errorsFarmaciaData.historicoConsumo}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="protocoloAtendimentoMNSRM"
                  >
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      A Farmácia possui protocolos ou critérios definidos para
                      atendimento em MNSRM
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Sim"
                        name="protocoloAtendimentoMNSRM"
                        id="protocoloAtendimentoMNSRMSim"
                        value="true"
                        checked={
                          farmaciaData.protocoloAtendimentoMNSRM === true
                        }
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "protocoloAtendimentoMNSRM",
                              value: true,
                            },
                          })
                        }
                        isInvalid={
                          !!errorsFarmaciaData.protocoloAtendimentoMNSRM
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Não"
                        name="protocoloAtendimentoMNSRM"
                        id="protocoloAtendimentoMNSRMNao"
                        value="false"
                        checked={
                          farmaciaData.protocoloAtendimentoMNSRM === false
                        }
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "protocoloAtendimentoMNSRM",
                              value: false,
                            },
                          })
                        }
                        isInvalid={
                          !!errorsFarmaciaData.protocoloAtendimentoMNSRM
                        }
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errorsFarmaciaData.protocoloAtendimentoMNSRM}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="12" controlId="participaEstudos">
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      A Farmácia participa regularmente em estudos ou projetos
                      de investigação
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Sim"
                        name="participaEstudos"
                        id="participaEstudosSim"
                        value="true"
                        checked={farmaciaData.participaEstudos === true}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "participaEstudos",
                              value: true,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.participaEstudos}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Não"
                        name="participaEstudos"
                        id="participaEstudosNao"
                        value="false"
                        checked={farmaciaData.participaEstudos === false}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "participaEstudos",
                              value: false,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.participaEstudos}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errorsFarmaciaData.participaEstudos}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="12" controlId="projetosIntLocal">
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      A Farmácia desenvolve projetos de intervenção local
                      (escolas, autarquia, comunidade)
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Sim"
                        name="projetosIntLocal"
                        id="projetosIntLocalSim"
                        value="true"
                        checked={farmaciaData.projetosIntLocal === true}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "projetosIntLocal",
                              value: true,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.projetosIntLocal}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Não"
                        name="projetosIntLocal"
                        id="projetosIntLocalNao"
                        value="false"
                        checked={farmaciaData.projetosIntLocal === false}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "projetosIntLocal",
                              value: false,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.projetosIntLocal}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errorsFarmaciaData.projetosIntLocal}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="12" controlId="servNacionalF">
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      A Farmácia colabora com o Serviço Nacional de
                      Farmacovigilância
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Sim"
                        name="servNacionalF"
                        id="servNacionalFSim"
                        value="true"
                        checked={farmaciaData.servNacionalF === true}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "servNacionalF",
                              value: true,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.servNacionalF}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Não"
                        name="servNacionalF"
                        id="servNacionalFNao"
                        value="false"
                        checked={farmaciaData.servNacionalF === false}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "servNacionalF",
                              value: false,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.servNacionalF}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errorsFarmaciaData.servNacionalF}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="12" controlId="prodPropriaFolhetos">
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      A Farmácia tem produção própria de folhetos de informação
                      ao utente
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Sim"
                        name="prodPropriaFolhetos"
                        id="prodPropriaFolhetosSim"
                        value="true"
                        checked={farmaciaData.prodPropriaFolhetos === true}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "prodPropriaFolhetos",
                              value: true,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.prodPropriaFolhetos}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Não"
                        name="prodPropriaFolhetos"
                        id="prodPropriaFolhetosNao"
                        value="false"
                        checked={farmaciaData.prodPropriaFolhetos === false}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "prodPropriaFolhetos",
                              value: false,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.prodPropriaFolhetos}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errorsFarmaciaData.prodPropriaFolhetos}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="planoPercursoEstagiario"
                  >
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      A Farmácia possui um plano definido para percurso
                      formativo do estagiário
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Sim"
                        name="planoPercursoEstagiario"
                        id="planoPercursoEstagiarioSim"
                        value="true"
                        checked={farmaciaData.planoPercursoEstagiario === true}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "planoPercursoEstagiario",
                              value: true,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.planoPercursoEstagiario}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Não"
                        name="planoPercursoEstagiario"
                        id="planoPercursoEstagiarioNao"
                        value="false"
                        checked={farmaciaData.planoPercursoEstagiario === false}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "planoPercursoEstagiario",
                              value: false,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.planoPercursoEstagiario}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errorsFarmaciaData.planoPercursoEstagiario}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Label className="block text-lg font-medium mb-2 text-fful">
                    A Farmácia possui plano anual de formação para os seus
                    colaboradores:
                  </Form.Label>
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="formInterna"
                    className="ml-10"
                  >
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      Formação interna
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Sim"
                        name="formInterna"
                        id="formInternaSim"
                        value="true"
                        checked={farmaciaData.formInterna === true}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "formInterna",
                              value: true,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.formInterna}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Não"
                        name="formInterna"
                        id="formInternaNao"
                        value="false"
                        checked={farmaciaData.formInterna === false}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "formInterna",
                              value: false,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.formInterna}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errorsFarmaciaData.formInterna}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="ministradaInterna"
                    className="ml-10"
                  >
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      Ministrada por Farmacêuticos da equipa
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Sim"
                        name="ministradaInterna"
                        id="ministradaInternaSim"
                        value="true"
                        checked={farmaciaData.ministradaInterna === true}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "ministradaInterna",
                              value: true,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.ministradaInterna}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Não"
                        name="ministradaInterna"
                        id="ministradaInternaNao"
                        value="false"
                        checked={farmaciaData.ministradaInterna === false}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "ministradaInterna",
                              value: false,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.ministradaInterna}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errorsFarmaciaData.ministradaInterna}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="formExterna"
                    className="ml-10"
                  >
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      Formação Externa
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Sim"
                        name="formExterna"
                        id="formExternaSim"
                        value="true"
                        checked={farmaciaData.formExterna === true}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "formExterna",
                              value: true,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.formExterna}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Não"
                        name="formExterna"
                        id="formExternaNao"
                        value="false"
                        checked={farmaciaData.formExterna === false}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "formExterna",
                              value: false,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.formExterna}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errorsFarmaciaData.formExterna}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="ministradaExterna"
                    className="ml-10"
                  >
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      Ministrada por entidades externas
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Sim"
                        name="ministradaExterna"
                        id="ministradaExternaSim"
                        value="true"
                        checked={farmaciaData.ministradaExterna === true}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "ministradaExterna",
                              value: true,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.ministradaExterna}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Não"
                        name="ministradaExterna"
                        id="ministradaExternaNao"
                        value="false"
                        checked={farmaciaData.ministradaExterna === false}
                        onChange={(e) =>
                          handleChangeFarmaciaData({
                            target: {
                              name: "ministradaExterna",
                              value: false,
                            },
                          })
                        }
                        isInvalid={!!errorsFarmaciaData.ministradaExterna}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errorsFarmaciaData.ministradaExterna}
                    </Form.Control.Feedback>
                  </Form.Group>
                </form>
              </div>
              {/* Orientador */}
              <div className="w-full max-w-3xl mx-auto mb-10">
                <h2 className="text-xl font-bold mb-4 text-center text-fful">
                  Orientador(es) da Farmácia
                </h2>
                {orientadores.map((orientador, index) => (
                  <form key={index} className="space-y-4 mb-8">
                    <h3 className="text-lg font-semibold text-fful">
                      Orientador {index + 1}
                    </h3>
                    <Form.Group as={Col} md="12" controlId={`nome-${index}`}>
                      <Form.Label className="block text-lg font-medium mb-2 text-fful">
                        Nome do Orientador Farmacêutico
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="nome"
                        placeholder="Digite o nome do Orientador Farmacêutico"
                        value={orientador.nome}
                        onChange={(e) => handleChangeOrientadorData(e, index)}
                        isInvalid={!!errorsOrientadorData[index]?.nome}
                        isValid={!errorsOrientadorData[index]?.nome}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorsOrientadorData[index]?.nome}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="12"
                      controlId={`numOrdem-${index}`}
                    >
                      <Form.Label className="block text-lg font-medium mb-2 text-fful">
                        Número da Ordem
                      </Form.Label>
                      <Form.Control
                        type="number"
                        name="numOrdem"
                        placeholder="Digite o número da Ordem"
                        value={orientador.numOrdem}
                        onChange={(e) => handleChangeOrientadorData(e, index)}
                        isInvalid={!!errorsOrientadorData[index]?.numOrdem}
                        isValid={!errorsOrientadorData[index]?.numOrdem}
                        onWheel={(e) => e.target.blur()}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorsOrientadorData[index]?.numOrdem}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Row>
                      <Form.Group as={Col} md="6" controlId={`sexo-${index}`}>
                        <Form.Label className="block text-lg font-medium mb-2 text-fful">
                          Sexo
                        </Form.Label>
                        <Form.Select
                          name="sexo"
                          onChange={(e) => handleChangeOrientadorData(e, index)}
                          value={orientador.sexo}
                          isInvalid={!!errorsOrientadorData[index]?.sexo}
                          isValid={!errorsOrientadorData[index]?.sexo}
                        >
                          <option value="">Selecione o sexo</option>
                          <option value="FEMININO">Feminino</option>
                          <option value="MASCULINO">Masculino</option>
                        </Form.Select>

                        <Form.Control.Feedback
                          type="valid"
                          tooltip
                          style={{ display: "none" }}
                        >
                          <span className="visually-hidden">Success</span>
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          {errorsOrientadorData[index]?.sexo}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId={`habilitacoes-${index}`}
                      >
                        <Form.Label className="block text-lg font-medium mb-2 text-fful">
                          Habilitações
                        </Form.Label>
                        <Form.Select
                          name="habilitacoes"
                          onChange={(e) => handleChangeOrientadorData(e, index)}
                          value={orientador.habilitacoes}
                          isInvalid={
                            !!errorsOrientadorData[index]?.habilitacoes
                          }
                          isValid={!errorsOrientadorData[index]?.habilitacoes}
                        >
                          <option value="">
                            Selecione as suas habilitações
                          </option>
                          <option value="LICENCIATURA">Licenciatura</option>
                          <option value="MICF">MICF</option>
                          <option value="MESTRADO">Mestrado</option>
                          <option value="DOUTORAMENTO">Doutoramento</option>
                          <option value="POS_GRADUACAO">Pós-Graduação</option>
                          <option value="ESPECIALISTA_FARMACIA">
                            Especialista em Farmácia Comunitária
                          </option>
                        </Form.Select>

                        <Form.Control.Feedback
                          type="valid"
                          tooltip
                          style={{ display: "none" }}
                        >
                          <span className="visually-hidden">Success</span>
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          {errorsOrientadorData[index]?.habilitacoes}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    <Row>
                      <Form.Group as={Col} md="6" controlId={`idade-${index}`}>
                        <Form.Label className="block text-lg font-medium mb-2 text-fful">
                          Idade
                        </Form.Label>
                        <Form.Control
                          type="number"
                          name="idade"
                          placeholder="Digite a sua idade"
                          value={orientador.idade}
                          onChange={(e) => handleChangeOrientadorData(e, index)}
                          isInvalid={!!errorsOrientadorData[index]?.idade}
                          isValid={!errorsOrientadorData[index]?.idade}
                          onWheel={(e) => e.target.blur()}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errorsOrientadorData[index]?.idade}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId={`numAnosExperiencia-${index}`}
                      >
                        <Form.Label className="block text-lg font-medium mb-2 text-fful">
                          Número de Anos de Experiência
                        </Form.Label>
                        <Form.Control
                          type="number"
                          name="numAnosExperiencia"
                          placeholder="Digite os seus anos de experiência"
                          value={orientador.numAnosExperiencia}
                          onChange={(e) => handleChangeOrientadorData(e, index)}
                          isInvalid={
                            !!errorsOrientadorData[index]?.numAnosExperiencia
                          }
                          isValid={
                            !errorsOrientadorData[index]?.numAnosExperiencia
                          }
                          onWheel={(e) => e.target.blur()}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errorsOrientadorData[index]?.numAnosExperiencia}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    {orientadores.length > 1 && (
                      <button
                        type="button"
                        className="bg-red-700 hover:bg-red-900 text-white py-2 px-4 rounded"
                        onClick={() => removeOrientador(index)}
                      >
                        Remover orientador
                      </button>
                    )}
                  </form>
                ))}
                <div className="text-center mt-4">
                  <button
                    type="button"
                    className="bg-fful hover:bg-iconhover text-white py-2 px-4 rounded"
                    onClick={addOrientador}
                  >
                    Adicionar outro orientador
                  </button>
                </div>
              </div>
              {/* Bloco */}
              <div className="w-full max-w-3xl mx-auto mb-10">
                <h2 className="text-xl font-bold mb-4 text-center text-fful">
                  Bloco da Vaga de Farmácia Disponível
                </h2>
                {blocos.map((bloco, index) => (
                  <form key={index} className="space-y-4 mb-8">
                    <h3 className="text-lg font-semibold text-fful">
                      Bloco {index + 1}
                    </h3>
                    <div>
                      <label
                        htmlFor={`blocoF-${index}`}
                        className="block text-lg font-medium mb-2 text-fful"
                      >
                        Bloco
                      </label>
                      <Select
                        isSearchable
                        isClearable
                        name={`idBlocoF-${index}`}
                        options={getAvailableBlocos(index).map((blocoF) => ({
                          value: blocoF.idBlocoF,
                          label: `Bloco: ${blocoF.bloco} | Início: ${blocoF.dataInicio} | Fim: ${blocoF.dataFim}`,
                        }))}
                        onChange={(option) => handleChangeBlocoF(index, option)}
                        value={
                          blocosF
                            .filter(
                              (blocoF) => blocoF.idBlocoF === bloco.idBlocoF
                            )
                            .map((blocoF) => ({
                              value: blocoF.idBlocoF,
                              label: `Bloco: ${blocoF.bloco} | Início: ${blocoF.dataInicio} | Fim: ${blocoF.dataFim}`,
                            }))[0]
                        }
                        styles={{
                          control: (base, state) => ({
                            ...base,
                            borderColor: errorsBlocoData[index]?.idBlocoF
                              ? "red"
                              : state.isFocused || state.isSelected
                              ? "green"
                              : base.borderColor,
                            boxShadow: errorsBlocoData[index]?.idBlocoF
                              ? "0 0 0 1px red"
                              : state.isFocused
                              ? "0 0 0 1px green"
                              : base.boxShadow,
                          }),
                        }}
                      />
                      {errorsBlocoData[index]?.idBlocoF && (
                        <p className="text-red-500 mt-1">
                          {errorsBlocoData[index].idBlocoF}
                        </p>
                      )}
                    </div>
                    <div>
                      <label
                        htmlFor={`numVagas-${index}`}
                        className="block text-lg font-medium mb-2 text-fful"
                      >
                        Número de Vagas
                      </label>
                      <select
                        id={`numVagas-${index}`}
                        value={numVagas[index] || 1}
                        onChange={(e) =>
                          handleNumVagasChange(index, e.target.value)
                        }
                        className="border border-gray-300 rounded px-3 py-2 w-full"
                      >
                        {[1].map((num) => (
                          <option key={num} value={num}>
                            {num}
                          </option>
                        ))}
                      </select>
                    </div>
                    {blocos.length > 1 && (
                      <button
                        type="button"
                        className="bg-red-700 hover:bg-red-900 text-white py-2 px-4 rounded"
                        onClick={() => removeBloco(index)}
                      >
                        Remover bloco
                      </button>
                    )}
                  </form>
                ))}
                <div className="text-center mt-4">
                  <button
                    type="button"
                    className="bg-fful hover:bg-iconhover text-white py-2 px-4 rounded"
                    onClick={addBloco}
                  >
                    Adicionar outro bloco
                  </button>
                </div>
              </div>
              <div className="text-center mt-20 mb-14">
                <button
                  type="submit"
                  onClick={handleSubmit}
                  disabled={!isFormValid()}
                  className={
                    !isFormValid()
                      ? "text-white px-4 py-2 rounded-md bg-gray-400 cursor-not-allowed font-bold"
                      : "text-white px-4 py-2 rounded-md bg-fful hover:bg-iconhover font-bold"
                  }
                >
                  Submeter Formulário
                </button>
              </div>
            </div>
          )}
        </div>
      )}

      {/* Success Modal */}
      <Modal
        show={showSuccessModal}
        onHide={() => setShowSuccessModal(false)}
        centered
      >
        <Modal.Body className="text-center py-5">
          <p className="mb-4" style={{ fontSize: "1.5rem" }}>
            Formulário enviado com sucesso.
          </p>
          <button
            className="bg-fful hover:bg-iconhover text-white py-2 px-4 rounded"
            onClick={handleCloseModal}
          >
            Fechar
          </button>
        </Modal.Body>
      </Modal>
    </div>
  );
}
