import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { FaRegSave } from "react-icons/fa";
import { ImCancelCircle } from "react-icons/im";
import Modal from "react-modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { AiOutlineUserAdd } from "react-icons/ai";

Modal.setAppElement("#root");

function CreateAluno({ anoLetivo }) {
  const INITIAL_STATE = {
    numAluno: "",
    anoLetivo: anoLetivo || getCurrentAcademicYear(),
    nome: "",
    morada: "",
    codPostal: "",
    localidade: "",
    email: "",
    emailAlternativo: "",
    estatuto: "ALUNO",
    telefone: "",
    telemovel: "",
    obs: "",
    visivel: true,
    revisaoTrabalho: false,
  };
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [newAlunoData, setNewAlunoData] = useState(INITIAL_STATE);

  const handleReset = () => {
    setNewAlunoData(INITIAL_STATE);
  };

  const apiUrl = process.env.REACT_APP_API_ALUNOS;

  const [errors, setErrors] = useState({
    numAluno: "",
    nome: "",
    morada: "",
    codPostal: "",
    localidade: "",
    email: "",
    emailAlternativo: "",
    estatuto: "",
    telefone: "",
    telemovel: "",
  });

  function getCurrentAcademicYear() {
    const today = new Date();
    const currentYear = today.getFullYear();

    // Determine the start and end months of the academic year
    const academicYearStartMonth = 8; // September (0-based index)
    // const academicYearEndMonth = 8; // August (0-based index)

    // Determine the academic year based on the current month
    if (today.getMonth() >= academicYearStartMonth) {
      // If the current month is greater than or equal to the start month,
      // consider it as part of the current academic year
      return `${currentYear}-${currentYear + 1}`;
    } else {
      // If the current month is before the start month,
      // consider it as part of the previous academic year
      return `${currentYear - 1}-${currentYear}`;
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewAlunoData((prevNewAlunoData) => ({
      ...prevNewAlunoData,
      [name]: value,
    }));
    validateField(name, value);
  };

  const validateField = (fieldName, value) => {
    let error = "";
    switch (fieldName) {
      case "numAluno":
        if (!value) {
          error = "Campo obrigatório";
        } else if (!/^\d+$/.test(value)) {
          error = "O número de aluno só pode conter dígitos";
        }
        break;
      case "nome":
        if (!value) {
          error = "Campo obrigatório";
        } else if (!/^[a-zA-Z\u00C0-\u017F\s']+$/.test(value)) {
          error = "O nome d@ alun@ só pode conter letras";
        }
        break;
      case "morada":
        if (!value) {
          error = "Campo obrigatório";
        }
        break;
      case "codPostal":
        if (!value) {
          error = "Campo obrigatório";
        } else if (!/\d\d\d\d-\d\d\d/.test(value)) {
          error = "Código postal deve obedecer ao formato XXXX-XXX";
        }
        break;
      case "localidade":
        if (!value) {
          error = "Campo obrigatório";
        }
        break;
      case "email":
        if (!value) {
          error = "Campo obrigatório";
        } else if (
          !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(value)
        ) {
          error = "Formato de email inválido";
        }
        break;
      case "telefone":
        if (!value) {
          error = "Campo obrigatório";
        } else if (!/^\d{9}$/.test(value)) {
          error = "Número de telefone tem de conter 9 dígitos";
        }
        break;
      case "telemovel":
        if (!value) {
          error = "Campo obrigatório";
        } else if (!/^\d{9}$/.test(value)) {
          error = "Número de telemóvel tem de conter 9 dígitos";
        }
        break;
      default:
        break;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: error,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    fetch(`${apiUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newAlunoData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setModalIsOpen(false);
        handleReset();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    handleReset();
    setModalIsOpen(false);
  };

  useEffect(() => {
    if (modalIsOpen) {
      for (const key in newAlunoData) {
        validateField(key, newAlunoData[key]);
      }
    }
  }, [modalIsOpen]);

  return (
    <>
      <button
        className="text-purple-700 mx-1 flex items-center"
        title="Adicionar"
        onClick={openModal}
      >
        <span className="mr-2">Adicionar aluno</span>
        <AiOutlineUserAdd />
      </button>
      <Modal isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)}>
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg p-6 md:p-8">
            <h2 className="text-lg font-semibold mb-4 bg-fful px-4 py-2 rounded-lg text-white">
              Adicionar Aluno
            </h2>
            <Form noValidate onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Form.Group as={Col} md="3" controlId="numAluno">
                  <Form.Label>Número de Aluno</Form.Label>
                  <Form.Control
                    type="text"
                    name="numAluno"
                    onChange={handleChange}
                    value={newAlunoData.numAluno}
                    isInvalid={!!errors.numAluno}
                    isValid={!errors.numAluno}
                  />
                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.numAluno}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="8" controlId="nome">
                  <Form.Label>Nome</Form.Label>
                  <Form.Control
                    type="text"
                    name="nome"
                    onChange={handleChange}
                    value={newAlunoData.nome}
                    isInvalid={!!errors.nome}
                    isValid={!errors.nome}
                  />
                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.nome}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="morada">
                  <Form.Label>Morada</Form.Label>
                  <Form.Control
                    type="text"
                    name="morada"
                    onChange={handleChange}
                    value={newAlunoData.morada}
                    isInvalid={!!errors.morada}
                    isValid={!errors.morada}
                  />

                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.morada}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="3" controlId="codPostal">
                  <Form.Label>Código Postal</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="1234-123"
                    name="codPostal"
                    onChange={handleChange}
                    isInvalid={!!errors.codPostal}
                    isValid={!errors.codPostal}
                  />

                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.codPostal}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="3" controlId="localidade">
                  <Form.Label>Localidade</Form.Label>
                  <Form.Control
                    type="text"
                    // placeholder="Lisboa"
                    name="localidade"
                    onChange={handleChange}
                    value={newAlunoData.localidade}
                    isInvalid={!!errors.localidade}
                    isValid={!errors.localidade}
                  />
                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.localidade}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="username@edu.ulisboa.pt"
                    name="email"
                    onChange={handleChange}
                    value={newAlunoData.email}
                    isInvalid={!!errors.email}
                    isValid={!errors.email}
                  />

                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="emailAlternativo">
                  <Form.Label>Email Alternativo</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="username@gmail.com"
                    name="emailAlternativo"
                    onChange={handleChange}
                    value={newAlunoData.emailAlternativo}
                    isInvalid={!!errors.emailAlternativo}
                    isValid={!errors.emailAlternativo}
                  />

                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.emailAlternativo}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="3" controlId="telefone">
                  <Form.Label>Telefone</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="212212212"
                    name="telefone"
                    onChange={handleChange}
                    value={newAlunoData.telefone}
                    isInvalid={!!errors.telefone}
                    isValid={!errors.telefone}
                  />
                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.telefone}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="3" controlId="telemovel">
                  <Form.Label>Telemóvel</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="919919919"
                    name="telemovel"
                    onChange={handleChange}
                    value={newAlunoData.telemovel}
                    isInvalid={!!errors.telemovel}
                    isValid={!errors.telemovel}
                  />

                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.telemovel}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="3" controlId="estatuto">
                  <Form.Label>Estatuto</Form.Label>

                  <Form.Select
                    name="estatuto"
                    onChange={handleChange}
                    value={newAlunoData.estatuto}
                  >
                    <option value="ALUNO">Aluno</option>
                    <option value="DIRIGENTES_DO_ENSINO_SUPERIOR">
                      Dirigentes do ensino superior
                    </option>
                    <option value="PRATICANTE_DE_CONFISSOES_RELIGIOSAS">
                      Praticante de confissões religiosas
                    </option>
                    <option value="MAES_E_PAIS_ESTUDANTES_GRÁVIDAS_PUÉRPERAS_E_LACTANTES">
                      Mães e pais estudantes, grávidas, puérperas e lactantes
                    </option>
                    <option value="ESTUDANTE_COM_NECESSIDADES_EDUCATIVAS_ESPECIAIS">
                      Estudante com necessidades educativas especiais
                    </option>
                    <option value="ESTUDANTE_MILITAR">Estudante militar</option>
                    <option value="TRABALHADOR_ESTUDANTE">
                      Trabalhador estudante
                    </option>
                    <option value="BOMBEIRO">Bombeiro</option>
                    <option value="ESTUDANTE_ATLETA">Estudante atleta</option>
                  </Form.Select>

                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.estatuto}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="obs">
                  <Form.Label>Observações</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="obs"
                    onChange={handleChange}
                    value={newAlunoData.obs}
                    isInvalid={!!errors.obs}
                    isValid={!errors.obs}
                  />

                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.obs}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <div className="py-4">
                <button
                  type="submit"
                  disabled={Object.values(errors).some((value) => value !== "")}
                  className={
                    Object.values(errors).some((value) => value !== "")
                      ? "text-white px-4 py-2 rounded-md bg-gray-400 cursor-not-allowed"
                      : "text-white px-4 py-2 rounded-md bg-fful hover:bg-iconhover"
                  }
                >
                  <FaRegSave />
                </button>
                <button
                  type="button"
                  className="text-white px-4 py-2 mx-2 rounded-md bg-fful hover:bg-iconhover"
                  onClick={closeModal}
                >
                  <ImCancelCircle />
                </button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default CreateAluno;
