import React, { useState, useEffect } from "react";
import CreateBlocoH from "../Create/CreateBlocoH";
import EditBlocoH from "../Edit/EditBlocoH";

function GerirBlocosH(anoLetivo) {
  const [blocosH, setBlocosH] = useState([]);

  const apiBlocoH = process.env.REACT_APP_API_BLOCOH;

  useEffect(() => {
    fetch(`${apiBlocoH}/anoLetivo/${anoLetivo.anoLetivo}`)
      .then((response) => response.json())
      .then((data) => setBlocosH(data));
  }, [apiBlocoH]);

  return (
    <div className="flex flex-col items-stretch min-h-full">
      <main className="flex-grow p-4">
        <div className="flex items-center mb-4">
          <CreateBlocoH key={anoLetivo} anoLetivo={anoLetivo.anoLetivo} />
        </div>
        <table className="table-auto w-full">
          <thead>
            <tr>
              <th className="px-4 py-2">ID</th>
              <th className="px-4 py-2">Bloco</th>
              <th className="px-4 py-2">Data Início</th>
              <th className="px-4 py-2">Data Fim</th>
              <th className="px-4 py-2">Ações</th>
            </tr>
          </thead>
          <tbody>
            {blocosH.map((bloco) => (
              <tr key={bloco.idBlocoH}>
                <td className="border px-4 py-2">{bloco.idBlocoH}</td>
                <td className="border px-4 py-2">{bloco.bloco}</td>
                <td className="border px-4 py-2">{bloco.dataInicio}</td>
                <td className="border px-4 py-2">{bloco.dataFim}</td>
                <td className="border px-4 py-2">
                  <div>
                    <EditBlocoH blocoData={bloco} />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </main>
    </div>
  );
}

export default GerirBlocosH;
