import React, { useState, useEffect } from "react";
import { Form, Row, Col, Modal } from "react-bootstrap";
import Select from "react-select";

export default function BodyFormTrabalho() {
  const anoLetivo = getCurrentAcademicYear();

  const INITIAL_STATE_TRABALHO = {
    anoLetivo: anoLetivo,
    orientador: "",
    tema: "",
    coorientador1: "",
    origem: "",
    tipoTrabalho: "",
    nVagas: "",
    nVagasInicial: "",
    obs: "",
    visivel: true,
  };

  const [trabalhos, setTrabalhos] = useState([INITIAL_STATE_TRABALHO]);
  const [alunos, setAlunos] = useState([]);
  const [isDateValid, setIsDateValid] = useState(false);
  const [datas, setDatas] = useState([]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState({
    0: {
      orientador: "",
      tema: "",
      tipoTrabalho: "",
      nVagas: "",
    },
  });
  const [selectedAlunos, setSelectedAlunos] = useState({});

  const apiDatas = process.env.REACT_APP_API_DATAS;
  const apiTrabalhos = process.env.REACT_APP_API_TRABALHOS;
  const apiAlunos = process.env.REACT_APP_API_ALUNOS;

  function getCurrentAcademicYear() {
    const today = new Date();
    const currentYear = today.getFullYear();
    // Determine the start and end months of the academic year
    const academicYearStartMonth = 8; // September (0-based index)
    // const academicYearEndMonth = 8; // August (0-based index)
    // Determine the academic year based on the current month
    if (today.getMonth() >= academicYearStartMonth) {
      // If the current month is greater than or equal to the start month,
      // consider it as part of the current academic year
      return `${currentYear}-${currentYear + 1}`;
    } else {
      // If the current month is before the start month,
      // consider it as part of the previous academic year
      return `${currentYear - 1}-${currentYear}`;
    }
  }

  // Add this function to handle aluno selection change
  const handleAlunoChange = (index, selectedOptions) => {
    const maxVagas = parseInt(trabalhos[index].nVagas, 10);
    if (selectedOptions.length > maxVagas) {
      alert(
        `Você só pode selecionar ${maxVagas} aluno${
          maxVagas > 1 ? "s" : ""
        } para este trabalho.`
      );
      return;
    }
    setSelectedAlunos((prev) => ({ ...prev, [index]: selectedOptions }));
  };

  // Modify the existing useEffect for fetching alunos to format them for react-select
  useEffect(() => {
    fetch(`${apiAlunos}/anoLetivo/${anoLetivo}`)
      .then((response) => response.json())
      .then((data) => {
        const formattedAlunos = data.map((aluno) => ({
          value: aluno.idAluno,
          label: `${aluno.numAluno} - ${aluno.nome}`,
          numAluno: aluno.numAluno,
          name: aluno.nome,
        }));
        setAlunos(formattedAlunos);
      });
  }, [apiAlunos, anoLetivo]);

  // useEffect(() => {
  //   fetch(`${apiAlunos}/anoLetivo/${anoLetivo}`)
  //     .then((response) => response.json())
  //     .then((data) => setAlunos(data));
  // }, [apiAlunos]);

  useEffect(() => {
    fetch(`${apiDatas}/1`)
      .then((response) => response.json())
      .then((data) => {
        setDatas(data);

        const currentDate = new Date();
        const startDateTrabalhos = new Date(data.startDateTrabalhos);
        const endDateTrabalhos = new Date(data.endDateTrabalhos);
        setIsDateValid(
          currentDate >= startDateTrabalhos && currentDate <= endDateTrabalhos
        );
        setIsLoading(false);
      });
  }, [apiDatas]);

  useEffect(() => {
    trabalhos.forEach((trabalho, index) => {
      for (const key in trabalho) {
        validateField(key, trabalho[key], index);
      }
    });
  }, [trabalhos]);

  if (!isLoading && !isDateValid) {
    return (
      <>
        <h1 className="flex items-center justify-center text-fful my-16">
          Neste momento o preenchimento do formulário encontra-se encerrado.
        </h1>
      </>
    );
  }

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    setTrabalhos((prevTrabalhos) =>
      prevTrabalhos.map((trabalho, i) =>
        i === index || (name === "orientador" && index === 0)
          ? {
              ...trabalho,
              [name]: value,
              ...(name === "nVagas" ? { nVagasInicial: value } : {}),
            }
          : trabalho
      )
    );
    validateField(name, value, index);
  };

  const validateField = (fieldName, value, index) => {
    let error = "";
    switch (fieldName) {
      case "orientador":
        if (!value) {
          error = "Campo obrigatório";
        } else if (!/^[a-zA-Z\u00C0-\u017F\s']+$/.test(value)) {
          error = "O nome só pode conter letras";
        }
        break;
      case "tema":
      case "tipoTrabalho":
      case "nVagas":
        if (!value) {
          error = "Campo obrigatório";
        }
        break;

      default:
        break;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [index]: {
        ...prevErrors[index],
        [fieldName]: error,
      },
    }));
  };

  const addTrabalho = () => {
    setTrabalhos((prevTrabalhos) => [
      ...prevTrabalhos,
      { ...INITIAL_STATE_TRABALHO, orientador: prevTrabalhos[0].orientador },
    ]);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [trabalhos.length]: {
        tema: "",
        tipoTrabalho: "",
        nVagas: "",
      },
    }));
  };

  const removeTrabalho = (index) => {
    setTrabalhos((prevTrabalhos) =>
      prevTrabalhos.filter((_, i) => i !== index)
    );
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[index];
      // Reindex the remaining errors
      return Object.fromEntries(
        Object.entries(newErrors).map(([key, value], i) => [i, value])
      );
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      for (let i = 0; i < trabalhos.length; i++) {
        const trabalho = trabalhos[i];

        // Create trabalho
        const trabalhoResponse = await fetch(`${apiTrabalhos}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(trabalho),
        });

        if (!trabalhoResponse.ok) {
          throw new Error(`Erro ao criar trabalho: ${trabalhoResponse.status}`);
        }

        const trabalhoId = await trabalhoResponse.json();

        if (!trabalhoId) {
          console.error("ID do trabalho não foi retornado pela API");
          continue;
        }

        console.log(`Trabalho criado com ID: ${trabalhoId}`);

        // Update alunos with the new trabalhoId
        const selectedAlunosForTrabalho = selectedAlunos[i] || [];
        for (const aluno of selectedAlunosForTrabalho) {
          console.log(
            `A atualizar aluno ${aluno.value} com trabalho ${trabalhoId}`
          );
          try {
            // First, fetch the current aluno data
            const alunoGetResponse = await fetch(`${apiAlunos}/${aluno.value}`);
            if (!alunoGetResponse.ok) {
              throw new Error(
                `Erro ao obter dados do aluno: ${alunoGetResponse.status}`
              );
            }
            const currentAlunoData = await alunoGetResponse.json();

            // Update the idTrabalho field
            const updatedAlunoData = {
              ...currentAlunoData,
              idTrabalho: trabalhoId,
            };

            // Send the updated aluno data back to the server
            const alunoUpdateResponse = await fetch(
              `${apiAlunos}/${aluno.value}`,
              {
                method: "PUT",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(updatedAlunoData),
              }
            );

            if (!alunoUpdateResponse.ok) {
              const errorText = await alunoUpdateResponse.text();
              console.error(
                `Erro ao atualizar aluno ${aluno.value}: ${alunoUpdateResponse.status}`,
                errorText
              );
            } else {
              console.log(`Aluno ${aluno.value} atualizado com sucesso`);

              // Update the trabalho's nVagas
              const trabalhoGetResponse = await fetch(
                `${apiTrabalhos}/${trabalhoId}`
              );
              if (!trabalhoGetResponse.ok) {
                throw new Error(
                  `Erro ao obter dados do trabalho: ${trabalhoGetResponse.status}`
                );
              }
              const currentTrabalhoData = await trabalhoGetResponse.json();

              const updatedTrabalhoData = {
                ...currentTrabalhoData,
                nVagas: Math.max(
                  0,
                  parseInt(currentTrabalhoData.nVagas) - 1
                ).toString(),
              };

              const trabalhoUpdateResponse = await fetch(
                `${apiTrabalhos}/${trabalhoId}`,
                {
                  method: "PUT",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(updatedTrabalhoData),
                }
              );

              if (!trabalhoUpdateResponse.ok) {
                console.error(
                  `Erro ao atualizar nVagas do trabalho ${trabalhoId}: ${trabalhoUpdateResponse.status}`
                );
              } else {
                console.log(
                  `nº vagas do trabalho ${trabalhoId} atualizado com sucesso`
                );
              }
            }
          } catch (error) {
            console.error(
              `Erro ao atualizar aluno ${aluno.value} ou trabalho ${trabalhoId}:`,
              error
            );
          }
        }
      }

      // Show success modal
      setShowSuccessModal(true);

      // Reset the form
      setTrabalhos([INITIAL_STATE_TRABALHO]);
      setSelectedAlunos({});
      setErrors({
        0: {
          orientador: "",
          tema: "",
          tipoTrabalho: "",
          nVagas: "",
        },
      });
    } catch (error) {
      console.error("Erro ao submeter formulário:", error);
      alert(
        `Ocorreu um erro ao submeter o formulário: ${error.message}. Por favor, tente novamente.`
      );
    }
  };

  const handleCloseModal = () => {
    setShowSuccessModal(false);
    window.location.reload();
  };

  const isFormValid = () => {
    const isValid = trabalhos.every((trabalho, index) => {
      const errorObj = errors[index];
      const isTrabalhoValid =
        trabalho.orientador &&
        trabalho.tema &&
        trabalho.tipoTrabalho &&
        trabalho.nVagas &&
        (!errorObj || Object.values(errorObj).every((error) => error === ""));

      return isTrabalhoValid;
    });

    return isValid;
  };

  return (
    <div>
      <div className="flex flex-col items-center justify-center text-center">
        <h1 className="text-5xl font-bold my-10 text-fful mb-10">
          Formulário de Trabalhos de Estágio
        </h1>
      </div>
      {/* Start of the form */}
      <div>
        <div className="w-full max-w-3xl mx-auto mb-10">
          <form className="space-y-4">
            <Form.Group as={Col} md="12" controlId="orientador">
              <Form.Label className="block text-lg font-medium mb-2 text-fful mt-10">
                Nome do Orientador
              </Form.Label>
              <Form.Control
                type="text"
                name="orientador"
                placeholder="Digite o nome do Orientador"
                value={trabalhos[0].orientador}
                onChange={(e) => handleChange(e, 0)}
                isInvalid={!!errors[0]?.orientador}
                isValid={!errors[0]?.orientador}
              />
              <Form.Control.Feedback
                type="valid"
                tooltip
                style={{ display: "none" }}
              >
                <span className="visually-hidden">Success</span>
              </Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                {errors[0]?.orientador}
              </Form.Control.Feedback>
            </Form.Group>
          </form>
        </div>

        <div className="w-full max-w-3xl mx-auto mb-10">
          <h2 className="text-xl font-bold mb-4 text-center text-fful">
            Informações do Trabalho
          </h2>
          {trabalhos.map((trabalho, index) => (
            <form key={index} className="space-y-4 mb-8">
              <h3 className="text-lg font-semibold text-fful">
                Trabalho {index + 1}
              </h3>
              <Form.Group as={Col} md="12" controlId={`tema-${index}`}>
                <Form.Label className="block text-lg font-medium mb-2 text-fful">
                  Tema do Trabalho
                </Form.Label>
                <Form.Control
                  type="text"
                  name="tema"
                  placeholder="Digite o tema do trabalho"
                  value={trabalho.tema}
                  onChange={(e) => handleChange(e, index)}
                  isInvalid={!!errors[index]?.tema}
                  isValid={!errors[index]?.tema}
                />
                <Form.Control.Feedback type="invalid">
                  {errors[index]?.tema}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="12" controlId={`coorientador1-${index}`}>
                <Form.Label className="block text-lg font-medium mb-2 text-fful">
                  Nome do Coorientador (se aplicável)
                </Form.Label>
                <Form.Control
                  type="text"
                  name="coorientador1"
                  placeholder="Digite o nome do coorientador"
                  value={trabalho.coorientador1}
                  onChange={(e) => handleChange(e, index)}
                  isInvalid={!!errors[index]?.coorientador1}
                  isValid={!errors[index]?.coorientador1}
                />
                <Form.Control.Feedback type="invalid">
                  {errors[index]?.coorientador1}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="12" controlId={`origem-${index}`}>
                <Form.Label className="block text-lg font-medium mb-2 text-fful">
                  Estabelecimento de ensino / Entidade de origem do Coorientador
                  (se aplicável)
                </Form.Label>
                <Form.Control
                  type="text"
                  name="origem"
                  placeholder="Digite a origem do coorientador"
                  value={trabalho.origem}
                  onChange={(e) => handleChange(e, index)}
                  isInvalid={!!errors[index]?.origem}
                  isValid={!errors[index]?.origem}
                />
                <Form.Control.Feedback type="invalid">
                  {errors[index]?.origem}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId={`tipoTrabalho-${index}`}>
                <Form.Label className="block text-lg font-medium mb-2 text-fful">
                  Tipo de Trabalho
                </Form.Label>
                <Form.Select
                  name="tipoTrabalho"
                  onChange={(e) => handleChange(e, index)}
                  value={trabalho.tipoTrabalho}
                  isInvalid={!!errors[index]?.tipoTrabalho}
                  isValid={!errors[index]?.tipoTrabalho}
                >
                  <option value="">Selecione o tipo de trabalho</option>
                  <option value="MONOGRAFIA">Monografia</option>
                  <option value="TRABALHOCAMPO">Trabalho de Campo</option>
                </Form.Select>

                <Form.Control.Feedback
                  type="valid"
                  tooltip
                  style={{ display: "none" }}
                >
                  <span className="visually-hidden">Success</span>
                </Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  {errors[index]?.tipoTrabalho}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId={`nVagas-${index}`}>
                <Form.Label className="block text-lg font-medium mb-2 text-fful">
                  Número de vagas
                </Form.Label>
                <Form.Select
                  name="nVagas"
                  onChange={(e) => {
                    handleChange(e, index);
                    // Reset selected alunos when nVagas changes
                    setSelectedAlunos((prev) => ({ ...prev, [index]: [] }));
                  }}
                  value={trabalho.nVagas}
                  isInvalid={!!errors[index]?.nVagas}
                  isValid={!errors[index]?.nVagas}
                >
                  <option value="">Selecione o nº de vagas disponíveis</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors[index]?.nVagas}
                </Form.Control.Feedback>
              </Form.Group>
              <div>
                <label
                  htmlFor={`alunos-${index}`}
                  className="block text-lg font-medium mb-2 text-fful"
                >
                  Aluno (Se o trabalho/projeto estiver a decorrer)
                </label>
                <Select
                  isMulti
                  isSearchable
                  name={`alunos-${index}`}
                  options={alunos}
                  onChange={(selectedOptions) =>
                    handleAlunoChange(index, selectedOptions)
                  }
                  value={selectedAlunos[index] || []}
                  filterOption={(option, inputValue) => {
                    const { label } = option;
                    return label
                      .toLowerCase()
                      .includes(inputValue.toLowerCase());
                  }}
                  placeholder="Pesquisar por número de aluno ou nome"
                  noOptionsMessage={() => "Nenhum aluno encontrado"}
                  isDisabled={!trabalho.nVagas}
                />
              </div>
              {trabalhos.length > 1 && (
                <button
                  type="button"
                  className="bg-red-700 hover:bg-red-900 text-white py-2 px-4 rounded"
                  onClick={() => removeTrabalho(index)}
                >
                  Remover trabalho
                </button>
              )}
            </form>
          ))}
          <div className="text-center mt-4">
            <button
              type="button"
              className="bg-fful hover:bg-iconhover text-white py-2 px-4 rounded"
              onClick={addTrabalho}
            >
              Adicionar outro trabalho
            </button>
          </div>
        </div>
        <div className="text-center mt-20 mb-14">
          <button
            type="submit"
            onClick={handleSubmit}
            disabled={!isFormValid()}
            className={
              !isFormValid()
                ? "text-white px-4 py-2 rounded-md bg-gray-400 cursor-not-allowed font-bold"
                : "text-white px-4 py-2 rounded-md bg-fful hover:bg-iconhover font-bold"
            }
          >
            Submeter Formulário
          </button>
        </div>
      </div>

      {/* Success Modal */}
      <Modal
        show={showSuccessModal}
        onHide={() => setShowSuccessModal(false)}
        centered
      >
        <Modal.Body className="text-center py-5">
          <p className="mb-4" style={{ fontSize: "1.5rem" }}>
            Formulário enviado com sucesso.
          </p>
          <button
            className="bg-fful hover:bg-iconhover text-white py-2 px-4 rounded"
            onClick={handleCloseModal}
          >
            Fechar
          </button>
        </Modal.Body>
      </Modal>
    </div>
  );
}
