import React, { useState, useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import * as XLSX from "xlsx";

function ImportarAlunos({ anoLetivo, onImportComplete }) {
  const [file, setFile] = useState(null);
  const [importing, setImporting] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleImport = async () => {
    if (!file) {
      alert("Por favor, selecione um arquivo Excel (.xlsx)");
      return;
    }

    setImporting(true);

    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);

      const alunosData = jsonData.map((row) => ({
        anoLetivo: anoLetivo,
        numAluno: row["Nº de Aluno"],
        nome: row["Nome"],
        estatuto: (() => {
          const estatutoValue = row["Estatutos"].toLowerCase();
          if (!estatutoValue || estatutoValue.includes("sem prioridade")) {
            return "ALUNO";
          } else if (estatutoValue.includes("ensino superior")) {
            return "DIRIGENTES_DO_ENSINO_SUPERIOR";
          } else if (estatutoValue.includes("religiosas")) {
            return "PRATICANTE_DE_CONFISSOES_RELIGIOSAS";
          } else if (estatutoValue.includes("pais")) {
            return "MAES_E_PAIS_ESTUDANTES_GRÁVIDAS_PUÉRPERAS_E_LACTANTES";
          } else if (estatutoValue.includes("educativas especiais")) {
            return "ESTUDANTES_COM_NECESSIDADES_EDUCATIVAS_ESPECIAIS";
          } else if (estatutoValue.includes("militar")) {
            return "ESTUDANTES_MILITAR";
          } else if (estatutoValue.includes("trabalhador estudante")) {
            return "TRABALHADOR_ESTUDANTE";
          } else if (estatutoValue.includes("bombeiro")) {
            return "BOMBEIRO";
          } else if (estatutoValue.includes("atleta")) {
            return "ESTUDANTE_ATLETA";
          } else {
            return "ALUNO"; // Default case if none of the conditions match
          }
        })(),
        emailAlternativo: row["Email"],
        email: row["Email Institucional"],
        telefone: row["Telefone"]?.replace(/[+\s]/g, "") || "",
        telemovel: row["Telemóvel"]?.replace(/[+\s]/g, "") || "",
        morada: row["Morada"],
        codPostal: row["Código Postal"],
        localidade: row["Área do Código Postal"],
        obs: "",
        visivel: true,
        revisaoTrabalho: false,
      }));

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_ALUNOS}/bulk`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(alunosData),
          }
        );

        if (response.ok) {
          alert("Alunos importados com sucesso!");
          onImportComplete();
        } else {
          alert("Erro ao importar alunos. Por favor, tente novamente.");
        }
      } catch (error) {
        console.error("Error importing students:", error);
        alert("Erro ao importar alunos. Por favor, tente novamente.");
      }

      setImporting(false);
      setFile(null);
    };

    reader.readAsArrayBuffer(file);
  };

  return (
    <>
      <button
        className="text-purple-700 mx-1 flex items-center"
        onClick={() => setShowModal(true)}
      >
        Importar Alunos
      </button>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Importar Alunos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="flex flex-col items-center mb-4">
            <div className="custom-file-input mb-2">
              <input
                type="file"
                accept=".xlsx"
                onChange={handleFileChange}
                ref={fileInputRef}
                className="hidden"
                id="file-input"
              />
              <label
                htmlFor="file-input"
                className="bg-fful hover:bg-iconhover text-white font-semibold py-1 px-3 rounded inline-flex items-center cursor-pointer text-sm"
              >
                <span>Escolher ficheiro</span>
              </label>
            </div>
            <span className="text-center">
              {file ? file.name : "Nenhum ficheiro escolhido"}
            </span>
          </div>
          <div className="flex justify-end">
            <button
              className={`text-white px-4 py-2 rounded-md ${
                file
                  ? "bg-fful hover:bg-iconhover cursor-pointer"
                  : "bg-gray-400 cursor-not-allowed"
              }`}
              onClick={handleImport}
              disabled={!file || importing}
            >
              {importing ? "A importar..." : "Importar"}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ImportarAlunos;
