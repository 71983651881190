import React, { useState, useRef } from "react";
import EditContactoHospital from "../Edit/EditContactoHospital";
import { FaUserEdit } from "react-icons/fa";
import Modal from "react-modal";
import { ImCancelCircle } from "react-icons/im";
import CreateContactoHospital from "../Create/CreateContactoHospital";
import { MdEmail } from "react-icons/md";
import {
  Modal as BootstrapModal,
  Row,
  Form,
  Col,
  Button,
} from "react-bootstrap";
import { FaTrash } from "react-icons/fa";
import { Editor } from "@tinymce/tinymce-react";
import { jsPDF } from "jspdf";
import logoImage from "../../../assets/images/fful-assinatura-email.png";
import signatureImage from "../../../assets/images/assinatura-antonio-almeida.png";
import html2pdf from "html2pdf.js";

function GerirContactosHospital({ hospitalData, anoLetivo }) {
  const [contactosHospital, setContactosHospital] = useState([]);
  const [contactoHospital, setContactoHospital] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [alunos, setAlunos] = useState([]);
  const [blocosH, setBlocosH] = useState([]);
  const [vagasHospital, setVagasHospital] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showConfirmationModalContacto, setShowConfirmationModalContacto] =
    useState(false);
  const [data, setData] = useState([]);
  const [emailTO, setEmailTO] = useState();
  const [emailCC, setEmailCC] = useState([]);
  const [subject, setSubject] = useState([]);
  const [htmlBody, setHtmlBody] = useState([]);
  const htmlBodyRef = useRef(htmlBody);
  const [showDeclaracoesModal, setShowDeclaracoesModal] = useState(false);
  const [selectedContacto, setSelectedContacto] = useState(null);

  const apiContactoHospital = process.env.REACT_APP_API_CONTATOHOSPITAIS;
  const apiVagasHospitais = process.env.REACT_APP_API_VAGASHOSPITAIS;
  const apiBlocoH = process.env.REACT_APP_API_BLOCOH;
  const apiAlunos = process.env.REACT_APP_API_ALUNOS;
  const apiEmail = process.env.REACT_APP_API_EMAIL;
  const apiOrientadorHospital = process.env.REACT_APP_API_ORIENTADOR_HOSPITAL;

  const currentYear = new Date().getFullYear();

  const handleOpenModal = () => {
    fetch(`${apiContactoHospital}/idHospital/${hospitalData.idHospital}`)
      .then((response) => response.json())
      .then((data) => setContactosHospital(data));
    setModalIsOpen(true);
  };

  const fetchContactosHospital = () => {
    fetch(`${apiContactoHospital}/idHospital/${hospitalData.idHospital}`)
      .then((response) => response.json())
      .then((data) => setContactosHospital(data));
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  function filteredContactosHospital() {
    const searchTermLowerCase = removeAccents(searchTerm.toLowerCase());
    const filtered = contactosHospital.filter((contactosHospital) => {
      const nomeLowerCase =
        contactosHospital && contactosHospital.nome
          ? removeAccents(contactosHospital.nome.toLowerCase())
          : "";
      const emailLowerCase =
        contactosHospital && contactosHospital.email
          ? removeAccents(contactosHospital.email.toLowerCase())
          : "";
      const matchesSearchTerm =
        nomeLowerCase.includes(searchTermLowerCase) ||
        emailLowerCase.includes(searchTermLowerCase);
      return matchesSearchTerm;
    });
    return Array.isArray(filtered) ? filtered : [filtered];
  }

  const filteredHospitaisArray = Array.isArray(filteredContactosHospital())
    ? filteredContactosHospital()
    : [];

  // Function to remove accents from a string
  function removeAccents(str) {
    const accents = "ÀÁÂÃÄÅàáâãäåÒÓÔÕÖØòóôõöøÈÉÊËèéêëÇçÌÍÎÏìíîïÙÚÛÜùúûüÿÑñ";
    const accentsOut = "AAAAAAaaaaaaOOOOOOooooooEEEEeeeeCcIIIIiiiiUUUUuuuuyNn";
    return str
      .split("")
      .map((letter) => {
        const accentIndex = accents.indexOf(letter);
        return accentIndex !== -1 ? accentsOut[accentIndex] : letter;
      })
      .join("");
  }

  const handleCancelSend = () => {
    setShowConfirmationModal(false);
  };

  const handleConfirmSend = () => {
    sendEmail();
  };

  const sendEmail = () => {
    fetch(`${apiEmail}/vagas`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        emailTO: emailTO
          .filter((email) => email && email.trim() !== "")
          .join(";"),
        emailCC: emailCC
          .filter((email) => email && email.trim() !== "")
          .join(";"),

        subject: subject,
        htmlBody: htmlBodyRef.current,
      }),
    })
      .then((response) => {
        if (response.ok) {
          setShowConfirmationModal(false);
        } else {
          throw new Error("Failed to send email");
        }
        return response.json();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleSendEmail = (contactoHospital) => {
    setEmailTO([contactoHospital.email]);
    setEmailCC([
      contactoHospital.emailEstagios,
      contactoHospital.emailAlternativo,
    ]);
    setSubject(
      `FFUL -> Vagas disponibilizadas para os alunos a realizar o Estágio Curricular em ${anoLetivo.anoLetivo}`
    );
    Promise.all([
      fetch(
        `${apiVagasHospitais}/anoLetivo/${anoLetivo.anoLetivo}/hospital/${hospitalData.idHospital}`
      ),
      fetch(`${apiAlunos}/anoLetivo/${anoLetivo.anoLetivo}`),
      fetch(`${apiBlocoH}/anoLetivo/${anoLetivo.anoLetivo}`),
    ])
      .then((responses) => Promise.all(responses.map((res) => res.json())))
      .then(([vagasHospitalData, alunosData, blocoHData]) => {
        setVagasHospital(vagasHospitalData);
        setAlunos(alunosData);
        setBlocosH(blocoHData);

        const data = vagasHospitalData.map((item) => {
          const blocoH =
            Array.isArray(blocoHData) &&
            blocoHData.find((b) => b.idBlocoH === item.idBlocoH);
          const aluno =
            Array.isArray(alunosData) &&
            alunosData.find((a) => a.idAluno === item.idAluno);

          return {
            ...item,
            blocoH,
            aluno,
          };
        });

        setData(data);
        const emailContent = `
        <p>Exmo(a). Sr(a).</p> 
        <p>Diretor(a) dos Serviços Farmacêuticos</p>
        <p>De acordo com as vagas disponibilizadas para os alunos a iniciar o estágio curricular do Mestrado Integrado em Ciências Farmacêuticas da Universidade de Lisboa, relativo a ${currentYear}, informamos que vão realizar o estágio curricular no Hospital ${
          hospitalData.nome
        } os seguintes alunos:</p>
        <table>
          <tr>
            <th>Nome do Aluno</th>
            <th>Número de Aluno</th>
            <th>Bloco</th>
            <th>Data Início</th>
            <th>Data Fim</th>
          </tr>
          ${data
            .map(
              (item) => `
            <tr>
              <td>${item.aluno.nome}</td>
              <td>${item.aluno.numAluno}</td>
              <td>${item.blocoH.bloco}</td>
              <td>${item.blocoH.dataInicio}</td>
              <td>${item.blocoH.dataFim}</td>
            </tr>
          `
            )
            .join("")}
        </table>
        <p>Os documentos que enquadram o funcionamento do estágio, nomeadamente o Regulamento e o Manual de Estágio, estão disponíveis nas ligações abaixo indicadas, com a password <strong>FFUL_NE1!</strong></p>                
        <p>- <a href='https://www.ff.ulisboa.pt/wp-content/uploads/2019/01/Regulamento-MICF_2018.pdf'>Link para o Regulamento do Estágio</a> (ver sff. págs. 24211 a 24214)</p>
        <p>- <a href='https://www.ff.ulisboa.pt/wp-content/uploads/2024/01/Manual-Estagio_F.-Hospitalar_PP.pdf'>Link para o Manual de Estágio em Farmácia Hospitalar</a></p>
        <p>O Manual tem como objetivo ser um guia para Orientadores e Estagiários, visando também a uniformização dos Estágios. Está organizado por módulos, sendo para cada um deles indicados objetivos a atingir, os respetivos conteúdos e as metodologias recomendadas. No final de cada módulo, incluiu-se uma grelha de objetivos, cujo preenchimento por parte do Estagiário e do seu Orientador, permitirá a ambos a monitorização da eficácia pedagógica do Estágio. É com base nas atividades desenvolvidas que incide a prova pública dos alunos.</p>
        <p>Dada a diversidade dos locais de Estágio, haverá sempre alguns onde, pelas suas características, não será possível atingir todos os objetivos indicados. Também a sequência pela qual os diferentes temas são abordados deverá ser encarada com flexibilidade, pois a articulação dos conteúdos depende obviamente das características do local de Estágio.</p>
        <p>Todos os alunos estão abrangidos pelo seguro escolar, cuja ligação está identificada abaixo:</p>
        <p>- <a href='https://www.ff.ulisboa.pt/seguro-escolar/'>Link para a Apólice do Seguro Escolar</a></p>
        <p>Em caso de acidente devem ser contactados de imediato a Seguradora Fidelidade e os Serviços Académicos da FFUL através dos contactos:</p>
        <p>Seguradora Fidelidade</p>
        <p>Telefone: 217 943 039 / 213 102 450</p>
        <p>Email: sinistrospessoaisap@ageas.pt</p>
        <p>Serviços Académicos</p>
        <p>Telefone: 217 946 400. Ext.: 14362</p>
        <p>Email: academicos@ff.ulisboa.pt</p>
        <p>A avaliação dos alunos pelo(s) orientador(es) de estágio efetua-se no final do período de estágio, sendo disponibilizada para o efeito a Ficha de Avaliação e de Assiduidade (com a password: <strong>FFUL_NE1!</strong>)</p>
        <p>- <a href='https://www.ff.ulisboa.pt/wp-content/uploads/2024/01/Ficha-de-avaliacao-do-aluno-2024.pdf'>Link para a Ficha de Avaliação</a></p>
        <p>- <a href='https://www.ff.ulisboa.pt/wp-content/uploads/2024/01/Ficha-de-assiduidade-do-aluno-2024.pdf'>Link para a Ficha de Assiduidade</a></p>
        <p>De acordo com o nº. 5, do Artigo 7º, do Regulamento de Estágio e para os alunos não trabalhadores estudantes, a carga horária semanal é no máximo de 30 horas, no período compreendido entre segunda-feira e sexta-feira.</p>
        <p>Relativamente aos alunos trabalhadores estudantes e de acordo com o nº. 6 do mesmo Artigo, o estágio tem a duração mínima de 600 horas: 468 horas para a atividade em local de estágio e 132 horas para as atividades letivas e para a elaboração da monografia/trabalho de campo.</p>
        <p>Agradecendo desde já a colaboração prestada, apresentamos os melhores cumprimentos,</p>
        <table>
        <tr>
        <td><img src='https://www.ff.ulisboa.pt/wp-content/uploads/2018/03/logo_header.png' alt='Signature Image' style='width: 150px; height: auto;' /></td>
        <td style='font-size: 0.8em;'>
        <p><strong>Ana Marta Monteiro</strong></p>
        <p>Núcleo de Estágios / Curricular Internships  | Conselhos de Escola e Estratégico / School and Strategic Coucil</p>
        <p>Dep. de Farmácia, Farmacologia e Tecnologias em Saúde/Dep. of Social Pharmacy, Pharmacology and Health Technologies</p>
        <p>Faculdade de Farmácia da Universidade de Lisboa | Faculty of Pharmacy of the University of Lisbon</p>
        <p>Av. Prof. Gama Pinto, 1649-003 Lisboa, Portugal | (351) 21 794 64 00 I Ext. 514332</p>
        <p>nucleodeestagios@ff.ulisboa.pt | www.ff.ul.pt</p>
        <p>(Em caso de resposta, agradece-se que reencaminhe sempre a troca de emails feita com estes serviços)</p>
        </td>
        </tr>
        </table>
      `;
        setHtmlBody(emailContent);
        htmlBodyRef.current = emailContent;
      });
    setShowConfirmationModal(true);
  };

  const handleDelete = (id) => {
    setContactoHospital(id);
    setShowConfirmationModalContacto(true);
  };

  const handleConfirmDelete = () => {
    fetch(`${apiContactoHospital}/${contactoHospital}`, {
      method: "DELETE",
    })
      .then((response) => {
        setShowConfirmationModalContacto(false);
        fetchContactosHospital();
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        // handle successful deletion here, e.g. by updating the UI
      })
      .catch((error) => {
        console.error(
          "There has been a problem with your fetch operation:",
          error
        );
        // handle error here
      });
  };

  const handleCancelDelete = () => {
    setContactoHospital(null); // Limpar a farmacia selecionada
    setShowConfirmationModalContacto(false); // Fechar a janela de confirmação
  };

  const handleChangeHtmlBody = (content) => {
    setHtmlBody(content);
    htmlBodyRef.current = content;
  };

  const handleSendOrientadorDeclaracoes = (contactoHospital) => {
    setSelectedContacto(contactoHospital);
    setShowDeclaracoesModal(true);
  };

  const handleConfirmDeclaracoes = async () => {
    try {
      await sendOrientadorDeclaracoes(selectedContacto);
      setShowDeclaracoesModal(false);
    } catch (error) {
      console.error("Erro ao enviar declarações:", error);
      alert("Ocorreu um erro ao enviar as declarações.");
    }
  };

  const handleCancelDeclaracoes = () => {
    setShowDeclaracoesModal(false);
    setSelectedContacto(null);
  };

  const sendOrientadorDeclaracoes = async (contactoHospital) => {
    try {
      const response = await fetch(
        `${apiOrientadorHospital}/anoLetivo/${anoLetivo.anoLetivo}`
      );
      const orientadores = await response.json();

      const hospitalOrientadores = orientadores.filter(
        (orientador) => orientador.idHospital === hospitalData.idHospital
      );

      if (hospitalOrientadores.length === 0) {
        alert(
          "Não existem orientadores registados para este hospital no ano letivo atual."
        );
        return;
      }

      // Generate PDFs
      const pdfs = await Promise.all(
        hospitalOrientadores.map(async (orientador) => {
          // Create a container for HTML content
          const container = document.createElement("div");
          container.innerHTML = `
          <div style="font-family: Helvetica, sans-serif; font-size: 14px; line-height: 1.8; text-align: justify; margin: 20px;">
            <div style="text-align: center; margin-bottom: 30px;">
              <div style="display: flex; justify-content: center; align-items: center; gap: 20px;">
                <img src="${logoImage}" alt="Logo FFUL" style="width: 150px; height: auto;" />
              </div>
              <h2 style="margin-top: 60px; margin-bottom: 60px;">DECLARAÇÃO</h2>
            </div>

            <p style="font-size: 16px; margin-left: 20px; margin-right: 20px;">
              Doutor António José Leitão das Neves Almeida, Professor Catedrático e Presidente do Conselho Científico da Faculdade de Farmácia da Universidade de Lisboa declara que, de acordo com o nº. 6, do Art.º 6.º, do Regulamento do Estágio Curricular do Curso de Mestrado Integrado em Ciências Farmacêuticas, o(a) Dr(a). 
              <strong>${orientador.nome}</strong>, do Hospital 
              <strong>${hospitalData.nome}</strong>, inscrito(a) na Ordem dos Farmacêuticos com o n.º 
              <strong>${orientador.numOrdem}</strong>, exerceu funções de Orientador(a), no ano letivo de 
              <strong>${anoLetivo.anoLetivo}</strong>.
            </p>

            <div style="text-align: center; margin-top: 100px;">
              <p style="font-size: 16px; margin-bottom: 20px;">O Presidente do Conselho Científico</p>
              <div style="display: flex; justify-content: center; margin: 20px 0;">
                <img src="${signatureImage}" alt="Assinatura" style="width: 150px; height: auto;" />
              </div>
              <p style="font-size: 16px;">Professor Doutor António José Leitão das Neves Almeida</p>
            </div>
          </div>
        `;

          // Convert HTML to PDF
          const pdfBlob = await html2pdf()
            .set({
              filename: `declaracao_orientador_${orientador.nome}.pdf`,
              margin: 10,
              html2canvas: { scale: 2 },
              jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
            })
            .from(container)
            .outputPdf("blob");

          return pdfBlob;
        })
      );

      // Send PDFs via email
      const formData = new FormData();
      pdfs.forEach((pdf, index) => {
        formData.append(
          "attachments",
          pdf,
          `declaracao_orientador_${index + 1}.pdf`
        );
      });

      formData.append("emailTO", contactoHospital.email);
      formData.append(
        "emailCC",
        [contactoHospital.emailEstagios, contactoHospital.emailAlternativo]
          .filter(Boolean)
          .join(";")
      );
      formData.append(
        "subject",
        `FFUL -> Declaração de Orientador do Estágio Curricular MICF ${anoLetivo.anoLetivo}`
      );
      formData.append(
        "htmlBody",
        `
        <p>Exmo(a). Sr(a).</p>
        <p>Diretor(a) Farmacêutico(a),</p>
        <div></div>
        <p>Encarrega-me a Coordenadora do Estágio Curricular do MICF, Professora Carla Teixeira de Barros, de enviar a(s) Declaração(ões) comprovativa(s) da atividade de orientador do Estágio Curricular do MICF no Hospital ${hospitalData.nome}, durante o ano letivo de ${anoLetivo.anoLetivo}.</p>
        <div></div>
        <p>O Núcleo de Estágio agradece a colaboração e dedicação colocada na aprendizagem dos alunos da Faculdade de Farmácia da Universidade de Lisboa que, estamos certos, será fundamental para o seu sucesso profissional.</p>
        <div></div>
        <p>Com os melhores cumprimentos,</p>
        <table>
        <tr>
        <td><img src='https://www.ff.ulisboa.pt/wp-content/uploads/2024/10/fful-assinatura-email.png' alt='Signature Image' style='width: 110px; height: auto;' /></td>
        <td style='font-size: 0.6em;'>
        <p><strong>Ana Marta Monteiro</strong></p>
        <p>Núcleo de Estágios / Curricular Internships  | Conselhos de Escola e Estratégico / School and Strategic Council</p>
        <p>Dep. de Farmácia, Farmacologia e Tecnologias em Saúde/Dep. of Social Pharmacy, Pharmacology and Health Technologies</p>
        <p>Faculdade de Farmácia da Universidade de Lisboa | Faculty of Pharmacy of the University of Lisbon</p>
        <p>Av. Prof. Gama Pinto, 1649-003 Lisboa, Portugal | (351) 21 794 64 00 I Ext. 514332</p>
        <p>nucleodeestagios@ff.ulisboa.pt | www.ff.ul.pt</p>
        <p>(Em caso de resposta, agradece-se que reencaminhe sempre a troca de emails feita com estes serviços)</p>
        </td>
        </tr>
        </table>
      `
      );

      const emailResponse = await fetch(`${apiEmail}/attachments`, {
        method: "POST",
        body: formData,
      });

      if (emailResponse.ok) {
        alert("Declarações enviadas com sucesso!");
      } else {
        throw new Error(
          `Falha no envio das declarações: ${await emailResponse.text()}`
        );
      }
    } catch (error) {
      console.error("Erro detalhado:", error);
      alert("Ocorreu um erro ao processar as declarações dos orientadores.");
    }
  };

  return (
    <>
      <button
        className="text-purple-700 mx-1"
        title="Editar Contactos do Hospital"
        onClick={handleOpenModal}
      >
        <FaUserEdit />
      </button>
      <div>
        <BootstrapModal show={showConfirmationModal} centered>
          <style type="text/css">
            {`
      .modal-dialog {
        max-width: 90% !important;
        height: 80vh !important;
      }

      .modal-body {
        height: calc(80vh - 60px); /* 60px is an estimated height for the header and footer */
        overflow-y: auto; /* add a scrollbar if the content is too tall */
      }
    `}
          </style>
          <BootstrapModal.Body>
            <form>
              <div className="form-group">
                <label htmlFor="to">Para:</label>
                <input
                  type="email"
                  id="to"
                  className="form-control"
                  value={emailTO}
                  onChange={(e) => setEmailTO(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="cc">CC:</label>
                <input
                  type="email"
                  id="cc"
                  className="form-control"
                  value={emailCC.join(";")}
                  onChange={(e) =>
                    setEmailCC(e.target.value ? e.target.value.split(";") : [])
                  }
                />
              </div>
              <div className="form-group">
                <label htmlFor="subject">Assunto:</label>
                <input
                  type="text"
                  id="subject"
                  value={subject}
                  className="form-control"
                  onChange={(e) => setSubject(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="body">Email:</label>
                <Editor
                  apiKey="fra21wku4hoonua8iqg7xbfv9uhgzud33f456hj07igwxs2o"
                  value={htmlBody}
                  init={{
                    height: 500,
                    menubar: false,
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount",
                    ],
                    toolbar:
                      "undo redo | formatselect | bold italic backcolor | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | removeformat | help",
                    branding: false,
                    elementpath: false,
                    resize: false,
                  }}
                  onEditorChange={handleChangeHtmlBody}
                />
              </div>
            </form>
          </BootstrapModal.Body>
          <BootstrapModal.Footer>
            <Button
              style={{
                backgroundColor: "lightcoral",
                borderColor: "lightcoral",
                color: "black",
              }}
              variant="secondary"
              onClick={handleCancelSend}
            >
              Cancelar
            </Button>
            <Button
              style={{
                backgroundColor: "lightgreen",
                borderColor: "lightgreen",
                color: "black",
              }}
              variant="danger"
              onClick={handleConfirmSend}
            >
              Enviar
            </Button>
          </BootstrapModal.Footer>
        </BootstrapModal>
      </div>
      <div>
        <BootstrapModal show={showConfirmationModalContacto} centered>
          <BootstrapModal.Body>
            Tem a certeza que pretende eliminar o contacto?
          </BootstrapModal.Body>
          <BootstrapModal.Footer>
            <Button variant="danger" onClick={handleConfirmDelete}>
              Sim
            </Button>
            <Button variant="secondary" onClick={handleCancelDelete}>
              Não
            </Button>
          </BootstrapModal.Footer>
        </BootstrapModal>
      </div>
      <BootstrapModal show={showDeclaracoesModal} centered>
        <BootstrapModal.Body>
          Quer enviar a(s) declaração(ões) de estágio?
        </BootstrapModal.Body>
        <BootstrapModal.Footer>
          <Button
            style={{
              backgroundColor: "lightgreen",
              borderColor: "lightgreen",
              color: "black",
            }}
            onClick={handleConfirmDeclaracoes}
          >
            Sim
          </Button>
          <Button
            style={{
              backgroundColor: "lightcoral",
              borderColor: "lightcoral",
              color: "black",
            }}
            onClick={handleCancelDeclaracoes}
          >
            Não
          </Button>
        </BootstrapModal.Footer>
      </BootstrapModal>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={handleCloseModal}
        className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50"
      >
        <div className="bg-white rounded-lg p-6 md:p-8 flex flex-col justify-center items-center">
          <main className="flex-grow p-4">
            <Row className="mb-4 d-flex align-items-center">
              <Form.Group as={Col}>
                <Form.Label className="font-bold">Nome do Hospital</Form.Label>
                <Form.Control
                  type="text"
                  value={hospitalData.nome}
                  readOnly
                  plaintext
                  className="!bg-gray-200 rounded"
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label className="font-bold">
                  Morada do Hospital
                </Form.Label>
                <Form.Control
                  type="text"
                  value={hospitalData.morada}
                  readOnly
                  plaintext
                  className="!bg-gray-200 rounded"
                />
              </Form.Group>
            </Row>
            <div>
              <input
                type="text"
                placeholder="Pesquisar"
                value={searchTerm}
                onChange={handleSearchTermChange}
                className="border border-gray-400 rounded px-2 py-1 mb-3"
              />
            </div>
            <div className="flex items-center mb-4">
              <CreateContactoHospital
                hospitalData={hospitalData}
                onClose={fetchContactosHospital}
              />
            </div>
            <table className="table-auto w-full">
              <thead>
                <tr>
                  <th className="px-4 py-2">Ano Letivo</th>
                  <th className="px-4 py-2">Nome</th>
                  <th className="px-4 py-2">Email</th>
                  <th className="px-4 py-2">Email Estágios</th>
                  <th className="px-4 py-2">Email Alternativo</th>
                  <th className="px-4 py-2">Telefone</th>
                  <th className="px-4 py-2">Telemóvel</th>
                  <th className="px-4 py-2">Cargo</th>
                  <th className="px-4 py-2">Observações</th>
                  <th className="px-4 py-2">Ações</th>
                </tr>
              </thead>
              <tbody>
                {filteredHospitaisArray.map((contactoHospital) => {
                  return (
                    <tr key={contactoHospital.idContatoHospital}>
                      <td className="border px-4 py-2">
                        {contactoHospital.anoLetivo}
                      </td>
                      <td className="border px-4 py-2">
                        {contactoHospital.nome}
                      </td>
                      <td className="border px-4 py-2">
                        {contactoHospital.email}
                      </td>
                      <td className="border px-4 py-2">
                        {contactoHospital.emailEstagios}
                      </td>
                      <td className="border px-4 py-2">
                        {contactoHospital.emailAlternativo}
                      </td>
                      <td className="border px-4 py-2">
                        {contactoHospital.telefone}
                      </td>
                      <td className="border px-4 py-2">
                        {contactoHospital.telemovel}
                      </td>
                      <td className="border px-4 py-2">
                        {contactoHospital.cargo}
                      </td>
                      <td className="border px-4 py-2">
                        {contactoHospital.obs}
                      </td>
                      <td className="border px-4 py-2">
                        <div>
                          <EditContactoHospital
                            contactoHospitalData={contactoHospital}
                            onClose={fetchContactosHospital}
                          />
                          <button
                            type="Enviar email"
                            className="text-purple-700 mx-1"
                            onClick={() => handleSendEmail(contactoHospital)}
                          >
                            <MdEmail />
                          </button>
                          <button
                            className="text-purple-700 mx-1"
                            title="Enviar declarações dos orientadores"
                            onClick={() =>
                              handleSendOrientadorDeclaracoes(contactoHospital)
                            }
                          >
                            <FaUserEdit />
                          </button>
                          <button
                            className="text-red-700 mx-1"
                            title="Excluir"
                            onClick={() =>
                              handleDelete(contactoHospital.idContatoHospital)
                            }
                          >
                            <FaTrash />
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </main>
          <button
            type="button"
            className="text-white px-4 py-2 mx-2 rounded-md bg-fful hover:bg-iconhover"
            onClick={handleCloseModal}
          >
            <ImCancelCircle />
          </button>
        </div>
      </Modal>
    </>
  );
}

export default GerirContactosHospital;
