import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { FaRegSave } from "react-icons/fa";
import { ImCancelCircle } from "react-icons/im";
import Modal from "react-modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { AiOutlineUserAdd } from "react-icons/ai";

Modal.setAppElement("#root");

function CreateBlocoH({ anoLetivo }) {
  const INITIAL_STATE = {
    anoLetivo: anoLetivo,
    bloco: "",
    dataInicio: "",
    dataFim: "",
  };
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [newBloco, setNewBloco] = useState(INITIAL_STATE);

  const handleReset = () => {
    setNewBloco(INITIAL_STATE);
  };

  const apiBlocoH = process.env.REACT_APP_API_BLOCOH;

  const [errors, setErrors] = useState({
    bloco: "",
    dataInicio: "",
    dataFim: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewBloco((prevnewBloco) => ({
      ...prevnewBloco,
      [name]: value,
    }));
    validateField(name, value);
  };

  const validateField = (fieldName, value) => {
    let error = "";
    switch (fieldName) {
      case "bloco":
        if (!value) {
          error = "Campo obrigatório";
        }
        break;
      case "dataInicio":
        if (!value) {
          error = "Campo obrigatório";
        }
        break;
      case "dataFim":
        if (!value) {
          error = "Campo obrigatório";
        }
        break;
      default:
        break;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: error,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(anoLetivo);
    console.log(newBloco);
    fetch(`${apiBlocoH}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newBloco),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setModalIsOpen(false);
        handleReset();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    handleReset();
    setModalIsOpen(false);
  };

  useEffect(() => {
    if (modalIsOpen) {
      for (const key in newBloco) {
        validateField(key, newBloco[key]);
      }
    }
  }, [modalIsOpen]);

  return (
    <>
      <button
        className="text-purple-700 mx-1 flex items-center"
        title="Adicionar"
        onClick={openModal}
      >
        <span className="mr-2">Adicionar Bloco de Hospital</span>
        <AiOutlineUserAdd />
      </button>
      <Modal isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)}>
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg p-6 md:p-8">
            <h2 className="text-lg font-semibold mb-4 bg-fful px-4 py-2 rounded-lg text-white">
              Adicionar Bloco de Hospital
            </h2>
            <Form noValidate onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="bloco">
                  <Form.Label>Bloco</Form.Label>
                  <Form.Control
                    type="text"
                    name="bloco"
                    onChange={handleChange}
                    value={newBloco.bloco}
                    isInvalid={!!errors.bloco}
                    isValid={!errors.bloco}
                  />
                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.bloco}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="dataInicio">
                  <Form.Label>Data Início</Form.Label>
                  <Form.Control
                    type="date"
                    format="yyyy-MM-dd"
                    name="dataInicio"
                    onChange={handleChange}
                    value={newBloco.dataInicio}
                    isInvalid={!!errors.dataInicio}
                    isValid={!errors.dataInicio}
                  />

                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.dataInicio}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="dataFim">
                  <Form.Label>Data Fim</Form.Label>
                  <Form.Control
                    type="date"
                    format="yyyy-MM-dd"
                    name="dataFim"
                    onChange={handleChange}
                    value={newBloco.dataFim}
                    isInvalid={!!errors.dataFim}
                    isValid={!errors.dataFim}
                  />

                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.dataFim}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <div className="py-4">
                <button
                  type="submit"
                  disabled={Object.values(errors).some((value) => value !== "")}
                  className={
                    Object.values(errors).some((value) => value !== "")
                      ? "text-white px-4 py-2 rounded-md bg-gray-400 cursor-not-allowed"
                      : "text-white px-4 py-2 rounded-md bg-fful hover:bg-iconhover"
                  }
                >
                  <FaRegSave />
                </button>
                <button
                  type="button"
                  className="text-white px-4 py-2 mx-2 rounded-md bg-fful hover:bg-iconhover"
                  onClick={closeModal}
                >
                  <ImCancelCircle />
                </button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default CreateBlocoH;
