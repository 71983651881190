import Menu from "./Menu";
import { useState, useEffect } from "react";
import GerirAlunos from "../Services/Gerir/GerirAlunos";
import GerirFarmacias from "../Services/Gerir/GerirFarmacias";
import GerirHospitais from "../Services/Gerir/GerirHospitais";
import GerirTrabalhos from "../Services/Gerir/GerirTrabalhos";
import GerirListagens from "../Services/Gerir/GerirListagens";
import GerirBlocosF from "../Services/Gerir/GerirBlocosF";
import GerirBlocosH from "../Services/Gerir/GerirBlocosH";
import { Form } from "react-bootstrap";
import GerirVagasFarmacias from "../Services/Gerir/GerirVagasFarmacias";
import GerirVagasHospitais from "../Services/Gerir/GerirVagasHospitais";
import GerirOrientadoresFarmacia from "../Services/Gerir/GerirOrientadoresFarmacia";
import GerirOrientadoresHospital from "../Services/Gerir/GerirOrientadoresHospital";

export default function BodyNucleoProjetos() {
  const [showGerirAlunos, setShowGerirAlunos] = useState(false);
  const [showGerirFarmacias, setShowGerirFarmacias] = useState(false);
  const [showGerirHospitais, setShowGerirHospitais] = useState(false);
  const [showGerirVagasFarmacias, setShowGerirVagasFarmacias] = useState(false);
  const [showGerirVagasHospitais, setShowGerirVagasHospitais] = useState(false);
  const [showGerirTrabalhos, setShowGerirTrabalhos] = useState(false);
  const [showGerirBlocosF, setShowGerirBlocosF] = useState(false);
  const [showGerirBlocosH, setShowGerirBlocosH] = useState(false);
  const [showGerirListagens, setShowGerirListagens] = useState(false);
  const [showGerirOrientadoresFarmacia, setShowGerirOrientadoresFarmacia] =
    useState(false);
  const [showGerirOrientadoresHospital, setShowGerirOrientadoresHospital] =
    useState(false);
  const [anoLetivo, setAnoLetivo] = useState("");
  const [datas, setDatas] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const apiDatas = process.env.REACT_APP_API_DATAS;

  useEffect(() => {
    fetch(`${apiDatas}/1`)
      .then((response) => response.json())
      .then((data) => setDatas(data));
  }, [apiDatas]);

  const handleAnoLetivoChange = (event) => {
    setAnoLetivo(event.target.value);
  };

  const currentYear = new Date().getFullYear();
  const options = [
    `${currentYear}-${currentYear + 1}`,
    `${currentYear - 1}-${currentYear}`,
    `${currentYear - 2}-${currentYear - 1}`,
    `${currentYear - 3}-${currentYear - 2}`,
    `${currentYear - 4}-${currentYear - 3}`,
    `${currentYear - 5}-${currentYear - 4}`,
  ].map((year) => `20${year.substring(2, 4)}-20${year.substring(7, 9)}`);

  useEffect(() => {
    if (isSubmitted) {
      handleSubmit();
      setIsSubmitted(false);
    }
  }, [datas, isSubmitted]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDatas((prevDatas) => ({
      ...prevDatas,
      [name]: value,
    }));
    setIsSubmitted(true);
  };

  const handleSubmit = async (e) => {
    fetch(`${apiDatas}/1`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(datas),
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div>
      <Menu />
      <div className="px-4 py-2">
        <label
          className="bg-fful text-white font-bold py-2 px-4 rounded"
          htmlFor="anoLetivo"
        >
          Selecione o ano letivo:
        </label>
        <select
          id="anoLetivo"
          name="anoLetivo"
          value={anoLetivo}
          onChange={handleAnoLetivoChange}
        >
          <option value="">Selecione</option>
          {options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>

      {anoLetivo && (
        <>
          <div className="px-4 py-2">
            <button
              className="bg-fful hover:bg-iconhover text-white font-bold py-2 px-4 rounded"
              onClick={() => setShowGerirAlunos(!showGerirAlunos)}
            >
              {showGerirAlunos ? "Fechar Gerir Alunos" : "Gerir Alunos"}
            </button>
            {showGerirAlunos && (
              <GerirAlunos key={anoLetivo} anoLetivo={anoLetivo} />
            )}
          </div>
          <div className="px-4 py-2">
            <button
              className="bg-fful hover:bg-iconhover text-white font-bold py-2 px-4 rounded"
              onClick={() => setShowGerirFarmacias(!showGerirFarmacias)}
            >
              {showGerirFarmacias
                ? "Fechar Gerir Farmácias"
                : "Gerir Farmácias"}
            </button>
            {showGerirFarmacias && (
              <GerirFarmacias key={anoLetivo} anoLetivo={anoLetivo} />
            )}
          </div>
          <div className="px-4 py-2">
            <button
              className="bg-fful hover:bg-iconhover text-white font-bold py-2 px-4 rounded"
              onClick={() => setShowGerirHospitais(!showGerirHospitais)}
            >
              {showGerirHospitais
                ? "Fechar Gerir Hospitais"
                : "Gerir Hospitais"}
            </button>
            {showGerirHospitais && (
              <GerirHospitais key={anoLetivo} anoLetivo={anoLetivo} />
            )}
          </div>
          <div className="px-4 py-2">
            <button
              className="bg-fful hover:bg-iconhover text-white font-bold py-2 px-4 rounded"
              onClick={() =>
                setShowGerirVagasFarmacias(!showGerirVagasFarmacias)
              }
            >
              {showGerirVagasFarmacias
                ? "Fechar Gerir Vagas Farmácias"
                : "Gerir Vagas Farmácias"}
            </button>
            {showGerirVagasFarmacias && (
              <GerirVagasFarmacias key={anoLetivo} anoLetivo={anoLetivo} />
            )}
          </div>
          <div className="px-4 py-2">
            <button
              className="bg-fful hover:bg-iconhover text-white font-bold py-2 px-4 rounded"
              onClick={() =>
                setShowGerirVagasHospitais(!showGerirVagasHospitais)
              }
            >
              {showGerirVagasHospitais
                ? "Fechar Gerir Vagas Hospitais"
                : "Gerir Vagas Hospitais"}
            </button>
            {showGerirVagasHospitais && (
              <GerirVagasHospitais key={anoLetivo} anoLetivo={anoLetivo} />
            )}
          </div>
          <div className="px-4 py-2">
            <button
              className="bg-fful hover:bg-iconhover text-white font-bold py-2 px-4 rounded"
              onClick={() => setShowGerirTrabalhos(!showGerirTrabalhos)}
            >
              {showGerirTrabalhos
                ? "Fechar Gerir Trabalhos"
                : "Gerir Trabalhos"}
            </button>
            {showGerirTrabalhos && (
              <GerirTrabalhos key={anoLetivo} anoLetivo={anoLetivo} />
            )}
          </div>
          <div className="px-4 py-2">
            <button
              className="bg-fful hover:bg-iconhover text-white font-bold py-2 px-4 rounded"
              onClick={() =>
                setShowGerirOrientadoresFarmacia(!showGerirOrientadoresFarmacia)
              }
            >
              {showGerirOrientadoresFarmacia
                ? "Fechar Gerir Orientadores Farmácias"
                : "Gerir Orientadores Farmácias"}
            </button>
            {showGerirOrientadoresFarmacia && (
              <GerirOrientadoresFarmacia
                key={anoLetivo}
                anoLetivo={anoLetivo}
              />
            )}
          </div>
          <div className="px-4 py-2">
            <button
              className="bg-fful hover:bg-iconhover text-white font-bold py-2 px-4 rounded"
              onClick={() =>
                setShowGerirOrientadoresHospital(!showGerirOrientadoresHospital)
              }
            >
              {showGerirOrientadoresHospital
                ? "Fechar Gerir Orientadores Hospitais"
                : "Gerir Orientadores Hospitais"}
            </button>
            {showGerirOrientadoresHospital && (
              <GerirOrientadoresHospital
                key={anoLetivo}
                anoLetivo={anoLetivo}
              />
            )}
          </div>
          <div className="px-4 py-2">
            <button
              className="bg-fful hover:bg-iconhover text-white font-bold py-2 px-4 rounded"
              onClick={() => setShowGerirBlocosF(!showGerirBlocosF)}
            >
              {showGerirBlocosF
                ? "Fechar Gerir Blocos Farmácias"
                : "Gerir Blocos Farmácias"}
            </button>
            {showGerirBlocosF && (
              <GerirBlocosF key={anoLetivo} anoLetivo={anoLetivo} />
            )}
          </div>
          <div className="px-4 py-2">
            <button
              className="bg-fful hover:bg-iconhover text-white font-bold py-2 px-4 rounded"
              onClick={() => setShowGerirBlocosH(!showGerirBlocosH)}
            >
              {showGerirBlocosH
                ? "Fechar Gerir Blocos Hospitais"
                : "Gerir Blocos Hospitais"}
            </button>
            {showGerirBlocosH && (
              <GerirBlocosH key={anoLetivo} anoLetivo={anoLetivo} />
            )}
          </div>
          <div className="px-4 py-2">
            <button
              className="bg-fful hover:bg-iconhover text-white font-bold py-2 px-4 rounded"
              onClick={() => setShowGerirListagens(!showGerirListagens)}
            >
              {showGerirListagens
                ? "Fechar Gerir Listagens"
                : "Gerir Listagens"}
            </button>
            {showGerirListagens && (
              <GerirListagens key={anoLetivo} anoLetivo={anoLetivo} />
            )}
          </div>
        </>
      )}
      <div className="px-4 py-2 my-8">
        <label
          className="bg-fful text-white font-bold py-2 px-4 rounded my-4"
          htmlFor="anoLetivo"
        >
          Definir datas de visualização:
        </label>
        <table className="table-auto w-2/8">
          <thead>
            <tr>
              <th className="px-4 py-2">Categoria</th>
              <th className="px-4 py-2">Data Início</th>
              <th className="px-4 py-2">Data Final</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className=" px-4 py-2">Vagas Alunos</td>
              <td className=" px-4 py-2">
                <Form.Group>
                  <Form.Control
                    type="date"
                    name="startDateAlunos"
                    value={datas.startDateAlunos}
                    onChange={handleChange}
                    format="yyyy-MM-dd"
                  />
                </Form.Group>
              </td>
              <td className=" px-4 py-2">
                <Form.Group>
                  <Form.Control
                    type="date"
                    name="endDateAlunos"
                    value={datas.endDateAlunos}
                    onChange={handleChange}
                    format="yyyy-MM-dd"
                  />
                </Form.Group>
              </td>
            </tr>
            <tr>
              <td className=" px-4 py-2">Configuração Docentes</td>
              <td className=" px-4 py-2">
                <Form.Group>
                  <Form.Control
                    type="date"
                    name="startDateDocentes"
                    value={datas.startDateDocentes}
                    onChange={handleChange}
                    format="yyyy-MM-dd"
                  />
                </Form.Group>
              </td>
              <td className=" px-4 py-2">
                <Form.Group>
                  <Form.Control
                    type="date"
                    name="endDateDocentes"
                    value={datas.endDateDocentes}
                    onChange={handleChange}
                    format="yyyy-MM-dd"
                  />
                </Form.Group>
              </td>
              <td className=" px-4 py-2">
                <Form.Group>
                  <Form.Control
                    as="select"
                    name="anoLetivoDocentes"
                    value={datas.anoLetivoDocentes}
                    onChange={handleChange}
                    className="w-32 appearance-none bg-white border border-gray-300 rounded-md py-2 px-3 pr-8 leading-tight focus:outline-none focus:border-blue-500"
                    style={{
                      backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'%3E%3C/path%3E%3C/svg%3E")`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "right 0.5rem center",
                      backgroundSize: "1.5em 1.5em",
                    }}
                  >
                    <option value="">Selecione</option>
                    {options.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </td>
            </tr>
            <tr>
              <td className=" px-4 py-2">Formulário Farmácias</td>
              <td className=" px-4 py-2">
                <Form.Group>
                  <Form.Control
                    type="date"
                    name="startDateFarmacias"
                    value={datas.startDateFarmacias}
                    onChange={handleChange}
                    format="yyyy-MM-dd"
                  />
                </Form.Group>
              </td>
              <td className=" px-4 py-2">
                <Form.Group>
                  <Form.Control
                    type="date"
                    name="endDateFarmacias"
                    value={datas.endDateFarmacias}
                    onChange={handleChange}
                    format="yyyy-MM-dd"
                  />
                </Form.Group>
              </td>
              <td>
                <a
                  href="https://nitapps.ff.ulisboa.pt/form-farmacia"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://nitapps.ff.ulisboa.pt/form-farmacia
                </a>
              </td>
            </tr>
            <tr>
              <td className=" px-4 py-2">Formulário Hospitais</td>
              <td className=" px-4 py-2">
                <Form.Group>
                  <Form.Control
                    type="date"
                    name="startDateHospitais"
                    value={datas.startDateHospitais}
                    onChange={handleChange}
                    format="yyyy-MM-dd"
                  />
                </Form.Group>
              </td>
              <td className=" px-4 py-2">
                <Form.Group>
                  <Form.Control
                    type="date"
                    name="endDateHospitais"
                    value={datas.endDateHospitais}
                    onChange={handleChange}
                    format="yyyy-MM-dd"
                  />
                </Form.Group>
              </td>
              <td>
                <a
                  href="https://nitapps.ff.ulisboa.pt/form-hospital"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://nitapps.ff.ulisboa.pt/form-hospital
                </a>
              </td>
            </tr>
            <tr>
              <td className=" px-4 py-2">Formulário Trabalhos</td>
              <td className=" px-4 py-2">
                <Form.Group>
                  <Form.Control
                    type="date"
                    name="startDateTrabalhos"
                    value={datas.startDateTrabalhos}
                    onChange={handleChange}
                    format="yyyy-MM-dd"
                  />
                </Form.Group>
              </td>
              <td className=" px-4 py-2">
                <Form.Group>
                  <Form.Control
                    type="date"
                    name="endDateTrabalhos"
                    value={datas.endDateTrabalhos}
                    onChange={handleChange}
                    format="yyyy-MM-dd"
                  />
                </Form.Group>
              </td>
              <td>
                <a
                  href="https://nitapps.ff.ulisboa.pt/form-trabalho"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://nitapps.ff.ulisboa.pt/form-trabalho
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
