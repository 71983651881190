import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { FaEdit, FaRegSave } from "react-icons/fa";
import { ImCancelCircle } from "react-icons/im";
import Modal from "react-modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

Modal.setAppElement("#root");

function EditHospital({ hospitalData }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editedHospitalData, setEditedHospitalData] = useState(hospitalData);

  const apiUrl = process.env.REACT_APP_API_HOSPITAIS;

  const [errors, setErrors] = useState({
    nome: "",
    morada: "",
    codPostal: "",
    localidade: "",
    dataExpProtocolo: "",
    areaGeoLVT: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedHospitalData((prevEditedHospitalData) => ({
      ...prevEditedHospitalData,
      [name]: value,
    }));
    validateField(name, value);
  };

  const validateField = (fieldName, value) => {
    let error = "";
    switch (fieldName) {
      case "nome":
        if (!value) {
          error = "Campo obrigatório";
        }
        break;
      case "morada":
        if (!value) {
          error = "Campo obrigatório";
        }
        break;
      case "codPostal":
        if (!value) {
          error = "Campo obrigatório";
        } else if (!/\d\d\d\d-\d\d\d/.test(value)) {
          error = "Código postal deve obedecer ao formato XXXX-XXX";
        }
        break;
      case "localidade":
        if (!value) {
          error = "Campo obrigatório";
        }
        break;
      default:
        break;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: error,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    fetch(`${apiUrl}/${hospitalData.idHospital}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(editedHospitalData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setModalIsOpen(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <>
      <button
        className="text-purple-700 mx-1"
        title="Editar Hospital"
        onClick={() => setModalIsOpen(true)}
      >
        <FaEdit />
      </button>
      <Modal isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)}>
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg p-6 md:p-8">
            <h2 className="text-lg font-semibold mb-4 bg-fful px-4 py-2 rounded-lg text-white">
              Editar Hospital
            </h2>
            <Form noValidate onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Form.Group as={Col} md="8" controlId="nome">
                  <Form.Label>Nome</Form.Label>
                  <Form.Control
                    type="text"
                    name="nome"
                    value={editedHospitalData.nome}
                    onChange={handleChange}
                    isInvalid={!!errors.nome}
                    isValid={!errors.nome}
                  />
                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.nome}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="morada">
                  <Form.Label>Morada</Form.Label>
                  <Form.Control
                    type="text"
                    name="morada"
                    value={editedHospitalData.morada}
                    onChange={handleChange}
                    isInvalid={!!errors.morada}
                    isValid={!errors.morada}
                  />

                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.morada}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="3" controlId="codPostal">
                  <Form.Label>Código Postal</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="1234-123"
                    name="codPostal"
                    value={editedHospitalData.codPostal}
                    onChange={handleChange}
                    isInvalid={!!errors.codPostal}
                    isValid={!errors.codPostal}
                  />

                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.codPostal}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="3" controlId="localidade">
                  <Form.Label>Localidade</Form.Label>
                  <Form.Control
                    type="text"
                    // placeholder="Lisboa"
                    name="localidade"
                    value={editedHospitalData.localidade}
                    onChange={handleChange}
                    isInvalid={!!errors.localidade}
                    isValid={!errors.localidade}
                  />
                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.localidade}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="dataExpProtocolo">
                  <Form.Label>Data do Protocolo</Form.Label>
                  <Form.Control
                    type="date"
                    name="dataExpProtocolo"
                    value={editedHospitalData.dataExpProtocolo}
                    onChange={handleChange}
                    isInvalid={!!errors.dataExpProtocolo}
                    isValid={!errors.dataExpProtocolo}
                  />
                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.dataExpProtocolo}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="areaGeoLVT">
                  <Form.Label>Área Metropolitana de Lisboa</Form.Label>

                  <Form.Select
                    name="areaGeoLVT"
                    value={editedHospitalData.areaGeoLVT}
                    onChange={handleChange}
                  >
                    <option value="false">Não</option>
                    <option value="true">Sim</option>
                  </Form.Select>

                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.areaGeoLVT}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="obs">
                  <Form.Label>Observações</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="obs"
                    value={editedHospitalData.obs}
                    onChange={handleChange}
                    isInvalid={!!errors.obs}
                    isValid={!errors.obs}
                  />

                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.obs}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <div className="py-4">
                <button
                  type="submit"
                  disabled={Object.values(errors).some((value) => value !== "")}
                  className={
                    Object.values(errors).some((value) => value !== "")
                      ? "text-white px-4 py-2 rounded-md bg-gray-400 cursor-not-allowed"
                      : "text-white px-4 py-2 rounded-md bg-fful hover:bg-iconhover"
                  }
                >
                  <FaRegSave />
                </button>
                <button
                  type="button"
                  className="text-white px-4 py-2 mx-2 rounded-md bg-fful hover:bg-iconhover"
                  onClick={() => setModalIsOpen(false)}
                >
                  <ImCancelCircle />
                </button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default EditHospital;
