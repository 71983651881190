import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import { useKeycloak } from "@react-keycloak/web";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const ResponsiveAppBar = () => {
  const [anchorElUser, setAnchorElUser] = useState(null);
  let navigate = useNavigate();
  const { keycloak } = useKeycloak();

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    alert("Esta ferramenta ainda se encontra em desenvolvimento.");
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    setAnchorElUser(null);
    navigate("/");
    keycloak.logout();
  };

  return (
    <AppBar position="static" sx={{ mb: "1cm", backgroundColor: "#543B83" }}>
      <Container maxWidth="xl" sx={{ backgroundColor: "#543B83" }}>
        <Toolbar disableGutters>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              backgroundColor: "#543B83",
            }}
          >
            <Button
              onClick={() => navigate("/")}
              sx={{
                my: 2,
                color: "white",
                display: "block",
                "&:hover": {
                  backgroundColor: "#543B83",
                  opacity: [0.5, 0.5, 0.5],
                },
              }}
            >
              Home
            </Button>
            <Button
              onClick={() => navigate("/aluno")}
              sx={{
                my: 2,
                color: "white",
                display: "block",
                "&:hover": {
                  backgroundColor: "#543B83",
                  opacity: [0.5, 0.5, 0.5],
                },
              }}
            >
              Alunos
            </Button>
            {keycloak.hasRealmRole("00_PresidenteJuriNe") ||
            keycloak.hasRealmRole("00_Informatica") ? (
              <Button
                onClick={() => navigate("/docente")}
                sx={{
                  my: 2,
                  color: "white",
                  display: "block",
                  "&:hover": {
                    backgroundColor: "#543B83",
                    opacity: [0.5, 0.5, 0.5],
                  },
                }}
              >
                Docentes
              </Button>
            ) : null}
            {keycloak.hasRealmRole("00_Nucleo_Estagios") ||
            keycloak.hasRealmRole("00_Informatica") ? (
              <Button
                onClick={() => navigate("/ne")}
                sx={{
                  my: 2,
                  color: "white",
                  display: "block",
                  "&:hover": {
                    backgroundColor: "#543B83",
                    opacity: [0.5, 0.5, 0.5],
                  },
                }}
              >
                Núcleo de Estágios
              </Button>
            ) : null}
            {keycloak.hasRealmRole("00_Informatica") ? (
              <Button
                onClick={() => navigate("/nit")}
                sx={{
                  my: 2,
                  color: "white",
                  display: "block",
                  "&:hover": {
                    backgroundColor: "#543B83",
                    opacity: [0.5, 0.5, 0.5],
                  },
                }}
              >
                Nit
              </Button>
            ) : null}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar alt="USER" />
            </IconButton>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={() => setAnchorElUser(null)}
            >
              <MenuItem onClick={handleCloseUserMenu}>Perfil</MenuItem>
              <MenuItem onClick={handleLogout}>Terminar sessão</MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
