import React, { useState, useEffect } from "react";
import { FaTrash } from "react-icons/fa";
import EditAluno from "../Edit/EditAluno";
import AlocarVaga from "../Alocar/AlocarVaga";
import CreateAluno from "../Create/CreateAluno";
import { Modal, Button } from "react-bootstrap";
import AlocarNota from "../Alocar/AlocarNota";
import ScrollToTopButton from "../Utils/ScrollToTopButton";
import ListagemAlunos from "../Listagens/ListagemAlunos";
import { useKeycloak } from "@react-keycloak/web";
import ImportarAlunos from "../Utils/ImportarAlunos";

function GerirAlunos(anoLetivo) {
  const [alunos, setAlunos] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedAluno, setSelectedAluno] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [childUpdate, setChildUpdate] = useState(false);

  const apiUrl = process.env.REACT_APP_API_ALUNOS;

  useEffect(() => {
    fetch(`${apiUrl}/anoLetivo/${anoLetivo.anoLetivo}`)
      .then((response) => response.json())
      .then((data) => setAlunos(data));
  }, [apiUrl, childUpdate]);

  const handleDeleteAluno = (aluno) => {
    setSelectedAluno(aluno);
    setShowConfirmationModal(true);
  };

  const handleConfirmDelete = () => {
    fetch(`${apiUrl}/${selectedAluno.idAluno}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...selectedAluno, visivel: false }),
    })
      .then((response) => response.json())
      .then((data) => {
        setAlunos((prevAlunos) =>
          prevAlunos.filter((aluno) => aluno.idAluno !== selectedAluno.idAluno)
        );
        setSelectedAluno(null);
        setShowConfirmationModal(false);
      })
      .catch((error) => {
        console.error("Error deleting student:", error);
        // Handle the error condition
      });
  };

  const handleCancelDelete = () => {
    setSelectedAluno(null); // Limpar o aluno selecionado
    setShowConfirmationModal(false); // Fechar a janela de confirmação
  };

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  function removeAccents(str) {
    const accents = "ÀÁÂÃÄÅàáâãäåÒÓÔÕÖØòóôõöøÈÉÊËèéêëÇçÌÍÎÏìíîïÙÚÛÜùúûüÿÑñ";
    const accentsOut = "AAAAAAaaaaaaOOOOOOooooooEEEEeeeeCcIIIIiiiiUUUUuuuuyNn";
    return str
      .split("")
      .map((letter) => {
        const accentIndex = accents.indexOf(letter);
        return accentIndex !== -1 ? accentsOut[accentIndex] : letter;
      })
      .join("");
  }

  function filteredAlunos() {
    const searchTermLowerCase = removeAccents(searchTerm.toLowerCase());
    const filtered = alunos.filter((aluno) => {
      const numAluno = aluno.numAluno.toString();
      const nomeLowerCase = removeAccents(aluno.nome.toLowerCase());
      const emailLowerCase = removeAccents(aluno.email.toLowerCase());
      const matchesSearchTerm =
        numAluno.includes(searchTerm) ||
        nomeLowerCase.includes(searchTermLowerCase) ||
        emailLowerCase.includes(searchTermLowerCase);
      return aluno.visivel && matchesSearchTerm;
    });
    return Array.isArray(filtered) ? filtered : [filtered];
  }

  const filteredAlunosArray = Array.isArray(filteredAlunos())
    ? filteredAlunos()
    : [];

  const { keycloak } = useKeycloak();

  const showNucleoEstagios = keycloak.hasRealmRole("00_Nucleo_Estagios");

  return (
    <div className="flex flex-col items-stretch min-h-full">
      <main className="flex-grow p-4">
        <div>
          <input
            type="text"
            placeholder="Pesquisar"
            value={searchTerm}
            onChange={handleSearchTermChange}
            className="border border-gray-400 rounded px-2 py-1 mb-3"
          />
        </div>
        {showNucleoEstagios && (
          <div className="flex items-center mb-4">
            <CreateAluno anoLetivo={anoLetivo.anoLetivo} />
          </div>
        )}
        {showNucleoEstagios && (
          <div className="flex items-center mb-4">
            <ImportarAlunos
              anoLetivo={anoLetivo.anoLetivo}
              onImportComplete={() => setChildUpdate(!childUpdate)}
            />
          </div>
        )}
        {showNucleoEstagios && (
          <div className="flex items-center mb-4">
            <ListagemAlunos alunos={alunos} anoLetivo={anoLetivo} />
          </div>
        )}
        <div>
          <Modal show={showConfirmationModal} centered>
            <Modal.Body>
              Tem a certeza que pretende eliminar o aluno?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={handleConfirmDelete}>
                Sim
              </Button>
              <Button variant="secondary" onClick={handleCancelDelete}>
                Não
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        <table className="table-auto w-full" id="alunos">
          <thead>
            <tr>
              <th className="px-4 py-2">Nº Aluno</th>
              <th className="px-4 py-2">Nome</th>
              <th className="px-4 py-2">E-mail</th>
              <th className="px-4 py-2">Ações</th>
            </tr>
          </thead>
          <tbody>
            {filteredAlunosArray.map((aluno) => (
              <tr key={aluno.numAluno}>
                <td className="border px-4 py-2">{aluno.numAluno}</td>
                <td className="border px-4 py-2">{aluno.nome}</td>
                <td className="border px-4 py-2">{aluno.email}</td>
                <td className="border px-4 py-2 flex justify-center">
                  <div>
                    {showNucleoEstagios && <EditAluno alunoData={aluno} />}
                    {showNucleoEstagios && (
                      <AlocarVaga
                        alunoData={aluno}
                        onChildUpdate={setChildUpdate}
                        anoLetivo={anoLetivo}
                      />
                    )}
                    <AlocarNota alunoData={aluno} />
                    {showNucleoEstagios && (
                      <button
                        className="text-red-700 mx-1"
                        title="Excluir"
                        onClick={() => handleDeleteAluno(aluno)}
                      >
                        <FaTrash />
                      </button>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <ScrollToTopButton targetId="alunos" />
      </main>
    </div>
  );
}

export default GerirAlunos;
