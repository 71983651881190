import { ReactKeycloakProvider } from "@react-keycloak/web";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import BodyNucleoProjetos from "./BodyNucleoProjetos";
import BodyAluno from "./BodyAluno";
import BodyDocente from "./BodyDocente";
import BodyLogin from "./BodyLogin";
import BodyNit from "./BodyNit";
import keycloak from "./Keycloak";
import {
  NucleoProjetosRoute,
  DocenteRoute,
  PrivateRoute,
  NitRoute,
} from "./ProtectedRoute";
import React, { useEffect } from "react";
import BodyImages from "./BodyImages";
import BodyFormFarmacia from "./BodyFormFarmacia";
import BodyFormHospital from "./BodyFormHospital";
import BodyFormTrabalho from "./BodyFormTrabalho";

export default function Body() {
  useEffect(() => {
    if (keycloak.authenticated) {
      keycloak.onTokenExpired = function () {
        window.location.href = "https://nitapps.ff.ulisboa.pt/";
      };
    }
  }, [keycloak]);

  return (
    <div className="flex flex-col min-h-[64vh]">
      <ReactKeycloakProvider authClient={keycloak}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<BodyLogin />} />
            <Route
              path="/aluno"
              element={
                <PrivateRoute>
                  <BodyAluno />
                </PrivateRoute>
              }
            />
            <Route
              path="/ne"
              element={
                <NucleoProjetosRoute>
                  <BodyNucleoProjetos />
                </NucleoProjetosRoute>
              }
            />
            <Route
              path="/docente"
              element={
                <DocenteRoute>
                  <BodyDocente />
                </DocenteRoute>
              }
            />
            <Route
              path="/nit"
              element={
                <NitRoute>
                  <BodyNit />
                </NitRoute>
              }
            />
            <Route path="/images" element={<BodyImages />} />
            <Route path="/form-farmacia" element={<BodyFormFarmacia />} />
            <Route path="/form-hospital" element={<BodyFormHospital />} />
            <Route path="/form-trabalho" element={<BodyFormTrabalho />} />
          </Routes>
        </BrowserRouter>
      </ReactKeycloakProvider>
    </div>
  );
}
