import React, { useState, useEffect, useRef } from "react";
import CreateFarmacia from "../Create/CreateFarmacia";
import EditFarmacia from "../Edit/EditFarmacia";
import GerirContactosFarmacia from "./GerirContactosFarmacia";
import { FaTrash } from "react-icons/fa";
import { Modal, Button } from "react-bootstrap";
import ScrollToTopButton from "../Utils/ScrollToTopButton";
import ListagemFarmacias from "../Listagens/ListagemFarmacias";
import { Modal as BootstrapModal } from "react-bootstrap";
import { Editor } from "@tinymce/tinymce-react";

function GerirFarmacias(anoLetivo) {
  const [farmacias, setFarmacias] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedFarmacia, setSelectedFarmacia] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showEmailButton, setShowEmailButton] = useState(false);
  const [showConfirmationModalEmail, setShowConfirmationModalEmail] =
    useState(false);
  const [htmlBody, setHtmlBody] = useState([]);
  const htmlBodyRef = useRef(htmlBody);
  const [contatosFarmacias, setContatosFarmacias] = useState([]);
  const [contatosFarmaciasLastYear, setContatosFarmaciasLastYear] = useState(
    []
  );
  const [loading, setLoading] = useState(true);

  const apiFarmacias = process.env.REACT_APP_API_FARMACIAS;
  const apiContatoFarmacias = process.env.REACT_APP_API_CONTATOFARMACIAS;
  const apiEmail = process.env.REACT_APP_API_EMAIL;

  useEffect(() => {
    fetch(`${apiFarmacias}`)
      .then((response) => response.json())
      .then((data) => {
        setFarmacias(data);
      });
  }, [apiFarmacias]);

  useEffect(() => {
    fetch(`${apiContatoFarmacias}/anoLetivo/${anoLetivo.anoLetivo}`)
      .then((response) => response.json())
      .then((data) => {
        setContatosFarmacias(data);
        setLoading(false);
      });
  }, [apiContatoFarmacias, anoLetivo]);

  useEffect(() => {
    fetch(`${apiContatoFarmacias}/anoLetivo/${previousAnoLetivo}`)
      .then((response) => response.json())
      .then((data) => {
        setContatosFarmaciasLastYear(data);
      });
  }, [apiContatoFarmacias, anoLetivo]);

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  function filteredFarmacias() {
    const searchTermLowerCase = removeAccents(searchTerm.toLowerCase());
    const filtered = farmacias.filter((farmacia) => {
      const nomeLowerCase = removeAccents(farmacia.nome.toLowerCase());
      const moradaLowerCase = removeAccents(farmacia.morada.toLowerCase());
      const codPostalLowerCase = removeAccents(
        farmacia.codPostal.toLowerCase()
      );
      const localidadeLowerCase = removeAccents(
        farmacia.localidade.toLowerCase()
      );
      const matchesSearchTerm =
        farmacia.idFarmacia.toString().includes(searchTerm) ||
        nomeLowerCase.includes(searchTermLowerCase) ||
        moradaLowerCase.includes(searchTermLowerCase) ||
        codPostalLowerCase.includes(searchTermLowerCase) ||
        localidadeLowerCase.includes(searchTermLowerCase);
      return farmacia.visivel && matchesSearchTerm;
    });
    return Array.isArray(filtered) ? filtered : [filtered];
  }

  const filteredFarmaciasArray = Array.isArray(filteredFarmacias())
    ? filteredFarmacias()
    : [];

  // Function to remove accents from a string
  function removeAccents(str) {
    const accents = "ÀÁÂÃÄÅàáâãäåÒÓÔÕÖØòóôõöøÈÉÊËèéêëÇçÌÍÎÏìíîïÙÚÛÜùúûüÿÑñ";
    const accentsOut = "AAAAAAaaaaaaOOOOOOooooooEEEEeeeeCcIIIIiiiiUUUUuuuuyNn";
    return str
      .split("")
      .map((letter) => {
        const accentIndex = accents.indexOf(letter);
        return accentIndex !== -1 ? accentsOut[accentIndex] : letter;
      })
      .join("");
  }

  const handleDelete = (farmacia) => {
    setSelectedFarmacia(farmacia);
    setShowConfirmationModal(true);
  };

  const handleConfirmDelete = () => {
    fetch(`${apiFarmacias}/${selectedFarmacia.idFarmacia}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...selectedFarmacia, visivel: false }),
    })
      .then((response) => response.json())
      .then((data) => {
        setFarmacias((prevFarmacias) =>
          prevFarmacias.filter(
            (farmacia) => farmacia.idFarmacia !== selectedFarmacia.idFarmacia
          )
        );
        setSelectedFarmacia(null);
        setShowConfirmationModal(false);
      })
      .catch((error) => {
        console.error("Error deleting farmacia: ", error);
        // Handle the error condition
      });
  };

  const handleCancelDelete = () => {
    setSelectedFarmacia(null); // Limpar a farmacia selecionada
    setShowConfirmationModal(false); // Fechar a janela de confirmação
  };

  const handleChangeHtmlBody = (content) => {
    setHtmlBody(content);
    htmlBodyRef.current = content;
  };

  const handleCancelSend = () => {
    setShowConfirmationModalEmail(false);
  };

  const formatDate = () => {
    const months = [
      "janeiro",
      "fevereiro",
      "março",
      "abril",
      "maio",
      "junho",
      "julho",
      "agosto",
      "setembro",
      "outubro",
      "novembro",
      "dezembro",
    ];
    const today = new Date();
    const day = today.getDate();
    const month = months[today.getMonth()];
    const year = today.getFullYear();
    return `Lisboa, ${day} de ${month} de ${year}`;
  };

  const sendEmail = (emailTO, emailCC, subject, htmlBody) => {
    return fetch(`${apiEmail}/vagas`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        emailTO: emailTO
          .filter((email) => email && email.trim() !== "")
          .join(";"),
        emailCC: emailCC
          .filter((email) => email && email.trim() !== "")
          .join(";"),
        subject: subject,
        htmlBody: htmlBody,
      }),
    })
      .then((response) => {
        if (response.ok) {
          setShowConfirmationModal(false);
        } else {
          throw new Error("Failed to send email");
        }
        return response.json();
      })
      .catch((error) => {
        console.error("Error:", error);
        throw error;
      });
  };

  const handleConfirmSend = () => {
    sendEmailsForFarmacias();
  };

  const currentAnoLetivo = anoLetivo.anoLetivo;
  const [startYear, endYear] = currentAnoLetivo.split("-").map(Number);
  const previousAnoLetivo = `${startYear - 1}-${endYear - 1}`;

  // const sendEmailsForFarmacias = () => {
  //   farmacias
  //     .filter((farmacia) => farmacia.visivel === true)
  //     .forEach((farmacia) => {
  //       const contatoFarmacia = contatosFarmaciasLastYear.find(
  //         (contato) => contato.idFarmacia === farmacia.idFarmacia
  //       );

  //       if (contatoFarmacia) {
  //         const updatedHtmlBody = htmlBody
  //           .replace("NOMEFARMACIA", farmacia.nome)
  //           .replace("IDFARMACIA", farmacia.idFarmacia);

  //         const emailTO = [contatoFarmacia.email];
  //         const emailCC = [
  //           contatoFarmacia.emailEstagios,
  //           contatoFarmacia.emailAlternativo,
  //         ];

  //         const subject = `Convite a abertura de vagas de Estágio Curricular para ${currentAnoLetivo}`;

  //         sendEmail(emailTO, emailCC, subject, updatedHtmlBody);
  //         setShowConfirmationModalEmail(false);
  //       }
  //     });
  // };

  const sendEmailsForFarmacias = () => {
    const visibleFarmacias = farmacias.filter(
      (farmacia) => farmacia.visivel === true
    );
    const batchSize = 20;
    const delayBetweenBatches = 60000;
    const delayBetweenEmails = 1000;
    const totalEmails = visibleFarmacias.length;
    let emailsSent = 0;

    const sendBatch = (batchIndex) => {
      const start = batchIndex * batchSize;
      const end = start + batchSize;
      const batch = visibleFarmacias.slice(start, end);

      const sendEmailWithDelay = (index) => {
        if (index >= batch.length) {
          if (end < visibleFarmacias.length) {
            setTimeout(() => sendBatch(batchIndex + 1), delayBetweenBatches);
          } else {
            setShowConfirmationModalEmail(false);
            console.log("All emails sent");
          }
          return;
        }

        const farmacia = batch[index];
        const contatoFarmacia = contatosFarmaciasLastYear.find(
          (contato) => contato.idFarmacia === farmacia.idFarmacia
        );

        if (contatoFarmacia) {
          const updatedHtmlBody = htmlBody
            .replace("NOMEFARMACIA", farmacia.nome)
            .replace("IDFARMACIA", farmacia.idFarmacia);

          const emailTO = [contatoFarmacia.email];
          const emailCC = [
            contatoFarmacia.emailEstagios,
            contatoFarmacia.emailAlternativo,
          ];

          const subject = `FFUL -> Convite para abertura de vagas de Estágio Curricular ${currentAnoLetivo}`;

          sendEmail(emailTO, emailCC, subject, updatedHtmlBody)
            .then(() => {
              emailsSent++;
              console.log(`Sent ${emailsSent}/${totalEmails} emails`);
              setTimeout(
                () => sendEmailWithDelay(index + 1),
                delayBetweenEmails
              );
            })
            .catch((error) => {
              console.error(
                `Error sending email to: ${emailTO.join(", ")} - ${error}`
              );
              setTimeout(
                () => sendEmailWithDelay(index + 1),
                delayBetweenEmails
              );
            });
        } else {
          setTimeout(() => sendEmailWithDelay(index + 1), delayBetweenEmails);
        }
      };

      sendEmailWithDelay(0);
    };

    sendBatch(0);
  };

  const handleSendEmail = () => {
    emailBody();
    setShowConfirmationModalEmail(true);
  };

  const emailBody = () => {
    const emailContent = `
      <div style='text-align: center;'>
              <img src='https://www.ff.ulisboa.pt/wp-content/uploads/2024/10/fful-assinatura-email.png' alt='Header image' style='width: 150px; height: auto;' />
              <p><strong>FACULDADE DE FARMÁCIA DA UNIVERSIDADE DE LISBOA</strong></p>
            </div>
            <div style='text-align: right; margin-top: 20px;'>
              <p>${formatDate()}</p>
            </div>
            <div></div>
            <div></div>
            <div>Exmo(a). Sr.(a) Dr.(a)</div>
            <div>Diretor(a) da Farmácia <strong>NOMEFARMACIA</strong></div>
            <div></div>
            <div></div>
            <div>A Faculdade de Farmácia da Universidade de Lisboa vem por este meio agradecer a disponibilidade das Farmácias registadas no Núcleo de Estágios da FFUL, na orientação do estágio curricular do Mestrado Integrado em Ciências Farmacêuticas. Gostaríamos de sublinhar que graças a este contributo, foi possível assegurar o estágio a cerca de <strong>(NUMALUNOS)</strong>  alunos no ano letivo ${previousAnoLetivo}.</div>
            <div>Expressamos a vontade de continuar a colaborar com a vossa instituição e renovamos o convite para orientarem o estágio relativo ao ano ${currentAnoLetivo}.</div>
            <div>A candidatura realiza-se online, sendo obrigatório o preenchimento de um formulário:</div>
            <div></div>
            <div><strong>Formulário disponível em -> https://nitapps.ff.ulisboa.pt/form-farmacia</strong></div>
            <div></div>
            <div><strong>Importante,</strong> onde é solicitado o Código da Farmácia deve ser colocado o nº <strong>IDFARMACIA</strong>.</div>
            <div></div>
            <div>A secção de "Orientador(es) de Estágio" deve ser preenchida por todos os orientadores de Estágio, ou seja, deve ser preenchida uma entrada por cada Orientador (carregando no botão "Adicionar outro Orientador").</div>
            <div></div>
            <div>Após preenchimento do formulário deve submeter o mesmo através da opção "Submeter Formulário".</div>
            <div></div>
            <div>Agradecemos a submissão até ao dia <strong>(DIASUBMISSAO)</strong>.</div>
            <div></div>
            <div><strong>Chamamos à atenção que os alunos finalistas do MICF escolhem as vagas de estágio oferecidas pela Farmácia na última semana de dezembro, altura em que comunicaremos o resultado dessa escolha.</strong></div>
            <div style='text-align: center; margin-top: 20px;'>
            <div>Com os melhores cumprimentos,</div>
            <div><img src='https://www.ff.ulisboa.pt/wp-content/uploads/2024/10/assinatura-carla-barros.png' alt='Signature Image' style='width: 300px; height: auto;' /></div>
            <div>Carla Teixeira de Barros</div>
            <div>(Coordenadora da Unidade Curricular "Estágio" do MICF, da FFUL)</div>
          </div>
    `;

    setHtmlBody(emailContent);
  };

  return (
    <div className="flex flex-col items-stretch min-h-full">
      <main className="flex-grow p-4">
        <div>
          <BootstrapModal show={showConfirmationModalEmail} centered>
            <style type="text/css">
              {`
      .modal-dialog {
        max-width: 90% !important;
        height: 80vh !important;
      }

      .modal-body {
        height: calc(80vh - 60px); /* 60px is an estimated height for the header and footer */
        overflow-y: auto; /* add a scrollbar if the content is too tall */
      }
    `}
            </style>
            <BootstrapModal.Body>
              <form>
                <div className="form-group">
                  <label htmlFor="body">Email:</label>
                  <Editor
                    apiKey="fra21wku4hoonua8iqg7xbfv9uhgzud33f456hj07igwxs2o"
                    value={htmlBody}
                    init={{
                      height: 700,
                      menubar: false,
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                      ],
                      toolbar:
                        "undo redo | formatselect | bold italic backcolor | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | removeformat | help",
                      branding: false,
                      elementpath: false,
                      resize: false,
                    }}
                    onEditorChange={handleChangeHtmlBody}
                  />
                </div>
              </form>
            </BootstrapModal.Body>
            <BootstrapModal.Footer>
              <Button
                style={{
                  backgroundColor: "lightcoral",
                  borderColor: "lightcoral",
                  color: "black",
                }}
                variant="secondary"
                onClick={handleCancelSend}
              >
                Cancelar
              </Button>
              <Button
                style={{
                  backgroundColor: "lightgreen",
                  borderColor: "lightgreen",
                  color: "black",
                }}
                variant="danger"
                onClick={handleConfirmSend}
              >
                Enviar
              </Button>
            </BootstrapModal.Footer>
          </BootstrapModal>
        </div>
        <div className="py-2 mb-8 flex flex-col items-start">
          <button
            className="text-fful font-bold py-2 rounded"
            onClick={() => setShowEmailButton(!showEmailButton)}
          >
            {showEmailButton ? "Fechar Envio de Emails" : "Envio de Emails"}
          </button>
          {showEmailButton && (
            <button
              className="text-fful ml-4 pl-10 mt-2 pb-6"
              onClick={() => handleSendEmail()}
            >
              Envio de emails para pedido de vagas
            </button>
          )}
        </div>

        <div>
          <input
            type="text"
            placeholder="Pesquisar"
            value={searchTerm}
            onChange={handleSearchTermChange}
            className="border border-gray-400 rounded px-2 py-1 mb-3"
          />
        </div>
        <div className="flex items-center mb-4">
          <CreateFarmacia />
        </div>
        <div className="flex items-center mb-4">
          <ListagemFarmacias
            farmacias={farmacias}
            anoLetivo={anoLetivo}
            contatosFarmacias={contatosFarmacias}
            loading={loading}
          />
        </div>
        <div>
          <Modal show={showConfirmationModal} centered>
            <Modal.Body>
              Tem a certeza que pretende eliminar a farmácia?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={handleConfirmDelete}>
                Sim
              </Button>
              <Button variant="secondary" onClick={handleCancelDelete}>
                Não
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        <div></div>
        <table className="table-auto w-full" id="farmacias">
          <thead>
            <tr>
              <th className="px-4 py-2">ID</th>
              <th className="px-4 py-2">Nome</th>
              <th className="px-4 py-2">Morada</th>
              <th className="px-4 py-2">Código Postal</th>
              <th className="px-4 py-2">Localidade</th>
              <th className="px-4 py-2">Data Expiração Protocolo</th>
              <th className="px-4 py-2">Área Metropolitana de Lisboa</th>
              <th className="px-4 py-2">Ações</th>
            </tr>
          </thead>
          <tbody>
            {filteredFarmaciasArray.map((farmacia) => {
              const isAreaLVT = farmacia.areaGeoLVT
                ? "bg-green-200"
                : "bg-red-200";
              return (
                <tr key={farmacia.idFarmacia}>
                  <td className="border px-4 py-2">{farmacia.idFarmacia}</td>
                  <td className="border px-4 py-2">{farmacia.nome}</td>
                  <td className="border px-4 py-2">{farmacia.morada}</td>
                  <td className="border px-4 py-2">{farmacia.codPostal}</td>
                  <td className="border px-4 py-2">{farmacia.localidade}</td>
                  <td className="border px-4 py-2">
                    {farmacia.dataExpProtocolo}
                  </td>
                  <td className={`border px-4 py-2 ${isAreaLVT}`}></td>
                  <td className="border px-4 py-2 flex justify-center">
                    <div>
                      <EditFarmacia farmaciaData={farmacia} />
                      <GerirContactosFarmacia
                        farmaciaData={farmacia}
                        anoLetivo={anoLetivo}
                      />
                      <button
                        className="text-red-700 mx-1"
                        title="Excluir"
                        onClick={() => handleDelete(farmacia)}
                      >
                        <FaTrash />
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <ScrollToTopButton targetId="farmacias" />
      </main>
    </div>
  );
}

export default GerirFarmacias;
