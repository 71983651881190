import Keycloak from "keycloak-js";
const keycloak = Keycloak({
  // url: "http://nitapps.ff.ulisboa.pt:8080/auth/",
  url: "https://nitapps.ff.ulisboa.pt/auth/",
  // url: "http://10.104.64.7:8080/auth/",
  realm: "FFUL",
  clientId: "frontend",
});

export default keycloak;
