import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { FaEdit, FaRegSave } from "react-icons/fa";
import { ImCancelCircle } from "react-icons/im";
import Modal from "react-modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function EditContactoHospital({ contactoHospitalData, onClose }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editedContactoHospitalData, setEditedContactoHospitalData] =
    useState(contactoHospitalData);

  const apiContactoHospital = process.env.REACT_APP_API_CONTATOHOSPITAIS;

  const [errors, setErrors] = useState({
    anoLetivo: "",
    nome: "",
    email: "",
    emailEstagios: "",
    emailAlternativo: "",
    telefone: "",
    telemovel: "",
    cargo: "",
    // obs: "",
  });

  useEffect(() => {
    // Validate all fields when the modal is opened
    validateAllFields();
  }, []);

  const validateAllFields = () => {
    let newErrors = {};
    Object.keys(editedContactoHospitalData).forEach((fieldName) => {
      const value = editedContactoHospitalData[fieldName];
      let error = "";
      switch (fieldName) {
        case "cargo":
          if (!value) {
            error = "Campo obrigatório";
          }
          break;
        default:
          break;
      }
      newErrors[fieldName] = error;
    });
    setErrors(newErrors);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedContactoHospitalData((prevEditedContactoHospitalData) => ({
      ...prevEditedContactoHospitalData,
      [name]: value,
    }));
    validateField(name, value);
  };

  const validateField = (fieldName, value) => {
    let error = "";
    switch (fieldName) {
      case "anoLetivo":
        if (!value) {
          error = "Campo obrigatório";
        } else if (!/^\d{4}-\d{4}$/.test(value)) {
          error = "O ano letivo deve estar no formato 1234-1234";
        }
        break;
      case "nome":
        if (!value) {
          error = "Campo obrigatório";
        } else if (!/^[a-zA-Z\u00C0-\u017F\s']+$/.test(value)) {
          error = "O nome só pode conter letras";
        }
        break;
      case "email":
        if (!value) {
          error = "Campo obrigatório";
        } else if (!/^\S+@\S+\.\S+(\.\S+)?$/.test(value)) {
          error = "O email deve estar no formato a@b.c";
        }
        break;
      case "emailEstagios":
        if (value && !/^\S+@\S+\.\S+(\.\S+)?$/.test(value)) {
          error = "O email deve estar no formato a@b.c";
        }
        break;
      case "emailAlternativo":
        if (value && !/^\S+@\S+\.\S+(\.\S+)?$/.test(value)) {
          error = "O email deve estar no formato a@b.c";
        }
        break;
      case "telefone":
        if (value && !/^\d+$/.test(value)) {
          error = "O telefone deve conter apenas dígitos";
        }
        break;
      case "telemovel":
        if (value && !/^\d+$/.test(value)) {
          error = "O telemóvel deve conter apenas dígitos";
        }
        break;
      case "cargo":
        if (!value) {
          error = "Campo obrigatório";
        }
        break;

      default:
        break;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: error,
    }));
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
    onClose();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    fetch(`${apiContactoHospital}/${contactoHospitalData.idContatoHospital}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(editedContactoHospitalData),
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        handleCloseModal();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <>
      <button
        className="text-purple-700 mx-1"
        title="Editar Contacto Hospital"
        onClick={() => setModalIsOpen(true)}
      >
        <FaEdit />
      </button>
      <Modal isOpen={modalIsOpen} onRequestClose={handleCloseModal}>
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg p-6 md:p-8">
            <h2 className="text-lg font-semibold mb-4 bg-fful px-4 py-2 rounded-lg text-white">
              Editar Contacto Hospital
            </h2>
            <Form noValidate onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Form.Group as={Col} md="3" controlId="anoLetivo">
                  <Form.Label>Ano Letivo</Form.Label>
                  <Form.Control
                    type="text"
                    name="anoLetivo"
                    placeholder="1111-2222"
                    value={editedContactoHospitalData.anoLetivo}
                    onChange={handleChange}
                    isInvalid={!!errors.anoLetivo}
                    isValid={!errors.anoLetivo}
                  />
                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.anoLetivo}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="8" controlId="nome">
                  <Form.Label>Nome</Form.Label>
                  <Form.Control
                    type="text"
                    name="nome"
                    value={editedContactoHospitalData.nome}
                    onChange={handleChange}
                    isInvalid={!!errors.nome}
                    isValid={!errors.nome}
                  />
                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.nome}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="4" controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    name="email"
                    value={editedContactoHospitalData.email}
                    onChange={handleChange}
                    isInvalid={!!errors.email}
                    isValid={!errors.email}
                  />

                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="emailEstagios">
                  <Form.Label>Email Estágios</Form.Label>
                  <Form.Control
                    type="text"
                    name="emailEstagios"
                    value={editedContactoHospitalData.emailEstagios}
                    onChange={handleChange}
                    isInvalid={!!errors.emailEstagios}
                    isValid={!errors.emailEstagios}
                  />

                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.emailEstagios}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="emailAlternativo">
                  <Form.Label>Email Alternativo</Form.Label>
                  <Form.Control
                    type="text"
                    name="emailAlternativo"
                    value={editedContactoHospitalData.emailAlternativo}
                    onChange={handleChange}
                    isInvalid={!!errors.emailAlternativo}
                    isValid={!errors.emailAlternativo}
                  />

                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.emailAlternativo}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="2" controlId="telefone">
                  <Form.Label>Telefone</Form.Label>
                  <Form.Control
                    type="number"
                    name="telefone"
                    value={editedContactoHospitalData.telefone}
                    onChange={handleChange}
                    isInvalid={!!errors.telefone}
                    isValid={!errors.telefone}
                  />

                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.telefone}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="2" controlId="telemovel">
                  <Form.Label>Telemóvel</Form.Label>
                  <Form.Control
                    type="number"
                    name="telemovel"
                    value={editedContactoHospitalData.telemovel}
                    onChange={handleChange}
                    isInvalid={!!errors.telemovel}
                    isValid={!errors.telemovel}
                  />

                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.telemovel}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="3" controlId="cargo">
                  <Form.Label>Cargo</Form.Label>
                  <Form.Control
                    type="text"
                    name="cargo"
                    value={editedContactoHospitalData.cargo}
                    onChange={handleChange}
                    isInvalid={!!errors.cargo}
                    isValid={!errors.cargo}
                  />

                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Sucess</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.cargo}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="obs">
                  <Form.Label>Observações</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="obs"
                    value={editedContactoHospitalData.obs}
                    onChange={handleChange}
                    isInvalid={!!errors.obs}
                    isValid={!errors.obs}
                  />

                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.obs}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <div className="py-4">
                <button
                  type="submit"
                  onClick={handleSubmit}
                  disabled={Object.values(errors).some((value) => value !== "")}
                  className={
                    Object.values(errors).some((value) => value !== "")
                      ? "text-white px-4 py-2 rounded-md bg-gray-400 cursor-not-allowed"
                      : "text-white px-4 py-2 rounded-md bg-fful hover:bg-iconhover"
                  }
                >
                  <FaRegSave />
                </button>
                <button
                  type="button"
                  className="text-white px-4 py-2 mx-2 rounded-md bg-fful hover:bg-iconhover"
                  onClick={handleCloseModal}
                >
                  <ImCancelCircle />
                </button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default EditContactoHospital;
