import React, { useState, useEffect } from "react";
import { FaTrash } from "react-icons/fa";
import { Modal, Button } from "react-bootstrap";
import CreateVagaHospital from "../Create/CreateVagaHospital";
import EditVagaHospital from "../Edit/EditVagaHospital";
import ScrollToTopButton from "../Utils/ScrollToTopButton";

function GerirVagasHospitais(anoLetivo) {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedVaga, setSelectedVaga] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [vagasHospitais, setVagasHospitais] = useState([]);
  const [hospitais, setHospitais] = useState([]);
  const [alunos, setAlunos] = useState([]);
  const [blocosH, setBlocosH] = useState([]);

  const apiVagasHospitais = process.env.REACT_APP_API_VAGASHOSPITAIS;
  const apiHospitais = process.env.REACT_APP_API_HOSPITAIS;
  const apiBlocoH = process.env.REACT_APP_API_BLOCOH;
  const apiAlunos = process.env.REACT_APP_API_ALUNOS;

  useEffect(() => {
    const fetchData = () => {
      const year = anoLetivo?.anoLetivo || anoLetivo?.anoLetivo?.anoLetivo;
      Promise.all([
        fetch(`${apiVagasHospitais}/anoLetivo/${year}`),
        fetch(`${apiAlunos}/anoLetivo/${year}`),
        fetch(`${apiBlocoH}/anoLetivo/${year}`),
        fetch(`${apiHospitais}`),
      ])
        .then((responses) => Promise.all(responses.map((res) => res.json())))
        .then(
          ([vagasHospitaisData, alunosData, blocosHData, hospitaisData]) => {
            setVagasHospitais(vagasHospitaisData);
            setHospitais(hospitaisData);
            setAlunos(alunosData);
            setBlocosH(blocosHData);
          }
        );
    };

    // Fetch the data immediately
    fetchData();

    // Then fetch the data every 2 minutes
    const intervalId = setInterval(fetchData, 2 * 60 * 1000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [apiVagasHospitais, apiHospitais, apiAlunos, apiBlocoH]);

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredVagasHospitais = vagasHospitais.filter((item) => {
    const hospital =
      Array.isArray(hospitais) &&
      hospitais.find((f) => f.idHospital === item.idHospital);
    const blocoH =
      Array.isArray(blocosH) &&
      blocosH.find((b) => b.idBlocoH === item.idBlocoH);
    const aluno =
      Array.isArray(alunos) && alunos.find((a) => a.idAluno === item.idAluno);
    const searchTermLowerCase = removeAccents(searchTerm.toLowerCase());
    const farmaciaNomeLowerCase = hospital
      ? removeAccents(hospital.nome.toLowerCase())
      : "";
    const farmaciaMoradaLowerCase = hospital
      ? removeAccents(hospital.morada.toLowerCase())
      : "";
    const blocoFLowerCase = blocoH
      ? removeAccents(blocoH.bloco.toLowerCase())
      : "";
    const alunoNumLowerCase = aluno ? aluno.numAluno : "";
    const alunoNomeLowerCase = aluno
      ? removeAccents(aluno.nome.toLowerCase())
      : "";
    const obsLowerCase = removeAccents(item.obs.toLowerCase() || "");
    const matchesSearchTerm =
      item.idVagaHospital.toString().includes(searchTerm) ||
      farmaciaNomeLowerCase.includes(searchTermLowerCase) ||
      farmaciaMoradaLowerCase.includes(searchTermLowerCase) ||
      blocoFLowerCase.includes(searchTermLowerCase) ||
      alunoNumLowerCase.toString().includes(searchTerm) ||
      alunoNomeLowerCase.includes(searchTermLowerCase) ||
      obsLowerCase.includes(searchTermLowerCase);
    return matchesSearchTerm;
  });

  // Function to remove accents from a string
  function removeAccents(str) {
    const accents = "ÀÁÂÃÄÅàáâãäåÒÓÔÕÖØòóôõöøÈÉÊËèéêëÇçÌÍÎÏìíîïÙÚÛÜùúûüÿÑñ";
    const accentsOut = "AAAAAAaaaaaaOOOOOOooooooEEEEeeeeCcIIIIiiiiUUUUuuuuyNn";
    return str
      .split("")
      .map((letter) => {
        const accentIndex = accents.indexOf(letter);
        return accentIndex !== -1 ? accentsOut[accentIndex] : letter;
      })
      .join("");
  }

  const handleDelete = (item) => {
    setSelectedVaga(item);
    setShowConfirmationModal(true);
  };

  const handleConfirmDelete = () => {
    fetch(`${apiVagasHospitais}/${selectedVaga.idVagaHospital}`, {
      method: "DELETE",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error deleting vaga");
        }
      })
      .then(() => {
        setVagasHospitais((prevVagasHospitais) =>
          prevVagasHospitais.filter(
            (vaga) => vaga.idVagaHospital !== selectedVaga.idVagaHospital
          )
        );
        setSelectedVaga(null);
        setShowConfirmationModal(false);
      })
      .catch((error) => {
        console.error("Error deleting vaga: ", error);
        // Handle the error condition
      });
  };

  const handleCancelDelete = () => {
    setSelectedVaga(null); // Limpar o trabalho selecionado
    setShowConfirmationModal(false); // Fechar a janela de confirmação
  };

  return (
    <div className="flex flex-col items-stretch min-h-full">
      <main className="flex-grow p-4">
        <div>
          <input
            type="text"
            placeholder="Pesquisar"
            value={searchTerm}
            onChange={handleSearchTermChange}
            className="border border-gray-400 rounded px-2 py-1 mb-3"
          />
        </div>
        <div className="flex items-center mb-4">
          <CreateVagaHospital
            key={anoLetivo}
            anoLetivo={anoLetivo}
            hospitais={hospitais}
            alunos={alunos}
            blocosH={blocosH}
          />
        </div>
        <div>
          <Modal show={showConfirmationModal} centered>
            <Modal.Body>Tem a certeza que pretende eliminar a vaga?</Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={handleConfirmDelete}>
                Sim
              </Button>
              <Button variant="secondary" onClick={handleCancelDelete}>
                Não
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        <table className="table-auto w-full" id="vagasHospitais">
          <thead>
            <tr>
              <th className="px-4 py-2">ID da Vaga</th>
              <th className="px-4 py-2">Hospital</th>
              <th className="px-4 py-2">Código Postal</th>
              <th className="px-4 py-2">Disponível</th>
              <th className="px-4 py-2">Bloco</th>
              <th className="px-4 py-2">Data Início</th>
              <th className="px-4 py-2">Data Fim</th>
              <th className="px-4 py-2">Número Aluno</th>
              <th className="px-4 py-2">Nome</th>
              <th className="px-4 py-2">Observações</th>
              <th className="px-4 py-2">Ações</th>
            </tr>
          </thead>
          <tbody>
            {filteredVagasHospitais.map((item) => {
              const hospital =
                Array.isArray(hospitais) &&
                hospitais.find((f) => f.idHospital === item.idHospital);
              const aluno =
                Array.isArray(alunos) &&
                alunos.find((a) => a.idAluno === item.idAluno);
              const blocoH =
                Array.isArray(blocosH) &&
                blocosH.find((b) => b.idBlocoH === item.idBlocoH);
              const disponivelClass = item.disponivel
                ? "bg-green-200"
                : "bg-red-200";

              return (
                <tr key={item.idVagaHospital}>
                  <td className="border px-4 py-2">{item.idVagaHospital}</td>
                  <td className="border px-4 py-2">
                    {hospital ? hospital.nome : ""}
                  </td>
                  <td className="border px-4 py-2">
                    {hospital ? hospital.codPostal : ""}
                  </td>
                  <td className={`border px-4 py-2 ${disponivelClass}`}></td>
                  <td className="border px-4 py-2">
                    {blocoH ? blocoH.bloco : ""}
                  </td>
                  <td className="border px-4 py-2">
                    {blocoH
                      ? new Date(blocoH.dataInicio).toLocaleDateString()
                      : ""}
                  </td>
                  <td className="border px-4 py-2">
                    {blocoH
                      ? new Date(blocoH.dataFim).toLocaleDateString()
                      : ""}
                  </td>
                  <td className="border px-4 py-2">
                    {aluno ? aluno.numAluno : ""}
                  </td>
                  <td className="border px-4 py-2">
                    {aluno ? aluno.nome : ""}
                  </td>
                  <td className="border px-4 py-2">{item.obs}</td>
                  <td className="border px-4 py-2">
                    <div>
                      <EditVagaHospital
                        data={item}
                        hospitais={hospitais}
                        alunos={alunos}
                        blocosH={blocosH}
                      />
                      <button
                        className="text-red-700 mx-1"
                        title="Excluir"
                        onClick={() => handleDelete(item)}
                      >
                        <FaTrash />
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <ScrollToTopButton targetId="vagasHospitais" />
      </main>
    </div>
  );
}

export default GerirVagasHospitais;
