import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { FaRegSave } from "react-icons/fa";
import { ImCancelCircle } from "react-icons/im";
import Modal from "react-modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { AiOutlineUserAdd } from "react-icons/ai";
import Select from "react-select";

Modal.setAppElement("#root");

function CreateOrientadorFarmacia({ anoLetivo, farmacias }) {
  const INITIAL_STATE = {
    nome: "",
    email: "",
    emailAlternativo: "",
    telefone: "",
    telemovel: "",
    numOrdem: "",
    obs: "",
    idFarmacia: "",
    anoLetivo: anoLetivo,
    habilitacoes: "",
    idade: 0,
    numAnosExperiencia: 0,
    sexo: "",
  };
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [newOrientadorData, setNewOrientadorData] = useState(INITIAL_STATE);
  const [farmaciaOptions, setFarmaciaOptions] = useState(farmacias);

  const handleReset = () => {
    setNewOrientadorData(INITIAL_STATE);
  };

  const apiOrientadorFarmacia = process.env.REACT_APP_API_ORIENTADOR_FARMACIA;
  const apiFarmacias = process.env.REACT_APP_API_FARMACIAS;

  const [errors, setErrors] = useState({
    nome: "",
    email: "",
    emailAlternativo: "",
    telefone: "",
    telemovel: "",
    numOrdem: "",
    idFarmacia: "",
    habilitacoes: "",
    idade: "",
    numAnosExperiencia: "",
    sexo: "",
  });

  const fetchFarmacias = async () => {
    const newFarmacias = farmacias.map((farmacia) => ({
      value: farmacia.idFarmacia,
      label: farmacia.nome,
    }));
    setFarmaciaOptions(newFarmacias);
  };

  const handleFarmaciaChange = (selectedOption) => {
    setNewOrientadorData((prev) => ({
      ...prev,
      idFarmacia: selectedOption.value,
    }));
    validateField("idFarmacia", selectedOption.value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewOrientadorData((prevNewOrientadorData) => ({
      ...prevNewOrientadorData,
      [name]: value,
    }));
    validateField(name, value);
  };

  const validateField = (fieldName, value) => {
    let error = "";
    switch (fieldName) {
      case "nome":
        if (!value) {
          error = "Campo obrigatório";
        }
        break;
      case "idFarmacia":
        if (!value) {
          error = "Campo obrigatório";
        }
        break;
      case "habilitacoes":
        if (!value) {
          error = "Campo obrigatório";
        }
        break;
      case "sexo":
        if (!value) {
          error = "Campo obrigatório";
        }
        break;
      default:
        break;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: error,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    fetch(`${apiOrientadorFarmacia}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newOrientadorData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setModalIsOpen(false);
        handleReset();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const openModal = () => {
    setModalIsOpen(true);
    fetchFarmacias();
  };

  const closeModal = () => {
    handleReset();
    setModalIsOpen(false);
  };

  useEffect(() => {
    if (modalIsOpen) {
      for (const key in newOrientadorData) {
        validateField(key, newOrientadorData[key]);
      }
    }
  }, [modalIsOpen]);

  return (
    <>
      <button
        className="text-purple-700 mx-1 flex items-center"
        title="Adicionar"
        onClick={openModal}
      >
        <span className="mr-2">Adicionar Orientador</span>
        <AiOutlineUserAdd />
      </button>
      <Modal isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)}>
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg p-6 md:p-8">
            <h2 className="text-lg font-semibold mb-4 bg-fful px-4 py-2 rounded-lg text-white">
              Adicionar Orientador
            </h2>
            <Form noValidate onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Form.Group as={Col} md="8" controlId="nome">
                  <Form.Label>Nome</Form.Label>
                  <Form.Control
                    type="text"
                    name="nome"
                    onChange={handleChange}
                    value={newOrientadorData.nome}
                    isInvalid={!!errors.nome}
                    isValid={!errors.nome}
                  />
                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.nome}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    name="email"
                    onChange={handleChange}
                    value={newOrientadorData.email}
                    isInvalid={!!errors.email}
                    isValid={!errors.email}
                  />

                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="emailAlternativo">
                  <Form.Label>Email Alternativo</Form.Label>
                  <Form.Control
                    type="text"
                    name="emailAlternativo"
                    onChange={handleChange}
                    value={newOrientadorData.emailAlternativo}
                    isInvalid={!!errors.emailAlternativo}
                    isValid={!errors.emailAlternativo}
                  />
                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.emailAlternativo}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="4" controlId="telemovel">
                  <Form.Label>Telemóvel</Form.Label>
                  <Form.Control
                    type="number"
                    name="telemovel"
                    value={newOrientadorData.telemovel}
                    onChange={handleChange}
                    isInvalid={!!errors.telemovel}
                    isValid={!errors.telemovel}
                  />
                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.telemovel}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="telefone">
                  <Form.Label>Telefone</Form.Label>
                  <Form.Control
                    type="number"
                    name="telefone"
                    value={newOrientadorData.telefone}
                    onChange={handleChange}
                    isInvalid={!!errors.telefone}
                    isValid={!errors.telefone}
                  />
                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.telefone}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="numOrdem">
                  <Form.Label>Nº de Ordem</Form.Label>
                  <Form.Control
                    type="number"
                    name="numOrdem"
                    value={newOrientadorData.numOrdem}
                    onChange={handleChange}
                    isInvalid={!!errors.numOrdem}
                    isValid={!errors.numOrdem}
                  />
                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.numOrdem}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="4" controlId="habilitacoes">
                  <Form.Label>Habilitações</Form.Label>
                  <Form.Select
                    name="habilitacoes"
                    value={newOrientadorData.habilitacoes}
                    onChange={handleChange}
                    isInvalid={!!errors.habilitacoes}
                    isValid={!errors.habilitacoes}
                  >
                    <option value="">Selecione as habilitações</option>
                    <option value="LICENCIATURA">Licenciatura</option>
                    <option value="MICF">MICF</option>
                    <option value="MESTRADO">Mestrado</option>
                    <option value="DOUTORAMENTO">Doutoramento</option>
                    <option value="POS_GRADUACAO">Pós-Graduação</option>
                    <option value="ESPECIALISTA_FARMACIA">
                      Especialista em Farmácia Comunitária
                    </option>
                  </Form.Select>
                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.habilitacoes}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="3" controlId="sexo">
                  <Form.Label>Sexo</Form.Label>
                  <Form.Select
                    name="sexo"
                    value={newOrientadorData.sexo}
                    onChange={handleChange}
                    isInvalid={!!errors.sexo}
                    isValid={!errors.sexo}
                  >
                    <option value="">Selecione o sexo</option>
                    <option value="FEMININO">Feminino</option>
                    <option value="MASCULINO">Masculino</option>
                  </Form.Select>
                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.sexo}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="2" controlId="idade">
                  <Form.Label>Idade</Form.Label>
                  <Form.Control
                    type="number"
                    name="idade"
                    value={newOrientadorData.idade}
                    onChange={handleChange}
                    isInvalid={!!errors.idade}
                    isValid={!errors.idade}
                  />
                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.idade}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="3" controlId="numAnosExperiencia">
                  <Form.Label>Nº de Anos de Experiência</Form.Label>

                  <Form.Control
                    type="number"
                    name="numAnosExperiencia"
                    value={newOrientadorData.numAnosExperiencia}
                    onChange={handleChange}
                    isInvalid={!!errors.numAnosExperiencia}
                    isValid={!errors.numAnosExperiencia}
                  />
                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.numAnosExperiencia}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="obs">
                  <Form.Label>Observações</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="obs"
                    onChange={handleChange}
                    value={newOrientadorData.obs}
                    isInvalid={!!errors.obs}
                    isValid={!errors.obs}
                  />

                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.obs}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="idFarmacia">
                  <Form.Label>Farmácia</Form.Label>
                  <Select
                    options={farmaciaOptions}
                    onChange={handleFarmaciaChange}
                    value={farmaciaOptions.find(
                      (option) => option.value === newOrientadorData.idFarmacia
                    )}
                    isInvalid={!!errors.idFarmacia}
                    placeholder="Selecione ou pesquise uma farmácia"
                  />
                  {errors.idFarmacia && (
                    <div className="invalid-feedback d-block">
                      {errors.idFarmacia}
                    </div>
                  )}
                </Form.Group>
              </Row>
              <div className="py-4">
                <button
                  type="submit"
                  disabled={Object.values(errors).some((value) => value !== "")}
                  className={
                    Object.values(errors).some((value) => value !== "")
                      ? "text-white px-4 py-2 rounded-md bg-gray-400 cursor-not-allowed"
                      : "text-white px-4 py-2 rounded-md bg-fful hover:bg-iconhover"
                  }
                >
                  <FaRegSave />
                </button>
                <button
                  type="button"
                  className="text-white px-4 py-2 mx-2 rounded-md bg-fful hover:bg-iconhover"
                  onClick={closeModal}
                >
                  <ImCancelCircle />
                </button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default CreateOrientadorFarmacia;
