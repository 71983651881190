import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { FaEdit, FaRegSave } from "react-icons/fa";
import { ImCancelCircle } from "react-icons/im";
import Modal from "react-modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Select from "react-select";

Modal.setAppElement("#root");

function EditOrientadorHospital({ orientadorData, hospitais }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editedOrientadorHospital, setEditedOrientadorHospital] =
    useState(orientadorData);
  const [hospitalOptions, setHospitalOptions] = useState(hospitais);

  const apiOrientadorHospital = process.env.REACT_APP_API_ORIENTADOR_HOSPITAL;

  const [errors, setErrors] = useState({
    nome: "",
    email: "",
    emailAlternativo: "",
    telefone: "",
    telemovel: "",
    obs: "",
    numOrdem: "",
    idHospital: "",
    habilitacoes: "",
    idade: "",
    numAnosExperiencia: "",
    sexo: "",
  });

  const fetchHospitais = async () => {
    const newHospitais = hospitais.map((hospital) => ({
      value: hospital.idHospital,
      label: hospital.nome,
    }));
    setHospitalOptions(newHospitais);
  };

  const handleOpenComponent = () => {
    setModalIsOpen(true);
    fetchHospitais();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedOrientadorHospital((prevEditedOrientador) => ({
      ...prevEditedOrientador,
      [name]: value,
    }));
    validateField(name, value);
  };

  const validateField = (fieldName, value) => {
    let error = "";
    switch (fieldName) {
      case "nome":
        if (!value) {
          error = "Campo obrigatório";
        }
        break;
      case "email":
        if (!value) {
          error = "Campo obrigatório";
        }
        break;
      case "numOrdem":
        if (!value) {
          error = "Campo obrigatório";
        }
        break;
      case "idHospital":
        if (!value) {
          error = "Campo obrigatório";
        }
        break;
      case "habilitacoes":
        if (!value) {
          error = "Campo obrigatório";
        }
        break;
      case "sexo":
        if (!value) {
          error = "Campo obrigatório";
        }
        break;
      case "idade":
        if (!value) {
          error = "Campo obrigatório";
        }
        break;
      case "numAnosExperiencia":
        if (!value) {
          error = "Campo obrigatório";
        }
        break;

      default:
        break;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: error,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    fetch(
      `${apiOrientadorHospital}/${editedOrientadorHospital.idOrientadorH}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(editedOrientadorHospital),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setModalIsOpen(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleHospitalChange = (selectedOption) => {
    setEditedOrientadorHospital((prev) => ({
      ...prev,
      idHospital: selectedOption.value,
    }));
    validateField("idHospital", selectedOption.value);
  };

  return (
    <>
      <button
        className="text-purple-700 mx-1"
        title="Editar"
        onClick={() => handleOpenComponent()}
      >
        <FaEdit />
      </button>
      <Modal isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)}>
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg p-6 md:p-8">
            <h2 className="text-lg font-semibold mb-4 bg-fful px-4 py-2 rounded-lg text-white">
              Editar Orientador
            </h2>
            <Form noValidate onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="nome">
                  <Form.Label>Nome</Form.Label>
                  <Form.Control
                    type="text"
                    name="nome"
                    onChange={handleChange}
                    value={editedOrientadorHospital.nome}
                    isInvalid={!!errors.nome}
                    isValid={!errors.nome}
                  />
                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.nome}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    name="email"
                    onChange={handleChange}
                    value={editedOrientadorHospital.email}
                    isInvalid={!!errors.email}
                    isValid={!errors.email}
                  />
                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="telemovel">
                  <Form.Label>Telemóvel</Form.Label>
                  <Form.Control
                    type="number"
                    name="telemovel"
                    onChange={handleChange}
                    value={editedOrientadorHospital.telemovel}
                    isInvalid={!!errors.telemovel}
                    isValid={!errors.telemovel}
                  />

                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.telemovel}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="numOrdem">
                  <Form.Label>Nº de Ordem</Form.Label>
                  <Form.Control
                    type="number"
                    name="numOrdem"
                    onChange={handleChange}
                    value={editedOrientadorHospital.numOrdem}
                    isInvalid={!!errors.numOrdem}
                    isValid={!errors.numOrdem}
                  />

                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.numOrdem}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="4" controlId="habilitacoes">
                  <Form.Label>Habilitações</Form.Label>
                  <Form.Select
                    name="habilitacoes"
                    value={editedOrientadorHospital.habilitacoes}
                    onChange={handleChange}
                    isInvalid={!!errors.habilitacoes}
                    isValid={!errors.habilitacoes}
                  >
                    <option value="">Selecione as habilitações</option>
                    <option value="LICENCIATURA">Licenciatura</option>
                    <option value="MICF">MICF</option>
                    <option value="MESTRADO">Mestrado</option>
                    <option value="DOUTORAMENTO">Doutoramento</option>
                    <option value="POS_GRADUACAO">Pós-Graduação</option>
                    <option value="ESPECIALISTA_FARMACIA">
                      Especialista em Farmácia Comunitária
                    </option>
                  </Form.Select>
                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.habilitacoes}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="3" controlId="sexo">
                  <Form.Label>Sexo</Form.Label>
                  <Form.Select
                    name="sexo"
                    value={editedOrientadorHospital.sexo}
                    onChange={handleChange}
                    isInvalid={!!errors.sexo}
                    isValid={!errors.sexo}
                  >
                    <option value="">Selecione o sexo</option>
                    <option value="FEMININO">Feminino</option>
                    <option value="MASCULINO">Masculino</option>
                  </Form.Select>
                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.sexo}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="2" controlId="idade">
                  <Form.Label>Idade</Form.Label>
                  <Form.Control
                    type="number"
                    name="idade"
                    value={editedOrientadorHospital.idade}
                    onChange={handleChange}
                    isInvalid={!!errors.idade}
                    isValid={!errors.idade}
                  />
                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.idade}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="3" controlId="numAnosExperiencia">
                  <Form.Label>Nº de Anos de Experiência</Form.Label>

                  <Form.Control
                    type="number"
                    name="numAnosExperiencia"
                    value={editedOrientadorHospital.numAnosExperiencia}
                    onChange={handleChange}
                    isInvalid={!!errors.numAnosExperiencia}
                    isValid={!errors.numAnosExperiencia}
                  />
                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.numAnosExperiencia}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="obs">
                  <Form.Label>Observações</Form.Label>
                  <Form.Control
                    type="text"
                    name="obs"
                    onChange={handleChange}
                    value={editedOrientadorHospital.obs}
                    isInvalid={!!errors.obs}
                    isValid={!errors.obs}
                  />
                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.obs}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="idHospital">
                  <Form.Label>Hospital</Form.Label>
                  <Select
                    options={hospitalOptions}
                    onChange={handleHospitalChange}
                    value={hospitalOptions.find(
                      (option) =>
                        option.value === editedOrientadorHospital.idHospital
                    )}
                    isInvalid={!!errors.idHospital}
                    placeholder="Selecione ou pesquise um hospital"
                  />
                  {errors.idHospital && (
                    <div className="invalid-feedback d-block">
                      {errors.idHospital}
                    </div>
                  )}
                </Form.Group>
              </Row>
              <div className="py-4">
                <button
                  type="submit"
                  disabled={Object.values(errors).some((value) => value !== "")}
                  className={
                    Object.values(errors).some((value) => value !== "")
                      ? "text-white px-4 py-2 rounded-md bg-gray-400 cursor-not-allowed"
                      : "text-white px-4 py-2 rounded-md bg-fful hover:bg-iconhover"
                  }
                >
                  <FaRegSave />
                </button>
                <button
                  type="button"
                  className="text-white px-4 py-2 mx-2 rounded-md bg-fful hover:bg-iconhover"
                  onClick={() => setModalIsOpen(false)}
                >
                  <ImCancelCircle />
                </button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default EditOrientadorHospital;
