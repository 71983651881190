import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { FaRegSave } from "react-icons/fa";
import { ImCancelCircle } from "react-icons/im";
import Modal from "react-modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { AiOutlineUserAdd } from "react-icons/ai";
import Select from "react-select";

Modal.setAppElement("#root");

function CreateVagaHospital({ anoLetivo, hospitais, alunos, blocosH }) {
  const INITIAL_STATE = {
    anoLetivo: anoLetivo.anoLetivo,
    disponivel: 1,
    idHospital: "",
    idAluno: "",
    idBlocoH: "",
    obs: "",
  };
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [newVagaData, setNewVagaData] = useState(INITIAL_STATE);

  const handleReset = () => {
    setNewVagaData(INITIAL_STATE);
  };

  const apiVagasHospitais = process.env.REACT_APP_API_VAGASHOSPITAIS;

  const [errors, setErrors] = useState({
    idHospital: "",
    idBlocoH: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewVagaData((prevNewVagaData) => ({
      ...prevNewVagaData,
      [name]: value,
    }));
    validateField(name, value);
  };

  const validateField = (fieldName, value) => {
    let error = "";
    switch (fieldName) {
      case "idHospital":
        if (!value) {
          error = "Campo obrigatório";
        }
        break;
      case "idBlocoH":
        if (!value) {
          error = "Campo obrigatório";
        }
        break;
      default:
        break;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: error,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(newVagaData);
    fetch(`${apiVagasHospitais}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newVagaData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setModalIsOpen(false);
        handleReset();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    handleReset();
    setModalIsOpen(false);
  };

  useEffect(() => {
    if (modalIsOpen) {
      for (const key in newVagaData) {
        validateField(key, newVagaData[key]);
      }
    }
  }, [modalIsOpen]);

  return (
    <>
      <button
        className="text-purple-700 mx-1 flex items-center"
        title="Adicionar"
        onClick={openModal}
      >
        <span className="mr-2">Adicionar Vaga de Hospital</span>
        <AiOutlineUserAdd />
      </button>
      <Modal isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)}>
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg p-6 md:p-8">
            <h2 className="text-lg font-semibold mb-4 bg-fful px-4 py-2 rounded-lg text-white">
              Adicionar Vaga de Hospital
            </h2>
            <Form noValidate onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="disponivel">
                  <Form.Label>Disponibilidade</Form.Label>
                  <Form.Select
                    name="disponivel"
                    onChange={handleChange}
                    value={newVagaData.disponivel}
                  >
                    <option value="true">Disponível</option>
                    <option value="false">Indisponível</option>
                  </Form.Select>
                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.disponivel}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="idHospital">
                  <Form.Label>Hospital</Form.Label>
                  <Select
                    isSearchable
                    isClearable
                    name="idHospital"
                    options={hospitais.map((hospital) => ({
                      value: hospital.idHospital,
                      label: `ID: ${hospital.idHospital} | Nome: ${hospital.nome} | Morada: ${hospital.morada}`,
                    }))}
                    onChange={(option) =>
                      handleChange({
                        target: {
                          name: "idHospital",
                          value: option ? option.value : "",
                        },
                      })
                    }
                    styles={{
                      control: (base, state) => ({
                        ...base,
                        borderColor: errors.idHospital
                          ? "red"
                          : state.isFocused || state.isSelected
                          ? "green"
                          : base.borderColor,
                        boxShadow: errors.idHospital
                          ? "0 0 0 1px red"
                          : state.isFocused
                          ? "0 0 0 1px green"
                          : base.boxShadow,
                      }),
                    }}
                    isValid={!errors.idHospital}
                    isInvalid={!!errors.idHospital}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.idHospital}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="idBlocoH">
                  <Form.Label>Bloco Hospital</Form.Label>
                  <Select
                    isSearchable
                    isClearable
                    name="idBlocoH"
                    options={blocosH.map((bloco) => ({
                      value: bloco.idBlocoH,
                      label: `ID: ${bloco.idBlocoH} | Bloco: ${bloco.bloco} | Início: ${bloco.dataInicio} | Fim: ${bloco.dataFim}`,
                    }))}
                    onChange={(option) =>
                      handleChange({
                        target: {
                          name: "idBlocoH",
                          value: option ? option.value : "",
                        },
                      })
                    }
                    styles={{
                      control: (base, state) => ({
                        ...base,
                        borderColor: errors.idBlocoH
                          ? "red"
                          : state.isFocused || state.isSelected
                          ? "green"
                          : base.borderColor,
                        boxShadow: errors.idBlocoH
                          ? "0 0 0 1px red"
                          : state.isFocused
                          ? "0 0 0 1px green"
                          : base.boxShadow,
                      }),
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.idBlocoH}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="idAluno">
                  <Form.Label>Aluno</Form.Label>
                  <Select
                    isSearchable
                    isClearable
                    name="idAluno"
                    options={alunos.map((aluno) => ({
                      value: aluno.idAluno,
                      label: `Nº Aluno: ${aluno.numAluno} | Nome: ${aluno.nome}`,
                    }))}
                    onChange={(option) =>
                      handleChange({
                        target: {
                          name: "idAluno",
                          value: option ? option.value : "",
                        },
                      })
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.idAluno}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="obs">
                  <Form.Label>Observações</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="obs"
                    onChange={handleChange}
                    value={newVagaData.obs}
                    isInvalid={!!errors.obs}
                    isValid={!errors.obs}
                  />

                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.obs}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <div className="py-4">
                <button
                  type="submit"
                  disabled={Object.values(errors).some((value) => value !== "")}
                  className={
                    Object.values(errors).some((value) => value !== "")
                      ? "text-white px-4 py-2 rounded-md bg-gray-400 cursor-not-allowed"
                      : "text-white px-4 py-2 rounded-md bg-fful hover:bg-iconhover"
                  }
                >
                  <FaRegSave />
                </button>
                <button
                  type="button"
                  className="text-white px-4 py-2 mx-2 rounded-md bg-fful hover:bg-iconhover"
                  onClick={closeModal}
                >
                  <ImCancelCircle />
                </button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default CreateVagaHospital;
