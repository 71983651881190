import React, { useState, useEffect } from "react";
import CreateTrabalho from "../Create/CreateTrabalho";
import EditTrabalho from "../Edit/EditTrabalho";
import { FaTrash } from "react-icons/fa";
import { Modal, Button } from "react-bootstrap";
import ScrollToTopButton from "../Utils/ScrollToTopButton";

function GerirTrabalhos(anoLetivo) {
  const [trabalhos, setTrabalhos] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTipoTrabalho, setSelectedTipoTrabalho] = useState("");
  const [selectedTrabalho, setSelectedTrabalho] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const apiTrabalhos = process.env.REACT_APP_API_TRABALHOS;
  const apiAlunos = process.env.REACT_APP_API_ALUNOS;

  useEffect(() => {
    fetch(`${apiTrabalhos}/anoLetivo/${anoLetivo.anoLetivo}`)
      .then((response) => response.json())
      .then((data) => setTrabalhos(data));
  }, [apiTrabalhos]);

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  function filteredTrabalhos() {
    const searchTermLowerCase = removeAccents(searchTerm.toLowerCase());
    const filtered = trabalhos.filter((trabalho) => {
      const temaLowerCase = removeAccents(trabalho.tema.toLowerCase());
      const orientadorLowerCase = removeAccents(
        trabalho.orientador.toLowerCase()
      );
      const matchesSearchTerm =
        trabalho.idTrabalho.toString().includes(searchTerm) ||
        temaLowerCase.includes(searchTermLowerCase) ||
        orientadorLowerCase.includes(searchTermLowerCase);
      return trabalho.visivel && matchesSearchTerm;
    });
    return Array.isArray(filtered) ? filtered : [filtered];
  }

  const filteredTrabalhosArray = Array.isArray(filteredTrabalhos())
    ? filteredTrabalhos()
    : [];

  // Function to remove accents from a string
  function removeAccents(str) {
    const accents = "ÀÁÂÃÄÅàáâãäåÒÓÔÕÖØòóôõöøÈÉÊËèéêëÇçÌÍÎÏìíîïÙÚÛÜùúûüÿÑñ";
    const accentsOut = "AAAAAAaaaaaaOOOOOOooooooEEEEeeeeCcIIIIiiiiUUUUuuuuyNn";
    return str
      .split("")
      .map((letter) => {
        const accentIndex = accents.indexOf(letter);
        return accentIndex !== -1 ? accentsOut[accentIndex] : letter;
      })
      .join("");
  }

  function displayTipoTrabalho(tipoTrabalho) {
    switch (tipoTrabalho) {
      case "MONOGRAFIA":
        return "Monografia";
      case "TRABALHOCAMPO":
        return "Trabalho de campo";
      case "ERASMUS":
        return "Erasmus";
      case "EXCECAO":
        return "Exceção";
      default:
        return tipoTrabalho;
    }
  }

  const handleDelete = (trabalho) => {
    setSelectedTrabalho(trabalho);
    setShowConfirmationModal(true);
  };

  // const handleConfirmDelete = () => {
  //   fetch(`${apiTrabalhos}/${selectedTrabalho.idTrabalho}`, {
  //     method: "PUT",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({ ...selectedTrabalho, visivel: false }),
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setTrabalhos((prevTrabalhos) =>
  //         prevTrabalhos.filter(
  //           (trabalho) => trabalho.idTrabalho !== selectedTrabalho.idTrabalho
  //         )
  //       );
  //       setSelectedTrabalho(null);
  //       setShowConfirmationModal(false);
  //     })
  //     .catch((error) => {
  //       console.error("Error deleting trabalho: ", error);
  //       // Handle the error condition
  //     });
  // };

  const handleConfirmDelete = () => {
    fetch(`${apiAlunos}/trabalho/${selectedTrabalho.idTrabalho}`)
      .then((response) => response.json())
      .then((alunos) => {
        const updateAlunosPromises = alunos.map((aluno) =>
          fetch(`${apiAlunos}/${aluno.idAluno}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ ...aluno, idTrabalho: null }),
          })
        );

        Promise.all(updateAlunosPromises)
          .then(() => {
            fetch(`${apiTrabalhos}/${selectedTrabalho.idTrabalho}`, {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ ...selectedTrabalho, visivel: false }),
            })
              .then((response) => response.json())
              .then(() => {
                setTrabalhos((prevTrabalhos) =>
                  prevTrabalhos.filter(
                    (trabalho) =>
                      trabalho.idTrabalho !== selectedTrabalho.idTrabalho
                  )
                );
                setSelectedTrabalho(null);
                setShowConfirmationModal(false);
              })
              .catch((error) => {
                console.error("Error updating trabalho visibility: ", error);
                // Handle the error condition
              });
          })
          .catch((error) => {
            console.error("Error updating alunos: ", error);
            // Handle the error condition
          });
      })
      .catch((error) => {
        console.error("Error fetching alunos: ", error);
        // Handle the error condition
      });
  };

  const handleCancelDelete = () => {
    setSelectedTrabalho(null); // Limpar o trabalho selecionado
    setShowConfirmationModal(false); // Fechar a janela de confirmação
  };

  return (
    <div className="flex flex-col items-stretch min-h-full">
      <main className="flex-grow p-4">
        <div>
          <input
            type="text"
            placeholder="Pesquisar"
            value={searchTerm}
            onChange={handleSearchTermChange}
            className="border border-gray-400 rounded px-2 py-1 mb-3"
          />
        </div>
        <select
          value={selectedTipoTrabalho}
          onChange={(e) => setSelectedTipoTrabalho(e.target.value)}
          className="border border-gray-400 rounded px-2 py-1 mb-3"
        >
          <option value="">Ver tudo</option>
          <option value="MONOGRAFIA">Monografia</option>
          <option value="TRABALHOCAMPO">Trabalho de campo</option>
          <option value="ERASMUS">Erasmus</option>
          <option value="EXCECAO">Exceção</option>
        </select>
        <div className="flex items-center mb-4">
          <CreateTrabalho anoLetivo={anoLetivo.anoLetivo} />
        </div>
        <div>
          <Modal show={showConfirmationModal} centered>
            <Modal.Body>
              Tem a certeza que pretende eliminar o trabalho?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={handleConfirmDelete}>
                Sim
              </Button>
              <Button variant="secondary" onClick={handleCancelDelete}>
                Não
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        <table className="table-auto w-full" id="trabalhos">
          <thead>
            <tr>
              <th className="px-4 py-2">ID</th>
              <th className="px-4 py-2">Tema</th>
              <th className="px-4 py-2">Orientador</th>
              <th className="px-4 py-2">Nº de Vagas</th>
              <th className="px-4 py-2">Tipo</th>
              <th className="px-4 py-2">Ações</th>
            </tr>
          </thead>
          <tbody>
            {filteredTrabalhosArray
              .filter(
                (item) =>
                  selectedTipoTrabalho === "" ||
                  item.tipoTrabalho === selectedTipoTrabalho
              )
              .map((trabalho) => (
                <tr key={trabalho.idTrabalho}>
                  <td className="border px-4 py-2">{trabalho.idTrabalho}</td>
                  <td className="border px-4 py-2">{trabalho.tema}</td>
                  <td className="border px-4 py-2">{trabalho.orientador}</td>
                  <td
                    className={`border px-4 py-2 ${
                      trabalho.nVagas > 0 ? "bg-green-200" : "bg-red-200"
                    }`}
                  >
                    {trabalho.nVagas}
                  </td>
                  <td className="border px-4 py-2">
                    {displayTipoTrabalho(trabalho.tipoTrabalho)}
                  </td>
                  <td className="border px-4 py-2">
                    <div>
                      <EditTrabalho trabalhoData={trabalho} />
                      <button
                        className="text-red-700 mx-1"
                        title="Excluir"
                        onClick={() => handleDelete(trabalho)}
                      >
                        <FaTrash />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <ScrollToTopButton targetId="trabalhos" />
      </main>
    </div>
  );
}

export default GerirTrabalhos;
