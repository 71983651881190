import React, { useState, useEffect } from "react";
import { useKeycloak } from "@react-keycloak/web";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { FaFileExcel, FaFilePdf } from "react-icons/fa";
import ScrollToTopButton from "../Utils/ScrollToTopButton";

function ShowTrabalhos({
  anoLetivo,
  editedAlunoData,
  setEditedAlunoData,
  onChildUpdate,
  onChoiceMade,
}) {
  const [trabalhos, setTrabalhos] = useState([]);
  const [alunos, setAlunos] = useState([]);
  const [aluno, setAluno] = useState([]);
  const [trabalho, setTrabalho] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTipoTrabalho, setSelectedTipoTrabalho] = useState("");
  const [tableData, setTableData] = useState([]);
  const [showExportar, setShowExportar] = useState(false);
  const [reloadData, setReloadData] = useState(0);
  const [showDisponiveis, setShowDisponiveis] = useState(true);
  const [showIndisponiveis, setShowIndisponiveis] = useState(true);

  const apiTrabalhos = process.env.REACT_APP_API_TRABALHOS;
  const apiAlunos = process.env.REACT_APP_API_ALUNOS;

  useEffect(() => {
    const fetchData = () => {
      const year = anoLetivo || getCurrentAcademicYear();
      Promise.all([
        fetch(`${apiTrabalhos}/anoLetivo/${year}`),
        fetch(`${apiAlunos}/anoLetivo/${year}`),
      ])
        .then((responses) => Promise.all(responses.map((res) => res.json())))
        .then(([trabalhosData, alunosData]) => {
          setTrabalhos(trabalhosData);
          setAlunos(alunosData);
        });
    };

    // Fetch the data immediately
    fetchData();

    // Then fetch the data every 2 minutes
    const intervalId = setInterval(fetchData, 2 * 60 * 1000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [apiTrabalhos, reloadData]);

  function getCurrentAcademicYear() {
    const today = new Date();
    const currentYear = today.getFullYear();
    const academicYearStartMonth = 8;
    if (today.getMonth() >= academicYearStartMonth) {
      return `${currentYear}-${currentYear + 1}`;
    } else {
      return `${currentYear - 1}-${currentYear}`;
    }
  }

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    const filteredTrabalhos = trabalhos
      .filter((trabalho) => {
        return (
          selectedTipoTrabalho === "" ||
          trabalho.tipoTrabalho === selectedTipoTrabalho
        );
      })
      .map((trabalho) => {
        const alunosMatching =
          Array.isArray(alunos) &&
          alunos.filter((a) => a.idTrabalho === trabalho.idTrabalho);

        const numAlunos = alunosMatching.map((a) => a.numAluno).join(" | ");
        const nomes = alunosMatching.map((a) => a.nome).join(" | ");
        const emails = alunosMatching.map((a) => a.email).join(" | ");

        return { ...trabalho, numAlunos, nomes, emails };
      })
      .filter((item) => {
        const searchTermLowerCase = removeAccents(searchTerm.toLowerCase());
        const temaLowerCase = removeAccents(item.tema.toLowerCase());
        const orientadorLowerCase = removeAccents(
          item.orientador.toLowerCase()
        );
        const alunoNumLowerCase = trabalho.numAlunos || "";
        const alunoNomeLowerCase = trabalho.nomes
          ? removeAccents(trabalho.nomes.toLowerCase())
          : "";
        const alunoEmailLowerCase = trabalho.emails
          ? removeAccents(trabalho.emails.toLowerCase())
          : "";
        const disponivel = item.nVagas > 0;
        const matchesSearchTerm =
          item.idTrabalho.toString().includes(searchTerm) ||
          temaLowerCase.includes(searchTermLowerCase) ||
          alunoNumLowerCase.toString().includes(searchTerm) ||
          alunoNomeLowerCase.includes(searchTermLowerCase) ||
          alunoEmailLowerCase.includes(searchTermLowerCase) ||
          orientadorLowerCase.includes(searchTermLowerCase);
        return (
          ((disponivel && showDisponiveis) ||
            (!disponivel && showIndisponiveis)) &&
          matchesSearchTerm &&
          item.visivel
        );
      })
      .map((item) => {
        return {
          Id: item.idTrabalho,
          Tema: item.tema,
          Orientador: item.orientador,
          Coorientador1: item.coorientador1,
          Origem: item.origem,
          NumeroVagas: item.nVagas,
          NumeroVagasInicial: item.nVagasInicial,
          NumeroAluno: item.numAlunos,
          NomeAluno: item.nomes,
          EmailAluno: item.emails,
          TipoTrabalho: displayTipoTrabalho(item.tipoTrabalho),
        };
      });

    setTableData(filteredTrabalhos);
  }, [
    trabalhos,
    searchTerm,
    showDisponiveis,
    showIndisponiveis,
    selectedTipoTrabalho,
    alunos,
  ]);

  function filteredTrabalhos() {
    const searchTermLowerCase = removeAccents(searchTerm.toLowerCase());
    const filtered = trabalhos
      .filter((trabalho) => {
        return (
          selectedTipoTrabalho === "" ||
          trabalho.tipoTrabalho === selectedTipoTrabalho
        );
      })
      .map((trabalho) => {
        const alunosMatching =
          Array.isArray(alunos) &&
          alunos.filter((a) => a.idTrabalho === trabalho.idTrabalho);

        const numAlunos = alunosMatching.map((a) => a.numAluno).join(" | ");
        const nomes = alunosMatching.map((a) => a.nome).join(" | ");

        return { ...trabalho, numAlunos, nomes };
      })
      .filter((trabalho) => {
        const temaLowerCase = removeAccents(trabalho.tema.toLowerCase());
        const orientadorLowerCase = removeAccents(
          trabalho.orientador.toLowerCase()
        );
        const alunoNumLowerCase = trabalho.numAlunos || "";
        const alunoNomeLowerCase = trabalho.nomes
          ? removeAccents(trabalho.nomes.toLowerCase())
          : "";
        const disponivel = trabalho.nVagas > 0;
        const matchesSearchTerm =
          trabalho.idTrabalho.toString().includes(searchTerm) ||
          temaLowerCase.includes(searchTermLowerCase) ||
          alunoNumLowerCase.toString().includes(searchTerm) ||
          alunoNomeLowerCase.includes(searchTermLowerCase) ||
          orientadorLowerCase.includes(searchTermLowerCase);
        return (
          ((disponivel && showDisponiveis) ||
            (!disponivel && showIndisponiveis)) &&
          matchesSearchTerm &&
          trabalho.visivel
        );
      });
    return Array.isArray(filtered) ? filtered : [filtered];
  }

  const filteredTrabalhosArray = Array.isArray(filteredTrabalhos())
    ? filteredTrabalhos()
    : [];

  // Function to remove accents from a string
  function removeAccents(str) {
    const accents = "ÀÁÂÃÄÅàáâãäåÒÓÔÕÖØòóôõöøÈÉÊËèéêëÇçÌÍÎÏìíîïÙÚÛÜùúûüÿÑñ";
    const accentsOut = "AAAAAAaaaaaaOOOOOOooooooEEEEeeeeCcIIIIiiiiUUUUuuuuyNn";
    return str
      .split("")
      .map((letter) => {
        const accentIndex = accents.indexOf(letter);
        return accentIndex !== -1 ? accentsOut[accentIndex] : letter;
      })
      .join("");
  }

  const exportToExcel = () => {
    const headerData = [
      [
        "Ano Letivo " +
          (anoLetivo || getCurrentAcademicYear()) +
          " - Trabalhos",
      ],
      [new Date().toLocaleDateString()],
    ];
    const columnNames = [Object.keys(tableData[0])];
    const data = tableData.map(Object.values);
    const combinedData = [...headerData, ...columnNames, ...data];

    const ws = XLSX.utils.aoa_to_sheet(combinedData);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataExcel = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    saveAs(dataExcel, "Trabalhos.xlsx");
  };

  function getBase64Image(img) {
    let canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    let ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, img.width, img.height);
    let dataURL = canvas.toDataURL("image/png");
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }

  const exportToPDF = () => {
    // Load the image
    let img = new Image();
    img.src = "/images/fful-header-logo.png";
    img.onload = function () {
      let dataURI = getBase64Image(img);
      const doc = new jsPDF("l");

      // Add a colored header
      doc.setFillColor(84, 59, 131); // RGB color for #543b83
      doc.rect(0, 0, doc.internal.pageSize.getWidth(), 30, "F");
      // Add header
      doc.setFontSize(14);
      doc.setTextColor(255, 255, 255);
      let str =
        "Ano Letivo " +
        (anoLetivo || getCurrentAcademicYear()) +
        " - Trabalhos";
      let txtWidth =
        (doc.getStringUnitWidth(str) * doc.internal.getFontSize()) /
        doc.internal.scaleFactor;
      let center = (doc.internal.pageSize.width - txtWidth) / 2;
      doc.text(str, center, 20);
      doc.setTextColor(0, 0, 0);

      // Add the image to the left of the title
      doc.addImage(dataURI, "PNG", 5, 0, 30, 30); // Adjust position and size as needed

      let totalVagas = tableData.reduce((total, row) => {
        let numeroVagas = parseFloat(row.NumeroVagasInicial);
        return total + (isNaN(numeroVagas) ? 0 : numeroVagas);
      }, 0);

      const tableColumns = [
        "Id",
        "Tema",
        "Orientador/Co-Tutor Erasmus",
        "Coorientador",
        "Origem",
        "Nº Vagas",
        "Nº Aluno",
        "Aluno(s)",
        "Email(s)",
        "Tipo Trabalho",
      ];
      const tableRows = tableData.map((item) => [
        item.Id,
        item.Tema,
        item.Orientador,
        item.Coorientador1,
        item.Origem,
        item.NumeroVagas,
        item.NumeroAluno,
        item.NomeAluno,
        item.EmailAluno,
        item.TipoTrabalho,
      ]);

      let totalPagesExp = "{total_pages_count_string}";
      doc.autoTable({
        head: [tableColumns],
        body: tableRows,
        startY: 31,
        styles: { cellWidth: "wrap", fontSize: 8 },
        headStyles: { fillColor: [84, 59, 131] },
        columnStyles: {
          0: { cellWidth: 10 },
          1: { cellWidth: 50 },
          2: { cellWidth: 25 },
          3: { cellWidth: 26 },
          4: { cellWidth: 26 },
          5: { cellWidth: 14 },
          6: { cellWidth: 18 },
          7: { cellWidth: 40 },
          8: { cellWidth: 40 },
          9: { cellWidth: 25 },
        },
        didDrawPage: (data) => {
          doc.setFontSize(6);

          // Reset font size for body
          doc.setFontSize(8);

          // Add date to the left
          doc.text(
            new Date().toLocaleDateString(),
            data.settings.margin.left,
            doc.internal.pageSize.height - 10
          );

          doc.text(
            "Total de Vagas: " + totalVagas,
            doc.internal.pageSize.width / 2,
            doc.internal.pageSize.height - 10,
            { align: "center" }
          );

          // Add page number to the right
          if (typeof doc.putTotalPages === "function") {
            doc.text(
              "Page " +
                doc.internal.getNumberOfPages() +
                " of " +
                totalPagesExp,
              doc.internal.pageSize.width - data.settings.margin.right,
              doc.internal.pageSize.height - 10,
              { align: "right" }
            );
          }
        },
      });

      if (typeof doc.putTotalPages === "function") {
        doc.putTotalPages(totalPagesExp);
      }

      doc.save("Trabalhos.pdf");
    };
  };

  function displayTipoTrabalho(tipoTrabalho) {
    switch (tipoTrabalho) {
      case "MONOGRAFIA":
        return "Monografia";
      case "TRABALHOCAMPO":
        return "Trabalho de campo";
      case "ERASMUS":
        return "Erasmus";
      case "EXCECAO":
        return "Exceção";
      default:
        return tipoTrabalho;
    }
  }

  const pickTrabalho = (item) => {
    handlePickTrabalho(item);
  };

  const handlePickTrabalho = (item) => {
    const aluno = editedAlunoData;
    const trabalho = item;
    if (aluno && trabalho) {
      setAluno(aluno);
      setTrabalho(trabalho);

      let updatedIdTrabalho = aluno.idTrabalho;
      let updatedNumVagas = item.nVagas;

      if (
        (aluno.idTrabalho === null || aluno.idTrabalho === "") &&
        item.nVagas > 0
      ) {
        updatedIdTrabalho = item.idTrabalho;
        updatedNumVagas = item.nVagas - 1;
      } else if (aluno.idTrabalho === item.idTrabalho) {
        updatedIdTrabalho = null;
        updatedNumVagas = item.nVagas + 1;
      } else if (item.nVagas === 0) {
        alert("Este trabalho já não possui vagas disponíveis");
        return;
      } else if (aluno.idTrabalho !== item.idTrabalho) {
        alert("Este Aluno já tem um trabalho atribuído");
        return;
      }
      const updatedAluno = {
        ...aluno,
        idTrabalho: updatedIdTrabalho,
      };
      const updatedTrabalho = {
        ...trabalho,
        nVagas: updatedNumVagas,
      };
      handleEditAlunoTrabalho(updatedAluno, updatedTrabalho);
    }
  };

  const handleEditAlunoTrabalho = (aluno, trabalho) => {
    Promise.all([
      fetch(`${apiAlunos}/${aluno.idAluno}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(aluno),
      }),
      fetch(`${apiTrabalhos}/${trabalho.idTrabalho}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(trabalho),
      }),
    ])
      .then(() => {
        setReloadData(reloadData + 1);
        setEditedAlunoData(aluno);
        onChoiceMade(aluno.idTrabalho ? trabalho : null);
        onChildUpdate((prevState) => prevState + 1);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleShowDisponiveisChange = (event) => {
    setShowDisponiveis(event.target.checked);
  };

  const handleShowIndisponiveisChange = (event) => {
    setShowIndisponiveis(event.target.checked);
  };

  const { keycloak } = useKeycloak();

  const showNucleoEstagios = keycloak.hasRealmRole("00_Nucleo_Estagios");

  return (
    <div>
      <div className="flex-grow p-4">
        <h1 className="text-2xl font-bold mb-4">Trabalhos</h1>
        <div className="flex justify-between mb-4">
          <div>
            <div>
              <input
                type="text"
                placeholder="Pesquisar"
                value={searchTerm}
                onChange={handleSearchTermChange}
                className="border border-gray-400 rounded px-2 py-1 mb-3"
              />
            </div>
            <div>
              <select
                value={selectedTipoTrabalho}
                onChange={(e) => setSelectedTipoTrabalho(e.target.value)}
                className="border border-gray-400 rounded px-2 py-1 mb-3"
              >
                <option value="">Ver tudo</option>
                <option value="MONOGRAFIA">Monografia</option>
                <option value="TRABALHOCAMPO">Trabalho de campo</option>
                <option value="ERASMUS">Erasmus</option>
                <option value="EXCECAO">Exceção</option>
              </select>
            </div>
            <div className="my-2">
              <label className="mr-6">
                <input
                  type="checkbox"
                  checked={showDisponiveis}
                  onChange={handleShowDisponiveisChange}
                  className="mr-1"
                />
                Disponíveis
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={showIndisponiveis}
                  onChange={handleShowIndisponiveisChange}
                  className="mr-1"
                />
                Indisponíveis
              </label>
            </div>
            {showNucleoEstagios && (
              <div>
                <button
                  className="text-fful hover:text-iconhover font-bold rounded"
                  onClick={() => setShowExportar(!showExportar)}
                >
                  {showExportar ? "Fechar Exportar" : "Exportar Trabalhos"}
                </button>
                {showExportar && (
                  <>
                    <div></div>
                    <button
                      className="text-fful hover:text-iconhover py-4 text-6xl"
                      title="Gerar Excel"
                      onClick={exportToExcel}
                    >
                      <FaFileExcel />
                    </button>
                    <button
                      className="text-fful hover:text-iconhover mx-1 py-4 px-4 text-6xl"
                      title="Gerar PDF"
                      onClick={exportToPDF}
                    >
                      <FaFilePdf />
                    </button>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
        <table className="table-auto w-full" id="trabalhos">
          <thead>
            <tr>
              <th className="px-4 py-2">ID do Trabalho</th>
              <th className="px-4 py-2">Tema</th>
              <th className="px-4 py-2">Orientador / Co-Tutor Erasmus</th>
              <th className="px-4 py-2">Co-Orientador</th>
              <th className="px-4 py-2">Nº de Vagas</th>
              <th className="px-4 py-2">Tipo</th>
              {showNucleoEstagios && <th className="px-4 py-2">Nº Aluno</th>}
              {showNucleoEstagios && <th className="px-4 py-2">Nome Aluno</th>}
              {showNucleoEstagios && <th className="px-4 py-2">Observações</th>}
            </tr>
          </thead>
          <tbody>
            {filteredTrabalhosArray.map((item) => {
              return (
                <tr key={item.idTrabalho}>
                  <td className="border px-4 py-2">{item.idTrabalho}</td>
                  {/* <td className="border px-4 py-2">{item.tema}</td> */}
                  <td className="border px-4 py-2">
                    {showNucleoEstagios && (
                      <button
                        onClick={() => pickTrabalho(item)}
                        disabled={!editedAlunoData}
                      >
                        {item.tema}
                      </button>
                    )}
                    {!showNucleoEstagios && item.tema}
                  </td>
                  <td className="border px-4 py-2">{item.orientador}</td>
                  <td className="border px-4 py-2">{item.coorientador1}</td>
                  <td
                    className={`border px-4 py-2 ${
                      item.nVagas > 0 ? "bg-green-200" : "bg-red-200"
                    }`}
                  >
                    {item.nVagas}
                  </td>
                  <td className="border px-4 py-2">
                    {displayTipoTrabalho(item.tipoTrabalho)}
                  </td>
                  {showNucleoEstagios && (
                    <>
                      <td className="border px-4 py-2">{item.numAlunos}</td>
                      <td className="border px-4 py-2">{item.nomes}</td>
                      <td className="border px-4 py-2">{item.obs}</td>
                    </>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
        <ScrollToTopButton targetId="trabalhos" />
      </div>
    </div>
  );
}

export default ShowTrabalhos;
