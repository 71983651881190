import React, { useState, useEffect, useRef } from "react";
import { FaArrowAltCircleUp } from "react-icons/fa";

const ScrollToTopButton = ({ targetId }) => {
  const [showScrollTopButton, setShowScrollTopButton] = useState(false);
  const targetRef = useRef(null);

  useEffect(() => {
    targetRef.current = document.getElementById(targetId);

    const checkScrollTop = () => {
      if (!targetRef.current) return;

      const rect = targetRef.current.getBoundingClientRect();
      if (
        !showScrollTopButton &&
        rect.top < window.innerHeight &&
        rect.bottom > 0
      ) {
        setShowScrollTopButton(true);
      } else if (
        showScrollTopButton &&
        (rect.top >= window.innerHeight || rect.bottom <= 0)
      ) {
        setShowScrollTopButton(false);
      }
    };

    window.addEventListener("scroll", checkScrollTop);
    return () => window.removeEventListener("scroll", checkScrollTop);
  }, [showScrollTopButton, targetId]);

  const scrollTop = () => {
    targetRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    showScrollTopButton && (
      <button
        onClick={scrollTop}
        style={{ position: "fixed", bottom: "20px", right: "20px" }}
      >
        <FaArrowAltCircleUp className="text-fful hover:text-iconhover text-6xl" />
      </button>
    )
  );
};

export default ScrollToTopButton;
