import React from "react";
import { useState } from "react";
import ShowVagasFarmacias from "../Show/ShowVagasFarmacias";
import ShowVagasHospitais from "../Show/ShowVagasHospitais";
import ShowTrabalhos from "../Show/ShowTrabalhos";

const GerirListagens = ({ anoLetivo }) => {
  const [showVagasFarmacias, setShowVagasFarmacias] = useState(false);
  const [showVagasHospitais, setShowVagasHospitais] = useState(false);
  const [showTrabalhos, setTrabalhos] = useState(false);

  return (
    <>
      <div className="px-4 py-2">
        <button
          className="text-fful hover:text-iconhover font-bold py-2 px-4 rounded"
          onClick={() => setShowVagasFarmacias(!showVagasFarmacias)}
        >
          {showVagasFarmacias ? "Fechar Vagas Farmácias" : "Vagas Farmácias"}
        </button>
        {showVagasFarmacias && (
          <ShowVagasFarmacias editedAlunoData={null} anoLetivo={anoLetivo} />
        )}
      </div>
      <div className="px-4 py-2">
        <button
          className="text-fful hover:text-iconhover font-bold py-2 px-4 rounded"
          onClick={() => setShowVagasHospitais(!showVagasHospitais)}
        >
          {showVagasHospitais ? "Fechar Vagas Hospitais" : "Vagas Hospitais"}
        </button>
        {showVagasHospitais && (
          <ShowVagasHospitais editedAlunoData={null} anoLetivo={anoLetivo} />
        )}
      </div>
      <div className="px-4 py-2">
        <button
          className="text-fful hover:text-iconhover font-bold py-2 px-4 rounded"
          onClick={() => setTrabalhos(!showTrabalhos)}
        >
          {showTrabalhos ? "Fechar Trabalhos" : "Trabalhos"}
        </button>
        {showTrabalhos && (
          <ShowTrabalhos editedAlunoData={null} anoLetivo={anoLetivo} />
        )}
      </div>
    </>
  );
};

export default GerirListagens;
