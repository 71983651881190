import Menu from "./Menu";
import GerirAlunos from "./../Services/Gerir/GerirAlunos";
import { useState, useEffect } from "react";

export default function BodyDocente() {
  const [isDateValid, setIsDateValid] = useState(false);
  const [datas, setDatas] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const apiDatas = process.env.REACT_APP_API_DATAS;

  useEffect(() => {
    fetch(`${apiDatas}/1`)
      .then((response) => response.json())
      .then((data) => {
        setDatas(data);

        const currentDate = new Date();
        const startDateDocentes = new Date(data.startDateDocentes);
        const endDateDocentes = new Date(data.endDateDocentes);
        setIsDateValid(
          currentDate >= startDateDocentes && currentDate <= endDateDocentes
        );
        setIsLoading(false);
      });
  }, [apiDatas]);

  if (!isLoading && !isDateValid) {
    return (
      <>
        <Menu />
        <h1 className="flex items-center justify-center text-fful my-16">
          Neste momento a atribuição de notas encontra-se encerrada.
        </h1>
      </>
    );
  }

  return (
    <div>
      <Menu />
      {!isLoading && (
        <>
          <GerirAlunos
            key={datas.anoLetivoDocentes}
            anoLetivo={datas.anoLetivoDocentes}
          />
        </>
      )}
    </div>
  );
}
