import React from "react";
import { useState, useEffect } from "react";
import Menu from "./Menu";
import ShowVagasFarmacias from "../Services/Show/ShowVagasFarmacias";
import ShowVagasHospitais from "../Services/Show/ShowVagasHospitais";
import ShowTrabalhos from "../Services/Show/ShowTrabalhos";

function BodyAluno() {
  const [showVagasFarmacias, setShowVagasFarmacias] = useState(true);
  const [showVagasHospitais, setShowVagasHospitais] = useState(true);
  const [showTrabalhos, setShowTrabalhos] = useState(true);
  const [isDateValid, setIsDateValid] = useState(false);
  const [datas, setDatas] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const apiDatas = process.env.REACT_APP_API_DATAS;

  useEffect(() => {
    fetch(`${apiDatas}/1`)
      .then((response) => response.json())
      .then((data) => {
        setDatas(data);

        const currentDate = new Date();
        const startDateAlunos = new Date(data.startDateAlunos);
        const endDateAlunos = new Date(data.endDateAlunos);
        setIsDateValid(
          currentDate >= startDateAlunos && currentDate <= endDateAlunos
        );
        setIsLoading(false);
      });
  }, [apiDatas]);

  if (!isLoading && !isDateValid) {
    return (
      <>
        <Menu />
        <h1 className="flex items-center justify-center text-fful my-16">
          Neste momento a visualização de vagas está fechada.
        </h1>
      </>
    );
  }

  return (
    <div>
      <Menu />
      {!isLoading && (
        <>
          <div className="px-4 py-2">
            <button
              className="bg-fful hover:bg-iconhover text-white font-bold py-2 px-4 rounded"
              onClick={() => setShowVagasFarmacias(!showVagasFarmacias)}
            >
              {showVagasFarmacias
                ? "Fechar Vagas Farmácias"
                : "Ver Vagas Farmácias"}
            </button>
            {showVagasFarmacias && <ShowVagasFarmacias />}
          </div>
          <div className="px-4 py-2">
            <button
              className="bg-fful hover:bg-iconhover text-white font-bold py-2 px-4 rounded"
              onClick={() => setShowVagasHospitais(!showVagasHospitais)}
            >
              {showVagasHospitais
                ? "Fechar Vagas Hospitais"
                : "Ver Vagas Hospitais"}
            </button>
            {showVagasHospitais && <ShowVagasHospitais />}
          </div>
          <div className="px-4 py-2">
            <button
              className="bg-fful hover:bg-iconhover text-white font-bold py-2 px-4 rounded"
              onClick={() => setShowTrabalhos(!showTrabalhos)}
            >
              {showTrabalhos ? "Fechar Trabalhos" : "Ver Trabalhos"}
            </button>
            {showTrabalhos && <ShowTrabalhos />}
          </div>
        </>
      )}
    </div>
  );
}

export default BodyAluno;
