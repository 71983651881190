export default function BodyImages() {
  return (
    <div>
      <div>
        <img src="/images/fful-assinatura-email.png" alt="Header image" />
      </div>
      <div>
        <img
          src="/images/assinatura-carla-barros.jpg"
          alt="Signature Image Carla Barros"
        />
      </div>
      <div>
        <img
          src="/images/assinatura-antonio-almeida.png"
          alt="Signature Image Antonio Almeida"
        />
      </div>
    </div>
  );
}
