import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Body from "./components/Body/Body";

export default function App() {
  return (
    <div className="flex flex-col min-h-full items-stretch">
      <div className="bg-fful flex text-white m-0 shrink-0 text-xs md:text-base lg:text-lg">
        <Header />
      </div>
      <div className="shrink-0 grow">
        <Body />
      </div>
      <div className="mt-auto shrink-0 text-white min-h-[50px]">
        <Footer />
      </div>
    </div>
  );
}
