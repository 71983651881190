import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Form, Modal } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function BodyFormHospital() {
  const anoLetivo = getCurrentAcademicYear();

  const INITIAL_STATE_CONTATO = {
    anoLetivo: anoLetivo,
    idHospital: "",
    email: "",
    telefone: "",
    telemovel: "",
    nome: "",
    emailAlternativo: "",
    emailEstagios: "",
  };

  const INITIAL_STATE_BLOCO = {
    anoLetivo: anoLetivo,
    idHospital: "",
    idBlocoH: "",
    disponivel: true,
    obs: "",
  };

  const INITIAL_STATE_ORIENTADOR = {
    anoLetivo: anoLetivo,
    idHospital: "",
    nome: "",
    numOrdem: "",
    obs: "",
    sexo: "",
    idade: "",
    numAnosExperiencia: "",
    habilitacoes: "",
  };

  const INITIAL_STATE_HOSPITAL = {
    numTotalFarmaceuticos: "",
    numTotalFarmOriEst: "",
    numCamas: "",
    numTecDiaTer: "",
    sistemaGestaoIntegrada: "",
    participacaoAtiAvaTer: "",
    participacaoDesImplProTer: "",
    gestaoRiscoPrevErros: "",
    gestaoRiscoPrevReacoes: "",
    sisArmazenamentoMed: "",
    dispMedAmb: "",
    formasFarmEst: "",
    formasFarmNaoEst: "",
    citotoxicos: "",
    monTer: "",
    ensaiosCli: "",
    reconTer: "",
    desenProFarm: "",
    gestaoQualEmpExt: "",
    planoPercursoEstagiario: "",
  };

  const [isDateValid, setIsDateValid] = useState(false);
  const [datas, setDatas] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [codigo, setCodigo] = useState("");
  const [isCodigoValid, setIsCodigoValid] = useState(false);
  const [hospitalData, setHospitalData] = useState(INITIAL_STATE_HOSPITAL);
  const [confirmButton, setConfirmButton] = useState(false);
  const [contatoData, setContatoData] = useState(INITIAL_STATE_CONTATO);
  const [blocos, setBlocos] = useState([INITIAL_STATE_BLOCO]);
  const [orientadores, setOrientadores] = useState([INITIAL_STATE_ORIENTADOR]);
  const [blocosH, setBlocosH] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [formAlreadySubmitted, setFormAlreadySubmitted] = useState(false);

  const [errorsContatoData, setErrorsContatoData] = useState({
    email: "",
    emailAlternativo: "",
    emailEstagios: "",
    telefone: "",
    telemovel: "",
    nome: "",
  });
  const [errorsHospitalData, setErrorsHospitalData] = useState({
    numTotalFarmaceuticos: "",
    numTotalFarmOriEst: "",
    numCamas: "",
    numTecDiaTer: "",
    sistemaGestaoIntegrada: "",
    participacaoAtiAvaTer: "",
    participacaoDesImplProTer: "",
    gestaoRiscoPrevErros: "",
    gestaoRiscoPrevReacoes: "",
    sisArmazenamentoMed: "",
    dispMedAmb: "",
    formasFarmEst: "",
    formasFarmNaoEst: "",
    citotoxicos: "",
    monTer: "",
    ensaiosCli: "",
    reconTer: "",
    desenProFarm: "",
    gestaoQualEmpExt: "",
    planoPercursoEstagiario: "",
  });
  const [errorsOrientadorData, setErrorsOrientadorData] = useState({
    0: {
      nome: "Campo obrigatório",
      numOrdem: "Campo obrigatório",
      sexo: "Campo obrigatório",
      idade: "Campo obrigatório",
      numAnosExperiencia: "Campo obrigatório",
      habilitacoes: "Campo obrigatório",
    },
  });
  const [errorsBlocoData, setErrorsBlocoData] = useState({
    0: { idBlocoH: "" },
  });

  const apiDatas = process.env.REACT_APP_API_DATAS;
  const apiHospitais = process.env.REACT_APP_API_HOSPITAIS;
  const apiContatoHospitais = process.env.REACT_APP_API_CONTATOHOSPITAIS;
  const apiVagasHospitais = process.env.REACT_APP_API_VAGASHOSPITAIS;
  const apiBlocoH = process.env.REACT_APP_API_BLOCOH;
  const apiOrientadorHospital = process.env.REACT_APP_API_ORIENTADOR_HOSPITAL;

  // Add this new state for storing the number of vagas for each bloco
  const [numVagas, setNumVagas] = useState({});

  // Add this function to handle numVagas change
  const handleNumVagasChange = (index, value) => {
    setNumVagas((prev) => ({ ...prev, [index]: parseInt(value) }));
  };

  function getCurrentAcademicYear() {
    const today = new Date();
    const currentYear = today.getFullYear();
    // Determine the start and end months of the academic year
    const academicYearStartMonth = 8; // September (0-based index)
    // const academicYearEndMonth = 8; // August (0-based index)
    // Determine the academic year based on the current month
    if (today.getMonth() >= academicYearStartMonth) {
      // If the current month is greater than or equal to the start month,
      // consider it as part of the current academic year
      return `${currentYear}-${currentYear + 1}`;
    } else {
      // If the current month is before the start month,
      // consider it as part of the previous academic year
      return `${currentYear - 1}-${currentYear}`;
    }
  }

  useEffect(() => {
    fetch(`${apiBlocoH}/anoLetivo/${anoLetivo}`)
      .then((response) => response.json())
      .then((data) => setBlocosH(data));
  }, [apiBlocoH]);

  useEffect(() => {
    fetch(`${apiDatas}/1`)
      .then((response) => response.json())
      .then((data) => {
        setDatas(data);

        const currentDate = new Date();
        const startDateHospitais = new Date(data.startDateHospitais);
        const endDateHospitais = new Date(data.endDateHospitais);
        setIsDateValid(
          currentDate >= startDateHospitais && currentDate <= endDateHospitais
        );
        setIsLoading(false);
      });
  }, [apiDatas]);

  useEffect(() => {
    if (isCodigoValid) {
      const fetchHospitalData = async () => {
        try {
          const response = await fetch(`${apiHospitais}/${codigo}`);
          if (!response.ok) {
            throw new Error("Hospital not found");
          }
          const data = await response.json();

          if (data.visivel) {
            setHospitalData(data);
            setBlocos((prevBlocos) =>
              prevBlocos.map((bloco) => ({
                ...bloco,
                idHospital: data.idHospital,
              }))
            );
            setContatoData((prevContatoData) => ({
              ...prevContatoData,
              idHospital: data.idHospital,
            }));
            setOrientadores((prevOrientadores) =>
              prevOrientadores.map((orientador) => ({
                ...orientador,
                idHospital: data.idHospital,
              }))
            );
            setErrorMessage("");
          } else {
            throw new Error("Hospital not visible");
          }
        } catch (error) {
          console.error("Error fetching hospital data:", error);
          setHospitalData(null);
          setErrorMessage(
            "Esse Hospital não existe, por favor, insira um código válido."
          );
        }
      };

      fetchHospitalData();
    }
  }, [codigo, isCodigoValid, apiHospitais]);

  useEffect(() => {
    if (confirmButton) {
      for (const key in contatoData) {
        validateFieldContatoData(key, contatoData[key]);
      }
      for (const key in hospitalData) {
        validateFieldHospitalData(key, hospitalData[key]);
      }
      blocos.forEach((bloco, index) => {
        for (const key in bloco) {
          validateFieldBlocoData(key, bloco[key], index);
        }
      });
      orientadores.forEach((orientador, index) => {
        for (const key in orientador) {
          validateFieldOrientadorData(key, orientador[key], index);
        }
      });
    }
  }, [confirmButton]);

  if (!isLoading && !isDateValid) {
    return (
      <>
        <h1 className="flex items-center justify-center text-fful my-16">
          Neste momento o preenchimento do formulário encontra-se encerrado.
        </h1>
      </>
    );
  }
  const confirmFormCode = () => {
    setConfirmButton(true);
    checkVagasThisAnoLetivo();
  };

  const handleCodigoChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setCodigo(value);
      setIsCodigoValid(value.length > 0);
      setConfirmButton(false);

      // Reset all form data and errors to initial state
      setHospitalData(INITIAL_STATE_HOSPITAL);
      setContatoData(INITIAL_STATE_CONTATO);
      setBlocos([INITIAL_STATE_BLOCO]);
      setOrientadores([INITIAL_STATE_ORIENTADOR]);
      setErrorMessage("");
      setErrorsContatoData({
        email: "",
        emailAlternativo: "",
        emailEstagios: "",
        telefone: "",
        telemovel: "",
        nome: "",
      });
      setErrorsHospitalData({
        numTotalFarmaceuticos: "",
        numTotalFarmOriEst: "",
        numCamas: "",
        numTecDiaTer: "",
        sistemaGestaoIntegrada: "",
        participacaoAtiAvaTer: "",
        participacaoDesImplProTer: "",
        gestaoRiscoPrevErros: "",
        gestaoRiscoPrevReacoes: "",
        sisArmazenamentoMed: "",
        dispMedAmb: "",
        formasFarmEst: "",
        formasFarmNaoEst: "",
        citotoxicos: "",
        monTer: "",
        ensaiosCli: "",
        reconTer: "",
        desenProFarm: "",
        gestaoQualEmpExt: "",
        planoPercursoEstagiario: "",
      });
      setErrorsOrientadorData({
        0: {
          nome: "Campo obrigatório",
          numOrdem: "Campo obrigatório",
          sexo: "Campo obrigatório",
          idade: "Campo obrigatório",
          numAnosExperiencia: "Campo obrigatório",
          habilitacoes: "Campo obrigatório",
        },
      });
      setErrorsBlocoData({
        0: { idBlocoH: "" },
      });
    }
  };

  const getAvailableBlocos = (currentIndex) => {
    const selectedBlocos = blocos
      .map((b) => b.idBlocoH)
      .filter((id, index) => index !== currentIndex && id !== "");
    return blocosH.filter(
      (blocoH) => !selectedBlocos.includes(blocoH.idBlocoH)
    );
  };

  const handleChangeBlocoH = (index, option) => {
    setBlocos((prevBlocos) =>
      prevBlocos.map((bloco, i) =>
        i === index ? { ...bloco, idBlocoH: option ? option.value : "" } : bloco
      )
    );
    validateFieldBlocoData("idBlocoH", option ? option.value : "", index);
  };

  const handleChangeContatoData = (e) => {
    const { name, value } = e.target;
    setContatoData((prevContatoData) => ({
      ...prevContatoData,
      [name]: value,
    }));
    validateFieldContatoData(name, value);
  };
  const handleChangeHospitalData = (e) => {
    const { name, value } = e.target;
    setHospitalData((prevHospitalData) => ({
      ...prevHospitalData,
      [name]: value,
    }));
    validateFieldHospitalData(name, value);
  };
  const handleChangeOrientadorData = (e, index) => {
    const { name, value } = e.target;
    setOrientadores((prevOrientadores) =>
      prevOrientadores.map((orientador, i) =>
        i === index ? { ...orientador, [name]: value } : orientador
      )
    );
    validateFieldOrientadorData(name, value, index);
  };
  const validateFieldContatoData = (fieldName, value) => {
    let error = "";
    switch (fieldName) {
      case "nome":
        if (!value) {
          error = "Campo obrigatório";
        } else if (!/^[a-zA-Z\u00C0-\u017F\s']+$/.test(value)) {
          error = "O nome só pode conter letras";
        }
        break;
      case "email":
        if (!value) {
          error = "Campo obrigatório";
        } else if (!/^\S+@\S+\.\S+(\.\S+)?$/.test(value)) {
          error = "O email deve estar no formato a@b.c";
        }
        break;
      case "emailEstagios":
        if (value && !/^\S+@\S+\.\S+(\.\S+)?$/.test(value)) {
          error = "O email deve estar no formato a@b.c";
        }
        break;
      case "emailAlternativo":
        if (value && !/^\S+@\S+\.\S+(\.\S+)?$/.test(value)) {
          error = "O email deve estar no formato a@b.c";
        }
        break;
      case "telefone":
        if (!value) {
          error = "Campo obrigatório";
        } else if (value && !/^\d+$/.test(value)) {
          error = "O telefone deve conter apenas dígitos";
        }
        break;
      case "telemovel":
        if (value && !/^\d+$/.test(value)) {
          error = "O telemóvel deve conter apenas dígitos";
        }
        break;

      default:
        break;
    }
    setErrorsContatoData((prevErrorsContatoData) => ({
      ...prevErrorsContatoData,
      [fieldName]: error,
    }));
  };

  const validateFieldHospitalData = (fieldName, value) => {
    let error = "";
    switch (fieldName) {
      case "numTotalFarmaceuticos":
        if (!value) {
          error = "Campo obrigatório";
        } else if (value && !/^\d+$/.test(value)) {
          error = "O número total de Farmacêuticos deve conter apenas dígitos";
        }
        break;
      case "numTotalFarmOriEst":
        if (!value) {
          error = "Campo obrigatório";
        } else if (value && !/^\d+$/.test(value)) {
          error =
            "O número total de Farmacêuticos Orientadores de Estágio deve conter apenas dígitos";
        }
        break;
      case "numCamas":
        if (!value) {
          error = "Campo obrigatório";
        } else if (value && !/^\d+$/.test(value)) {
          error = "O número total de camas deve conter apenas dígitos";
        }
        break;
      case "numTecDiaTer":
        if (!value) {
          error = "Campo obrigatório";
        } else if (value && !/^\d+$/.test(value)) {
          error =
            "O número de Técnicos de Diagnóstico e Terapêutica deve conter apenas dígitos";
        }
        break;
      case "sistemaGestaoIntegrada":
      case "participacaoAtiAvaTer":
      case "participacaoDesImplProTer":
      case "gestaoRiscoPrevErros":
      case "gestaoRiscoPrevReacoes":
      case "sisArmazenamentoMed":
      case "dispMedAmb":
      case "formasFarmEst":
      case "formasFarmNaoEst":
      case "citotoxicos":
      case "monTer":
      case "ensaiosCli":
      case "reconTer":
      case "desenProFarm":
      case "gestaoQualEmpExt":
      case "planoPercursoEstagiario":
        if (value === null || value === undefined) {
          error = "Campo obrigatório";
        }
        break;

      default:
        break;
    }
    setErrorsHospitalData((prevErrorsHospitalData) => ({
      ...prevErrorsHospitalData,
      [fieldName]: error,
    }));
  };
  const validateFieldOrientadorData = (fieldName, value, index) => {
    let error = "";
    switch (fieldName) {
      case "nome":
        if (!value) {
          error = "Campo obrigatório";
        } else if (!/^[a-zA-Z\u00C0-\u017F\s']+$/.test(value)) {
          error = "O nome só pode conter letras";
        }
        break;
      case "numOrdem":
        if (!value) {
          error = "Campo obrigatório";
        } else if (value && !/^\d+$/.test(value)) {
          error = "O número de Ordem deve conter apenas dígitos";
        }
        break;
      case "sexo":
        if (!value) {
          error = "Campo obrigatório";
        }
        break;
      case "idade":
        if (!value) {
          error = "Campo obrigatório";
        } else if (value && !/^\d+$/.test(value)) {
          error = "A idade deve conter apenas dígitos";
        }
        break;
      case "numAnosExperiencia":
        if (!value) {
          error = "Campo obrigatório";
        } else if (value && !/^\d+$/.test(value)) {
          error = "O número de anos de experiência deve conter apenas dígitos";
        }
        break;
      case "habilitacoes":
        if (!value) {
          error = "Campo obrigatório";
        }
        break;

      default:
        break;
    }
    setErrorsOrientadorData((prevErrors) => ({
      ...prevErrors,
      [index]: {
        ...prevErrors[index],
        [fieldName]: error,
      },
    }));
  };
  const validateFieldBlocoData = (fieldName, value, index) => {
    let error = "";
    if (fieldName === "idBlocoH" && !value) {
      error = "Campo obrigatório";
    }
    setErrorsBlocoData((prevErrors) => ({
      ...prevErrors,
      [index]: {
        ...prevErrors[index],
        [fieldName]: error,
      },
    }));
  };

  const addOrientador = () => {
    setOrientadores((prevOrientadores) => [
      ...prevOrientadores,
      {
        ...INITIAL_STATE_ORIENTADOR,
        ...(hospitalData && { idHospital: hospitalData.idHospital, obs: "" }),
      },
    ]);
    setErrorsOrientadorData((prevErrors) => ({
      ...prevErrors,
      [orientadores.length]: {
        nome: "Campo obrigatório",
        numOrdem: "Campo obrigatório",
        sexo: "Campo obrigatório",
        idade: "Campo obrigatório",
        numAnosExperiencia: "Campo obrigatório",
        habilitacoes: "Campo obrigatório",
      },
    }));
  };

  const removeOrientador = (index) => {
    setOrientadores((prevOrientadores) =>
      prevOrientadores.filter((_, i) => i !== index)
    );
    setErrorsOrientadorData((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[index];
      // Reindex the remaining errors
      return Object.fromEntries(
        Object.entries(newErrors).map(([key, value], i) => [i, value])
      );
    });
  };

  const addBloco = () => {
    setBlocos((prevBlocos) => [
      ...prevBlocos,
      {
        ...INITIAL_STATE_BLOCO,
        ...(hospitalData && { idHospital: hospitalData.idHospital }),
      },
    ]);
    setErrorsBlocoData((prevErrors) => ({
      ...prevErrors,
      [blocos.length]: { idBlocoH: "Campo obrigatório" },
    }));
  };

  const removeBloco = (index) => {
    setBlocos((prevBlocos) => prevBlocos.filter((_, i) => i !== index));
    setErrorsBlocoData((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[index];
      return Object.fromEntries(
        Object.entries(newErrors).map(([key, value], i) => [i, value])
      );
    });
  };

  const isFormValid = () => {
    const isContatoValid = Object.values(errorsContatoData).every(
      (error) => error === ""
    );
    const isHospitalValid = Object.values(errorsHospitalData).every(
      (error) => error === ""
    );
    const isBlocoValid = Object.values(errorsBlocoData).every((blocoErrors) =>
      Object.values(blocoErrors).every((error) => error === "")
    );
    const isOrientadoresValid = Object.values(errorsOrientadorData).every(
      (orientadorErrors) =>
        Object.values(orientadorErrors).every((error) => error === "")
    );
    return (
      isContatoValid && isHospitalValid && isBlocoValid && isOrientadoresValid
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Submit contato data
      await fetch(`${apiContatoHospitais}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(contatoData),
      });

      // Submit hospital data (update)
      await fetch(`${apiHospitais}/${hospitalData.idHospital}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(hospitalData),
      });

      // Submit bloco data for each bloco, creating multiple vagas based on numVagas
      for (let index = 0; index < blocos.length; index++) {
        const bloco = blocos[index];
        const vagasCount = numVagas[index] || 1;
        for (let i = 0; i < vagasCount; i++) {
          await fetch(`${apiVagasHospitais}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(bloco),
          });
        }
      }

      // Submit orientadores data
      for (const orientador of orientadores) {
        await fetch(`${apiOrientadorHospital}`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(orientador),
        });
      }

      // Show success modal
      setShowSuccessModal(true);

      // Handle successful submission (e.g., show success message, reset form)
      console.log("Form submitted successfully");
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle error (e.g., show error message)
    }
  };

  const handleCloseModal = () => {
    setShowSuccessModal(false);
    window.location.reload();
  };

  const checkVagasThisAnoLetivo = () => {
    fetch(
      `${apiVagasHospitais}/anoLetivo/${anoLetivo}/hospital/${codigo}/NoAlunos`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          setFormAlreadySubmitted(true);
        } else {
          setFormAlreadySubmitted(false);
        }
      });
  };

  return (
    <div>
      <div className="flex flex-col items-center justify-center text-center">
        <h1 className="text-5xl font-bold my-10 text-fful">
          Formulário Farmácia Hospitalar
        </h1>
      </div>
      <div className="flex flex-col items-center justify-center text-center">
        <img
          src="/images/codigo-hospital.jpg"
          alt="Secondary image"
          className="mb-4 w-96 h-auto"
        />
        <div className="mb-4">
          <label
            htmlFor="codigoHospital"
            className="block text-lg font-medium mb-2"
          >
            Código do Hospital
          </label>
          <input
            type="text"
            id="codigoHospital"
            value={codigo}
            onChange={handleCodigoChange}
            className="border border-gray-300 p-2 rounded"
            placeholder="Digite o código"
          />
        </div>
      </div>
      {errorMessage && (
        <p className="text-red-500 mt-4 text-center">{errorMessage}</p>
      )}
      {codigo && hospitalData && (
        <div className="w-full max-w-6xl mx-auto">
          <div className="flex justify-center items-center -mx-2">
            <div className="w-full md:w-2/4 px-2 mb-4">
              <label
                htmlFor="hospitalNome"
                className="text-lg font-medium mb-2 text-center"
              >
                Nome do Hospital
              </label>
              <input
                type="text"
                id="hospitalNome"
                className="border border-gray-300 p-2 rounded w-full bg-gray-100"
                value={hospitalData.nome ? "Hospital " + hospitalData.nome : ""}
                readOnly
              />
            </div>
            <div className="w-full md:w-2/4 px-2 mb-4">
              <label
                htmlFor="hospitalMorada"
                className=" text-lg font-medium mb-2 text-center"
              >
                Morada do Hospital
              </label>
              <input
                type="text"
                id="hospitalMorada"
                className="border border-gray-300 p-2 rounded w-full bg-gray-100"
                value={hospitalData.morada || ""}
                readOnly
              />
            </div>
            <div className="w-full md:w-2/4 px-2 mb-4">
              <label
                htmlFor="hospitalLocalidade"
                className=" text-lg font-medium mb-2 text-center"
              >
                Localidade do Hospital
              </label>
              <input
                type="text"
                id="hospitalLocalidade"
                className="border border-gray-300 p-2 rounded w-full bg-gray-100"
                value={hospitalData.localidade || ""}
                readOnly
              />
            </div>
            <div className="w-full md:w-2/4 px-2 mb-4">
              <label
                htmlFor="hospitalCodPostal"
                className=" text-lg font-medium mb-2 text-center"
              >
                Código Postal do Hospital
              </label>
              <input
                type="text"
                id="hospitalCodPostal"
                className="border border-gray-300 p-2 rounded w-full bg-gray-100"
                value={hospitalData.codPostal || ""}
                readOnly
              />
            </div>
          </div>
          <div className="flex flex-col items-center justify-center text-center mb-10">
            <div className="text-xl text-fful">
              Se confirma que é este o hospital para o qual pretende preencher o
              formulário clique no botão confirmo,
            </div>
            <div className="text-xl text-fful">
              caso contrário coloque o código do Hospital correto (recebeu no
              email).
            </div>
            <button
              className="bg-fful hover:bg-iconhover text-white font-bold py-2 px-4 rounded mt-6"
              onClick={() => confirmFormCode()}
            >
              Confirmo
            </button>
          </div>
          {confirmButton && formAlreadySubmitted && (
            <div className="flex items-center justify-center text-fful my-16">
              <h4>
                <div>
                  Esta entidade já submeteu o formulário para este ano letivo.
                  Para fazer alterações, por favor envie um email detalhado com
                  as alterações a fazer para nucleodeestagios@ff.ulisboa.pt
                </div>
                <div className="flex items-center justify-center text-fful my-16">
                  Obrigado
                </div>
              </h4>
            </div>
          )}
          {confirmButton && !formAlreadySubmitted && (
            <div>
              <div className="w-full max-w-3xl mx-auto mb-10">
                <h2 className="text-xl font-bold mb-4 text-center text-fful">
                  Contato do Hospital
                </h2>
                <form className="space-y-4">
                  {/* Contato */}
                  <Form.Group as={Col} md="12" controlId="nome">
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      Nome Diretor Técnico
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="nome"
                      placeholder="Digite o nome do Diretor Técnico"
                      value={contatoData.nome}
                      onChange={handleChangeContatoData}
                      isInvalid={!!errorsContatoData.nome}
                      isValid={!errorsContatoData.nome}
                    />
                    <Form.Control.Feedback
                      type="valid"
                      tooltip
                      style={{ display: "none" }}
                    >
                      <span className="visually-hidden">Success</span>
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errorsContatoData.nome}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="12" controlId="email">
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      Email Diretor Técnico
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="email"
                      placeholder="Digite o email do Diretor Técnico"
                      value={contatoData.email}
                      onChange={handleChangeContatoData}
                      isInvalid={!!errorsContatoData.email}
                      isValid={!errorsContatoData.email}
                    />
                    <Form.Control.Feedback
                      type="valid"
                      tooltip
                      style={{ display: "none" }}
                    >
                      <span className="visually-hidden">Success</span>
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errorsContatoData.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="12" controlId="emailEstagios">
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      Email Gabinete de Coordenação de estágios (se aplicável)
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="emailEstagios"
                      placeholder="Digite o email do Gabinete de Coordenação de estágios"
                      value={contatoData.emailEstagios}
                      onChange={handleChangeContatoData}
                      isInvalid={!!errorsContatoData.emailEstagios}
                      isValid={!errorsContatoData.emailEstagios}
                    />
                    <Form.Control.Feedback
                      type="valid"
                      tooltip
                      style={{ display: "none" }}
                    >
                      <span className="visually-hidden">Success</span>
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errorsContatoData.emailEstagios}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="12" controlId="emailAlternativo">
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      Email Alternativo
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="emailAlternativo"
                      placeholder="Digite o email Alternativo"
                      value={contatoData.emailAlternativo}
                      onChange={handleChangeContatoData}
                      isInvalid={!!errorsContatoData.emailAlternativo}
                      isValid={!errorsContatoData.emailAlternativo}
                    />
                    <Form.Control.Feedback
                      type="valid"
                      tooltip
                      style={{ display: "none" }}
                    >
                      <span className="visually-hidden">Success</span>
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errorsContatoData.emailAlternativo}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Row>
                    <Form.Group as={Col} md="6" controlId="telefone">
                      <Form.Label className="block text-lg font-medium mb-2 text-fful">
                        Telefone
                      </Form.Label>
                      <Form.Control
                        type="number"
                        name="telefone"
                        placeholder="Digite o telefone"
                        value={contatoData.telefone}
                        onChange={handleChangeContatoData}
                        isInvalid={!!errorsContatoData.telefone}
                        isValid={!errorsContatoData.telefone}
                        onWheel={(e) => e.target.blur()}
                      />
                      <Form.Control.Feedback
                        type="valid"
                        tooltip
                        style={{ display: "none" }}
                      >
                        <span className="visually-hidden">Success</span>
                      </Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        {errorsContatoData.telefone}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="telemovel">
                      <Form.Label className="block text-lg font-medium mb-2 text-fful">
                        Telemóvel
                      </Form.Label>
                      <Form.Control
                        type="number"
                        name="telemovel"
                        placeholder="Digite o telemóvel"
                        value={contatoData.telemovel}
                        onChange={handleChangeContatoData}
                        isInvalid={!!errorsContatoData.telemovel}
                        isValid={!errorsContatoData.telemovel}
                        onWheel={(e) => e.target.blur()}
                      />
                      <Form.Control.Feedback
                        type="valid"
                        tooltip
                        style={{ display: "none" }}
                      >
                        <span className="visually-hidden">Success</span>
                      </Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        {errorsContatoData.telemovel}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                </form>
              </div>
              <div className="w-full max-w-3xl mx-auto mb-10">
                <h2 className="text-xl font-bold mb-4 text-center text-fful">
                  Informação do Hospital
                </h2>
                <form className="space-y-4">
                  <Row>
                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="numTotalFarmaceuticos"
                    >
                      <Form.Label className="block text-lg font-medium mb-2 text-fful mt-4">
                        Número Total de Farmacêuticos
                      </Form.Label>
                      <Form.Control
                        type="number"
                        name="numTotalFarmaceuticos"
                        placeholder="Digite o número total de Farmacêuticos"
                        // value={hospitalData.numTotalFarmaceuticos}
                        onChange={handleChangeHospitalData}
                        isInvalid={!!errorsHospitalData.numTotalFarmaceuticos}
                        isValid={!errorsHospitalData.numTotalFarmaceuticos}
                        onWheel={(e) => e.target.blur()}
                      />
                      <Form.Control.Feedback
                        type="valid"
                        tooltip
                        style={{ display: "none" }}
                      >
                        <span className="visually-hidden">Success</span>
                      </Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        {errorsHospitalData.numTotalFarmaceuticos}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="numTotalFarmOriEst">
                      <Form.Label className="block text-lg font-medium mb-2 text-fful">
                        Número Total de Farmacêuticos Orientadores de Estágio
                      </Form.Label>
                      <Form.Control
                        type="number"
                        name="numTotalFarmOriEst"
                        placeholder="Digite o número total de Farmacêuticos Orientadores de Estágio"
                        // value={hospitalData.numTotalFarmOriEst}
                        onChange={handleChangeHospitalData}
                        isInvalid={!!errorsHospitalData.numTotalFarmOriEst}
                        isValid={!errorsHospitalData.numTotalFarmOriEst}
                        onWheel={(e) => e.target.blur()}
                      />
                      <Form.Control.Feedback
                        type="valid"
                        tooltip
                        style={{ display: "none" }}
                      >
                        <span className="visually-hidden">Success</span>
                      </Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        {errorsHospitalData.numTotalFarmOriEst}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Col} md="6" controlId="numCamas">
                      <Form.Label className="block text-lg font-medium mb-2 text-fful mt-4">
                        Número de Camas
                      </Form.Label>
                      <Form.Control
                        type="number"
                        name="numCamas"
                        placeholder="Digite o número de Camas"
                        // value={hospitalData.numCamas}
                        onChange={handleChangeHospitalData}
                        isInvalid={!!errorsHospitalData.numCamas}
                        isValid={!errorsHospitalData.numCamas}
                        onWheel={(e) => e.target.blur()}
                      />
                      <Form.Control.Feedback
                        type="valid"
                        tooltip
                        style={{ display: "none" }}
                      >
                        <span className="visually-hidden">Success</span>
                      </Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        {errorsHospitalData.numCamas}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="numTecDiaTer">
                      <Form.Label className="block text-lg font-medium mb-2 text-fful">
                        Número de Técnicos de Diagnóstico e Terapêutica
                      </Form.Label>
                      <Form.Control
                        type="number"
                        name="numTecDiaTer"
                        placeholder="Digite o número de Técnicos de Diagnóstico e Terapêutica"
                        // value={hospitalData.numTecDiaTer}
                        onChange={handleChangeHospitalData}
                        isInvalid={!!errorsHospitalData.numTecDiaTer}
                        isValid={!errorsHospitalData.numTecDiaTer}
                        onWheel={(e) => e.target.blur()}
                      />
                      <Form.Control.Feedback
                        type="valid"
                        tooltip
                        style={{ display: "none" }}
                      >
                        <span className="visually-hidden">Success</span>
                      </Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        {errorsHospitalData.numTecDiaTer}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="sistemaGestaoIntegrada"
                  >
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      Sistema informático de gestão integrada de processos
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Sim"
                        name="sistemaGestaoIntegrada"
                        id="sistemaGestaoIntegradaSim"
                        value="true"
                        checked={hospitalData.sistemaGestaoIntegrada === true}
                        onChange={(e) =>
                          handleChangeHospitalData({
                            target: {
                              name: "sistemaGestaoIntegrada",
                              value: true,
                            },
                          })
                        }
                        isInvalid={!!errorsHospitalData.sistemaGestaoIntegrada}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Não"
                        name="sistemaGestaoIntegrada"
                        id="sistemaGestaoIntegradaNao"
                        value="false"
                        checked={hospitalData.sistemaGestaoIntegrada === false}
                        onChange={(e) =>
                          handleChangeHospitalData({
                            target: {
                              name: "sistemaGestaoIntegrada",
                              value: false,
                            },
                          })
                        }
                        isInvalid={!!errorsHospitalData.sistemaGestaoIntegrada}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errorsHospitalData.sistemaGestaoIntegrada}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="participacaoAtiAvaTer"
                  >
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      Participação em atividades de avaliação e seleção da
                      terapêutica
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Sim"
                        name="participacaoAtiAvaTer"
                        id="participacaoAtiAvaTerSim"
                        value="true"
                        checked={hospitalData.participacaoAtiAvaTer === true}
                        onChange={(e) =>
                          handleChangeHospitalData({
                            target: {
                              name: "participacaoAtiAvaTer",
                              value: e.target.value === "true",
                            },
                          })
                        }
                        isInvalid={!!errorsHospitalData.participacaoAtiAvaTer}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Não"
                        name="participacaoAtiAvaTer"
                        id="participacaoAtiAvaTerNao"
                        value="false"
                        checked={hospitalData.participacaoAtiAvaTer === false}
                        onChange={(e) =>
                          handleChangeHospitalData({
                            target: {
                              name: "participacaoAtiAvaTer",
                              value: e.target.value === "true",
                            },
                          })
                        }
                        isInvalid={!!errorsHospitalData.participacaoAtiAvaTer}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errorsHospitalData.participacaoAtiAvaTer}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="participacaoDesImplProTer"
                  >
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      Participação no desenvolvimento e implementação de
                      protocolos terapêuticos
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Sim"
                        name="participacaoDesImplProTer"
                        id="participacaoDesImplProTerSim"
                        value="true"
                        checked={
                          hospitalData.participacaoDesImplProTer === true
                        }
                        onChange={(e) =>
                          handleChangeHospitalData({
                            target: {
                              name: "participacaoDesImplProTer",
                              value: e.target.value === "true",
                            },
                          })
                        }
                        isInvalid={
                          !!errorsHospitalData.participacaoDesImplProTer
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Não"
                        name="participacaoDesImplProTer"
                        id="participacaoDesImplProTerNao"
                        value="false"
                        checked={
                          hospitalData.participacaoDesImplProTer === false
                        }
                        onChange={(e) =>
                          handleChangeHospitalData({
                            target: {
                              name: "participacaoDesImplProTer",
                              value: e.target.value === "true",
                            },
                          })
                        }
                        isInvalid={
                          !!errorsHospitalData.participacaoDesImplProTer
                        }
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errorsHospitalData.participacaoDesImplProTer}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="12" controlId="gestaoRiscoPrevErros">
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      Programas de gestão de risco aplicado à prevenção de erros
                      de medicação
                    </Form.Label>

                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Sim"
                        name="gestaoRiscoPrevErros"
                        id="gestaoRiscoPrevErrosSim"
                        value="true"
                        checked={hospitalData.gestaoRiscoPrevErros === true}
                        onChange={(e) =>
                          handleChangeHospitalData({
                            target: {
                              name: "gestaoRiscoPrevErros",
                              value: e.target.value === "true",
                            },
                          })
                        }
                        isInvalid={!!errorsHospitalData.gestaoRiscoPrevErros}
                      />

                      <Form.Check
                        inline
                        type="radio"
                        label="Não"
                        name="gestaoRiscoPrevErros"
                        id="gestaoRiscoPrevErrosNao"
                        value="false"
                        checked={hospitalData.gestaoRiscoPrevErros === false}
                        onChange={(e) =>
                          handleChangeHospitalData({
                            target: {
                              name: "gestaoRiscoPrevErros",
                              value: e.target.value === "true",
                            },
                          })
                        }
                        isInvalid={!!errorsHospitalData.gestaoRiscoPrevErros}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errorsHospitalData.gestaoRiscoPrevErros}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="gestaoRiscoPrevReacoes"
                  >
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      Programas de gestão de risco aplicado à prevenção de
                      reações adversas
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Sim"
                        name="gestaoRiscoPrevReacoes"
                        id="gestaoRiscoPrevReacoesSim"
                        value="true"
                        checked={hospitalData.gestaoRiscoPrevReacoes === true}
                        onChange={(e) =>
                          handleChangeHospitalData({
                            target: {
                              name: "gestaoRiscoPrevReacoes",
                              value: e.target.value === "true",
                            },
                          })
                        }
                        isInvalid={!!errorsHospitalData.gestaoRiscoPrevReacoes}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Não"
                        name="gestaoRiscoPrevReacoes"
                        id="gestaoRiscoPrevReacoesNao"
                        value="false"
                        checked={hospitalData.gestaoRiscoPrevReacoes === false}
                        onChange={(e) =>
                          handleChangeHospitalData({
                            target: {
                              name: "gestaoRiscoPrevReacoes",
                              value: e.target.value === "true",
                            },
                          })
                        }
                        isInvalid={!!errorsHospitalData.gestaoRiscoPrevReacoes}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errorsHospitalData.gestaoRiscoPrevReacoes}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="12" controlId="sisArmazenamentoMed">
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      Sistemas automáticos de armazenamento e dispensa de
                      medicamentos
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Sim"
                        name="sisArmazenamentoMed"
                        id="sisArmazenamentoMedSim"
                        value="true"
                        checked={hospitalData.sisArmazenamentoMed === true}
                        onChange={(e) =>
                          handleChangeHospitalData({
                            target: {
                              name: "sisArmazenamentoMed",
                              value: e.target.value === "true",
                            },
                          })
                        }
                        isInvalid={!!errorsHospitalData.sisArmazenamentoMed}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Não"
                        name="sisArmazenamentoMed"
                        id="sisArmazenamentoMedNao"
                        value="false"
                        checked={hospitalData.sisArmazenamentoMed === false}
                        onChange={(e) =>
                          handleChangeHospitalData({
                            target: {
                              name: "sisArmazenamentoMed",
                              value: e.target.value === "true",
                            },
                          })
                        }
                        isInvalid={!!errorsHospitalData.sisArmazenamentoMed}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errorsHospitalData.sisArmazenamentoMed}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="12" controlId="dispMedAmb">
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      Dispensa de medicamentos para ambulatório
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Sim"
                        name="dispMedAmb"
                        id="dispMedAmbSim"
                        value="true"
                        checked={hospitalData.dispMedAmb === true}
                        onChange={(e) =>
                          handleChangeHospitalData({
                            target: {
                              name: "dispMedAmb",
                              value: e.target.value === "true",
                            },
                          })
                        }
                        isInvalid={!!errorsHospitalData.dispMedAmb}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Não"
                        name="dispMedAmb"
                        id="dispMedAmbNao"
                        value="false"
                        checked={hospitalData.dispMedAmb === false}
                        onChange={(e) =>
                          handleChangeHospitalData({
                            target: {
                              name: "dispMedAmb",
                              value: e.target.value === "true",
                            },
                          })
                        }
                        isInvalid={!!errorsHospitalData.dispMedAmb}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errorsHospitalData.dispMedAmb}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="12" controlId="formasFarmEst">
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      Preparação de Formas farmacêuticas estéreis
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Sim"
                        name="formasFarmEst"
                        id="formasFarmEstSim"
                        value="true"
                        checked={hospitalData.formasFarmEst === true}
                        onChange={(e) =>
                          handleChangeHospitalData({
                            target: {
                              name: "formasFarmEst",
                              value: e.target.value === "true",
                            },
                          })
                        }
                        isInvalid={!!errorsHospitalData.formasFarmEst}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Não"
                        name="formasFarmEst"
                        id="formasFarmEstNao"
                        value="false"
                        checked={hospitalData.formasFarmEst === false}
                        onChange={(e) =>
                          handleChangeHospitalData({
                            target: {
                              name: "formasFarmEst",
                              value: e.target.value === "true",
                            },
                          })
                        }
                        isInvalid={!!errorsHospitalData.formasFarmEst}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errorsHospitalData.formasFarmEst}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="12" controlId="formasFarmNaoEst">
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      Preparação de Formas farmacêuticas não estéreis
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Sim"
                        name="formasFarmNaoEst"
                        id="formasFarmNaoEstSim"
                        value="true"
                        checked={hospitalData.formasFarmNaoEst === true}
                        onChange={(e) =>
                          handleChangeHospitalData({
                            target: {
                              name: "formasFarmNaoEst",
                              value: e.target.value === "true",
                            },
                          })
                        }
                        isInvalid={!!errorsHospitalData.formasFarmNaoEst}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Não"
                        name="formasFarmNaoEst"
                        id="formasFarmNaoEstNao"
                        value="false"
                        checked={hospitalData.formasFarmNaoEst === false}
                        onChange={(e) =>
                          handleChangeHospitalData({
                            target: {
                              name: "formasFarmNaoEst",
                              value: e.target.value === "true",
                            },
                          })
                        }
                        isInvalid={!!errorsHospitalData.formasFarmNaoEst}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errorsHospitalData.formasFarmNaoEst}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="12" controlId="citotoxicos">
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      Preparação de Citotóxicos
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Sim"
                        name="citotoxicos"
                        id="citotoxicosSim"
                        value="true"
                        checked={hospitalData.citotoxicos === true}
                        onChange={(e) =>
                          handleChangeHospitalData({
                            target: {
                              name: "citotoxicos",
                              value: e.target.value === "true",
                            },
                          })
                        }
                        isInvalid={!!errorsHospitalData.citotoxicos}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Não"
                        name="citotoxicos"
                        id="citotoxicosNao"
                        value="false"
                        checked={hospitalData.citotoxicos === false}
                        onChange={(e) =>
                          handleChangeHospitalData({
                            target: {
                              name: "citotoxicos",
                              value: e.target.value === "true",
                            },
                          })
                        }
                        isInvalid={!!errorsHospitalData.citotoxicos}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errorsHospitalData.citotoxicos}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="12" controlId="monTer">
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      Monitorização terapêutica (farmacocinética)
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Sim"
                        name="monTer"
                        id="monTerSim"
                        value="true"
                        checked={hospitalData.monTer === true}
                        onChange={(e) =>
                          handleChangeHospitalData({
                            target: {
                              name: "monTer",
                              value: e.target.value === "true",
                            },
                          })
                        }
                        isInvalid={!!errorsHospitalData.monTer}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Não"
                        name="monTer"
                        id="monTerNao"
                        value="false"
                        checked={hospitalData.monTer === false}
                        onChange={(e) =>
                          handleChangeHospitalData({
                            target: {
                              name: "monTer",
                              value: e.target.value === "true",
                            },
                          })
                        }
                        isInvalid={!!errorsHospitalData.monTer}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errorsHospitalData.monTer}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="12" controlId="ensaiosCli">
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      Ensaios clínicos
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Sim"
                        name="ensaiosCli"
                        id="ensaiosCliSim"
                        value="true"
                        checked={hospitalData.ensaiosCli === true}
                        onChange={(e) =>
                          handleChangeHospitalData({
                            target: {
                              name: "ensaiosCli",
                              value: e.target.value === "true",
                            },
                          })
                        }
                        isInvalid={!!errorsHospitalData.ensaiosCli}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Não"
                        name="ensaiosCli"
                        id="ensaiosCliNao"
                        value="false"
                        checked={hospitalData.ensaiosCli === false}
                        onChange={(e) =>
                          handleChangeHospitalData({
                            target: {
                              name: "ensaiosCli",
                              value: e.target.value === "true",
                            },
                          })
                        }
                        isInvalid={!!errorsHospitalData.ensaiosCli}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errorsHospitalData.ensaiosCli}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="12" controlId="reconTer">
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      Procedimento normalizado de reconciliação da terapêutica
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Sim"
                        name="reconTer"
                        id="reconTerSim"
                        value="true"
                        checked={hospitalData.reconTer === true}
                        onChange={(e) =>
                          handleChangeHospitalData({
                            target: {
                              name: "reconTer",
                              value: e.target.value === "true",
                            },
                          })
                        }
                        isInvalid={!!errorsHospitalData.reconTer}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Não"
                        name="reconTer"
                        id="reconTerNao"
                        value="false"
                        checked={hospitalData.reconTer === false}
                        onChange={(e) =>
                          handleChangeHospitalData({
                            target: {
                              name: "reconTer",
                              value: e.target.value === "true",
                            },
                          })
                        }
                        isInvalid={!!errorsHospitalData.reconTer}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errorsHospitalData.reconTer}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="12" controlId="desenProFarm">
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      Programas de desenvolvimento profissional para
                      Farmacêuticos
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Sim"
                        name="desenProFarm"
                        id="desenProFarmSim"
                        value="true"
                        checked={hospitalData.desenProFarm === true}
                        onChange={(e) =>
                          handleChangeHospitalData({
                            target: {
                              name: "desenProFarm",
                              value: e.target.value === "true",
                            },
                          })
                        }
                        isInvalid={!!errorsHospitalData.desenProFarm}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Não"
                        name="desenProFarm"
                        id="desenProFarmNao"
                        value="false"
                        checked={hospitalData.desenProFarm === false}
                        onChange={(e) =>
                          handleChangeHospitalData({
                            target: {
                              name: "desenProFarm",
                              value: e.target.value === "true",
                            },
                          })
                        }
                        isInvalid={!!errorsHospitalData.desenProFarm}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errorsHospitalData.desenProFarm}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="12" controlId="gestaoQualEmpExt">
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      Sistema de gestão de qualidade certificado por uma empresa
                      externa
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Sim"
                        name="gestaoQualEmpExt"
                        id="gestaoQualEmpExtSim"
                        value="true"
                        checked={hospitalData.gestaoQualEmpExt === true}
                        onChange={(e) =>
                          handleChangeHospitalData({
                            target: {
                              name: "gestaoQualEmpExt",
                              value: e.target.value === "true",
                            },
                          })
                        }
                        isInvalid={!!errorsHospitalData.gestaoQualEmpExt}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Não"
                        name="gestaoQualEmpExt"
                        id="gestaoQualEmpExtNao"
                        value="false"
                        checked={hospitalData.gestaoQualEmpExt === false}
                        onChange={(e) =>
                          handleChangeHospitalData({
                            target: {
                              name: "gestaoQualEmpExt",
                              value: e.target.value === "true",
                            },
                          })
                        }
                        isInvalid={!!errorsHospitalData.gestaoQualEmpExt}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errorsHospitalData.gestaoQualEmpExt}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="planoPercursoEstagiario"
                  >
                    <Form.Label className="block text-lg font-medium mb-2 text-fful">
                      O Serviço Farmacêutico possui um plano definido para
                      percurso formativo do estagiário
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Sim"
                        name="planoPercursoEstagiario"
                        id="planoPercursoEstagiarioSim"
                        value="true"
                        checked={hospitalData.planoPercursoEstagiario === true}
                        onChange={(e) =>
                          handleChangeHospitalData({
                            target: {
                              name: "planoPercursoEstagiario",
                              value: e.target.value === "true",
                            },
                          })
                        }
                        isInvalid={!!errorsHospitalData.planoPercursoEstagiario}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Não"
                        name="planoPercursoEstagiario"
                        id="planoPercursoEstagiarioNao"
                        value="false"
                        checked={hospitalData.planoPercursoEstagiario === false}
                        onChange={(e) =>
                          handleChangeHospitalData({
                            target: {
                              name: "planoPercursoEstagiario",
                              value: e.target.value === "true",
                            },
                          })
                        }
                        isInvalid={!!errorsHospitalData.planoPercursoEstagiario}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errorsHospitalData.planoPercursoEstagiario}
                    </Form.Control.Feedback>
                  </Form.Group>
                </form>
              </div>

              {/* Orientador */}
              <div className="w-full max-w-3xl mx-auto mb-10">
                <h2 className="text-xl font-bold mb-4 text-center text-fful">
                  Orientador(es) do Hospital
                </h2>
                {orientadores.map((orientador, index) => (
                  <form key={index} className="space-y-4 mb-8">
                    <h3 className="text-lg font-semibold text-fful">
                      Orientador {index + 1}
                    </h3>
                    <Form.Group as={Col} md="12" controlId={`nome-${index}`}>
                      <Form.Label className="block text-lg font-medium mb-2 text-fful">
                        Nome do Orientador Farmacêutico
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="nome"
                        placeholder="Digite o nome do Orientador Farmacêutico"
                        value={orientador.nome}
                        onChange={(e) => handleChangeOrientadorData(e, index)}
                        isInvalid={!!errorsOrientadorData[index]?.nome}
                        isValid={!errorsOrientadorData[index]?.nome}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorsOrientadorData[index]?.nome}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="12"
                      controlId={`numOrdem-${index}`}
                    >
                      <Form.Label className="block text-lg font-medium mb-2 text-fful">
                        Número da Ordem
                      </Form.Label>
                      <Form.Control
                        type="number"
                        name="numOrdem"
                        placeholder="Digite o número da Ordem"
                        value={orientador.numOrdem}
                        onChange={(e) => handleChangeOrientadorData(e, index)}
                        isInvalid={!!errorsOrientadorData[index]?.numOrdem}
                        isValid={!errorsOrientadorData[index]?.numOrdem}
                        onWheel={(e) => e.target.blur()}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorsOrientadorData[index]?.numOrdem}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Row>
                      <Form.Group as={Col} md="6" controlId={`sexo-${index}`}>
                        <Form.Label className="block text-lg font-medium mb-2 text-fful">
                          Sexo
                        </Form.Label>
                        <Form.Select
                          name="sexo"
                          onChange={(e) => handleChangeOrientadorData(e, index)}
                          value={orientador.sexo}
                          isInvalid={!!errorsOrientadorData[index]?.sexo}
                          isValid={!errorsOrientadorData[index]?.sexo}
                        >
                          <option value="">Selecione o sexo</option>
                          <option value="FEMININO">Feminino</option>
                          <option value="MASCULINO">Masculino</option>
                        </Form.Select>

                        <Form.Control.Feedback
                          type="valid"
                          tooltip
                          style={{ display: "none" }}
                        >
                          <span className="visually-hidden">Success</span>
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          {errorsOrientadorData[index]?.sexo}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId={`habilitacoes-${index}`}
                      >
                        <Form.Label className="block text-lg font-medium mb-2 text-fful">
                          Habilitações
                        </Form.Label>
                        <Form.Select
                          name="habilitacoes"
                          onChange={(e) => handleChangeOrientadorData(e, index)}
                          value={orientador.habilitacoes}
                          isInvalid={
                            !!errorsOrientadorData[index]?.habilitacoes
                          }
                          isValid={!errorsOrientadorData[index]?.habilitacoes}
                        >
                          <option value="">
                            Selecione as suas habilitações
                          </option>
                          <option value="LICENCIATURA">Licenciatura</option>
                          <option value="MICF">MICF</option>
                          <option value="MESTRADO">Mestrado</option>
                          <option value="DOUTORAMENTO">Doutoramento</option>
                          <option value="POS_GRADUACAO">Pós-Graduação</option>
                          <option value="ESPECIALISTA_FARMACIA">
                            Especialista em Farmácia Hospitalar
                          </option>
                        </Form.Select>

                        <Form.Control.Feedback
                          type="valid"
                          tooltip
                          style={{ display: "none" }}
                        >
                          <span className="visually-hidden">Success</span>
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          {errorsOrientadorData[index]?.habilitacoes}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    <Row>
                      <Form.Group as={Col} md="6" controlId={`idade-${index}`}>
                        <Form.Label className="block text-lg font-medium mb-2 text-fful">
                          Idade
                        </Form.Label>
                        <Form.Control
                          type="number"
                          name="idade"
                          placeholder="Digite a sua idade"
                          value={orientador.idade}
                          onChange={(e) => handleChangeOrientadorData(e, index)}
                          isInvalid={!!errorsOrientadorData[index]?.idade}
                          isValid={!errorsOrientadorData[index]?.idade}
                          onWheel={(e) => e.target.blur()}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errorsOrientadorData[index]?.idade}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId={`numAnosExperiencia-${index}`}
                      >
                        <Form.Label className="block text-lg font-medium mb-2 text-fful">
                          Número de Anos de Experiência
                        </Form.Label>
                        <Form.Control
                          type="number"
                          name="numAnosExperiencia"
                          placeholder="Digite os seus anos de experiência"
                          value={orientador.numAnosExperiencia}
                          onChange={(e) => handleChangeOrientadorData(e, index)}
                          isInvalid={
                            !!errorsOrientadorData[index]?.numAnosExperiencia
                          }
                          isValid={
                            !errorsOrientadorData[index]?.numAnosExperiencia
                          }
                          onWheel={(e) => e.target.blur()}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errorsOrientadorData[index]?.numAnosExperiencia}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    {orientadores.length > 1 && (
                      <button
                        type="button"
                        className="bg-red-700 hover:bg-red-900 text-white py-2 px-4 rounded"
                        onClick={() => removeOrientador(index)}
                      >
                        Remover orientador
                      </button>
                    )}
                  </form>
                ))}
                <div className="text-center mt-4">
                  <button
                    type="button"
                    className="bg-fful hover:bg-iconhover text-white py-2 px-4 rounded"
                    onClick={addOrientador}
                  >
                    Adicionar outro orientador
                  </button>
                </div>
              </div>
              {/* Bloco */}
              <div className="w-full max-w-3xl mx-auto mb-10">
                <h2 className="text-xl font-bold mb-4 text-center text-fful">
                  Bloco(s) da Vaga de Hospital Disponível
                </h2>
                {blocos.map((bloco, index) => (
                  <form key={index} className="space-y-4 mb-8">
                    <h3 className="text-lg font-semibold text-fful">
                      Bloco {index + 1}
                    </h3>
                    <div>
                      <label
                        htmlFor={`blocoH-${index}`}
                        className="block text-lg font-medium mb-2 text-fful"
                      >
                        Bloco
                      </label>
                      <Select
                        isSearchable
                        isClearable
                        name={`idBlocoH-${index}`}
                        options={getAvailableBlocos(index).map((blocoH) => ({
                          value: blocoH.idBlocoH,
                          label: `Bloco: ${blocoH.bloco} | Início: ${blocoH.dataInicio} | Fim: ${blocoH.dataFim}`,
                        }))}
                        onChange={(option) => handleChangeBlocoH(index, option)}
                        value={
                          blocosH
                            .filter(
                              (blocoH) => blocoH.idBlocoH === bloco.idBlocoH
                            )
                            .map((blocoH) => ({
                              value: blocoH.idBlocoH,
                              label: `Bloco: ${blocoH.bloco} | Início: ${blocoH.dataInicio} | Fim: ${blocoH.dataFim}`,
                            }))[0]
                        }
                        styles={{
                          control: (base, state) => ({
                            ...base,
                            borderColor: errorsBlocoData[index]?.idBlocoH
                              ? "red"
                              : state.isFocused || state.isSelected
                              ? "green"
                              : base.borderColor,
                            boxShadow: errorsBlocoData[index]?.idBlocoH
                              ? "0 0 0 1px red"
                              : state.isFocused
                              ? "0 0 0 1px green"
                              : base.boxShadow,
                          }),
                        }}
                      />
                      {errorsBlocoData[index]?.idBlocoH && (
                        <p className="text-red-500 mt-1">
                          {errorsBlocoData[index].idBlocoH}
                        </p>
                      )}
                    </div>
                    <div>
                      <label
                        htmlFor={`numVagas-${index}`}
                        className="block text-lg font-medium mb-2 text-fful"
                      >
                        Número de Vagas
                      </label>
                      <select
                        id={`numVagas-${index}`}
                        value={numVagas[index] || 1}
                        onChange={(e) =>
                          handleNumVagasChange(index, e.target.value)
                        }
                        className="border border-gray-300 rounded px-3 py-2 w-full"
                      >
                        {[1, 2, 3, 4].map((num) => (
                          <option key={num} value={num}>
                            {num}
                          </option>
                        ))}
                      </select>
                    </div>
                    {blocos.length > 1 && (
                      <button
                        type="button"
                        className="bg-red-700 hover:bg-red-900 text-white py-2 px-4 rounded"
                        onClick={() => removeBloco(index)}
                      >
                        Remover bloco
                      </button>
                    )}
                  </form>
                ))}
                <div className="text-center mt-4">
                  <button
                    type="button"
                    className="bg-fful hover:bg-iconhover text-white py-2 px-4 rounded"
                    onClick={addBloco}
                  >
                    Adicionar outro bloco
                  </button>
                </div>
              </div>
              <div className="text-center mt-20 mb-14">
                <button
                  type="submit"
                  onClick={handleSubmit}
                  disabled={!isFormValid()}
                  className={
                    !isFormValid()
                      ? "text-white px-4 py-2 rounded-md bg-gray-400 cursor-not-allowed font-bold"
                      : "text-white px-4 py-2 rounded-md bg-fful hover:bg-iconhover font-bold"
                  }
                >
                  Submeter Formulário
                </button>
              </div>
            </div>
          )}
        </div>
      )}

      {/* Success Modal */}
      <Modal
        show={showSuccessModal}
        onHide={() => setShowSuccessModal(false)}
        centered
      >
        <Modal.Body className="text-center py-5">
          <p className="mb-4" style={{ fontSize: "1.5rem" }}>
            Formulário enviado com sucesso.
          </p>
          <button
            className="bg-fful hover:bg-iconhover text-white py-2 px-4 rounded"
            onClick={handleCloseModal}
          >
            Fechar
          </button>
        </Modal.Body>
      </Modal>
    </div>
  );
}
