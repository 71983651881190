import { useKeycloak } from "@react-keycloak/web";

const PrivateRoute = ({ children }) => {
  const { keycloak } = useKeycloak();

  const isLoggedIn = keycloak.authenticated;

  return isLoggedIn ? children : null;
};

const NucleoProjetosRoute = ({ children }) => {
  const { keycloak } = useKeycloak();

  const isLoggedIn = keycloak.authenticated;

  return (isLoggedIn && keycloak.hasRealmRole("00_Nucleo_Estagios")) ||
    keycloak.hasRealmRole("00_Informatica")
    ? children
    : null;
};

const NitRoute = ({ children }) => {
  const { keycloak } = useKeycloak();

  const isLoggedIn = keycloak.authenticated;

  return isLoggedIn && keycloak.hasRealmRole("00_Informatica")
    ? children
    : null;
};

const DocenteRoute = ({ children }) => {
  const { keycloak } = useKeycloak();

  const isLoggedIn = keycloak.authenticated;

  return isLoggedIn &&
    (keycloak.hasRealmRole("00_PresidenteJuriNe") ||
      keycloak.hasRealmRole("00_Nucleo_Estagios"))
    ? children
    : null;
};

export { PrivateRoute, NucleoProjetosRoute, DocenteRoute, NitRoute };
