import React, { useState, useEffect } from "react";
import { FaTrash } from "react-icons/fa";
import { Modal, Button } from "react-bootstrap";
import ScrollToTopButton from "../Utils/ScrollToTopButton";
import EditOrientadorFarmacia from "../Edit/EditOrientadorFarmacia";
import CreateOrientadorFarmacia from "../Create/CreateOrientadorFarmacia";
import { MdEmail } from "react-icons/md";

function GerirOrientadoresFarmacia({ anoLetivo }) {
  const [orientadoresData, setOrientadoresData] = useState([]);
  const [farmaciasData, setFarmaciasData] = useState([]);
  const [selectedOrientador, setSelectedOrientador] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showDuplicatesModal, setShowDuplicatesModal] = useState(false);
  const [duplicatesList, setDuplicatesList] = useState([]);

  const apiOrientadorFarmacia = process.env.REACT_APP_API_ORIENTADOR_FARMACIA;
  const apiFarmacias = process.env.REACT_APP_API_FARMACIAS;

  useEffect(() => {
    const fetchData = () => {
      Promise.all([
        fetch(`${apiOrientadorFarmacia}/anoLetivo/${anoLetivo}`),
        fetch(`${apiFarmacias}`),
      ])
        .then((responses) => Promise.all(responses.map((res) => res.json())))
        .then(([orientadoresData, farmaciasData]) => {
          setOrientadoresData(orientadoresData);
          setFarmaciasData(farmaciasData);
        });
    };

    fetchData();
  }, []);

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredOrientadores = orientadoresData.filter((orientador) => {
    const farmacia =
      Array.isArray(farmaciasData) &&
      farmaciasData.find((f) => f.idFarmacia === orientador.idFarmacia);
    const searchTermLowerCase = removeAccents(searchTerm.toLowerCase());
    const nomeLowerCase = removeAccents(orientador.nome?.toLowerCase() ?? "");
    const emailLowerCase = removeAccents(orientador.email?.toLowerCase() ?? "");
    const obsLowerCase = removeAccents(orientador.obs?.toLowerCase() ?? "");
    const telemovelStr = orientador.telemovel?.toString() ?? "";
    const ordemStr = orientador.numOrdem?.toString() ?? "";
    const idStr = orientador.idOrientadorF?.toString() ?? "";
    const farmaciaNomeLowerCase = farmacia
      ? removeAccents(farmacia.nome.toLowerCase())
      : "";

    const matchesSearchTerm =
      idStr.includes(searchTerm) ||
      nomeLowerCase.includes(searchTermLowerCase) ||
      emailLowerCase.includes(searchTermLowerCase) ||
      telemovelStr.includes(searchTerm) ||
      ordemStr.includes(searchTerm) ||
      obsLowerCase.includes(searchTermLowerCase) ||
      farmaciaNomeLowerCase.includes(searchTermLowerCase);

    return matchesSearchTerm;
  });

  //   return Array.isArray(filtered) ? filtered : [filtered];
  // };

  // const filteredOrientadoresArray = Array.isArray(filteredOrientadores())
  //   ? filteredOrientadores()
  //   : [];

  function removeAccents(str) {
    const accents = "ÀÁÂÃÄÅàáâãäåÒÓÔÕÖØòóôõöøÈÉÊËèéêëÇçÌÍÎÏìíîïÙÚÛÜùúûüÿÑñ";
    const accentsOut = "AAAAAAaaaaaaOOOOOOooooooEEEEeeeeCcIIIIiiiiUUUUuuuuyNn";
    return str
      .split("")
      .map((letter) => {
        const accentIndex = accents.indexOf(letter);
        return accentIndex !== -1 ? accentsOut[accentIndex] : letter;
      })
      .join("");
  }

  const handleDelete = (orientador) => {
    setSelectedOrientador(orientador);
    setShowConfirmationModal(true);
  };

  const handleConfirmDelete = () => {
    fetch(`${apiOrientadorFarmacia}/${selectedOrientador.idOrientadorF}`, {
      method: "DELETE",
    })
      .then((response) => {
        setShowConfirmationModal(false);
        setSelectedOrientador(null);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
      })
      .catch((error) => {
        console.error("Error deleting orientador: ", error);
      });
  };

  const handleCancelDelete = () => {
    setSelectedOrientador(null);
    setShowConfirmationModal(false);
  };

  const checkForDuplicateOrientadores = () => {
    const numOrdemMap = new Map();
    const duplicatesSet = new Set();
    const duplicates = [];

    orientadoresData.forEach((orientador) => {
      const farmacia = farmaciasData.find(
        (f) => f.idFarmacia === orientador.idFarmacia
      );
      if (orientador.numOrdem) {
        if (numOrdemMap.has(orientador.numOrdem)) {
          const existingEntry = numOrdemMap.get(orientador.numOrdem);
          const existingFarmacia = farmaciasData.find(
            (f) => f.idFarmacia === existingEntry.idFarmacia
          );

          const id1 = `${existingEntry.nome}-${existingFarmacia?.nome}`;
          const id2 = `${orientador.nome}-${farmacia?.nome}`;

          if (!duplicatesSet.has(id1) && !duplicatesSet.has(id2)) {
            duplicates.push({
              numOrdem: orientador.numOrdem,
              orientador1: {
                nome: existingEntry.nome,
                farmacia: existingFarmacia?.nome || "Unknown Farmacia",
              },
              orientador2: {
                nome: orientador.nome,
                farmacia: farmacia?.nome || "Unknown Farmacia",
              },
            });
            duplicatesSet.add(id1);
            duplicatesSet.add(id2);
          }
        } else {
          numOrdemMap.set(orientador.numOrdem, orientador);
        }
      }
    });

    setDuplicatesList(duplicates);
    setShowDuplicatesModal(true);
  };

  return (
    <div className="flex flex-col items-stretch min-h-full">
      <main className="flex-grow p-4">
        <div>
          <input
            type="text"
            placeholder="Pesquisar"
            value={searchTerm}
            onChange={handleSearchTermChange}
            className="border border-gray-400 rounded px-2 py-1 mb-3"
          />
        </div>
        <div className="flex items-center mb-4">
          <CreateOrientadorFarmacia
            anoLetivo={anoLetivo}
            farmacias={farmaciasData}
          />
        </div>
        <div className="flex items-center mb-4">
          <button
            onClick={checkForDuplicateOrientadores}
            className="text-purple-700 mx-1 flex items-center"
          >
            Verificar Duplicados
          </button>
        </div>
        <div>
          <Modal show={showConfirmationModal} centered>
            <Modal.Body>
              Tem a certeza que pretende eliminar o orientador?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={handleConfirmDelete}>
                Sim
              </Button>
              <Button variant="secondary" onClick={handleCancelDelete}>
                Não
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        <div>
          <Modal
            show={showDuplicatesModal}
            onHide={() => setShowDuplicatesModal(false)}
            centered
          >
            <Modal.Header>
              <Modal.Title>Orientadores Duplicados</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {duplicatesList.length > 0 ? (
                duplicatesList.map((dup, index) => (
                  <div key={index} className="mb-4">
                    <p className="font-bold">Nº de Ordem: {dup.numOrdem}</p>
                    <p>
                      • {dup.orientador1.nome} da Farmácia{" "}
                      {dup.orientador1.farmacia}
                    </p>
                    {/* <p>
                      • {dup.orientador2.nome} em {dup.orientador2.farmacia}
                    </p> */}
                  </div>
                ))
              ) : (
                <p>Não foram encontrados orientadores duplicados.</p>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowDuplicatesModal(false)}
              >
                Fechar
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        <div></div>
        <table className="table-auto w-full" id="orientadores">
          <thead>
            <tr>
              <th className="px-4 py-2">Nome</th>
              <th className="px-4 py-2">Nº de Ordem</th>
              <th className="px-4 py-2">Farmácia</th>
              <th className="px-4 py-2">Observações</th>
              <th className="px-4 py-2">Ações</th>
            </tr>
          </thead>
          <tbody>
            {filteredOrientadores.map((orientador) => {
              const farmacia =
                Array.isArray(farmaciasData) &&
                farmaciasData.find(
                  (f) => f.idFarmacia === orientador.idFarmacia
                );
              return (
                <tr key={orientador.idOrientadorF}>
                  <td className="border px-4 py-2">{orientador.nome}</td>
                  <td className="border px-4 py-2">{orientador.numOrdem}</td>
                  <td className="border px-4 py-2">
                    {farmacia ? farmacia.nome : ""}
                  </td>
                  <td className="border px-4 py-2">{orientador.obs}</td>
                  <td className="border px-4 py-2">
                    <div>
                      <EditOrientadorFarmacia
                        orientadorData={orientador}
                        farmacias={farmaciasData}
                      />
                      <button
                        className="text-red-700 mx-1"
                        title="Excluir"
                        onClick={() => handleDelete(orientador)}
                      >
                        <FaTrash />
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <ScrollToTopButton targetId="orientadores" />
      </main>
    </div>
  );
}

export default GerirOrientadoresFarmacia;
