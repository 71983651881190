export default function Header() {
  return (
    <div className="flex justify-start items-center m-0">
      <img
        src="/images/fful-header-logo.png"
        className="h-[15ch] m-0"
        alt="Header Logo"
      />
      <h1 className="pl-[2rem] m-0">FFUL</h1>
    </div>
  );
}
