import React, { useState, useEffect } from "react";
import { FaTrash } from "react-icons/fa";
import { Modal, Button } from "react-bootstrap";
import CreateVagaFarmacia from "../Create/CreateVagaFarmacia";
import EditVagaFarmacia from "../Edit/EditVagaFarmacia";
import ScrollToTopButton from "../Utils/ScrollToTopButton";

function GerirVagasFarmacias(anoLetivo) {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedVaga, setSelectedVaga] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [vagasFarmacias, setVagasFarmacias] = useState([]);
  const [farmacias, setFarmacias] = useState([]);
  const [alunos, setAlunos] = useState([]);
  const [blocosF, setBlocosF] = useState([]);

  const apiVagasFarmacias = process.env.REACT_APP_API_VAGASFARMACIAS;
  const apiFarmacias = process.env.REACT_APP_API_FARMACIAS;
  const apiBlocoF = process.env.REACT_APP_API_BLOCOF;
  const apiAlunos = process.env.REACT_APP_API_ALUNOS;

  useEffect(() => {
    const fetchData = () => {
      const year = anoLetivo?.anoLetivo || anoLetivo?.anoLetivo?.anoLetivo;
      Promise.all([
        fetch(`${apiVagasFarmacias}/anoLetivo/${year}`),
        fetch(`${apiAlunos}/anoLetivo/${year}`),
        fetch(`${apiBlocoF}/anoLetivo/${year}`),
        fetch(`${apiFarmacias}`),
      ])
        .then((responses) => Promise.all(responses.map((res) => res.json())))
        .then(
          ([vagasFarmaciasData, alunosData, blocosFData, farmaciasData]) => {
            setVagasFarmacias(vagasFarmaciasData);
            setFarmacias(farmaciasData);
            setAlunos(alunosData);
            setBlocosF(blocosFData);
          }
        );
    };

    // Fetch the data immediately
    fetchData();

    // Then fetch the data every 2 minutes
    const intervalId = setInterval(fetchData, 2 * 60 * 1000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [apiVagasFarmacias, apiFarmacias, apiAlunos, apiBlocoF]);

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredVagasFarmacias = vagasFarmacias.filter((item) => {
    const farmacia =
      Array.isArray(farmacias) &&
      farmacias.find((f) => f.idFarmacia === item.idFarmacia);
    const blocoF =
      Array.isArray(blocosF) &&
      blocosF.find((b) => b.idBlocoF === item.idBlocoF);
    const aluno =
      Array.isArray(alunos) && alunos.find((a) => a.idAluno === item.idAluno);
    const searchTermLowerCase = removeAccents(searchTerm.toLowerCase());
    const farmaciaNomeLowerCase = farmacia
      ? removeAccents(farmacia.nome.toLowerCase())
      : "";
    const farmaciaMoradaLowerCase = farmacia
      ? removeAccents(farmacia.morada.toLowerCase())
      : "";
    const blocoFLowerCase = blocoF
      ? removeAccents(blocoF.bloco.toLowerCase())
      : "";
    const alunoNumLowerCase = aluno ? aluno.numAluno : "";
    const alunoNomeLowerCase = aluno
      ? removeAccents(aluno.nome.toLowerCase())
      : "";
    const obsLowerCase = removeAccents(item.obs.toLowerCase() || "");
    const matchesSearchTerm =
      item.idVagaFarmacia.toString().includes(searchTerm) ||
      farmaciaNomeLowerCase.includes(searchTermLowerCase) ||
      farmaciaMoradaLowerCase.includes(searchTermLowerCase) ||
      blocoFLowerCase.includes(searchTermLowerCase) ||
      alunoNumLowerCase.toString().includes(searchTerm) ||
      alunoNomeLowerCase.includes(searchTermLowerCase) ||
      obsLowerCase.includes(searchTermLowerCase);
    return matchesSearchTerm;
  });

  // Function to remove accents from a string
  function removeAccents(str) {
    const accents = "ÀÁÂÃÄÅàáâãäåÒÓÔÕÖØòóôõöøÈÉÊËèéêëÇçÌÍÎÏìíîïÙÚÛÜùúûüÿÑñ";
    const accentsOut = "AAAAAAaaaaaaOOOOOOooooooEEEEeeeeCcIIIIiiiiUUUUuuuuyNn";
    return str
      .split("")
      .map((letter) => {
        const accentIndex = accents.indexOf(letter);
        return accentIndex !== -1 ? accentsOut[accentIndex] : letter;
      })
      .join("");
  }

  const handleDelete = (item) => {
    setSelectedVaga(item);
    setShowConfirmationModal(true);
  };

  const handleConfirmDelete = () => {
    fetch(`${apiVagasFarmacias}/${selectedVaga.idVagaFarmacia}`, {
      method: "DELETE",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error deleting vaga");
        }
      })
      .then(() => {
        setVagasFarmacias((prevVagasFarmacias) =>
          prevVagasFarmacias.filter(
            (vaga) => vaga.idVagaFarmacia !== selectedVaga.idVagaFarmacia
          )
        );
        setSelectedVaga(null);
        setShowConfirmationModal(false);
      })
      .catch((error) => {
        console.error("Error deleting vaga: ", error);
        // Handle the error condition
      });
  };

  const handleCancelDelete = () => {
    setSelectedVaga(null); // Limpar o trabalho selecionado
    setShowConfirmationModal(false); // Fechar a janela de confirmação
  };

  return (
    <div className="flex flex-col items-stretch min-h-full">
      <main className="flex-grow p-4">
        <div>
          <input
            type="text"
            placeholder="Pesquisar"
            value={searchTerm}
            onChange={handleSearchTermChange}
            className="border border-gray-400 rounded px-2 py-1 mb-3"
          />
        </div>
        <div className="flex items-center mb-4">
          <CreateVagaFarmacia
            key={anoLetivo}
            anoLetivo={anoLetivo}
            farmacias={farmacias}
            alunos={alunos}
            blocosF={blocosF}
          />
        </div>
        <div>
          <Modal show={showConfirmationModal} centered>
            <Modal.Body>Tem a certeza que pretende eliminar a vaga?</Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={handleConfirmDelete}>
                Sim
              </Button>
              <Button variant="secondary" onClick={handleCancelDelete}>
                Não
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        <table className="table-auto w-full" id="vagasFarmacias">
          <thead>
            <tr>
              <th className="px-4 py-2">ID da Vaga</th>
              <th className="px-4 py-2">Farmácia</th>
              <th className="px-4 py-2">Código Postal</th>
              <th className="px-4 py-2">Disponível</th>
              <th className="px-4 py-2">Bloco</th>
              <th className="px-4 py-2">Data Início</th>
              <th className="px-4 py-2">Data Fim</th>
              <th className="px-4 py-2">Número Aluno</th>
              <th className="px-4 py-2">Nome</th>
              <th className="px-4 py-2">Observações</th>
              <th className="px-4 py-2">Ações</th>
            </tr>
          </thead>
          <tbody>
            {filteredVagasFarmacias.map((item) => {
              const farmacia =
                Array.isArray(farmacias) &&
                farmacias.find((f) => f.idFarmacia === item.idFarmacia);
              const aluno =
                Array.isArray(alunos) &&
                alunos.find((a) => a.idAluno === item.idAluno);
              const blocoF =
                Array.isArray(blocosF) &&
                blocosF.find((b) => b.idBlocoF === item.idBlocoF);
              const disponivelClass = item.disponivel
                ? "bg-green-200"
                : "bg-red-200";

              return (
                <tr key={item.idVagaFarmacia}>
                  <td className="border px-4 py-2">{item.idVagaFarmacia}</td>
                  <td className="border px-4 py-2">
                    {farmacia ? farmacia.nome : ""}
                  </td>
                  <td className="border px-4 py-2">
                    {farmacia ? farmacia.codPostal : ""}
                  </td>
                  <td className={`border px-4 py-2 ${disponivelClass}`}></td>
                  <td className="border px-4 py-2">
                    {blocoF ? blocoF.bloco : ""}
                  </td>
                  <td className="border px-4 py-2">
                    {blocoF
                      ? new Date(blocoF.dataInicio).toLocaleDateString()
                      : ""}
                  </td>
                  <td className="border px-4 py-2">
                    {blocoF
                      ? new Date(blocoF.dataFim).toLocaleDateString()
                      : ""}
                  </td>
                  <td className="border px-4 py-2">
                    {aluno ? aluno.numAluno : ""}
                  </td>
                  <td className="border px-4 py-2">
                    {aluno ? aluno.nome : ""}
                  </td>
                  <td className="border px-4 py-2">{item.obs}</td>
                  <td className="border px-4 py-2">
                    <div>
                      <EditVagaFarmacia
                        data={item}
                        farmacias={farmacias}
                        alunos={alunos}
                        blocosF={blocosF}
                      />
                      <button
                        className="text-red-700 mx-1"
                        title="Excluir"
                        onClick={() => handleDelete(item)}
                      >
                        <FaTrash />
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <ScrollToTopButton targetId="vagasFarmacias" />
      </main>
    </div>
  );
}

export default GerirVagasFarmacias;
