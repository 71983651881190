import React, { useState, useEffect, useCallback } from "react";
import ReactPaginate from "react-paginate";
import { useKeycloak } from "@react-keycloak/web";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { FaFileExcel, FaFilePdf } from "react-icons/fa";
import ScrollToTopButton from "../Utils/ScrollToTopButton";

function ShowVagasHospitais(editedAlunoData) {
  const [vagasHospitais, setVagasHospitais] = useState([]);
  const [hospitais, setHospitais] = useState([]);
  const [alunos, setAlunos] = useState([]);
  const [blocosH, setBlocosH] = useState([]);
  const [pageCount, setPageCount] = useState(2);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(2000);
  const [searchTerm, setSearchTerm] = useState("");
  const [showDisponiveis, setShowDisponiveis] = useState(true);
  const [showIndisponiveis, setShowIndisponiveis] = useState(true);
  const [vagaHospitalData, setVagaHospitalData] = useState("");
  const [tableData, setTableData] = useState([]);
  const [reloadData, setReloadData] = useState(0);
  const [showExportar, setShowExportar] = useState(false);

  const apiVagasHospitais = process.env.REACT_APP_API_VAGASHOSPITAIS;
  const apiHospitais = process.env.REACT_APP_API_HOSPITAIS;
  const apiBlocoH = process.env.REACT_APP_API_BLOCOH;
  const apiAlunos = process.env.REACT_APP_API_ALUNOS;

  // const fetchData = useCallback(() => {
  //   fetch(`${apiVagasHospitais}?page=${currentPage}&size=${itemsPerPage}`)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setVagasHospitais(data.content);
  //       setPageCount(data.totalPages);
  //       const hospitalIds = data.content.map((item) => item.idHospital);
  //       const alunoIds = data.content.map((item) => item.idAluno);
  //       const blocoHIds = data.content.map((item) => item.idBlocoH);
  //       Promise.all([
  //         fetch(`${apiHospitais}?ids=${hospitalIds.join(",")}`),
  //         fetch(`${apiAlunos}?ids=${alunoIds.join(",")}`),
  //         fetch(`${apiBlocoH}?ids=${blocoHIds.join(",")}`),
  //       ])
  //         .then((responses) => Promise.all(responses.map((res) => res.json())))
  //         .then(([hospitais, alunos, blocosH]) => {
  //           setHospitais(hospitais);
  //           setAlunos(alunos);
  //           setBlocosH(blocosH);
  //         });
  //     });
  // }, [
  //   apiVagasHospitais,
  //   apiHospitais,
  //   apiAlunos,
  //   apiBlocoH,
  //   currentPage,
  //   itemsPerPage,
  // ]);

  // useEffect(() => {
  //   fetchData();
  // }, [fetchData]);

  function getCurrentAcademicYear() {
    const today = new Date();
    const currentYear = today.getFullYear();
    const academicYearStartMonth = 8; // September (0-based index)
    if (today.getMonth() >= academicYearStartMonth) {
      return `${currentYear}-${currentYear + 1}`;
    } else {
      return `${currentYear - 1}-${currentYear}`;
    }
  }

  useEffect(() => {
    const fetchData = () => {
      const year =
        editedAlunoData?.anoLetivo ||
        editedAlunoData?.editedAlunoData?.anoLetivo ||
        getCurrentAcademicYear();
      Promise.all([
        fetch(`${apiVagasHospitais}/anoLetivo/${year}`),
        fetch(`${apiAlunos}/anoLetivo/${year}`),
        fetch(`${apiBlocoH}/anoLetivo/${year}`),
        fetch(`${apiHospitais}`),
      ])
        .then((responses) => Promise.all(responses.map((res) => res.json())))
        .then(
          ([vagasHospitaisData, alunosData, blocosHData, hospitaisData]) => {
            setVagasHospitais(vagasHospitaisData);
            setHospitais(hospitaisData);
            setAlunos(alunosData);
            setBlocosH(blocosHData);
          }
        );
    };

    // Fetch the data immediately
    fetchData();

    // Then fetch the data every 2 minutes
    const intervalId = setInterval(fetchData, 2 * 60 * 1000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [
    apiVagasHospitais,
    apiHospitais,
    apiAlunos,
    apiBlocoH,
    currentPage,
    itemsPerPage,
    reloadData,
  ]);

  // const fetchData = useEffect(() => {
  //   fetch(`${apiVagasHospitais}/anoLetivo/${getCurrentAcademicYear()}`)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setVagasHospitais(data);
  //       const hospitalIds = data.map((item) => item.idHospital);
  //       const alunoIds = data.map((item) => item.idAluno);
  //       const blocoHIds = data.map((item) => item.idBlocoH);
  //       Promise.all([
  //         fetch(`${apiHospitais}?ids=${hospitalIds.join(",")}`),
  //         fetch(`${apiAlunos}?ids=${alunoIds.join(",")}`),
  //         fetch(`${apiBlocoH}?ids=${blocoHIds.join(",")}`),
  //       ])
  //         .then((responses) => Promise.all(responses.map((res) => res.json())))
  //         .then(([hospitais, alunos, blocosH]) => {
  //           setHospitais(hospitais);
  //           setAlunos(alunos);
  //           setBlocosH(blocosH);
  //         });
  //     });
  // }, [
  //   apiVagasHospitais,
  //   apiHospitais,
  //   apiAlunos,
  //   apiBlocoH,
  //   currentPage,
  //   itemsPerPage,
  // ]);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleShowDisponiveisChange = (event) => {
    setShowDisponiveis(event.target.checked);
  };

  const handleShowIndisponiveisChange = (event) => {
    setShowIndisponiveis(event.target.checked);
  };

  function removeAccents(str) {
    const accents = "ÀÁÂÃÄÅàáâãäåÒÓÔÕÖØòóôõöøÈÉÊËèéêëÇçÌÍÎÏìíîïÙÚÛÜùúûüÿÑñ";
    const accentsOut = "AAAAAAaaaaaaOOOOOOooooooEEEEeeeeCcIIIIiiiiUUUUuuuuyNn";
    return str
      .split("")
      .map((letter) => {
        const accentIndex = accents.indexOf(letter);
        return accentIndex !== -1 ? accentsOut[accentIndex] : letter;
      })
      .join("");
  }

  useEffect(() => {
    const filteredVagasHospitais = vagasHospitais
      .filter((item) => {
        const hospital =
          Array.isArray(hospitais) &&
          hospitais.find((f) => f.idHospital === item.idHospital);
        const blocoH =
          Array.isArray(blocosH) &&
          blocosH.find((b) => b.idBlocoH === item.idBlocoH);
        const aluno =
          Array.isArray(alunos) &&
          alunos.find((a) => a.idAluno === item.idAluno);
        const disponivel = item.disponivel;
        const searchTermLowerCase = removeAccents(searchTerm.toLowerCase());
        const hospitalNomeLowerCase = hospital
          ? removeAccents(hospital?.nome.toLowerCase())
          : "";
        const hospitalMoradaLowerCase = hospital
          ? removeAccents(hospital?.morada.toLowerCase())
          : "";
        const blocoHLowerCase = blocoH
          ? removeAccents(blocoH?.bloco.toLowerCase())
          : "";
        const alunoNumLowerCase = aluno?.numAluno || "";
        const alunoNomeLowerCase = aluno
          ? removeAccents(aluno.nome.toLowerCase())
          : "";
        const obsLowerCase = removeAccents(item.obs.toLowerCase() || "");
        const matchesSearchTerm =
          item.idVagaHospital.toString().includes(searchTerm) ||
          hospitalNomeLowerCase.includes(searchTermLowerCase) ||
          hospitalMoradaLowerCase.includes(searchTermLowerCase) ||
          blocoHLowerCase.includes(searchTermLowerCase) ||
          alunoNumLowerCase.toString().includes(searchTerm) ||
          alunoNomeLowerCase.includes(searchTermLowerCase) ||
          obsLowerCase.includes(searchTermLowerCase);
        return (
          ((disponivel && showDisponiveis) ||
            (!disponivel && showIndisponiveis)) &&
          matchesSearchTerm
        );
      })
      .map((item) => {
        const hospital =
          Array.isArray(hospitais) &&
          hospitais.find((f) => f.idHospital === item.idHospital);
        const aluno =
          Array.isArray(alunos) &&
          alunos.find((a) => a.idAluno === item.idAluno);
        const blocoH =
          Array.isArray(blocosH) &&
          blocosH.find((b) => b.idBlocoH === item.idBlocoH);

        return {
          Id: item.idVagaHospital,
          NomeHospital: hospital ? hospital.nome : "",
          CodigoPostal: hospital ? hospital.codPostal : "",
          Disponivel: item.disponivel,
          NumeroAluno: aluno ? aluno.numAluno : "",
          NomeAluno: aluno ? aluno.nome : "",
          Bloco: blocoH ? blocoH.bloco : "",
          DataInicio: blocoH
            ? new Date(blocoH.dataInicio).toLocaleDateString()
            : "",
          DataFim: blocoH ? new Date(blocoH.dataFim).toLocaleDateString() : "",
        };
      });

    setTableData(filteredVagasHospitais);
  }, [
    vagasHospitais,
    hospitais,
    alunos,
    blocosH,
    searchTerm,
    showDisponiveis,
    showIndisponiveis,
  ]);

  const filteredVagasHospitais = vagasHospitais.filter((item) => {
    const hospital =
      Array.isArray(hospitais) &&
      hospitais.find((f) => f.idHospital === item.idHospital);
    const blocoH =
      Array.isArray(blocosH) &&
      blocosH.find((b) => b.idBlocoH === item.idBlocoH);
    const aluno =
      Array.isArray(alunos) && alunos.find((a) => a.idAluno === item.idAluno);
    const disponivel = item.disponivel;
    const searchTermLowerCase = removeAccents(searchTerm.toLowerCase());
    const hospitalNomeLowerCase = hospital
      ? removeAccents(hospital?.nome.toLowerCase())
      : "";
    const hospitalMoradaLowerCase = hospital
      ? removeAccents(hospital?.morada.toLowerCase())
      : "";
    const blocoHLowerCase = blocoH
      ? removeAccents(blocoH?.bloco.toLowerCase())
      : "";
    const alunoNumLowerCase = aluno?.numAluno || "";
    const alunoNomeLowerCase = aluno
      ? removeAccents(aluno.nome.toLowerCase())
      : "";
    const obsLowerCase = removeAccents(item.obs.toLowerCase() || "");
    const matchesSearchTerm =
      item.idVagaHospital.toString().includes(searchTerm) ||
      hospitalNomeLowerCase.includes(searchTermLowerCase) ||
      hospitalMoradaLowerCase.includes(searchTermLowerCase) ||
      blocoHLowerCase.includes(searchTermLowerCase) ||
      alunoNumLowerCase.toString().includes(searchTerm) ||
      alunoNomeLowerCase.includes(searchTermLowerCase) ||
      obsLowerCase.includes(searchTermLowerCase);
    return (
      ((disponivel && showDisponiveis) || (!disponivel && showIndisponiveis)) &&
      matchesSearchTerm
    );
  });

  const handleSearchVagaHospitalById = (id) => {
    fetch(`${apiVagasHospitais}/${id}`)
      .then((response) => response.json())
      .then((data) => {
        const vagaHospital = data;
        if (vagaHospital) {
          setVagaHospitalData(vagaHospital);
          const hospitalIds = vagaHospital.idHospital;
          const alunoIds = vagaHospital.idAluno;
          const blocoHIds = vagaHospital.idBlocoH;
          Promise.all([
            hospitalIds ? fetch(`${apiHospitais}/${hospitalIds}`) : "",
            alunoIds ? fetch(`${apiAlunos}/${alunoIds}`) : "",
            blocoHIds ? fetch(`${apiBlocoH}/${blocoHIds}`) : "",
          ])
            .then((responses) =>
              Promise.all(responses.map((res) => res && res.json()))
            )
            .then(([hospital, aluno, blocoH]) => {
              let updatedIdAluno = vagaHospital.idAluno;
              let updatedDisponivel = vagaHospital.disponivel;

              const hasVagaHospital = vagasHospitais.some(
                (vaga) =>
                  vaga.idAluno === editedAlunoData.editedAlunoData.idAluno
              );
              if (
                vagaHospital.idAluno === editedAlunoData.editedAlunoData.idAluno
              ) {
                updatedIdAluno = null;
                updatedDisponivel = true;
              } else if (hasVagaHospital) {
                alert("Este Aluno já tem uma Vaga de Hospital atribuída");
                return;
              } else if (vagaHospital.idAluno === null) {
                updatedIdAluno = editedAlunoData.editedAlunoData.idAluno;
                updatedDisponivel = false;
              } else if (
                vagaHospital.idAluno !== null &&
                vagaHospital.idAluno !== editedAlunoData.editedAlunoData.idAluno
              ) {
                alert("Vaga já ocupada por outro aluno!");
                return;
              }
              const updatedVagaHospital = {
                ...data,
                idAluno: updatedIdAluno,
                disponivel: updatedDisponivel,
              };
              handleEditVagaHospital(updatedVagaHospital);
              setHospitais(hospital);
              setAlunos(aluno);
              setBlocosH(blocoH);
            });
        }
      })
      .catch((error) => console.log(error));
  };

  const handleEditVagaHospital = (updatedVagaHospital) => {
    fetch(`${apiVagasHospitais}/${updatedVagaHospital.idVagaHospital}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedVagaHospital),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.text();
      })
      .then((data) => {
        const hospital =
          Array.isArray(hospitais) &&
          hospitais.find(
            (f) => f.idHospital === updatedVagaHospital.idHospital
          );
        const blocoH =
          Array.isArray(blocosH) &&
          blocosH.find((b) => b.idBlocoH === updatedVagaHospital.idBlocoH);
        setReloadData(reloadData + 1);
        editedAlunoData.onChoiceMade(
          updatedVagaHospital.idAluno ? updatedVagaHospital : null
        );
        editedAlunoData.setHospitalData(
          updatedVagaHospital.idAluno ? hospital : null
        );
        editedAlunoData.setBlocoHData(
          updatedVagaHospital.idAluno ? blocoH : null
        );
      })
      .catch((error) => console.log(error));
  };

  const test = (idVagaHospital) => {
    handleSearchVagaHospitalById(idVagaHospital);
  };

  const exportToExcel = () => {
    const headerData = [
      [
        "Ano Letivo " +
          (editedAlunoData?.anoLetivo ||
            editedAlunoData?.editedAlunoData?.anoLetivo ||
            getCurrentAcademicYear()) +
          " - Vagas de Estágios Curriculares em Farmácias Hospitalares",
      ],
      [new Date().toLocaleDateString()],
    ];
    const columnNames = [Object.keys(tableData[0])];
    const data = tableData.map(Object.values);
    const combinedData = [...headerData, ...columnNames, ...data];

    const ws = XLSX.utils.aoa_to_sheet(combinedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataExcel = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    saveAs(dataExcel, "VagasHospitais.xlsx");
  };

  function getBase64Image(img) {
    let canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    let ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, img.width, img.height);
    let dataURL = canvas.toDataURL("image/png");
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }

  const exportToPDF = () => {
    // Load the image
    let img = new Image();
    img.src = "/images/fful-header-logo.png";
    img.onload = function () {
      let dataURI = getBase64Image(img);
      const doc = new jsPDF("l");
      // Add a colored header
      doc.setFillColor(84, 59, 131); // RGB color for #543b83
      doc.rect(0, 0, doc.internal.pageSize.getWidth(), 30, "F");
      // Add header
      doc.setFontSize(14);
      doc.setTextColor(255, 255, 255);
      let str1 =
        "Ano Letivo " +
        (editedAlunoData?.anoLetivo ||
          editedAlunoData?.editedAlunoData?.anoLetivo ||
          getCurrentAcademicYear()) +
        " - Vagas de Estágios Curriculares em Farmácias Hospitalares";
      let txtWidth =
        (doc.getStringUnitWidth(str1) * doc.internal.getFontSize()) /
        doc.internal.scaleFactor;
      let center = (doc.internal.pageSize.width - txtWidth) / 2;
      doc.text(str1, center, 20);
      doc.setTextColor(0, 0, 0);

      // Add the image to the left of the title
      doc.addImage(dataURI, "PNG", 5, 0, 30, 30); // Adjust position and size as needed

      const tableColumns = [
        "Id Vaga",
        "Hospital",
        "Código Postal",
        "Disponível",
        "Número Aluno",
        "Nome",
        "Bloco",
        "Data Início",
        "Data Fim",
      ];
      const tableRows = tableData.map((item) => [
        item.Id,
        item.NomeHospital,
        item.CodigoPostal,
        item.Disponivel ? "Sim" : "Não",
        item.NumeroAluno,
        item.NomeAluno,
        item.Bloco,
        item.DataInicio,
        item.DataFim,
      ]);

      let totalPagesExp = "{total_pages_count_string}";
      doc.autoTable({
        head: [tableColumns],
        body: tableRows,
        startY: 31,
        styles: { cellWidth: "wrap" },
        headStyles: { fillColor: [84, 59, 131] },
        columnStyles: {
          0: { cellWidth: 20 },
          1: { cellWidth: 50 },
          2: { cellWidth: 28 },
          3: { cellWidth: 22 },
          4: { cellWidth: 20 },
          5: { cellWidth: 40 },
          6: { cellWidth: 20 },
          7: { cellWidth: 30 },
          8: { cellWidth: 30 },
        },
        didDrawPage: (data) => {
          doc.setFontSize(6);

          // Reset font size for body
          doc.setFontSize(8);

          // Add date to the left
          doc.text(
            new Date().toLocaleDateString(),
            data.settings.margin.left,
            doc.internal.pageSize.height - 10
          );

          // Add total number of IDs in the middle
          doc.text(
            "Total de Vagas: " + tableData.length,
            doc.internal.pageSize.width / 2,
            doc.internal.pageSize.height - 10,
            { align: "center" }
          );

          // Add page number to the right
          if (typeof doc.putTotalPages === "function") {
            doc.text(
              "Page " +
                doc.internal.getNumberOfPages() +
                " of " +
                totalPagesExp,
              doc.internal.pageSize.width - data.settings.margin.right,
              doc.internal.pageSize.height - 10,
              { align: "right" }
            );
          }
        },
      });

      if (typeof doc.putTotalPages === "function") {
        doc.putTotalPages(totalPagesExp);
      }

      doc.save("VagasHospitais.pdf");
    };
  };

  const { keycloak } = useKeycloak();

  const showNucleoEstagios = keycloak.hasRealmRole("00_Nucleo_Estagios");

  return (
    <div>
      <div className="flex-grow p-4">
        <h1 className="text-2xl font-bold mb-4">Vagas Hospitais</h1>
        <div className="flex justify-between mb-4">
          <div>
            <div>
              <input
                type="text"
                placeholder="Pesquisar"
                value={searchTerm}
                onChange={handleSearchTermChange}
                className="border border-gray-400 rounded px-2 py-1 mb-3"
              />
            </div>
            <label className="mr-6">
              <input
                type="checkbox"
                checked={showDisponiveis}
                onChange={handleShowDisponiveisChange}
                className="mr-1"
              />
              Disponíveis
            </label>
            <label>
              <input
                type="checkbox"
                checked={showIndisponiveis}
                onChange={handleShowIndisponiveisChange}
                className="mr-1"
              />
              Indisponíveis
            </label>
            {showNucleoEstagios && (
              <div className="py-4">
                <button
                  className="text-fful hover:text-iconhover font-bold rounded"
                  onClick={() => setShowExportar(!showExportar)}
                >
                  {showExportar ? "Fechar Exportar" : "Exportar Vagas"}
                </button>
                {showExportar && (
                  <>
                    <div></div>
                    <button
                      className="text-fful hover:text-iconhover py-4 text-6xl"
                      title="Gerar Excel"
                      onClick={exportToExcel}
                    >
                      <FaFileExcel />
                    </button>
                    <button
                      className="text-fful hover:text-iconhover mx-1 py-4 px-4 text-6xl"
                      title="Gerar PDF"
                      onClick={exportToPDF}
                    >
                      <FaFilePdf />
                    </button>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
        <table className="table-auto w-full" id="vagasHospitais">
          <thead>
            <tr>
              <th className="px-4 py-2">ID da Vaga</th>
              <th className="px-4 py-2">Hospital</th>
              <th className="px-4 py-2">Código Postal</th>
              <th className="px-4 py-2">Disponível</th>
              <th className="px-4 py-2">Bloco</th>
              <th className="px-4 py-2">Data Início</th>
              <th className="px-4 py-2">Data Fim</th>
              <th className="px-4 py-2">Número Aluno</th>
              <th className="px-4 py-2">Nome</th>
              {showNucleoEstagios && <th className="px-4 py-2">Observações</th>}
            </tr>
          </thead>
          <tbody>
            {filteredVagasHospitais.map((item) => {
              const hospital =
                Array.isArray(hospitais) &&
                hospitais.find((f) => f.idHospital === item.idHospital);
              const aluno =
                Array.isArray(alunos) &&
                alunos.find((a) => a.idAluno === item.idAluno);
              const blocoH =
                Array.isArray(blocosH) &&
                blocosH.find((b) => b.idBlocoH === item.idBlocoH);
              const disponivelClass = item.disponivel
                ? "bg-green-200"
                : "bg-red-200";
              return (
                <tr key={item.idVagaHospital}>
                  <td className="border px-4 py-2">{item.idVagaHospital}</td>
                  <td className="border px-4 py-2">
                    {hospital ? hospital.nome : ""}
                  </td>
                  <td className="border px-4 py-2">
                    {hospital ? hospital.codPostal : ""}
                  </td>
                  <td className={`border px-4 py-2 ${disponivelClass}`}></td>
                  <td className="border px-4 py-2">
                    {showNucleoEstagios && (
                      <button
                        onClick={() => test(item.idVagaHospital)}
                        disabled={!editedAlunoData.editedAlunoData}
                      >
                        {blocoH ? blocoH.bloco : ""}
                      </button>
                    )}
                    {!showNucleoEstagios && blocoH ? blocoH.bloco : ""}
                  </td>
                  <td className="border px-4 py-2">
                    {blocoH
                      ? new Date(blocoH.dataInicio).toLocaleDateString()
                      : ""}
                  </td>
                  <td className="border px-4 py-2">
                    {blocoH
                      ? new Date(blocoH.dataFim).toLocaleDateString()
                      : ""}
                  </td>
                  <td className="border px-4 py-2">
                    {aluno ? aluno.numAluno : ""}
                  </td>
                  <td className="border px-4 py-2">
                    {aluno ? aluno.nome : ""}
                  </td>
                  {showNucleoEstagios && (
                    <td className="border px-4 py-2">{item.obs}</td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
        <ScrollToTopButton targetId="vagasHospitais" />
        {/* <ReactPaginate
          pageCount={pageCount}
          onPageChange={handlePageClick}
          containerClassName="flex justify-center my-4"
          pageClassName="mx-2 rounded-full bg-gray-200 px-3 py-2"
          activeClassName="bg-blue-500 text-white"
          previousClassName="mx-2 rounded-full bg-gray-200 px-3 py-2"
          nextClassName="mx-2 rounded-full bg-gray-200 px-3 py-2"
          disabledClassName="opacity-50"
          previousLabel="Anterior"
          nextLabel="Próxima"
        /> */}
      </div>
    </div>
  );
}

export default ShowVagasHospitais;
